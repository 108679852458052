<div
  class="info-body"
  [class.mat-elevation-z1]="true"
  [ngClass]="{ 'preview-body': previewMode }"
>
  <div fxLayout="row" fxLayout="start center">
    <div class="info-icon-layer accent-bg-color accent-contrast-color">
      <mat-icon class="info-icon">{{ icon }}</mat-icon>
    </div>
    <div
      *ngIf="showTitle"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      style="margin-right: 20px; height: 15px"
    >
      <div fxLayout="column">
        <app-identity-link
          [identity]="currentResource"
          [disabled]="true"
          [color]="headlineColor"
          class="info-header accent-color"
        ></app-identity-link>
        <div class="info-footer accent-text-color">
          {{ typeName }}
        </div>
      </div>
    </div>
    <!-- <div fxFlex="auto"></div> -->
    <div
      *ngIf="!previewMode && arrStatus.length > 0"
      fxLayout="row"
      style="max-width: 60%; overflow: hidden"
    >
      <div
        *ngFor="let status of arrStatus"
        fxFlex="none"
        class="status-layer"
        [ngClass]="{
          'primary-bg-color': status.color === 'primary',
          'primary-contrast-color': status.color === 'primary',
          'accent-bg-color': status.color === 'accent',
          'accent-contrast-color': status.color === 'accent',
          'warn-bg-color': status.color === 'warn',
          'white-color': status.color === 'warn'
        }"
      >
        {{ status.text | translate }}
      </div>
    </div>
    <div
      *ngIf="previewMode"
      class="preview-icon-layer accent-bg-color primary-contrast-color"
    >
      {{ 'key_preview' | translate }}
    </div>
  </div>
  <div [ngClass]="{ 'preview-resource-tab': previewMode }">
    <mat-tab-group
      *ngIf="!advancedView"
      #mtg
      class="info-tab"
      animationDuration="0ms"
      [style.marginTop.px]="showTitle ? 40 : 20"
      [disableRipple]="true"
      [selectedIndex]="currentTabIndex"
      (selectedIndexChange)="onTabIndexChange($event)"
    >
      <mat-tab *ngFor="let tabDef of configMode ? tabDefs : tabDefsToShow">
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="auto">
              {{ tabDef.displayName | translate }}
            </div>
            <div
              *ngIf="configMode"
              fxFlex="auto"
              style="margin-left: 10px; margin-top: 5px"
              [matTooltip]="'key_exportToClipboard' | translate"
              (click)="onExportTab(tabDef.name)"
            >
              <span class="material-icons-outlined primary-color"
                >download</span
              >
            </div>
            <div
              *ngIf="configMode"
              fxFlex="auto"
              [hidden]="tabDefs.length === 1"
              style="margin-left: 5px; margin-top: 5px"
              [matTooltip]="'key_delete' | translate"
              (click)="onDeleteTab(tabDef)"
            >
              <span class="material-icons-outlined warn-color">delete</span>
            </div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div style="margin-top: 20px; margin-left: 15px">
            <app-attribute-view
              #attributeView
              [viewType]="'tab'"
              [configMode]="configMode"
              [attributeDefs]="tabDef.attributes"
              [columnNumber]="tabDef.columnNumber"
              [tabName]="tabDef.name"
              [(attributeArray)]="editorResults[tabDef.name]"
              [attributesToLoad]="addtionalTabAttributes"
            ></app-attribute-view>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngIf="addtionalTab1Name && addtionalTab1Dir"
        [label]="addtionalTab1Name"
      >
        <ng-template matTabContent>
          <ng-container
            *ngTemplateOutlet="
              addtionalTab1Tpl;
              context: addtionalTabTplContext
            "
          ></ng-container>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngIf="addtionalTab2Name && addtionalTab2Dir"
        [label]="addtionalTab2Name"
      >
        <ng-template matTabContent>
          <ng-container
            *ngTemplateOutlet="
              addtionalTab2Tpl;
              context: addtionalTabTplContext
            "
          ></ng-container>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="configMode">
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-stroked-button color="primary" (click)="onImportTab()">
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="10px"
              >
                <div>{{ 'key_importFromClipboard' | translate }}</div>
                <span class="material-icons-outlined">upload</span>
              </div>
            </button>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="advancedView" style="margin: 60px 30px 30px 30px">
      <app-attribute-view
        #attributeView
        [simpleMode]="true"
        [viewType]="'tab'"
        [configMode]="configMode"
        [attributeDefs]="advancedAttributeConfig"
        [tabName]="'!advancedView!'"
        [(attributeArray)]="advancedResults"
      ></app-attribute-view>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngIf="!configMode"
      style="margin: 40px 20px 0 20px"
    >
      <div *ngIf="readOnlyFlag !== 'true'" fxFlex="none">
        <button
          *ngIf="!previewAllowed || isInSideView"
          mat-stroked-button
          color="primary"
          [disabled]="!isTabDirty() || hasError() || saveDisabled"
          (click)="onSave()"
        >
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="auto">
              {{ 'key_save' | translate }}
            </div>
            <div *ngIf="saveDisabled" fxFlex="none" style="margin-left: 10px">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </div>
        </button>
        <kendo-splitbutton
          #previewButton
          *ngIf="previewAllowed && !isInSideView"
          [data]="previewButtonData"
          [disabled]="!isTabDirty() || hasError() || saveDisabled"
          class="split-button"
          (buttonClick)="onToggleSave()"
          (itemClick)="onPreview()"
        >
          <div
            fxLayout="row"
            [ngClass]="{
              'primary-color': isTabDirty() && !hasError() && !saveDisabled
            }"
          >
            <div
              *ngIf="saveDisabled; else saveIcon"
              fxFlex="none"
              style="margin-right: 10px"
            >
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
            <ng-template #saveIcon>
              <span class="material-icons-outlined" style="margin-right: 10px"
                >save</span
              >
            </ng-template>
            <div style="line-height: 24px">{{ 'key_save' | translate }}</div>
          </div>
          <ng-template kendoSplitButtonItemTemplate let-dataItem>
            <div fxLayout="column" style="margin: 5px 0 2px -6px">
              <div fxLayout="row">
                <span
                  class="material-icons-outlined"
                  style="margin-right: 10px; line-height: 30px"
                  >{{ dataItem.icon }}</span
                >
                <div style="font-size: 16px">
                  {{ dataItem.text | translate }}
                </div>
              </div>
              <div style="margin-left: 34px; margin-top: -8px; font-size: 13px">
                {{ 'key_previewDesc' | translate }}
              </div>
            </div>
          </ng-template>
        </kendo-splitbutton>
      </div>
      <div fxFlex="auto"></div>
      <div fxFlex="none" *ngIf="advancedViewEnabled" style="margin-right: 20px">
        <button mat-stroked-button color="basic" (click)="onSwitchView($event)">
          {{
            (advancedView ? 'key_normalView' : 'key_advancedView') | translate
          }}
        </button>
      </div>
      <div fxFlex="none">
        <button mat-stroked-button color="basic" (click)="onRefresh($event)">
          {{ 'key_refresh' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="previewMode"
  fxLayout="column"
  class="info-body"
  [class.mat-elevation-z1]="true"
  style="min-height: 360px"
>
  <app-resource-preview fxFlex="100" #resourcePreview></app-resource-preview>
  <div
    *ngIf="!resourcePreview.isLoading"
    fxFlex="none"
    fxLayout="row"
    style="margin: 20px 20px 10px 20px"
  >
    <button
      mat-stroked-button
      color="warn"
      (click)="onExitPreview(false, false)"
      style="margin-right: 20px"
    >
      {{ 'key_exitPreview' | translate }}
    </button>
    <button mat-stroked-button color="warn" (click)="onExitPreview()">
      {{ 'key_exitPreviewWithoutSave' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button
      mat-stroked-button
      color="primary"
      (click)="onExitPreview(true)"
      [disabled]="resourcePreview && resourcePreview.errorMessage"
    >
      {{ 'key_exitPreviewWithSave' | translate }}
    </button>
  </div>
</div>
