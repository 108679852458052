import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';

import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  SearchScope,
  ResourceSet,
  Resource,
  RecentItem,
  HistoryItem,
  SelectableItem,
} from '../../models/dataContract.model';

import { SwapService } from '../../services/swap.service';
import { ConfigService } from '../../services/config.service';
import { NgxMasonryOptions } from 'ngx-masonry';
import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
})
export class SimpleSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private searchSubs: Array<Subscription> = [];

  private selectedScope: string;
  private selectedItem: SelectableItem;
  private selectedIndex: number;

  @Input()
  historyLimit = 5;

  @Input()
  recentLimit = 5;

  @Input()
  searchLimit = 10;

  @Input()
  autoComplete = true;

  @ViewChild('anchor', { static: true })
  public anchor: ElementRef;

  @ViewChild('searchBox', { static: true })
  public searchBox: ElementRef;

  @ViewChild('popup', { read: ElementRef })
  public popup: ElementRef;

  showPopup = false;

  popupWidth: number;

  mode = 'history';

  searchText = '';

  searchDefs: Array<SearchScope>;

  masonryOptions: NgxMasonryOptions = {
    percentPosition: true,
    transitionDuration: '0s',
  };
  updateMasonryLayout = false;

  historyItems: Array<HistoryItem>;
  recentItems: Array<RecentItem>;

  lettersToTrigger: number = this.config.getConfig('lettersToTriggerSearch', 3);

  advancedSearchTip = false;

  get canSearch() {
    const searchDef = this.utils.GetSearchDef(
      this.searchText,
      this.lettersToTrigger
    );
    return searchDef ? true : false;
  }

  private initSelectedItems() {
    this.selectedIndex = undefined;
    this.selectedScope = undefined;
    this.selectedItem = undefined;
  }

  private resetPopup() {
    this.showPopup = false;
    this.mode = 'history';
    this.searchText = '';
    if (this.searchBox) {
      this.searchBox.nativeElement.blur();
    }
  }

  private restorePopup() {
    if (!this.showPopup || this.mode !== 'history') {
      this.getHistoryItems();
      this.getRecentItems();

      this.initSelectedItems();
    }

    this.showPopup = true;
    this.mode = 'history';
    this.searchText = '';
  }

  private getHistoryItems() {
    const searchHistory = this.storage.getItem(
      `${this.utils.localStorageHistory}_${this.utils.ExtraValue(
        this.resource.loginUser,
        'ObjectID'
      )}`
    );
    this.historyItems = searchHistory ? JSON.parse(searchHistory) : [];

    return this.historyItems;
  }

  private getRecentItems() {
    const searchRecent = this.storage.getItem(
      `${this.utils.localStorageRecent}_${this.utils.ExtraValue(
        this.resource.loginUser,
        'ObjectID'
      )}`
    );
    this.recentItems = searchRecent ? JSON.parse(searchRecent) : [];

    return this.recentItems;
  }

  private setHistoryItems(item: string) {
    this.getHistoryItems();
    const index = this.historyItems.findIndex(
      (a) => a.text.toLowerCase() === item.toLowerCase()
    );
    if (index < 0) {
      this.historyItems.unshift({ text: item, selected: false });
      if (this.historyItems.length > this.historyLimit) {
        this.historyItems.pop();
      }
      this.storage.setItem(
        `${this.utils.localStorageHistory}_${this.utils.ExtraValue(
          this.resource.loginUser,
          'ObjectID'
        )}`,
        JSON.stringify(this.historyItems)
      );
    }
  }

  private setRecentItems(item: RecentItem) {
    this.getRecentItems();
    const index = this.recentItems.findIndex(
      (a) => a.id.toLowerCase() === item.id.toLowerCase()
    );
    if (index < 0) {
      this.recentItems.unshift(item);
      if (this.recentItems.length > this.recentLimit) {
        this.recentItems.pop();
      }
      this.storage.setItem(
        `${this.utils.localStorageRecent}_${this.utils.ExtraValue(
          this.resource.loginUser,
          'ObjectID'
        )}`,
        JSON.stringify(this.recentItems)
      );
    }
  }

  private moveToNextItem() {
    switch (this.mode) {
      case 'history':
        {
          if (this.selectedIndex === undefined) {
            if (this.historyItems && this.historyItems.length > 0) {
              this.selectedScope = 'history';
              this.selectedIndex = 0;
              this.selectedItem = this.historyItems[0];
              this.selectedItem.selected = true;
            } else if (this.recentItems && this.recentItems.length > 0) {
              this.selectedScope = 'recent';
              this.selectedIndex = 0;
              this.selectedItem = this.recentItems[0];
              this.selectedItem.selected = true;
            }
            break;
          }

          if (this.selectedScope === 'history') {
            if (this.historyItems.length <= this.selectedIndex + 1) {
              if (this.recentItems.length > 0) {
                this.selectedItem.selected = false;
                this.selectedScope = 'recent';
                this.selectedItem = this.recentItems[0];
                this.selectedIndex = 0;
                this.selectedItem.selected = true;
              } else {
                this.selectedItem.selected = false;
                this.selectedItem = this.historyItems[0];
                this.selectedIndex = 0;
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedItem.selected = false;
              this.selectedIndex++;
              this.selectedItem = this.historyItems[this.selectedIndex];
              this.selectedItem.selected = true;
            }
          } else if (this.selectedScope === 'recent') {
            if (this.recentItems.length <= this.selectedIndex + 1) {
              if (this.historyItems.length > 0) {
                this.selectedItem.selected = false;
                this.selectedScope = 'history';
                this.selectedItem = this.historyItems[0];
                this.selectedIndex = 0;
                this.selectedItem.selected = true;
              } else {
                this.selectedItem.selected = false;
                this.selectedItem = this.recentItems[0];
                this.selectedIndex = 0;
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedItem.selected = false;
              this.selectedIndex++;
              this.selectedItem = this.recentItems[this.selectedIndex];
              this.selectedItem.selected = true;
            }
          }
        }
        break;
      case 'search':
        {
          if (this.selectedIndex === undefined) {
            for (const def of this.searchDefs) {
              if (
                def.results &&
                def.results.results &&
                def.results.results.length > 0
              ) {
                this.selectedScope = def.name;
                this.selectedIndex = 0;
                this.selectedItem = def.results.results[0];
                this.selectedItem.selected = true;
                break;
              }
            }
            break;
          }

          const currentDefIndex = this.searchDefs.findIndex(
            (s) => s.name === this.selectedScope
          );
          if (currentDefIndex >= 0) {
            this.selectedItem.selected = false;
            const currentDef = this.searchDefs[currentDefIndex];
            if (
              this.searchLimit <= this.selectedIndex + 1 ||
              (currentDef.results.results &&
                currentDef.results.results.length <= this.selectedIndex + 1)
            ) {
              let nextDefIndex = -1;
              for (
                let index = currentDefIndex + 1;
                index < this.searchDefs.length;
                index++
              ) {
                if (
                  this.searchDefs[index].results &&
                  this.searchDefs[index].results.results &&
                  this.searchDefs[index].results.results.length > 0
                ) {
                  nextDefIndex = index;
                  break;
                }
              }
              if (nextDefIndex < 0) {
                for (let index = 0; index < currentDefIndex; index++) {
                  if (
                    this.searchDefs[index].results &&
                    this.searchDefs[index].results.results &&
                    this.searchDefs[index].results.results.length > 0
                  ) {
                    nextDefIndex = index;
                    break;
                  }
                }
              }
              if (nextDefIndex >= 0) {
                this.selectedScope = this.searchDefs[nextDefIndex].name;
                this.selectedIndex = 0;
                this.selectedItem =
                  this.searchDefs[nextDefIndex].results.results[0];
                this.selectedItem.selected = true;
              } else {
                this.selectedIndex = 0;
                this.selectedItem =
                  this.searchDefs[currentDefIndex].results.results[0];
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedIndex++;
              this.selectedItem =
                this.searchDefs[currentDefIndex].results.results[
                  this.selectedIndex
                ];
              this.selectedItem.selected = true;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  private moveToPreviousItem() {
    switch (this.mode) {
      case 'history':
        {
          if (this.selectedIndex === undefined) {
            if (this.recentItems && this.recentItems.length > 0) {
              this.selectedScope = 'recent';
              this.selectedIndex = this.recentItems.length - 1;
              this.selectedItem = this.recentItems[this.selectedIndex];
              this.selectedItem.selected = true;
            } else if (this.historyItems && this.historyItems.length > 0) {
              this.selectedScope = 'history';
              this.selectedIndex = this.historyItems.length - 1;
              this.selectedItem = this.historyItems[this.selectedIndex];
              this.selectedItem.selected = true;
            }
            break;
          }

          if (this.selectedScope === 'history') {
            if (this.selectedIndex === 0) {
              if (this.recentItems.length > 0) {
                this.selectedItem.selected = false;
                this.selectedScope = 'recent';
                this.selectedIndex = this.recentItems.length - 1;
                this.selectedItem = this.recentItems[this.selectedIndex];
                this.selectedItem.selected = true;
              } else {
                this.selectedItem.selected = false;
                this.selectedIndex = this.historyItems.length - 1;
                this.selectedItem = this.historyItems[this.selectedIndex];
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedItem.selected = false;
              this.selectedIndex--;
              this.selectedItem = this.historyItems[this.selectedIndex];
              this.selectedItem.selected = true;
            }
          } else if (this.selectedScope === 'recent') {
            if (this.selectedIndex === 0) {
              if (this.historyItems.length > 0) {
                this.selectedItem.selected = false;
                this.selectedScope = 'history';
                this.selectedIndex = this.historyItems.length - 1;
                this.selectedItem = this.historyItems[this.selectedIndex];
                this.selectedItem.selected = true;
              } else {
                this.selectedItem.selected = false;
                this.selectedIndex = this.recentItems.length - 1;
                this.selectedItem = this.recentItems[this.selectedIndex];
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedItem.selected = false;
              this.selectedIndex--;
              this.selectedItem = this.recentItems[this.selectedIndex];
              this.selectedItem.selected = true;
            }
          }
        }
        break;
      case 'search':
        {
          if (this.selectedIndex === undefined) {
            for (let index = this.searchDefs.length - 1; index >= 0; index--) {
              const def = this.searchDefs[index];
              if (
                def.results &&
                def.results.results &&
                def.results.results.length > 0
              ) {
                this.selectedScope = def.name;
                this.selectedIndex =
                  def.results.results.length < this.searchLimit
                    ? def.results.results.length - 1
                    : this.searchLimit - 1;
                this.selectedItem = def.results.results[this.selectedIndex];
                this.selectedItem.selected = true;
                break;
              }
            }
            break;
          }

          const currentDefIndex = this.searchDefs.findIndex(
            (s) => s.name === this.selectedScope
          );
          if (currentDefIndex >= 0) {
            this.selectedItem.selected = false;
            if (this.selectedIndex === 0) {
              let previousDefIndex = -1;
              for (let index = currentDefIndex - 1; index >= 0; index--) {
                if (
                  this.searchDefs[index].results &&
                  this.searchDefs[index].results.results &&
                  this.searchDefs[index].results.results.length > 0
                ) {
                  previousDefIndex = index;
                  break;
                }
              }
              if (previousDefIndex < 0) {
                for (
                  let index = this.searchDefs.length - 1;
                  index > currentDefIndex;
                  index--
                ) {
                  if (
                    this.searchDefs[index].results &&
                    this.searchDefs[index].results.results &&
                    this.searchDefs[index].results.results.length > 0
                  ) {
                    previousDefIndex = index;
                    break;
                  }
                }
              }
              if (previousDefIndex >= 0) {
                this.selectedScope = this.searchDefs[previousDefIndex].name;
                this.selectedIndex =
                  this.searchDefs[previousDefIndex].results.results &&
                  this.searchDefs[previousDefIndex].results.results.length <
                    this.searchLimit
                    ? this.searchDefs[previousDefIndex].results.results.length -
                      1
                    : this.searchLimit - 1;
                this.selectedItem =
                  this.searchDefs[previousDefIndex].results.results[
                    this.selectedIndex
                  ];
                this.selectedItem.selected = true;
              } else {
                this.selectedIndex =
                  this.searchDefs[currentDefIndex].results.results &&
                  this.searchDefs[currentDefIndex].results.results.length <
                    this.searchLimit
                    ? this.searchDefs[currentDefIndex].results.results.length -
                      1
                    : this.searchLimit - 1;
                this.selectedItem =
                  this.searchDefs[currentDefIndex].results.results[
                    this.selectedIndex
                  ];
                this.selectedItem.selected = true;
              }
            } else {
              this.selectedIndex--;
              this.selectedItem =
                this.searchDefs[currentDefIndex].results.results[
                  this.selectedIndex
                ];
              this.selectedItem.selected = true;
            }
          }
        }
        break;
      default:
        break;
    }
  }

  private contains(target: any): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  constructor(
    private swap: SwapService,
    private config: ConfigService,
    private resource: ResourceService,
    private utils: UtilsService,
    private storage: StorageService,
    private router: Router
  ) {
    this.subscription.add(
      this.swap.windowResized.subscribe(() => {
        if (this.anchor) {
          this.popupWidth = this.anchor.nativeElement.offsetWidth;
        }
      })
    );
  }

  @HostListener('document:keydown.escape', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27) {
      if (this.mode === 'search') {
        this.restorePopup();
        return;
      }

      this.resetPopup();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.resetPopup();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    const sidebarConfig: Array<any> = this.config.getConfig('sidebarItems');
    if (sidebarConfig) {
      const advSearchConfig = sidebarConfig.find(
        (item) => item.name === 'search'
      );
      if (advSearchConfig && advSearchConfig.enabled) {
        if (advSearchConfig.permissionSets) {
          const found = this.resource.rightSets.some(
            (r) => advSearchConfig.permissionSets.indexOf(r) >= 0
          );
          if (found) {
            this.advancedSearchTip = true;
          }
        } else {
          this.advancedSearchTip = true;
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.anchor) {
      this.popupWidth = this.anchor.nativeElement.offsetWidth;
    }
  }

  ngOnDestroy() {
    this.searchSubs.forEach((s: Subscription) => {
      s.unsubscribe();
    });

    this.subscription.unsubscribe();
  }

  getUnitWidth(def: SearchScope, isMain = false): string {
    if (def && def.displayedAttributesInSearch) {
      const unitWidth = def.displayedAttributesInSearch.unitWidth || 20;
      const attributeCount = def.displayedAttributesInSearch.secondary
        ? def.displayedAttributesInSearch.secondary.length
        : 0;
      const unit = isMain ? 100 - unitWidth * attributeCount : unitWidth;
      return `0 0 ${unit}`;
    }

    return 'auto';
  }

  onFocuse() {
    if (!this.searchText) {
      this.restorePopup();
    }
  }

  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        if (this.selectedItem) {
          switch (this.selectedScope) {
            case 'history':
              {
                const item = this.selectedItem as HistoryItem;
                if (item) {
                  this.onHistoryClicked(item.text);
                }
              }
              break;
            case 'recent':
              {
                const item = this.selectedItem as RecentItem;
                if (item) {
                  this.onRecentClicked(item);
                }
              }
              break;
            default:
              {
                const item = this.selectedItem as Resource;
                if (item) {
                  const def = this.searchDefs.find(
                    (s) => s.name === this.selectedScope
                  );
                  this.onItemClicked(item, def);
                }
              }
              break;
          }
        } else {
          this.onSearch();
        }
        break;
      case 'Escape':
      case 'Esc':
        return;
      case 'Backspace':
      case 'Delete':
      case 'Del':
        if (this.searchText === '') {
          this.restorePopup();
        } else {
          if (this.autoComplete) {
            this.onSearch();
          }
        }
        break;
      case 'Down':
      case 'ArrowDown':
        this.moveToNextItem();
        break;
      case 'Up':
      case 'ArrowUp':
        this.moveToPreviousItem();
        break;
      default:
        if (this.autoComplete && event.key.length === 1) {
          this.onSearch();
        }
        break;
    }
  }

  onSearch() {
    if (!this.canSearch) {
      return;
    }

    this.searchDefs = this.config.getConfig('searchScopes', []);
    this.searchDefs = this.searchDefs.filter((s) => s.enabled);

    this.initSelectedItems();

    const searchDef = this.utils.GetSearchDef(
      this.searchText,
      this.lettersToTrigger
    );
    const isExactSearch = searchDef.exactSearch;
    const isGuid = searchDef.isGuid;
    const searchValue = this.utils.encodeSearchText(searchDef.text.trim());

    const obs: Array<Observable<ResourceSet>> = [];

    if (!this.autoComplete) {
      this.setHistoryItems(this.searchText);
    }

    this.mode = 'search';
    this.showPopup = true;

    this.searchDefs.forEach((def) => {
      def.isLoading = true;
      let query = '';
      if (isGuid) {
        query = def.isSubScope
          ? `/${def.type}[DisplayName='!NeverReach!']`
          : `/${def.type}[ObjectID='%SearchText%']`;
      } else {
        query = isExactSearch ? def.exactQuery ?? def.query : def.query;
      }
      query = query.replace(/%SearchText%/gi, searchValue);

      let attributesToLoad = [];
      if (def.tooltipAttribute) {
        attributesToLoad = this.utils.pushIfUnique(
          attributesToLoad,
          def.tooltipAttribute
        );
      }
      if (def.displayedAttributesInSearch) {
        if (def.displayedAttributesInSearch.main) {
          attributesToLoad = this.utils.pushIfUnique(
            attributesToLoad,
            def.displayedAttributesInSearch.main
          );
        } else {
          attributesToLoad = this.utils.pushIfUnique(
            attributesToLoad,
            'DisplayName'
          );
        }
        if (def.displayedAttributesInSearch.secondary) {
          attributesToLoad = this.utils.pushIfUnique(
            attributesToLoad,
            def.displayedAttributesInSearch.secondary
          );
        }
      } else {
        attributesToLoad = this.utils.pushIfUnique(
          attributesToLoad,
          'DisplayName'
        );
      }

      obs.push(
        this.resource
          .getResourceByQuery(
            query,
            attributesToLoad,
            this.searchLimit,
            0,
            false,
            [],
            false,
            def.includeCount === false ? false : true
          )
          .pipe(
            tap((results: ResourceSet) => {
              def.isLoading = false;
              def.results = results;
              setTimeout(() => {
                this.updateMasonryLayout = !this.updateMasonryLayout;
              }, this.config.getConfig('intervalSmall', 200));
            }),
            catchError((err: any) => {
              console.log(err);
              def.isLoading = false;
              def.results = null;
              return of(null);
            })
          )
      );
    });

    for (const [index, s] of this.searchSubs.entries()) {
      s.unsubscribe();
      this.searchSubs.splice(index, 1);
    }

    const sub = forkJoin(obs).subscribe(() => {
      setTimeout(() => {
        this.updateMasonryLayout = !this.updateMasonryLayout;
      }, this.config.getConfig('intervalMiddle', 200));
    });

    this.searchSubs.push(sub);
  }

  onItemClicked(item: Resource, scope: SearchScope) {
    if (this.autoComplete) {
      const searchDef = this.utils.GetSearchDef(
        this.searchText,
        this.lettersToTrigger
      );
      if (!searchDef.isGuid) {
        this.setHistoryItems(this.searchText);
      }
    }

    this.setRecentItems({
      icon: scope.icon,
      id: this.utils.ExtraValue(item, 'ObjectID'),
      resource: item,
      navigationKey: scope.navigationKey,
    });

    this.resetPopup();
    this.utils.NavigateToIdentity(item, null, scope.navigationKey);
  }

  onHistoryClicked(item: string) {
    setTimeout(() => {
      this.searchText = item;
      this.onSearch();
      if (this.searchBox) {
        this.searchBox.nativeElement.focus();
      }
    });
  }

  onRecentClicked(item: RecentItem) {
    this.resetPopup();
    this.utils.NavigateToIdentity(item.resource, null, item.navigationKey);
  }

  onSeeAll(scope: SearchScope) {
    const search = this.searchText;
    this.resetPopup();

    const path = scope.navigationKey ? scope.navigationKey : scope.type;

    // force refresh on same url
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([`app/resourcelist/${path}/0`], {
        queryParams: { search },
      })
    );
  }

  onAdvancedSearch() {
    this.resetPopup();
    this.router.navigate(['/app/search']);
  }
}
