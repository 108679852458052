import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';

@Component({
  selector: 'app-expired-page',
  templateUrl: './expired-page.component.html',
  styleUrls: ['./expired-page.component.scss'],
})
export class ExpiredPageComponent implements OnInit {
  bgImage: string;
  bgFilterOpacity: number;

  constructor(
    private auth: AuthService,
    private resource: ResourceService,
    private router: Router,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.bgImage = this.config.getConfigEx(
      'images:bgLogin',
      'assets/img/img-identity.jpg'
    );
    if (this.bgImage.indexOf('/') >= 0) {
      this.bgImage = `url(${this.bgImage})`;
    }
    this.bgFilterOpacity = this.config.getConfigEx(
      'images:bgLoginFilterOpacity',
      0.6
    );
  }

  onTryAgain() {
    if (this.resource.apiActivationUrl) {
      this.router
        .navigate([this.resource.apiActivationUrl])
        .then(() => window.location.reload());
    } else {
      this.router.navigate(['/app']).then(() => window.location.reload());
    }
  }

  onLogin() {
    this.auth.logout();
  }
}
