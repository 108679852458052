import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DragulaService } from 'ng2-dragula';

import { DynamicComponent } from '../../models/dynamicComponent.interface';
import {
  ResourceTableConfig,
  ResourceColumnConfig,
} from '../../models/componentContract.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { IterablesEditorConfig } from '../../models/editorContract.model';
import { ResourceService } from '../../services/resource.service';
import { AuthMode, ModelUpdateMode } from '../../models/dataContract.model';
import { EditorIterablesComponent } from '../editor-iterables/editor-iterables.component';

@Component({
  selector: 'app-resource-table-config',
  templateUrl: './resource-table-config.component.html',
  styleUrls: ['./resource-table-config.component.scss'],
})
export class ResourceTableConfigComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('itrColumnStatus')
  itrColumnStatus: EditorIterablesComponent;

  public selectedColumnAttribute: ResourceColumnConfig;
  public columnToAdd: string;

  sortConfig = new IterablesEditorConfig();
  attrDetailSorting = {
    dataType: 'Dictionary',
    displayName: '',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrDetailSorting',
    value: null,
    values: undefined,
  };

  columnStatusConfig = new IterablesEditorConfig();

  showFallbackStatus = false;

  isCloud = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: DynamicComponent;
      config: ResourceTableConfig;
    },
    private dragula: DragulaService,
    private resource: ResourceService
  ) {
    try {
      this.dragula.createGroup('COLUMNS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit() {
    this.isCloud = this.resource.authenticationMode === AuthMode.azure;

    this.sortConfig.saveAs = 'object';
    this.sortConfig.iterableType = 'array';
    this.sortConfig.maxCount = this.isCloud ? 1 : undefined;
    this.sortConfig.showDisplayName = false;
    this.sortConfig.properties = [
      {
        name: 'field',
        displayName: 'key_attributeName',
        type: 'text',
        isKey: true,
        required: true,
        width: 60,
        options: [],
      },
      {
        name: 'dir',
        displayName: 'key_order',
        type: 'select',
        isKey: false,
        width: 40,
        default: 'asc',
        options: [
          {
            text: 'key_ascending',
            value: 'asc',
          },
          {
            text: 'key_descending',
            value: 'desc',
          },
        ],
      },
    ];

    this.columnStatusConfig.saveAs = 'object';
    this.columnStatusConfig.iterableType = 'dictionary';
    this.columnStatusConfig.isSimpleValue = true;
    this.columnStatusConfig.buttonText = 'key_addStatus';
    this.columnStatusConfig.updateOn = ModelUpdateMode.change;
    this.columnStatusConfig.properties = [
      {
        name: 'value',
        displayName: 'key_value',
        type: 'text',
        isKey: true,
        required: true,
        width: 33,
      },
      {
        name: 'text',
        displayName: 'key_text',
        type: 'text',
        isKey: false,
        width: 33,
      },
      {
        name: 'color',
        displayName: 'key_color',
        type: 'text',
        isKey: false,
        width: 33,
      },
    ];

    if (
      this.data &&
      this.data.config &&
      this.data.config.columns &&
      this.data.config.columns.length > 0
    ) {
      this.selectedColumnAttribute = this.data.config.columns[0];

      if (Array.isArray(this.data.config.initSort)) {
        if (this.data.config.initSort.length > 0) {
          this.attrDetailSorting.value = this.data.config.initSort[0];
          this.attrDetailSorting.values = this.data.config.initSort;
        }
      } else {
        this.attrDetailSorting.value = this.data.config.initSort;
        this.attrDetailSorting.values = [this.data.config.initSort];
      }

      this.onSelectColumnAttribute(this.data.config.columns[0]);
    }
  }

  onRefresh() {
    this.data.config.initSort = this.attrDetailSorting.values;
    this.data.component.updateDataSource(false, true);
  }

  onClose() {
    this.data.config.initSort = this.attrDetailSorting.values;
  }

  onAddLinkAction(event: MatChipInputEvent) {
    if (event && event.value) {
      const inputValue = event.value.trim();

      if (
        this.data.config.linkActions &&
        this.data.config.linkActions.length > 0
      ) {
        const index = this.data.config.linkActions.findIndex((a: string) => {
          return a.toLowerCase() === inputValue.toLowerCase();
        });
        if (index < 0) {
          this.data.config.linkActions.push(inputValue);
        }
      } else {
        this.data.config.linkActions = [inputValue];
      }
    }

    if (event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }
  }

  onRemoveLinkAction(action: string) {
    const index = this.data.config.linkActions.indexOf(action);
    if (index >= 0) {
      this.data.config.linkActions.splice(index, 1);
    }
  }

  onSelectColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedColumnAttribute = ca;
    if (ca.fallbackStatus) {
      this.showFallbackStatus = true;
    } else {
      this.showFallbackStatus = false;
    }
    if (this.itrColumnStatus) {
      this.itrColumnStatus.value = ca.showStatus;
      this.itrColumnStatus.refresh();
    }
  }

  onAddColumn() {
    if (this.columnToAdd) {
      const pos = this.data.config.columns.findIndex(
        (item: ResourceColumnConfig) =>
          item.field.toLowerCase() === this.columnToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.columns.push({
          field: this.columnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        });
      }
    }
    this.columnToAdd = undefined;
  }

  onDeleteColumn(column: ResourceColumnConfig) {
    const index = this.data.config.columns.findIndex(
      (c) => c.field === column.field
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.config.columns.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (this.data.config.columns[lastColumnIndex].locked) {
          this.data.config.columns[lastColumnIndex].locked = false;
        }
      }

      // remove column
      this.data.config.columns.splice(index, 1);

      // set focus
      if (column.field === this.selectedColumnAttribute.field) {
        if (this.data.config.columns.length > 0) {
          this.selectedColumnAttribute = this.data.config.columns[0];
        } else {
          this.selectedColumnAttribute = undefined;
        }
      }
    }
  }

  onEnableReferenceFilter(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.isReference === true) {
      selectedColumn.sortable = false;
    }
  }

  onEnableSorting(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.sortable) {
      selectedColumn.isReference = false;
    }
  }

  onColumnStatusDefChange(
    statusDef: any,
    selectedColumn: ResourceColumnConfig
  ) {
    if (statusDef) {
      this.showFallbackStatus = true;
      if (!selectedColumn.fallbackStatus) {
        selectedColumn.fallbackStatus = {
          color: 'grey',
          text: '',
        };
      }
    } else {
      this.showFallbackStatus = false;
      selectedColumn.fallbackStatus = null;
    }
  }
}
