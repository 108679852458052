<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.config.attributeName"
              />
            </mat-form-field>
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_text' | translate"
                [(ngModel)]="data.config.text"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.style"
              >
                <mat-option value="basic">Basic</mat-option>
                <mat-option value="raised">Raised</mat-option>
                <mat-option value="stroked">Stroked</mat-option>
                <mat-option value="flat">Flat</mat-option>
                <mat-option value="icon">Icon</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <mat-form-field fxFlex="100">
              <input
                matInput
                [placeholder]="'key_description' | translate"
                [(ngModel)]="data.config.description"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_stateCardIconText' | translate"
                [(ngModel)]="data.config.icon"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_color' | translate"
                [(ngModel)]="data.config.color"
              >
                <mat-option value="">None</mat-option>
                <mat-option value="primary">Primary</mat-option>
                <mat-option value="accent">Accent</mat-option>
                <mat-option value="warn">Warn</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                type="number"
                min="2"
                max="50"
                [placeholder]="'key_fontSize' | translate"
                [(ngModel)]="data.config.fontSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_align' | translate"
                [(ngModel)]="data.config.align"
              >
                <mat-option value="start center">Left</mat-option>
                <mat-option value="end center">Right</mat-option>
                <mat-option value="center center">Center</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-slide-toggle
              fxFlex="none"
              [(ngModel)]="data.config.stretch"
              style="margin-bottom: 20px"
            >
              {{ 'key_stretch' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
