// Angular
import { Component, Input, OnInit } from '@angular/core';

// Component
@Component({
  selector: 'app-popup-title',
  templateUrl: './popup-title.component.html',
  styleUrls: ['./popup-title.component.scss'],
})

// Class
export class PopupTitleComponent implements OnInit {
  // Bindings
  @Input() canResize: boolean;
  @Input() icon: string;
  @Input() leftPadding: number;
  @Input() title: string;
  @Input() win: any;

  // Angular
  ngOnInit(): void {
    // Defaults
    if (this.canResize === undefined) {
      this.canResize = true;
    }
  }
}
