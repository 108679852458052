import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  animateChild,
  query,
} from '@angular/animations';

import { AuthMode, System, SystemType } from '../../models/dataContract.model';

import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';
import {
  VerifiedIdData,
  VerifiedIdService,
} from 'src/app/services/verified-id.service';
import { SignalService } from '../../services/signal.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [
    trigger('flyIn', [
      state(
        'init',
        style({
          opacity: 0,
          transform: 'translateY(-200%)',
        })
      ),
      state(
        'in',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        })
      ),
      state(
        'out',
        style({
          opacity: 0,
          transform: 'translateY(200%)',
        })
      ),
      transition('* => *', animate(200)),
    ]),
    trigger('classicLogin', [
      state(
        'collapsed',
        style({
          height: '150px',
        })
      ),
      state(
        'expanded',
        style({
          height: '360px',
        })
      ),
      transition('collapsed => expanded', [
        style({ height: '150px' }),
        group([
          animate(200, style({ height: '360px' })),
          query('@loginForm', [animateChild()]),
        ]),
      ]),
      transition('expanded => collapsed', animate(200)),
    ]),
    trigger('loginForm', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms 100ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SigninComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @ViewChild('txtUserName')
  txtUserName: ElementRef;

  @Input()
  showConnections = true;

  @Input()
  mode = 'mix';

  @Input()
  systems: Array<System> = [];

  selectedSystem: System;

  animChooseSystem = 'init';
  animSignin = 'init';

  classicLogin = 'collapsed';
  loginForm = 'hide';

  userName: string;
  password: string;

  hidePwd = true;
  invalidUser = false;

  signingWindows = false;
  signingBasic = false;

  displayMode = '';

  presentationQR = '';
  presentationError = '';
  presentationState = '';
  presentationCompleted = false;
  presentationLoading = false;
  presentationTimeout: NodeJS.Timeout;

  enableVerifiedId = false;
  useVerifiedId = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private storage: StorageService,
    private util: UtilsService,
    private vid: VerifiedIdService,
    private signal: SignalService
  ) {}

  ngOnInit() {
    this.displayMode = this.mode;

    this.enableVerifiedId = this.config.getConfig('enableVerifiedId', false);

    setTimeout(() => {
      if (this.showConnections) {
        this.animChooseSystem = 'in';
        if (this.displayMode === SystemType.basic) {
          this.onClassicLogin();
        }
      } else {
        switch (this.displayMode) {
          case SystemType.azure:
            this.onAzureLogin();
            break;
          case SystemType.basic:
            this.animSignin = 'in';
            this.onClassicLogin();
            break;
          case SystemType.windows:
            this.animSignin = 'in';
            this.onWindowsLogin();
            break;
          case SystemType.mix:
            this.animSignin = 'in';
            break;
          default:
            break;
        }
      }
    }, 500);

    if (this.enableVerifiedId) {
      this.subscription.add(
        this.signal
          .startConnection()
          .pipe(
            switchMap(() => {
              return this.signal.getConnectionId();
            }),
            tap(() => {
              this.signal.addListener('PresentationRetrieved', () => {
                this.presentationLoading = true;
                this.presentationState = 'Waiting for verification';
              });
              this.signal.addListener('PresentationCompleted', () => {
                this.presentationLoading = false;
                this.presentationCompleted = true;
                this.presentationState = 'Login into system...';
                this.onBasicLogin('jie.li', 'PA$$w0rd');
              });
            })
          )
          .subscribe()
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.signal.close();
  }

  onVerifyId(system: System) {
    if (this.presentationTimeout) {
      clearTimeout(this.presentationTimeout);
    }

    this.selectedSystem = system;
    this.storage.setItem(this.util.localStorageLoginSystem, system.name);

    this.subscription.add(
      this.config
        .load()
        .pipe(
          tap(() => {
            this.config.patchConfig(system.config);
          })
        )
        .subscribe()
    );

    this.useVerifiedId = true;

    this.animChooseSystem = 'out';
    setTimeout(() => {
      this.animSignin = 'in';

      this.presentationQR = '';
      this.presentationError = '';
      this.presentationState = '';
      this.presentationCompleted = false;
      this.presentationLoading = true;

      this.subscription.add(
        this.vid
          .presentation(this.signal.connectionId)
          .pipe(
            tap((data: VerifiedIdData) => {
              if (data && data.qrCode) {
                this.presentationQR = data.qrCode;
                this.presentationTimeout = setTimeout(() => {
                  if (!this.presentationCompleted) {
                    this.presentationError = 'Verification timed out';
                  }
                }, 180000);
              } else {
                this.presentationError = 'No presentation QR code was found';
              }
            }),
            finalize(() => {
              this.presentationLoading = false;
            }),
            catchError((err: string) => {
              this.presentationError = err;
              return EMPTY;
            })
          )
          .subscribe()
      );
    }, 300);
  }

  onGotoSystem(system: System) {
    this.selectedSystem = system;
    this.storage.setItem(this.util.localStorageLoginSystem, system.name);

    this.subscription.add(
      this.config
        .load()
        .pipe(
          tap(() => {
            this.config.patchConfig(system.config);
          })
        )
        .subscribe()
    );

    switch (system.type) {
      case SystemType.azure:
      case SystemType.cloud:
        this.onAzureLogin();
        break;
      case SystemType.basic:
        this.displayMode = SystemType.basic;
        this.animChooseSystem = 'out';
        setTimeout(() => {
          this.animSignin = 'in';
          this.onClassicLogin();
        }, 300);
        break;
      case SystemType.windows:
        this.displayMode = SystemType.windows;
        this.animChooseSystem = 'out';
        setTimeout(() => {
          this.animSignin = 'in';
          this.onWindowsLogin();
        }, 300);
        break;
      case SystemType.mix:
      case SystemType.onPrem:
        this.displayMode = SystemType.mix;
        this.animChooseSystem = 'out';
        setTimeout(() => {
          this.animSignin = 'in';
        }, 300);
        break;
      case SystemType.link:
        if (system.config && system.config['url']) {
          window.open(system.config['url'], '_blank');
        }
        break;
      default:
        break;
    }
  }

  onGoBack() {
    this.animSignin = 'init';
    this.useVerifiedId = false;
    setTimeout(() => {
      this.displayMode = this.mode;
      this.animChooseSystem = 'in';
    }, 300);
  }

  onClassicLogin() {
    this.classicLogin =
      this.classicLogin === 'collapsed' ? 'expanded' : 'collapsed';
    this.loginForm = this.loginForm === 'hide' ? 'show' : 'hide';
    setTimeout(() => {
      if (this.txtUserName) {
        this.txtUserName.nativeElement.focus();
      }
    }, 0);
  }

  onInputChange() {
    this.invalidUser = false;
  }

  onWindowsLogin() {
    this.signingWindows = true;
    this.auth.login(AuthMode.windows).subscribe(
      (result: any) => {
        if (Object.keys(result).length === 0) {
          location.reload();
        } else {
          this.signingWindows = false;
          this.router.navigate(['/splash']);
        }
      },
      () => {
        this.signingWindows = false;
        this.invalidUser = true;
      }
    );
  }

  onBasicLogin(userName = undefined, password = undefined) {
    this.signingBasic = true;
    this.auth
      .login(
        AuthMode.basic,
        userName ?? this.userName,
        password ?? this.password
      )
      .subscribe(
        (result: any) => {
          if (Object.keys(result).length === 0) {
            location.reload();
          } else {
            this.signingBasic = false;
            this.router.navigate(['/splash']);
          }
        },
        () => {
          this.signingBasic = false;
          this.invalidUser = true;
        }
      );
  }

  onAzureLogin() {
    this.auth
      .login(AuthMode.azure)
      .pipe(
        tap((result: any) => {
          if (Object.keys(result).length === 0) {
            setTimeout(() => {
              location.reload();
            }, 500);
          }
        })
      )
      .subscribe();
  }
}
