import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';

import { EMPTY, Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  GridsterConfig,
  GridType,
  CompactType,
  DisplayGrid,
} from 'angular-gridster2';

import {
  GridsterComponentItem,
  DynamicComponent,
} from '../core/models/dynamicComponent.interface';
import { BroadcastEvent } from '../core/models/dataContract.model';
import {
  ModalType,
  ComponentDef,
} from '../core/models/componentContract.model';

import { DynamicContainerDirective } from '../core/directives/dynamic-container.directive';

import { ResourceService } from '../core/services/resource.service';
import { SwapService } from '../core/services/swap.service';
import { ComponentIndexService } from '../core/services/component-index.service';
import { UtilsService } from '../core/services/utils.service';
import { ModalService } from '../core/services/modal.service';

import { WidgetCreatorComponent } from '../core/components/widget-creator/widget-creator.component';

import * as moment from 'moment';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../core/services/config.service';
import { CanComponentDeactivate } from '../core/services/route-guard.service';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  private subscription: Subscription = new Subscription();

  @ViewChildren(DynamicContainerDirective)
  dynamicContainers: QueryList<DynamicContainerDirective>;

  editMode = false;
  buttonColor = 'slategray';

  gdOptions: GridsterConfig;

  gdItems: Array<GridsterComponentItem> = [];

  private compactType = this.configService.getConfig(
    'compactTypeDashboard',
    CompactType.CompactLeftAndUp
  );

  private initDynamicComponent(item: GridsterComponentItem) {
    const componentFactory = this.cfr.resolveComponentFactory(
      this.com.getInstance(item.componentType)
    );
    const container = this.dynamicContainers.find(
      (h) => h.containerName === item.name
    );
    if (container) {
      const viewContainerRef = container.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      item.componentInstance = componentRef.instance as DynamicComponent;
      item.componentInstance.config = item.componentConfig;
      if (item.componentInstance.primaryAction) {
        item.componentInstance.primaryAction.subscribe(
          this.handelDynamicEvent.bind(this)
        );
      }
    }
  }

  private handelDynamicEvent(event: BroadcastEvent) {
    switch (event.name) {
      case 'ResourceTableComponent':
        if (event.parameter && event.parameter.resource) {
          this.utils.NavigateToIdentity(
            event.parameter.resource,
            null,
            event.parameter.navigationKey
          );
        }
        break;
      default:
        break;
    }
  }

  private loadGridsterItems(loadHiddenItems: boolean) {
    this.gdItems = [];

    if (loadHiddenItems) {
      // this.gdItems = this.resource.primaryViewSetting.dashboard
      //   .components as Array<GridsterComponentItem>;
      this.gdItems = this.utils.parseComponentConfig(
        this.utils.DeepCopy(this.resource.primaryViewString)
      ).dashboard.components as Array<GridsterComponentItem>;
    } else {
      if (
        this.resource.primaryViewSetting &&
        this.resource.primaryViewSetting.dashboard &&
        this.resource.primaryViewSetting.dashboard.components
      ) {
        this.resource.primaryViewSetting.dashboard.components.forEach(
          (item: GridsterComponentItem) => {
            if (
              this.resource.inPermissionSets(
                item.componentConfig.permissionSets
              )
            ) {
              this.gdItems.push(item);
            }
          }
        );
      }
    }

    setTimeout(() => {
      this.gdItems.forEach((item: GridsterComponentItem) => {
        this.initDynamicComponent(item);
      });
    }, 0);
  }

  constructor(
    private cfr: ComponentFactoryResolver,
    private resource: ResourceService,
    private swap: SwapService,
    private com: ComponentIndexService,
    private utils: UtilsService,
    private modal: ModalService,
    private dialog: MatDialog,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.editMode = this.swap.editMode;

    this.gdOptions = {
      gridType: GridType.Fixed,
      compactType: this.compactType,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 1,
      maxCols: 100,
      minRows: 1,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 50,
      fixedRowHeight: 50,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: true,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.Always,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };

    // this.loadGristerItems(false);

    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'start-edit':
              this.editMode = this.resource.isAdminViewSet ? true : false;
              this.onGridsterEdit();
              break;
            case 'exit-edit':
              this.editMode = false;
              this.onGridsterCancel();
              break;
            case 'edit-add':
              if (this.gdItems && this.gdItems.length > 0) {
                this.onGridsterAdd();
              }
              break;
            case 'edit-save':
              if (this.gdItems && this.gdItems.length > 0) {
                this.onGridsterSave();
              }
              break;
            default:
              break;
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadGridsterItems(false);
    });
  }

  ngOnDestroy() {
    this.swap.broadcast({ name: 'exit-edit-mode', parameter: true });

    this.gdItems = this.utils.parseComponentConfig(
      this.utils.DeepCopy(this.resource.primaryViewString)
    ).dashboard.components as Array<GridsterComponentItem>;
    this.resource.primaryViewSetting.dashboard.components = this.gdItems;

    this.subscription.unsubscribe();
  }

  onGridsterEdit() {
    this.loadGridsterItems(true);

    this.gdOptions.compactType = CompactType.None;
    this.gdOptions.draggable.enabled = true;
    this.gdOptions.resizable.enabled = true;
    this.gdOptions.displayGrid = DisplayGrid.Always;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }
  }

  hasPermission(item: GridsterComponentItem) {
    if (item && item.componentConfig) {
      return this.resource.inPermissionSets(
        item.componentConfig.permissionSets
      );
    }
    return true;
  }

  onGridsterAdd() {
    const dialogRef = this.dialog.open(WidgetCreatorComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'cancel') {
        const widget = result as ComponentDef;
        if (widget) {
          const gdItem: GridsterComponentItem = {
            x: 0,
            y: 0,
            cols: widget.width ? widget.width : 1,
            rows: widget.height ? widget.height : 1,
            name: `${widget.id}${moment().format('YYYYMMDDHHmmss')}`,
            componentType: widget.instanceName,
            componentConfig: {
              name: `${widget.id}${moment().format('YYYYMMDDHHmmss')}`,
            },
          };

          this.gdItems.push(gdItem);

          setTimeout(() => {
            this.initDynamicComponent(gdItem);
          }, 0);
        }
      }
    });
  }

  onGridsterCancel() {
    this.resource.primaryViewSetting.dashboard =
      this.utils.parseComponentConfig(
        this.utils.DeepCopy(this.resource.primaryViewString)
      ).dashboard;

    this.loadGridsterItems(false);

    this.gdOptions.compactType = this.compactType;
    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }
  }

  onGridsterSave() {
    this.resource.primaryViewSetting.dashboard.components = this.gdItems;
    this.resource.primaryViewString = this.utils.stringifyComponentConfig(
      this.resource.primaryViewSetting
    );
    this.resource.primaryViewSet[this.utils.attConfiguration] =
      this.resource.primaryViewString;

    this.gdOptions.compactType = this.compactType;
    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }

    const process = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );
    this.subscription.add(
      this.resource
        .updateUISettings()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            } else {
              this.loadGridsterItems(false);
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this.onGridsterCancel();
            this.modal.show(ModalType.error, 'key_error', err.error);
            return EMPTY;
          }),
          finalize(() => {
            process.close();
          })
        )
        .subscribe()
    );
  }

  onGridsterSetting() {
    console.log('gridster setting');
  }

  onGridsterDelete(event: Event, item: GridsterComponentItem) {
    event.preventDefault();
    event.stopPropagation();
    this.gdItems.splice(this.gdItems.indexOf(item), 1);
  }

  onGridsterConfig(event: Event, item: GridsterComponentItem) {
    event.preventDefault();
    event.stopPropagation();
    item.componentInstance.configure().subscribe((config) => {
      item.componentConfig = config;
    });
  }

  canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (nextState && nextState.url === '/expired') {
      return true;
    }

    if (this.swap.editMode) {
      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'l10n_exitConfigMode'
      );
      return confirm.afterClosed().pipe(
        switchMap((result) => {
          return result && result === 'yes' ? of(true) : of(false);
        })
      );
    }

    return true;
  }
}
