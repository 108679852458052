<div
  *ngIf="simpleMode && loadAsReadonly && !simpleEditing; else fullMode"
  [matTooltip]="tooltip"
  style="font-weight: 400"
>
  <div class="accent-text-color" style="font-size: 0.75em">
    {{ displayName | translate }}
  </div>
  <button mat-stroked-button style="width: 100%" (click)="onStartEdit()">
    {{ 'key_viewEdit' | translate }}
  </button>
  <!-- <div (click)="onStartEdit()">{{ 'key_startEditing' | translate }}</div> -->
</div>
<ng-template #fullMode>
  <!-- ngIf will not be used any more, use ngClass for display control  -->
  <!-- *ngIf="configMode || showEditor(resource.rightSets)" -->
  <div
    fxLayout="column"
    [ngClass]="{
      'config-hidden': configMode && !showEditor(resource.rightSets),
      'edit-mode':
        !creationMode &&
        config.attributeName &&
        !config.attributeName.endsWith('#')
    }"
    style="width: 100%; margin-top: -8px"
  >
    <div
      fxFlex="none"
      fxFlexOrder="0"
      [matTooltip]="tooltip"
      class="label-attribute-name"
      style="margin-top: 2px; font-size: 0.75em"
    >
      {{ displayName ? (displayName | translate) : undefined }}
    </div>
    <div
      fxFlex="none"
      [fxFlexOrder]="listAbove ? 1 : 2"
      class="identity-grid"
      style="width: 100%"
    >
      <app-resource-table
        #identityTable
        [config]="config.tableConfig"
        [editable]="!disabled(resource.rightSets)"
        [enableRemoveAll]="config.enableRemoveAll"
        [enableDataExchange]="config.enableDataExchange"
        [simpleMode]="simpleMode"
        (selectionChange)="onSelectionChange()"
        (importData)="onImoprtData()"
        (exportData)="onExportData()"
        (removeAllData)="onRemoveAll()"
      ></app-resource-table>
    </div>
    <div
      fxFlex="none"
      [fxFlexOrder]="listAbove ? 2 : 3"
      class="label-attribute-desc"
      style="margin-top: 5px"
    >
      <div fxLayout="row">
        <div
          fxFlex="auto"
          *ngIf="description"
          style="margin-right: 10px; line-height: initial"
          [matTooltip]="description | translate"
        >
          {{ description | translate | ellipsis: config.hintLimit }}
        </div>
        <div
          fxFlex="none"
          *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
          style="font-size: 14px; color: #d8bb2e"
        >
          <mat-icon
            [matTooltip]="'key_noWriteAccess' | translate"
            style="font-size: 14px"
            >error</mat-icon
          >
        </div>
        <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
          <mat-icon
            [matTooltip]="'key_noReadAccess' | translate"
            style="font-size: 14px; color: red; padding-top: 4px"
          >
            error</mat-icon
          >
        </div>
      </div>
    </div>
    <div
      fxFlex="none"
      [fxFlexOrder]="listAbove ? 3 : 1"
      fxLayout="row"
      *ngIf="!disabled(resource.rightSets)"
      style="margin-top: -10px"
    >
      <div fxFlex="auto">
        <app-editor-identity
          #identityPicker
          name="ipdIdentity"
          [placeholder]="'key_searchAndAddResource' | translate"
          [(ngModel)]="pickedIdentities"
          [(config)]="config.idpConfig"
        >
        </app-editor-identity>
      </div>
      <div fxFlex="none">
        <button
          mat-icon-button
          color="primary"
          [disabled]="pickedIdentities.values === null"
          [matTooltip]="'key_add' | translate"
          (click)="onAddIdentities()"
          style="margin-top: 2px"
        >
          <mat-icon>playlist_add</mat-icon>
        </button>
      </div>
      <div fxFlex="none">
        <button
          mat-icon-button
          color="warn"
          [disabled]="selection.length === 0"
          [matTooltip]="'key_remove' | translate"
          (click)="onRemoveIdentities()"
          style="margin-top: 2px"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
