<div fxLayout="row" fxLayoutAlign="start center" class="search-input">
  <mat-icon class="search-icon search-icon-ie">search</mat-icon>
  <kendo-combobox
    #resourceList
    fxFlex="auto"
    [data]="resourceData"
    [textField]="attDisplayName"
    [valueField]="attObjectID"
    [filterable]="true"
    (filterChange)="handleSearchFilter($event)"
    (valueChange)="searchValueChange($event)"
    [placeholder]="'key_searchAllResources' | translate"
  >
    <ng-template kendoComboBoxItemTemplate let-dataItem>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        style="font-size: 18px; color: gray; padding: 10px;"
      >
        <div fxFlex="none">
          <mat-icon style="margin-top: 6px;">person</mat-icon>
        </div>
        <div fxFlex="auto">{{ dataItem | extraValue: 'DisplayName' }}</div>
      </div>
    </ng-template>
  </kendo-combobox>
</div>
