import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';
import {
  HtmlCardConfig,
  HtmlCardEntry,
} from '../../models/componentContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';

@Component({
  selector: 'app-html-card-config',
  templateUrl: './html-card-config.component.html',
  styleUrls: ['./html-card-config.component.scss'],
})
export class HtmlCardConfigComponent {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public selectedEntry: HtmlCardEntry;
  public entryToAdd: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: DynamicComponent;
      config: HtmlCardConfig;
    },
    private dragula: DragulaService
  ) {
    try {
      this.dragula.createGroup('COLUMNS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  onSelectEntry(entry: HtmlCardEntry) {
    this.selectedEntry = entry;
  }

  onDeleteEntry(entry: HtmlCardEntry) {
    const pos = this.data.config.entries.findIndex((item: HtmlCardEntry) => {
      return entry.name.toLowerCase() === item.name.toLocaleLowerCase();
    });
    if (pos >= 0) {
      this.data.config.entries.splice(pos, 1);
    }
  }

  onAddEntry() {
    if (this.entryToAdd) {
      const pos = this.data.config.entries.findIndex(
        (item: HtmlCardEntry) =>
          item.name.toLowerCase() === this.entryToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.entries.push({
          name: this.entryToAdd,
          html: '',
        });
      }
    }
    this.entryToAdd = undefined;
  }

  onAddAllowedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!this.data.config.permissionSets) {
      this.data.config.permissionSets = [];
    }

    if ((value || '').trim()) {
      const index = this.data.config.permissionSets.indexOf(value.trim());
      if (index < 0) {
        this.data.config.permissionSets.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string) {
    const index = this.data.config.permissionSets.indexOf(setName);
    if (index >= 0) {
      this.data.config.permissionSets.splice(index, 1);
    }
  }
}
