import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import { IntlService } from '@progress/kendo-angular-intl';

import { TransService } from '../models/translation.model';

@Pipe({
  name: 'extraValue',
})
export class ExtraValuePipe implements PipeTransform {
  constructor(private translate: TransService, private intl: IntlService) {}

  private toDisplayDate(value: any, format?: string) {
    if (typeof value === 'string') {
      const regEx1 =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.(\d{1,3}))?[+-](\d{2}):(\d{2})$/;
      const regEx2 =
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.(\d{1,7}Z))$/;
      if (value.match(regEx1) || value.match(regEx2)) {
        return format
          ? this.intl.formatDate(moment(value).toDate(), format)
          : this.intl.formatDate(
              moment(value).toDate(),
              `${this.translate.instant(
                'key_dateFormat'
              )} ${this.translate.instant('key_timeFormat')}`
            );
      }
    }

    return value;
  }

  private extraProperty(
    value: any,
    keys: string[],
    index: number,
    format?: string,
    forDisplay = true
  ) {
    // return null by error
    if (value === undefined || value === null || !keys || keys.length === 0) {
      return null;
    }
    // return the current value if finished
    if (index === keys.length) {
      if (forDisplay) {
        return this.toDisplayDate(value, format);
      } else {
        return value;
      }
    }
    // return the last available value if key was not found any more
    const key = keys[index].trim();
    // if (!value.hasOwnProperty(key) && !value.hasOwnProperty(key.toLowerCase())) {
    //   return value;
    // } else {
    //   return this.extraProperty(value[key], keys, index + 1);
    // }

    if (value.hasOwnProperty(key)) {
      return this.extraProperty(
        value[key],
        keys,
        index + 1,
        format,
        forDisplay
      );
    } else if (value.hasOwnProperty(key.toLowerCase())) {
      return this.extraProperty(
        value[key.toLowerCase()],
        keys,
        index + 1,
        format,
        forDisplay
      );
    } else {
      const k = key.charAt(0).toUpperCase() + key.slice(1);
      if (value.hasOwnProperty(k)) {
        return this.extraProperty(
          value[k],
          keys,
          index + 1,
          format,
          forDisplay
        );
      }
    }

    switch (typeof value) {
      case 'string':
        return this.toDisplayDate(value, format);
      case 'number':
      case 'boolean':
        return value;
      default:
        return '';
    }
  }

  transform(value: any, propertyName: any, forDisplay = true): any {
    if (!propertyName) {
      return '';
    }
    if (typeof propertyName === 'string' || propertyName instanceof String) {
      const names = propertyName.split(':');
      return this.extraProperty(value, names, 0, null, forDisplay);
    } else {
      if (propertyName.value) {
        const names = propertyName.value.split(':');
        return this.extraProperty(
          value.$historical && propertyName.isHistorical
            ? value.$historical
            : value,
          names,
          0,
          propertyName.format,
          forDisplay
        );
      } else {
        return '';
      }
    }
  }
}
