<div style="margin-top: 10px">
  <app-editor-identity
    #idpWorkflowTemplate
    name="idpTemplateId"
    [(ngModel)]="targetTemplate"
    [creationMode]="true"
    [config]="rdpConfig"
    (valueChange)="onTemplateChange($event)"
  ></app-editor-identity>
</div>
<div *ngIf="workflowTemplateId && parameterConfig">
  <div style="font-weight: 400; margin-bottom: 10px">
    {{ 'key_inputParameters' | translate }}
  </div>
  <div
    *ngFor="let parameter of parameterConfig"
    fxLayout="row"
    fxLayoutAlign="start center"
    class="background-bg-color"
    style="padding: 5px; margin-bottom: 2px"
  >
    <div fxFlex="160px" fxLayout="column" style="margin-right: 20px">
      <div>{{ parameter.displayname | translate }}</div>
      <div
        class="accent-text-color"
        style="font-size: 0.75em; margin-top: 5px; font-weight: 400"
      >
        {{ parameter.description | translate }}
      </div>
    </div>
    <mat-form-field fxFlex="auto" style="font-size: 12px">
      <mat-label>{{ 'key_value' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="parameter.paramValue"
        (change)="onValueChange()"
      />
    </mat-form-field>
    <div
      fxFlex="60px"
      style="
        margin-left: 20px;
        padding-left: 5px;
        border-left: solid 4px darkseagreen;
        font-size: 12px;
      "
    >
      {{ parameter.datatype }}
    </div>
    <div
      fxFlex="60px"
      style="
        margin-left: 20px;
        padding-left: 5px;
        border-left: solid 4px darkseagreen;
        font-size: 12px;
      "
    >
      {{ parameter.required ? 'required' : 'optional' }}
    </div>
  </div>
</div>
