<div class="full-size-relative">
  <div fxLayout="column" style="height: 100%; width: 100%">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="width: 100%; margin: 10px"
    >
      <mat-form-field
        fxFlex="200px"
        appearance="outline"
        style="font-size: 12px"
      >
        <mat-label>{{ 'key_searchTags' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="tagName"
          style="font-size: 14px"
          (keyup.enter)="onBuildChart()"
        />
        <div matSuffix fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-icon-button
            [disabled]="!tagName"
            [matTooltip]="'key_search' | translate"
            (click)="onBuildChart()"
            style="margin-right: 5px"
          >
            <mat-icon style="font-size: 22px; color: rgba(0, 0, 0, 0.54)"
              >input</mat-icon
            >
          </button>
        </div>
      </mat-form-field>
      <div fxFlex="auto"></div>
      <mat-form-field
        appearance="outline"
        style="font-size: 12px; margin-right: 20px"
      >
        <mat-label>{{ 'key_highlightTag' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="highlightTag"
          [disabled]="!categories || categories.length === 0"
          (selectionChange)="onBuildChart()"
        >
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let category of categories"
            [value]="category.id"
            style="font-size: 14px; height: 32px"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <app-object-explorer
      #objExplorer
      [query]="query"
      [title]="tagName"
      [linkedAttributes]="linkAttributesToLoad"
      [highlightCategories]="[highlightTag]"
      (categories)="onSetCategories($event)"
      (itemClick)="onChartClick($event)"
      style="height: 100%; width: 100%"
    ></app-object-explorer>
  </div>
</div>
