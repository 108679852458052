import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { tap, switchMap } from 'rxjs/operators';

import { TransService, Language } from '../models/translation.model';
import { AuthMode } from '../models/dataContract.model';

import { ConfigService } from './config.service';
import { ResourceService } from './resource.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';
import { SwapService } from './swap.service';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private initialized = false;
  get isInitialized() {
    return this.initialized;
  }

  private loaded = false;
  get isLoaded() {
    return this.initialized && this.loaded;
  }

  private initTransService() {
    const supportedLanguages: Language[] = this.config.getConfig(
      'supportedLanguages',
      [
        {
          code: ['en-US', 'en'],
          label: 'English',
          route: 'en',
        },
      ]
    );
    let userLang = navigator.language;
    const pos = supportedLanguages.findIndex((l) => l.default === true);
    if (pos >= 0) {
      userLang = supportedLanguages[pos].route;
    }
    const languages: string[] = [];
    let currentLanguage = 'en';
    supportedLanguages.forEach((language) => {
      languages.push(language.route);
      if (language.code.indexOf(userLang) >= 0) {
        currentLanguage = language.route;
      }
    });
    this.translate.addLangs(languages);
    this.translate.setDefaultLang(currentLanguage);
    this.translate.supportedLanguages = supportedLanguages;
    this.swap.currentLanguage = currentLanguage;
    return this.translate.use(currentLanguage).pipe(
      tap(() => {
        this.initialized = true;
      })
    );
  }

  constructor(
    private config: ConfigService,
    private translate: TransService,
    private resource: ResourceService,
    private auth: AuthService,
    private router: Router,
    private storage: StorageService,
    private utils: UtilsService,
    private swap: SwapService
  ) {}

  public init(currentPath: string) {
    return this.config.load().pipe(
      tap(() => {
        const currentSystemName = this.storage.getItem(
          this.utils.localStorageLoginSystem
        );
        if (currentSystemName) {
          const systemArray: any[] = this.config.getConfigEx(
            `systems:items`,
            null
          );
          if (systemArray && systemArray.length > 0) {
            const currentSystem = systemArray.find((s) => {
              return s.name === currentSystemName;
            });
            if (currentSystem) {
              this.config.patchConfig(currentSystem.config);
            }
          }
        }
      }),
      switchMap(() => {
        const defaultPath = this.config.getConfig('startPath', '/app');

        return this.initTransService().pipe(
          tap(() => {
            if (this.config.getConfig('maintenanceMode', false)) {
              this.router.navigate(['/maintenance']);
              return EMPTY;
            }

            this.auth.init();

            if (this.swap.hasError()) {
              this.router.navigate(['/error']);
            } else {
              const systemEnabled = this.config.getConfigEx(
                'systems:enabled',
                false
              );
              if (
                this.auth.authMode &&
                this.auth.authUser &&
                (!systemEnabled || (systemEnabled && this.auth.system))
              ) {
                this.resource.setService(this.auth.authUser);
                if (
                  this.resource.authenticationMode === AuthMode.azure &&
                  currentPath === '/login'
                ) {
                  currentPath = '/';
                }
                if (currentPath !== '/') {
                  this.router.navigate(['/splash'], {
                    queryParams: { path: currentPath },
                  });
                } else {
                  this.router.navigate(['/splash'], {
                    queryParams: defaultPath,
                  });
                }
              } else {
                this.router.navigate(['/login'], {
                  queryParams: defaultPath,
                });
              }
            }
          })
        );
      })
    );
  }
}
