import { Injectable } from '@angular/core';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { Observable, of, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { PopupWizardComponent } from '../components/popup-wizard/popup-wizard.component';
import { ModalType } from '../models/componentContract.model';
import { WizardConfig } from '../models/dataContract.model';
import { TransService } from '../models/translation.model';
import { ConfigService } from './config.service';
import { ModalService } from './modal.service';
import { ResourceService } from './resource.service';
import { SwapService } from './swap.service';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private defaultWizard: WizardConfig = {
    type: 'stepper',
    icon: 'fingerprint',
    isCreation: true,
    width: 800,
    displayName: 'key_creationWizard',
    maxHeight: 600,
  };

  constructor(
    private resource: ResourceService,
    private modal: ModalService,
    private config: ConfigService,
    private translate: TransService,
    private swap: SwapService,
    private window: WindowService
  ) {}

  public open(
    name: string,
    parameters?: any,
    allowNoConfig?: boolean,
    submitButtonText?: string,
    cancelButtonText?: string
  ): Observable<WindowCloseResult> {
    const viewSetting = this.resource.primaryViewSetting.creationView;
    if (viewSetting) {
      let configSetting: WizardConfig = viewSetting[name];

      if (!configSetting && allowNoConfig && typeof parameters === 'string') {
        this.defaultWizard.objectType = parameters;
        configSetting = this.defaultWizard;
      }

      configSetting.submitButtonText = submitButtonText;
      configSetting.cancelButtonText = cancelButtonText;

      if (configSetting && configSetting.objectType) {
        const progress = this.modal.show(
          ModalType.progress,
          'key_processing',
          '',
          '300px'
        );

        return this.resource
          .getType(
            configSetting.objectType,
            this.config.getCulture(this.translate.currentCulture)
          )
          .pipe(
            switchMap((schema) => {
              progress.close();
              this.swap.broadcast({
                name: 'show-overlay',
                parameter: undefined,
              });
              const windowRef = this.window.open({
                title: this.translate.instant(
                  configSetting.displayName
                    ? configSetting.displayName
                    : 'key_creationWizard'
                ),
                content: PopupWizardComponent,
                titleBarContent: null,
                height: this.config.getConfig('wizardHeight', null),
                width: configSetting.width ? configSetting.width : 800,
                top: this.config.getConfig('wizardTopPosition', null),
              });
              const windowData = windowRef.content.instance;
              windowData.data = {
                config: configSetting,
                schema,
                parameters,
              };
              return windowRef.result;
            }),
            finalize(() => {
              progress.close();
              this.swap.broadcast({
                name: 'hide-overlay',
                parameter: undefined,
              });
            })
          );
      } else if (
        allowNoConfig &&
        (typeof parameters === 'string' || parameters instanceof String)
      ) {
        console.log('no config wizard');
      } else {
        return throwError('key_noConfiguration');
      }
    }

    this.modal.show(
      ModalType.error,
      'key_error',
      'key_noConfiguration',
      '300px'
    );
    return of(new WindowCloseResult());
  }
}
