<div fxLayout="column" class="full-size-relative">
  <div fxLayout="row" fxFlex="none" style="margin-bottom: 10px">
    <div
      fxFlex="auto"
      style="
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0 0 10px;
        color: rgba(0, 0, 0, 0.54);
      "
    >
      Segregation of Duties
      <!--{{ localConfig.title | translate }}-->
    </div>
    <mat-button-toggle-group
      [(ngModel)]="displayMode"
      (change)="onDisplayModeChange()"
      style="
        height: 26px;
        line-height: 26px;
        margin-top: 10px;
        margin-right: 15px;
        font-size: 15px;
      "
    >
      <mat-button-toggle value="table" style="margin-top: 0"
        >Table</mat-button-toggle
      >
      <mat-button-toggle value="heatmap" style="margin-top: 0"
        >Heatmap</mat-button-toggle
      >
    </mat-button-toggle-group>
    <div
      fxFlex="none"
      style="
        width: 36px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        padding-top: 10px;
        margin-right: 10px;
      "
      [matTooltip]="'key_refresh' | translate"
      (click)="refresh()"
    >
      <mat-icon>refresh</mat-icon>
    </div>
  </div>
  <div *ngIf="displayMode === 'table'" fxFlex="auto">
    <app-resource-table
      #resourceTable
      [config]="tableConfig"
    ></app-resource-table>
  </div>
  <div *ngIf="displayMode === 'heatmap'" fxFlex="auto">
    <div
      id="resource-matrix"
      echarts
      [options]="options"
      (chartClick)="onChartClick($event)"
      style="height: 100%"
    ></div>
  </div>
  <div fxFlex="none" style="margin-top: 10px">
    <button
      mat-stroked-button
      (click)="onAddSod()"
      style="height: 32px; line-height: 32px"
    >
      Add
    </button>
    <button
      *ngIf="displayMode === 'table'"
      mat-stroked-button
      color="warn"
      [disabled]="!selectedItems || selectedItems.length === 0"
      (click)="onDeleteSod()"
      style="height: 32px; line-height: 32px; margin-left: 20px"
    >
      Delete
    </button>
  </div>
</div>
