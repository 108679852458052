import {
  Component,
  forwardRef,
  IterableDiffers,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  Injector,
  OnInit,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { of, Observable, Subscription, EMPTY, from } from 'rxjs';
import { tap, switchMap, finalize, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';

import { AttributeEditor } from '../../models/dynamicEditor.interface';

import { IdsEditorConfig } from '../../models/editorContract.model';
import { ModalType } from '../../models/componentContract.model';
import { TransService } from '../../models/translation.model';
import {
  ResourceSet,
  AttributeResource,
  Resource,
  EditorEvent,
  BasicResource,
  DataExchange,
  AuthMode,
  BroadcastEvent,
} from '../../models/dataContract.model';

import { createIdentitiesEditorValidator } from '../../validators/validators';

import { SwapService } from '../../services/swap.service';
import { ModalService } from '../../services/modal.service';

import { EditorIdentitiesConfigComponent } from './editor-identities-config.component';
import { EditorIdentityComponent } from '../editor-identity/editor-identity.component';
import { ResourceTableComponent } from '../resource-table/resource-table.component';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-editor-identities',
  templateUrl: './editor-identities.component.html',
  styleUrls: ['./editor-identities.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorIdentitiesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorIdentitiesComponent),
      multi: true,
    },
  ],
})
export class EditorIdentitiesComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  private pagingToken: string;

  private onResourceTableAvailable() {
    if (this.simpleMode && this.simpleEditing) {
      this.ngOnInit();
      this.ngAfterViewInit();
    }
  }

  private rtc: ResourceTableComponent;

  @ViewChild('identityPicker') idp: EditorIdentityComponent;

  @ViewChild('identityTable')
  set idt(component: ResourceTableComponent) {
    this.rtc = component;
    if (component) {
      this.onResourceTableAvailable();
    }
  }
  get idt(): ResourceTableComponent {
    return this.rtc;
  }

  @Input()
  loadAsReadonly = false;

  @Output()
  dbClick = new EventEmitter<any>();

  @Output()
  addIdentities = new EventEmitter<any>();

  @Output()
  removeIdentities = new EventEmitter<any>();

  @Output()
  removeAllIdentities = new EventEmitter<any>();

  loadInSimpleMode = true;

  private conf = new IdsEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  set value(value: string) {
    if (this.idt && value) {
      this.idt.localConfig.resolvedQuery = value;
      this.idt.updateDataSource();
    }
  }

  get isCloud(): boolean {
    return this.auth.authMode === AuthMode.azure;
  }

  differ: any;

  gridState: State;
  gridSelect: any;
  selection: string[] = [];
  gridResources: Observable<GridDataResult>;
  excelData: Observable<GridDataResult>;
  gridLoading = false;

  resolvedQuery: string = undefined;
  resolvedEditableItemsQuery: string = undefined;

  clickedRowItem: Resource;

  listAbove = true;

  pickedIdentities: AttributeResource = {
    displayName: '',
    multivalued: true,
    systemName: '',
    dataType: 'Reference',
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    value: null,
    values: null,
  };

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  simpleEditing = false;

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private differs: IterableDiffers,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private translate: TransService,
    private modal: ModalService,
    private window: WindowService,
    private auth: AuthService,
    private configService: ConfigService
  ) {
    super(injector);

    this.differ = this.differs.find([]).create(null);
  }

  setConfig(config: any = null) {
    const regExPlaceholder = /\[#\w+(-\w+)?#?\]/g;
    if (config) {
      if (config.name === 'queryExpression') {
        this.resolvedQuery = config.value;
        const match = regExPlaceholder.exec(this.resolvedEditableItemsQuery);
        if (!match || match.length === 0) {
          if (this.idt) {
            this.idt.localConfig.resolvedQuery = this.resolvedQuery;
            this.idt.localConfig.resolvedEditableQuery =
              this.resolvedEditableItemsQuery;
            this.idt.updateDataSource();
          }
        }
      }
      if (config.name === 'queryEditableItems') {
        this.resolvedEditableItemsQuery = config.value;
        const match = regExPlaceholder.exec(this.resolvedQuery);
        if (!match || match.length === 0) {
          if (this.idt) {
            this.idt.localConfig.resolvedQuery = this.resolvedQuery;
            this.idt.localConfig.resolvedEditableQuery =
              this.resolvedEditableItemsQuery;
            this.idt.updateDataSource();
          }
        }
      }
    } else {
      this.resolvedQuery = this.config.expression;
      this.resolvedEditableItemsQuery =
        this.config.tableConfig.queryEditableItems;

      if (this.resolvedQuery) {
        let needResolveQuery = false;
        let needResolveEditableQuery = false;

        const matchQuery = /\[#\w+(-\w+)?#?\]/g.exec(this.resolvedQuery);
        if (matchQuery && matchQuery.length > 0) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryExpression',
            this.resolvedQuery
          );
          needResolveQuery = true;
        } else {
          needResolveQuery = false;
        }

        const matchEditableQuery = /\[#\w+(-\w+)?#?\]/g.exec(
          this.resolvedEditableItemsQuery
        );
        if (matchEditableQuery && matchEditableQuery.length > 0) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryEditableItems',
            this.resolvedEditableItemsQuery
          );
          needResolveEditableQuery = true;
        } else {
          needResolveEditableQuery = false;
        }

        if (this.idt) {
          this.idt.localConfig.resolvedQuery = needResolveQuery
            ? this.resolveExpression(this.resolvedQuery)
            : this.resolvedQuery;
          this.idt.localConfig.resolvedEditableQuery = needResolveEditableQuery
            ? this.resolveExpression(this.resolvedEditableItemsQuery)
            : this.resolvedEditableItemsQuery;
          if (!this.simpleMode || (this.simpleMode && this.loadInSimpleMode)) {
            this.idt.updateDataSource();
          }
        }
      } else {
        if (this.idt) {
          this.idt.localConfig.resolvedQuery = this.resolvedQuery;
          this.idt.localConfig.resolvedEditableQuery =
            this.resolvedEditableItemsQuery;
          if (!this.simpleMode || (this.simpleMode && this.loadInSimpleMode)) {
            this.idt.updateDataSource();
          }
        }
      }
    }
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setConfig();
      this.setDisplay();
      if (this.idp && !this.simpleMode) {
        this.idp.setConfig();
      }
    });
  }

  ngOnInit(): void {
    if (this.simpleMode && this.loadAsReadonly && !this.simpleEditing) {
      return;
    }

    const appearance = this.configService.getConfig(
      'identityListAppearance',
      'listAbove'
    );
    this.loadInSimpleMode = this.configService.getConfigEx(
      'advancedViewSettings:loadMultivaluedReferences',
      true
    );
    this.listAbove = appearance.toLowerCase() === 'listabove';

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-list':
              {
                if (this.idt) {
                  this.idt.resetSelection();
                  this.idt.updateDataSource();
                }
              }
              break;

            default:
              break;
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.simpleMode && this.loadAsReadonly && !this.simpleEditing) {
      return;
    }

    if (!this.simpleMode) {
      this.initComponent();
    }

    setTimeout(() => {
      this.applyConfig();

      if (!this.simpleMode) {
        this.validationFn = createIdentitiesEditorValidator(this.config);

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            const resolved = this.resolveExpression(this.config.initExpression);
            this.value = resolved ? resolved : null;
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
          } else {
            this.value = null;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.pagingToken) {
      this.resource.removeRefreshToken(this.pagingToken);
    }
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new IdsEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    this.config.idpConfig.attributeName = 'idp_' + this.config.attributeName;

    if (this.idt && this.idt.localConfig) {
      // this.config.tableConfig = this.idt.localConfig;
      this.idt.localConfig = this.config.tableConfig;
    }

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorIdentitiesConfigComponent,
      width: 800,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
          if (this.idt) {
            this.idt.localConfig = this.config.tableConfig;
          }
        } else {
          this.validationFn = createIdentitiesEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        const returnValue: IdsEditorConfig = this.utils.DeepCopy(this.config);
        if (
          returnValue.tableConfig &&
          (returnValue.tableConfig.resolvedQuery ||
            returnValue.tableConfig.resolvedQuery === '')
        ) {
          delete returnValue.tableConfig.resolvedQuery;
        }
        if (
          returnValue.tableConfig &&
          (returnValue.tableConfig.resolvedEditableQuery ||
            returnValue.tableConfig.resolvedEditableQuery === '')
        ) {
          delete returnValue.tableConfig.resolvedEditableQuery;
        }
        return of(returnValue);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  refresh() {
    if (this.idt) {
      this.idt.updateDataSource();
    }
  }

  // #endregion

  // #region Event handler

  onChange() {
    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(this.value);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        this.value
      )
    );
  }

  onSelectionChange() {
    if (this.idt) {
      this.selection = this.idt.selection;
    }
  }

  // #endregion

  // rowCallback = (context: RowClassArgs): any => {
  //   if (!this.config) {
  //     return {
  //       editable: true,
  //       invalid: false,
  //     };
  //   }

  //   if (this.config && !this.config.tableConfig.queryEditableItems) {
  //     return {
  //       editable: true,
  //       invalid: false,
  //     };
  //   }

  //   return {
  //     editable: context.dataItem && context.dataItem.editable,
  //     invalid: context.dataItem && !context.dataItem.editable,
  //   };
  // };

  onImoprtData() {
    this.gridLoading = true;
    let pasteObject: DataExchange;
    let existingObjects: Array<BasicResource> = [];
    let duplicates: Array<BasicResource> = [];
    this.subscription.add(
      from(navigator.clipboard.readText())
        .pipe(
          switchMap((pasteResult: string) => {
            try {
              pasteObject = JSON.parse(pasteResult);
              if (
                pasteObject &&
                pasteObject.payload === 'dataExchange' &&
                pasteObject.content
              ) {
                if (this.config.dataExchangeCheckType) {
                  if (pasteObject.objectType) {
                    const index = pasteObject.content.findIndex(
                      (p) => p.ObjectType !== this.config.tableConfig.objectType
                    );
                    if (index >= 0) {
                      this.modal.show(
                        ModalType.error,
                        'key_error',
                        'key_invalidDataExchangeType'
                      );
                      return EMPTY;
                    }
                  } else {
                    this.modal.show(
                      ModalType.error,
                      'key_error',
                      'key_invalidDataExchangeType'
                    );
                    return EMPTY;
                  }
                }
                return this.modal
                  .show(
                    ModalType.confirm,
                    'key_confirmation',
                    'key_confirmImportData'
                  )
                  .afterClosed();
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidDataExchangeContent'
                );
                return EMPTY;
              }
            } catch {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_invalidDataExchangeContent'
              );
              return EMPTY;
            }
          }),
          switchMap((confirmResult) => {
            if (confirmResult === 'yes') {
              return this.resource.getResourceByQuery(
                this.resolvedQuery,
                this.config.dataExchangeAttribute
                  ? ['DisplayName', this.config.dataExchangeAttribute]
                  : ['DisplayName'],
                this.config.dataExchangeLimit,
                0,
                this.config.dataExchangeAttribute ? true : false
              );
            } else {
              return EMPTY;
            }
          }),
          switchMap((resourceResult: ResourceSet) => {
            if (this.config.dataExchangeAttribute) {
              existingObjects = resourceResult.results.map((r) => {
                return r[this.config.dataExchangeAttribute];
              });
            } else {
              existingObjects = resourceResult.results as Array<BasicResource>;
            }

            if (!existingObjects) {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_failedToGetDuplicates'
              );
              return EMPTY;
            }

            if (existingObjects.length > pasteObject.content.length) {
              duplicates = existingObjects.filter((e) => {
                return (
                  pasteObject.content.findIndex(
                    (p) =>
                      p.ObjectID &&
                      e.ObjectID &&
                      p.ObjectID.toLowerCase() === e.ObjectID.toLowerCase()
                  ) >= 0
                );
              });
            } else {
              duplicates = pasteObject.content.filter((p) => {
                return (
                  existingObjects.findIndex(
                    (e) =>
                      p.ObjectID &&
                      e.ObjectID &&
                      p.ObjectID.toLowerCase() === e.ObjectID.toLowerCase()
                  ) >= 0
                );
              });
            }

            if (duplicates.length > 0) {
              const duplicatesLimit = 5;
              let confirmText = this.translate.instant('key_findDuplicates');
              if (duplicates.length > duplicatesLimit) {
                confirmText = `<b>${confirmText}:</b><br/>${duplicates
                  .slice(0, duplicatesLimit)
                  .map((d) => d.DisplayName)
                  .join('<br/>')} ...<br/><br/>${this.translate.instant(
                  'key_andTotalObjects'
                )}: ${duplicates.length}`;
              } else {
                confirmText = `<b>${confirmText}:</b><br/>${duplicates
                  .map((d) => d.DisplayName)
                  .join('<br/>')}`;
              }

              return this.modal
                .show(ModalType.confirm, 'key_confirmation', confirmText, 520, [
                  {
                    text: 'key_importWithoutDuplicates',
                    value: 'distinct',
                    primary: true,
                  },
                  { text: 'key_importWithDuplicates', value: 'all' },
                  { text: 'key_cancel', value: 'no', focus: true },
                ])
                .afterClosed();
            } else {
              return of('all');
            }
          }),
          tap((finalConfirmResult) => {
            if (finalConfirmResult === 'all') {
              this.addIdentities.emit({
                attribute: this.config.attributeName,
                values: pasteObject.content,
              });
            } else if (finalConfirmResult === 'distinct') {
              const distinctObject = pasteObject.content.filter((p) => {
                return (
                  duplicates.findIndex((d) => {
                    return (
                      p.ObjectID &&
                      d.ObjectID &&
                      p.ObjectID.toLowerCase() === d.ObjectID.toLowerCase()
                    );
                  }) < 0
                );
              });
              if (distinctObject.length > 0) {
                this.addIdentities.emit({
                  attribute: this.config.attributeName,
                  values: this.pickedIdentities.values,
                });
              }
            }
          }),
          finalize(() => {
            this.gridLoading = false;
          }),
          catchError((err: any) => {
            this.gridLoading = false;
            this.modal.show(ModalType.error, 'key_error', err.message);
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onExportData() {
    this.gridLoading = true;
    this.subscription.add(
      this.resource
        .getResourceByQuery(
          this.resolvedEditableItemsQuery
            ? this.resolvedEditableItemsQuery
            : this.resolvedQuery,
          this.config.dataExchangeAttribute
            ? ['DisplayName', this.config.dataExchangeAttribute]
            : ['DisplayName'],
          this.config.dataExchangeLimit,
          0,
          this.config.dataExchangeAttribute ? true : false
        )
        .pipe(
          tap((resourceResult: ResourceSet) => {
            if (resourceResult.hasMoreItems) {
              const txtCopyLimit = `${this.translate.instant(
                'key_copyLimit1'
              )} (${
                this.config.dataExchangeLimit
              })<br/>${this.translate.instant('key_copyLimit2')}`;
              this.modal.show(ModalType.error, 'key_error', txtCopyLimit);
              return;
            }
            let resourceToExport: Resource[] = [];
            if (this.selection && this.selection.length > 0) {
              this.selection.forEach((s: string) => {
                if (s) {
                  const foundIndex = resourceResult.results.findIndex(
                    (r: Resource) => {
                      return (
                        r.ObjectID &&
                        r.ObjectID.toLowerCase() === s.toLowerCase()
                      );
                    }
                  );
                  if (foundIndex >= 0) {
                    if (
                      this.config.dataExchangeAttribute &&
                      resourceResult.results[foundIndex][
                        this.config.dataExchangeAttribute
                      ]
                    ) {
                      resourceToExport.push(
                        resourceResult.results[foundIndex][
                          this.config.dataExchangeAttribute
                        ]
                      );
                    } else {
                      resourceToExport.push(resourceResult.results[foundIndex]);
                    }
                  }
                }
              });
            } else {
              if (this.config.dataExchangeAttribute) {
                resourceToExport = resourceResult.results.map((r: Resource) => {
                  if (r[this.config.dataExchangeAttribute]) {
                    return r[this.config.dataExchangeAttribute];
                  }
                });
              } else {
                resourceToExport = resourceResult.results;
              }
            }

            const exportObject = {
              payload: 'dataExchange',
              objectType: this.config.tableConfig.objectType,
              content: resourceToExport,
            };
            this.clipboard.copy(JSON.stringify(exportObject));
          }),
          finalize(() => {
            this.gridLoading = false;
          }),
          catchError((err: any) => {
            this.gridLoading = false;
            this.modal.show(ModalType.error, 'key_error', err.message);
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onAddIdentities() {
    if (this.addIdentities.observers.length > 0) {
      if (
        this.config.addMemberConfirmation &&
        !this.config.intermediateWizardToStart
      ) {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'key_confirmAddItems'
        );
        confirm.afterClosed().subscribe((result) => {
          if (result && result === 'yes') {
            this.addIdentities.emit({
              attribute: this.config.attributeName,
              values: this.pickedIdentities.values,
            });

            this.pickedIdentities.value = null;
            this.pickedIdentities.values = null;
          }
        });
      } else {
        this.addIdentities.emit({
          attribute: this.config.attributeName,
          values: this.pickedIdentities.values,
        });

        this.pickedIdentities.value = null;
        this.pickedIdentities.values = null;
      }
    }
  }

  onRemoveIdentities() {
    if (this.removeIdentities.observers.length > 0) {
      if (this.config.removeMemberConfirmation) {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'key_confirmRemoveItems'
        );
        confirm.afterClosed().subscribe((result) => {
          if (result && result === 'yes') {
            this.removeIdentities.emit({
              attribute: this.config.attributeName,
              values: this.selection.filter((s) => s !== undefined),
            });

            this.selection.splice(0, this.selection.length);
            if (this.idt) {
              this.idt.resetSelection();
            }
          }
        });
      } else {
        this.removeIdentities.emit({
          attribute: this.config.attributeName,
          values: this.selection.filter((s) => s !== undefined),
        });

        this.selection.splice(0, this.selection.length);
        if (this.idt) {
          this.idt.resetSelection();
        }
      }
    }
  }

  onRemoveAll() {
    if (this.removeAllIdentities.observers.length > 0) {
      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'key_confirmRemoveAllItems'
      );
      confirm.afterClosed().subscribe((result) => {
        if (result && result === 'yes') {
          this.removeAllIdentities.emit({
            attribute: this.config.attributeName,
          });
        }
      });
    }
  }

  onStartEdit() {
    this.simpleEditing = true;
  }
}
