import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription, EMPTY, BehaviorSubject, forkJoin, of } from 'rxjs';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { ResourceService } from '../core/services/resource.service';
import {
  AttributeResource,
  PopupConfig,
  ApiDef,
} from '../core/models/dataContract.model';
import { EventGraphComponent } from '../core/components/event-graph/event-graph.component';
import { ModalType } from '../core/models/componentContract.model';
import { ModalService } from '../core/services/modal.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { ResourcePreviewComponent } from '../core/components/activities/resource-preview/resource-preview.component';
import { ConfigService } from '../core/services/config.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { TextEditorConfig } from '../core/models/editorContract.model';
import { UtilsService } from '../core/services/utils.service';
import { TransService } from '../core/models/translation.model';
import { SwapService } from '../core/services/swap.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit, OnDestroy {
  private refreshToken = new BehaviorSubject(undefined);
  private subscription = new Subscription();

  @ViewChild('eventGraph') eventGraph: EventGraphComponent;

  @ViewChild('resourcePreview') resourcePreview: ResourcePreviewComponent;

  brandWidth = 20;
  detailWidth = 80;
  showBrand = true;

  currentEvent: any = {};
  initial = '';
  currentTabIndex = 0;

  editorConfig = {
    readOnly: true,
  };

  previewMode = false;
  previewResourceReadOnly = true;

  advancedView = false;
  advancedViewEnabled = false;
  advancedAttributeConfig: Array<any> = [];

  get revertVisible() {
    if (this.advancedView) {
      return false;
    }

    if (this.currentTabIndex !== 1) {
      return false;
    }

    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      return this.eventGraph.selectedNode.data.eventtype === 'Request';
    }

    return false;
  }

  get cancelVisible() {
    if (this.advancedView) {
      return false;
    }

    if (this.currentTabIndex !== 1) {
      return false;
    }

    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      switch (this.eventGraph.selectedNode.data.eventtype) {
        case 'WorkflowActivity':
        case 'Authorization':
          return true;
        default:
          break;
      }
      return this.eventGraph.selectedNode.data.eventtype === 'WorkflowActivity';
    }

    return false;
  }

  get resumeVisible() {
    if (this.advancedView) {
      return false;
    }

    if (this.currentTabIndex !== 1) {
      return false;
    }

    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      return this.eventGraph.selectedNode.data.eventtype === 'WorkflowActivity';
    }

    return false;
  }

  get cancelEnabled() {
    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      return ['Running', 'Planned', 'Waiting'].includes(
        this.eventGraph.selectedNode.data.status
      );
    }

    return false;
  }

  get resumeEnabled() {
    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      return (
        this.eventGraph.selectedNode.data.status === 'Failed' ||
        this.eventGraph.selectedNode.data.status === 'Canceled'
      );
    }

    return false;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resource: ResourceService,
    private modal: ModalService,
    private config: ConfigService,
    private utils: UtilsService,
    private translate: TransService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    const advancedViewPermission: string[] = this.config.getConfig(
      'advancedViewPermission',
      []
    );
    this.advancedViewEnabled = this.resource.rightSets.some(
      (r) => advancedViewPermission.indexOf(r) >= 0
    );

    this.previewResourceReadOnly = this.config.getConfig(
      'previewResourceReadOnly',
      true
    );

    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.refreshToken
        .pipe(
          switchMap(() => {
            return this.route.params;
          }),
          switchMap(() => {
            process = this.modal.show(
              ModalType.progress,
              'key_processing',
              '',
              '300px'
            );
            const objectID = this.route.snapshot.paramMap.get('id');
            if (objectID) {
              return this.resource.getEvent(objectID, false, false);
            } else {
              return EMPTY;
            }
          }),
          tap((events: Array<any>) => {
            if (events && events.length > 0) {
              this.currentEvent = events[0];
              this.currentEvent.attributes = {};
              if (this.currentEvent.displayname) {
                this.initial = String(this.currentEvent.displayname).substr(
                  0,
                  2
                );
              }
              Object.keys(this.currentEvent).forEach((key: string) => {
                const attribute: AttributeResource = {
                  dataType: 'String',
                  displayName: key,
                  systemName: key,
                  multivalued: false,
                  permissionHint: 'Read, Modify',
                  required: false,
                  value: this.currentEvent[key],
                  values: [this.currentEvent[key]],
                };
                this.currentEvent.attributes[key] = attribute;
              });

              this.currentTabIndex = 0;
            }
          })
        )
        .subscribe(
          () => {
            if (process) {
              process.close();
            }
          },
          () => {
            if (process) {
              process.close();
            }
          }
        )
    );

    if (this.route && this.route.parent) {
      this.subscription.add(
        this.route.parent.url.subscribe((segments: UrlSegment[]) => {
          if (this.router.url.indexOf('sidenav:') > 0) {
            this.brandWidth = 0;
            this.detailWidth = 100;
            this.showBrand = false;
          } else if (segments && segments.length === 1) {
            this.brandWidth = 20;
            this.detailWidth = 80;
            this.showBrand = true;
          } else {
            this.brandWidth = 0;
            this.detailWidth = 100;
            this.showBrand = false;
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onTabChange(event: any) {
    this.currentTabIndex = event;
    if (event === 1 && this.eventGraph && this.currentEvent.id) {
      this.eventGraph.eventId = this.currentEvent.id;
      this.eventGraph.refresh();
    }
  }

  onRefresh() {
    this.previewMode = false;

    switch (this.currentTabIndex) {
      case 0:
        this.refreshToken.next(undefined);
        break;
      case 1:
        if (this.eventGraph) {
          this.eventGraph.refresh();
        }
        break;
      default:
        break;
    }
  }

  onRevert() {
    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      if (
        this.resource.customViewSetting &&
        this.resource.customViewSetting.previewEnabled === true
      ) {
        this.previewMode = true;

        this.subscription.add(
          this.resource
            .startSimulation()
            .pipe(
              tap((simulationID: string) => {
                if (simulationID && this.resourcePreview) {
                  this.resourcePreview.resourceReadOnly =
                    this.previewResourceReadOnly;
                  this.resourcePreview.simulationID = simulationID;
                  this.resourcePreview.action = this.resource.revertRequest(
                    this.eventGraph.selectedNode.data.id,
                    simulationID
                  );
                  this.resourcePreview.start();
                }
              })
            )
            .subscribe()
        );
      } else {
        const confirm = this.modal.show(
          ModalType.confirm,
          'key_confirmation',
          'key_revertRequestDesc'
        );

        this.subscription.add(
          confirm
            .afterClosed()
            .pipe(
              switchMap((confirmResult: string) => {
                if (confirmResult && confirmResult === 'yes') {
                  return this.resource.revertRequest(
                    this.eventGraph.selectedNode.data.id
                  );
                } else {
                  return EMPTY;
                }
              }),
              tap((response: HttpResponse<any>) => {
                if (this.utils.hasSideView()) {
                  this.swap.broadcast({ name: 'close-sidenav' });
                } else {
                  if (response && response.ok && response.body) {
                    this.router.navigate([`/app/event/${response.body}`]);
                  }
                }
              }),
              catchError((err: HttpErrorResponse) => {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  this.utils.getServiceError(err)
                );
                return EMPTY;
              })
            )
            .subscribe()
        );
      }
    }
  }

  onExitPreview(withSave = false) {
    if (withSave) {
      if (this.resourcePreview) {
        this.resourcePreview.loadingPreview = true;
      }

      let revertEventId = '';

      this.subscription.add(
        this.resource
          .revertRequest(this.eventGraph.selectedNode.data.id)
          .pipe(
            switchMap((response: HttpResponse<any>) => {
              if (response && response.ok && response.body) {
                revertEventId = response.body;

                if (this.resourcePreview) {
                  return this.resourcePreview.stop();
                } else {
                  return of(null);
                }
              } else {
                return of(null);
              }
            }),
            tap(() => {
              this.previewMode = false;
              if (this.utils.hasSideView()) {
                this.swap.broadcast({ name: 'close-sidenav' });
              } else {
                if (revertEventId) {
                  this.router.navigate([`/app/event/${revertEventId}`]);
                }
              }
            }),
            finalize(() => {
              if (this.resourcePreview) {
                this.resourcePreview.loadingPreview = false;
              }
            })
          )
          .subscribe()
      );
    } else {
      this.onRefresh();
    }
  }

  onCancel() {
    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.data
    ) {
      switch (this.eventGraph.selectedNode.data.eventtype) {
        case 'Authorization':
          {
            const popupConfig: PopupConfig = new PopupConfig();
            popupConfig.title = 'key_cancelApproval';
            popupConfig.style = 'fill';
            popupConfig.singleButton = true;
            popupConfig.confirmButton = {
              text: 'key_cancelApproval',
              color: 'primary',
            };
            popupConfig.data = {
              reason: {
                text: 'key_reason',
                value: '',
                type: 'textarea',
                focused: true,
                required: true,
              },
            };
            let prg: MatDialogRef<ModalComponent, any>;
            let winResult: any;
            let savingProgress: MatDialogRef<ModalComponent, any>;
            this.subscription.add(
              this.modal
                .popup(popupConfig)
                .pipe(
                  switchMap((windowResult: any) => {
                    if (!(windowResult instanceof WindowCloseResult)) {
                      winResult = windowResult;
                      const processIds: string[] =
                        this.eventGraph.selectedNode.data.approvalprocessids;
                      if (processIds && processIds.length > 0) {
                        savingProgress = this.modal.show(
                          ModalType.progress,
                          'key_savingChanges',
                          '',
                          '300px'
                        );
                        const obsBatch = [];
                        processIds.forEach((processId: string) => {
                          const apApi: ApiDef = {
                            method: 'get',
                            path: `approval/search/${processId}`,
                            param: {
                              responseInclusion: 'None',
                            },
                          };
                          obsBatch.push(
                            this.resource.callApi(
                              apApi.method,
                              apApi.path,
                              apApi.param
                            )
                          );
                        });
                        return forkJoin(obsBatch);
                      }
                    }
                    return EMPTY;
                  }),
                  switchMap((approvalProcesses: any[]) => {
                    if (approvalProcesses && approvalProcesses.length > 0) {
                      const obsBatch = [];
                      approvalProcesses.forEach((approvalProcess: any) => {
                        const approvalInfo: any[] = approvalProcess.approvals;
                        if (approvalInfo) {
                          const pos = approvalInfo.findIndex(
                            (item: any) => item.approvalDecision === null
                          );
                          if (pos >= 0) {
                            const approvalId =
                              approvalInfo[pos].approvalObjectId;
                            const apApi: ApiDef = {
                              method: 'post',
                              path: `approval/admin/${approvalId}`,
                              param: {
                                reason: winResult.reason.value,
                              },
                            };
                            obsBatch.push(
                              this.resource.callApi(
                                apApi.method,
                                apApi.path,
                                apApi.param
                              )
                            );
                          }
                        }
                      });
                      return forkJoin(obsBatch);
                    }
                    return EMPTY;
                  }),
                  tap(() => {
                    this.onRefresh();
                  }),
                  catchError((error: HttpErrorResponse) => {
                    this.modal.show(
                      ModalType.error,
                      'key_error',
                      this.utils.getServiceError(error)
                    );
                    if (prg) {
                      prg.close();
                    }
                    return EMPTY;
                  }),
                  finalize(() => {
                    if (savingProgress) {
                      savingProgress.close();
                    }
                  })
                )
                .subscribe()
            );
          }
          break;
        default:
          {
            const confirm = this.modal.show(
              ModalType.confirm,
              'key_confirmation',
              'key_abortWFDesc'
            );

            this.subscription.add(
              confirm
                .afterClosed()
                .pipe(
                  switchMap((confirmResult: string) => {
                    if (confirmResult && confirmResult === 'yes') {
                      return this.resource.cancelRequest(
                        this.eventGraph.selectedNode.id
                      );
                    }
                    return EMPTY;
                  }),
                  tap((response: HttpResponse<any>) => {
                    if (response.ok) {
                      this.onRefresh();
                    }
                  }),
                  catchError((err: HttpErrorResponse) => {
                    this.modal.show(
                      ModalType.error,
                      'key_error',
                      this.utils.getServiceError(err)
                    );
                    return EMPTY;
                  })
                )
                .subscribe()
            );
          }
          break;
      }
    }
  }

  onResume() {
    if (
      this.eventGraph &&
      this.eventGraph.selectedNode &&
      this.eventGraph.selectedNode.id
    ) {
      const confirm = this.modal.show(
        ModalType.confirm,
        'key_confirmation',
        'key_resumeWFDesc'
      );

      this.subscription.add(
        confirm
          .afterClosed()
          .pipe(
            switchMap((confirmResult: string) => {
              if (confirmResult && confirmResult === 'yes') {
                return this.resource.resumeRequest(
                  this.eventGraph.selectedNode.id
                );
              }
              return EMPTY;
            }),
            tap((response: HttpResponse<any>) => {
              if (response.ok) {
                this.onRefresh();
              }
            }),
            catchError((err: HttpErrorResponse) => {
              this.modal.show(
                ModalType.error,
                'key_error',
                this.utils.getServiceError(err)
              );
              return EMPTY;
            })
          )
          .subscribe()
      );
    }
  }

  onSwitchView() {
    if (!this.advancedView) {
      this.subscription.add(
        this.resource
          .getType(
            'event',
            this.config.getCulture(this.translate.currentCulture)
          )
          .pipe(
            tap((result) => {
              if (result) {
                this.advancedAttributeConfig =
                  this.utils.prepareAdvancedViewAttributes(
                    result,
                    this.utils.isAttributeAllowed,
                    () => {
                      return true;
                    }
                  );

                this.advancedView = true;
              }
            })
          )
          .subscribe()
      );

      this.advancedView = true;
    } else {
      this.advancedView = false;
    }
  }
}
