<div fxLayout="column" class="resource-table">
  <div fxLayout="row" fxLayoutalign="start center" style="margin-bottom: 10px">
    <div fxFlex="auto"></div>
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      [(ngModel)]="selectedView"
      (change)="onViewChange(selectedView)"
      style="font-size: 14px; height: 36px"
    >
      <mat-button-toggle value="open" style="margin-top: -5px">{{
        'l10n_manualTasksOpen' | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="submitted" style="margin-top: -5px">{{
        'l10n_manualTasksSubmitted' | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="requested" style="margin-top: -5px">{{
        'l10n_manualTasksRequested' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
    <button mat-flat-button style="margin-left: 20px" (click)="onRefresh()">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span
          class="material-icons-outlined primary-color"
          style="margin-right: 10px"
          >refresh</span
        >
        <div style="font-weight: 400">
          {{ 'key_refresh' | translate }}
        </div>
      </div>
    </button>
  </div>
  <app-resource-table
    #resourceTable
    style="height: 100%"
    [config]="tableConfig"
    [adjustIncommingData]="adjustIncommingData"
    [adjustApiData]="adjustData"
    [adjustApiPageSize]="adjustPageSize"
    [adjustApiStateChange]="adjustStateChange"
  ></app-resource-table>
</div>
