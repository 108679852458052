<div fxLayout="column">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-bottom: 20px;"
  >
    <div fxFlex="30">{{ 'l10n_validFrom' | translate }}</div>
    <div fxFlex="70">
      <kendo-datepicker
        [(value)]="validFrom"
        [format]="format"
        formatPlaceholder="formatPattern"
        style="width: 100%;"
      >
        <kendo-datepicker-messages
          [today]="'key_calendarToday' | translate"
          [toggle]="'key_calendarToggle' | translate"
        >
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-bottom: 20px;"
  >
    <div fxFlex="30">{{ 'l10n_validTo' | translate }}</div>
    <div fxFlex="70">
      <kendo-datepicker
        [(value)]="validTo"
        [format]="format"
        formatPlaceholder="formatPattern"
        style="width: 100%;"
      >
        <kendo-datepicker-messages
          [today]="'key_calendarToday' | translate"
          [toggle]="'key_calendarToggle' | translate"
        >
        </kendo-datepicker-messages>
      </kendo-datepicker>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px;">
    <button mat-stroked-button color="primary" (click)="onClose()">
      {{ 'key_ok' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'l10n_assignmentParameters'"
  ></app-popup-title>
</ng-template>
