import {
  Component,
  OnInit,
  forwardRef,
  AfterViewInit,
  Injector,
  ViewChild,
  IterableDiffers,
  DoCheck,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
  Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgControl,
  FormControl,
} from '@angular/forms';

import { of, forkJoin, Subscription, EMPTY } from 'rxjs';
import { tap, switchMap, finalize, catchError, delay } from 'rxjs/operators';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { AttributeEditor } from '../../models/dynamicEditor.interface';

import {
  BasicResource,
  Resource,
  BroadcastEvent,
  EditorEvent,
  AuthMode,
  ResourceSet,
} from '../../models/dataContract.model';
import { IdentityEditorConfig } from '../../models/editorContract.model';

import { SwapService } from '../../services/swap.service';

import { createIdentityEditorValidator } from '../../validators/validators';

import { EditorIdentityConfigComponent } from './editor-identity-config.component';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';
import { ModalService } from '../../services/modal.service';
import {
  ModalType,
  ResourceColumnConfig,
} from '../../models/componentContract.model';
import { TransService } from '../../models/translation.model';
import { ConfigService } from '../../services/config.service';
import { IdentityBrowserComponent } from '../identity-browser/identity-browser.component';

@Component({
  selector: 'app-editor-identity',
  templateUrl: './editor-identity.component.html',
  styleUrls: ['./editor-identity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorIdentityComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorIdentityComponent),
      multi: true,
    },
  ],
})
export class EditorIdentityComponent
  extends AttributeEditor
  implements OnInit, DoCheck, AfterViewInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  private searchSubs: Array<Subscription> = [];

  private valuesBeforeChange: Array<any> = [];

  private searchQuery: string;

  private initValueSent = false;

  @ViewChild('idp') public idp: MultiSelectComponent;

  @ViewChild('anchor')
  public anchor: ElementRef;

  @ViewChild('popup', { read: ElementRef })
  public popup: ElementRef;

  @Input()
  instantValue: string;

  @Input()
  placeholder: string;

  @Input()
  loadAsReadonly = false;

  @Output()
  dbClick = new EventEmitter<any>();

  @Output()
  openBrowser = new EventEmitter<any>();

  @Output()
  closeBrowser = new EventEmitter<any>();

  @Output()
  addValue = new EventEmitter<any>();

  @Output()
  removeValue = new EventEmitter<any>();

  private conf = new IdentityEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  public get allAttributes() {
    if (this.config.attributesToShow) {
      return this.config.attributesToShow.filter(
        (a: ResourceColumnConfig) => !(a.width < 0)
      );
    } else {
      return [];
    }
  }

  differ: any;

  resourceValue: Array<BasicResource> = [];

  dataSource: Array<Resource> = [];

  totalCount = 0;

  isLoading = false;

  importMode = false;

  resolvedNormalSearch: string = undefined;
  resolvedExactSearch: string = undefined;
  resolvedEmptySearch: string = undefined;

  hasFilter = false;

  importString = '';

  simpleEditing = false;

  get value() {
    return typeof this.resourceValue === 'string' ? [] : this.resourceValue;
  }
  set value(value: any) {
    if (!value || value === 'undefined') {
      if (this.instantValue) {
        value = this.instantValue;
      } else {
        this.editorAttribute.value = null;
        this.editorAttribute.values = null;
        this.propagateChange(this.editorAttribute);
        return;
      }
    }

    if (typeof value === 'string') {
      // temporary set the string value
      if (this.editorAttribute) {
        this.editorAttribute.value = value;
      }

      // resolve identity
      if (value.indexOf('/') === 0) {
        this.resource
          .getResourceByQuery(
            value,
            ['DisplayName'],
            this.config.emptySearchMaxCount
          )
          .subscribe(
            (result) => {
              if (
                result.totalCount > 0 ||
                (result.results && result.results.length > 0)
              ) {
                if (this.config.isMultivalue) {
                  this.editorAttribute.value = result.results[0];
                  this.editorAttribute.values = result.results;
                } else {
                  this.editorAttribute.value = result.results[0];
                  this.editorAttribute.values = [result.results[0]];
                }
              } else {
                this.editorAttribute.value = null;
                this.editorAttribute.values = null;
              }
              this.propagateChange(this.editorAttribute);
            },
            () => {
              this.editorAttribute.value = null;
              this.editorAttribute.values = null;
              this.propagateChange(this.editorAttribute);
            }
          );
      } else {
        this.resource.getResourceByID(value, ['DisplayName']).subscribe(
          (result) => {
            if (result) {
              this.editorAttribute.value = result;
              this.editorAttribute.values = [result];
            }
            this.propagateChange(this.editorAttribute);
          },
          () => {
            this.editorAttribute.value = null;
            this.editorAttribute.values = null;
            this.propagateChange(this.editorAttribute);
          }
        );
      }
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        this.editorAttribute.value = null;
        this.editorAttribute.values = null;
        this.propagateChange(this.editorAttribute);
      } else {
        if (typeof value[0] === 'string') {
          // resolve identity
          const observableBatch = [];
          value.forEach((id) => {
            observableBatch.push(
              this.resource.getResourceByID(id, ['DisplayName'])
            );
          });
          forkJoin(observableBatch).subscribe((result) => {
            if (result && result.length > 0) {
              if (this.config.isMultivalue) {
                this.editorAttribute.value = result[0];
                this.editorAttribute.values = result;
              } else {
                this.editorAttribute.value = result[0];
                this.editorAttribute.values = [result[0]];
              }
            } else {
              this.editorAttribute.value = null;
              this.editorAttribute.values = null;
            }
            this.propagateChange(this.editorAttribute);
          });
        } else {
          if (!this.config.isMultivalue && value.length > 1) {
            const singleValue = value;
            singleValue.splice(0, 1);
            this.editorAttribute.value = singleValue[0];
            this.editorAttribute.values = singleValue;
          } else {
            this.editorAttribute.value = value[0];
            this.editorAttribute.values = value;
          }
          this.propagateChange(this.editorAttribute);
        }
      }
    }
  }

  get valueIsString() {
    if (this.creationMode) {
      return false;
    }

    if (
      this.editorAttribute &&
      typeof this.editorAttribute.value === 'string'
    ) {
      return true;
    }
    return false;
  }

  get textField() {
    return this.resource.authenticationMode === AuthMode.azure
      ? 'displayname'
      : 'DisplayName';
  }

  get valueField() {
    return this.resource.authenticationMode === AuthMode.azure
      ? 'objectid'
      : 'ObjectID';
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  private contains(target: any): boolean {
    return (
      this.anchor &&
      (this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false))
    );
  }

  private closeBulkImport() {
    this.importString = '';
    this.importMode = false;
  }

  private emitStringResults(ids: Array<any>) {
    this.ngDoCheck();

    if (!ids) {
      return;
    }

    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(ids);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        ids
      )
    );
  }

  private getValueDiff(
    arrLarge: Array<any>,
    arrSmall: Array<any>
  ): Array<string> {
    const idsLarge = arrLarge.map((v) => this.utils.ExtraValue(v, 'ObjectID'));
    const idsSmall = arrSmall.map((v) => this.utils.ExtraValue(v, 'ObjectID'));
    const idsResult = idsLarge.filter((v) => idsSmall.indexOf(v) < 0);

    return idsResult;
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private differs: IterableDiffers,
    private window: WindowService,
    private modal: ModalService,
    private translate: TransService,
    private configService: ConfigService
  ) {
    super(injector);

    this.differ = this.differs.find([]).create(null);
  }

  // lead to performance issue!!
  // @HostListener('document:keydown.escape', ['$event'])
  // public keydown(event: any): void {
  //   if (event.keyCode === 27) {
  //     this.importMode = false;
  //   }
  // }

  // @HostListener('document:click', ['$event'])
  // public documentClick(event: any): void {
  //   if (!this.contains(event.target)) {
  //     if (this.importMode) {
  //       this.importMode = false;
  //     }
  //   }
  // }

  setConfig(config: any = null) {
    if (config) {
      if (config.name === 'queryNormalSearch') {
        this.resolvedNormalSearch = config.value;
      } else if (config.name === 'queryExactSearch') {
        this.resolvedExactSearch = config.value;
      } else if (config.name === 'queryEmptySearch') {
        this.resolvedEmptySearch = config.value;
      }
    } else {
      this.resolvedNormalSearch = this.config.queryNormalSearch;
      if (this.resolvedNormalSearch) {
        const regEx = /\[#\w+(-\w+)?#?\]/g;
        const match = regEx.exec(this.resolvedNormalSearch);
        if (match && match.length > 0) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryNormalSearch',
            this.resolvedNormalSearch
          );
        }
      }

      this.resolvedExactSearch = this.config.queryExactSearch;
      if (this.resolvedExactSearch) {
        const regEx = /\[#\w+(-\w+)?#?\]/g;
        const match = regEx.exec(this.resolvedExactSearch);
        if (match && match.length > 0) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryExactSearch',
            this.resolvedExactSearch
          );
        }
      }

      this.resolvedEmptySearch = this.config.queryEmptySearch;
      if (this.resolvedEmptySearch) {
        const regExt = /\[#\w+(-\w+)?#?\]/g;
        const match = regExt.exec(this.resolvedEmptySearch);
        if (match && match.length > 0) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryEmptySearch',
            this.resolvedEmptySearch
          );
        }
      }
    }
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setConfig();
      this.setDisplay();
    });
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.subscription.add(
        this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
          if (event) {
            if (event.name === this.config.attributeName) {
              if (event.parameter.method === 'setConfig') {
                this.setConfig({
                  name: event.parameter.name,
                  value: event.parameter.value,
                });
              }
            }
          }
        })
      );

      this.initComponent();
    }
  }

  ngDoCheck() {
    if (this.editorAttribute) {
      const changeOccurred = this.differ.diff(this.editorAttribute.values);

      if (changeOccurred) {
        const tempValues = this.config.isMultivalue
          ? this.editorAttribute.values
          : this.editorAttribute.value
          ? [this.editorAttribute.value]
          : null;

        if (
          this.editorAttribute.value &&
          typeof this.editorAttribute.value === 'string' &&
          this.utils.IsGuid(this.editorAttribute.value)
        ) {
          const observableBatch = [];
          tempValues.forEach((id: any) => {
            observableBatch.push(
              this.resource.getResourceByID(id, ['DisplayName'])
            );
          });
          this.subscription.add(
            forkJoin(observableBatch)
              .pipe(
                tap((result: Array<Resource>) => {
                  if (result && result.length > 0) {
                    this.resourceValue = result;
                    this.editorAttribute.value = result[0];
                    this.editorAttribute.values = result;
                  }
                })
              )
              .subscribe()
          );
        } else {
          this.resourceValue = tempValues;
        }

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression && !this.initValueSent) {
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
            this.initValueSent = true;
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.simpleMode) {
      this.setDisplay(null, true);
    } else {
      if (this.instantValue) {
        this.value = this.instantValue;
      }

      setTimeout(() => {
        // const ngControl: NgControl = this.injector.get<NgControl>(
        //   NgControl as Type<NgControl>
        // );
        const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
        if (ngControl) {
          this.control = ngControl.control as FormControl;
        }
      });

      setTimeout(() => {
        this.validationFn = createIdentityEditorValidator(this.config);
        this.applyConfig();

        if (!this.configMode) {
          if (this.creationMode) {
            this.validateValue();
          } else if (
            this.configService.getConfig('validateBeforeEditing', false)
          ) {
            this.validateValue();
          }
        }

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            const resolved = this.resolveExpression(this.config.initExpression);
            this.value = resolved ? resolved : null;
          }
        }

        this.valuesBeforeChange = this.utils.DeepCopy(this.value);
      });
    }
  }

  ngOnDestroy() {
    this.searchSubs.forEach((s: Subscription) => {
      s.unsubscribe();
    });

    this.subscription.unsubscribe();
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new IdentityEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorIdentityConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createIdentityEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    if (this.idp) {
      this.dataSource.splice(0, this.dataSource.length);
      this.idp.toggle(false);

      this.propagateTouched();
    }
  }

  onFilterChange(value: string) {
    if (this.idp) {
      let searchValue = value;

      searchValue = searchValue.replace(/%|!/g, '').trim();

      this.hasFilter =
        searchValue.length >= this.config.lettersToTrigger - 1 ? true : false;

      if (this.resolvedNormalSearch || this.simpleMode) {
        if (searchValue.length >= this.config.lettersToTrigger) {
          this.idp.loading = true;
          this.isLoading = true;
          this.dataSource.splice(0, this.dataSource.length);
          this.totalCount = 0;

          let theFilter = this.utils.encodeSearchText(value);
          let theQuery = '';

          if (this.simpleMode) {
            theQuery = this.config.queryNormalSearch;
          } else {
            const regEx =
              /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            if (regEx.test(theFilter)) {
              theQuery = `/*[ObjectID='${theFilter}']`;
            } else {
              theQuery = this.resolvedNormalSearch;
            }
            if (value.indexOf('!') === 0) {
              theFilter = value.substring(1);
              theQuery = this.resolvedExactSearch
                ? this.resolvedExactSearch
                : this.resolvedNormalSearch;
            }
          }

          theQuery = theQuery.replace(/%SearchText%/gi, theFilter);

          this.searchQuery = theQuery;

          const attributesToLoad = this.config.attributesToShow.map(
            (a) => a.field
          );
          if (this.config.showPhoto && this.config.photoAttribute) {
            if (attributesToLoad.indexOf(this.config.photoAttribute) < 0) {
              attributesToLoad.push(this.config.photoAttribute);
            }
          }

          for (const [index, s] of this.searchSubs.entries()) {
            s.unsubscribe();
            this.searchSubs.splice(index, 1);
          }

          const sub = this.resource
            .getResourceByQuery(
              theQuery,
              attributesToLoad,
              this.config.suggestionNumber,
              0,
              true
            )
            .pipe(
              tap((result) => {
                if (
                  result.totalCount > 0 ||
                  (result.results && result.results.length > 0)
                ) {
                  this.dataSource = result.results;
                  this.totalCount = result.totalCount;
                }
                this.idp.loading = false;
                this.isLoading = false;
              }),
              catchError(() => {
                this.idp.loading = false;
                this.isLoading = false;
                return EMPTY;
              })
            )
            .subscribe();

          this.searchSubs.push(sub);
        } else {
          if (this.config.isMultivalue) {
            if (searchValue) {
              this.idp.toggle(false);
            }
          } else {
            this.idp.toggle(false);
          }
        }
      }
    }
  }

  onEmptySearch(query?: string) {
    this.openBrowser.emit(null);

    if (this.idp) {
      this.swap.broadcast({
        name: 'show-overlay',
        parameter: undefined,
      });

      const windowRef = this.window.open({
        title: '',
        content: IdentityBrowserComponent,
        titleBarContent: null,
        width: 640,
      });
      const windowIns = windowRef.content.instance;
      windowIns.data = {
        query: query ?? this.resolvedEmptySearch,
        config: this.config,
      };

      this.subscription.add(
        windowRef.result
          .pipe(
            tap((dialogResult: any) => {
              this.closeBrowser.emit(null);

              if (dialogResult) {
                if (this.config.isMultivalue) {
                  if (this.value && Array.isArray(this.value)) {
                    const valueIds = this.value.map((v: any) =>
                      this.utils.ExtraValue(v, 'ObjectID')
                    );
                    const insertionIds = dialogResult.map((v: any) =>
                      this.utils.ExtraValue(v, 'ObjectID')
                    );
                    const emitIds = insertionIds.filter(
                      (v: string) => valueIds.indexOf(v) < 0
                    );
                    if (emitIds.length > 0) {
                      this.addValue.emit(emitIds);
                    }

                    const resultIds = this.value;
                    dialogResult.forEach((item: any) => {
                      if (
                        resultIds.findIndex(
                          (element) => element.ObjectID === item.ObjectID
                        ) < 0
                      ) {
                        resultIds.push(item);
                      }
                    });
                    this.value = resultIds;
                    this.emitStringResults(resultIds);
                  } else {
                    this.addValue.emit(
                      dialogResult.map((v: any) =>
                        this.utils.ExtraValue(v, 'ObjectID')
                      )
                    );

                    this.value = dialogResult;
                    this.emitStringResults(dialogResult);
                  }
                } else {
                  this.value = dialogResult;
                  this.emitStringResults(dialogResult);
                }
                // if (this.config.isMultivalue) {
                //   if (this.value && Array.isArray(this.value)) {
                //     const resultIds = this.value.map((item: any) => {
                //       const id = this.utils.ExtraValue(item, 'ObjectID');
                //       if (id) {
                //         return id;
                //       }
                //     });
                //     dialogResult.ids.forEach((item: string) => {
                //       if (resultIds.indexOf(item) < 0) {
                //         resultIds.push(item);
                //       }
                //     });
                //     this.value = resultIds;
                //     this.emitStringResults(resultIds);
                //   } else {
                //     this.value = dialogResult.ids;
                //     this.emitStringResults(dialogResult.ids);
                //   }
                // } else {
                //   this.value = dialogResult.ids;
                //   this.emitStringResults(dialogResult.ids);
                // }
              }
            }),
            finalize(() => {
              if (this.idp) {
                this.idp.focus();
              }
              this.swap.broadcast({
                name: 'hide-overlay',
                parameter: undefined,
              });
            })
          )
          .subscribe()
      );
    }
  }

  onMoreItems() {
    if (this.searchQuery) {
      this.onEmptySearch(this.searchQuery);
    }
  }

  onImport() {
    setTimeout(() => {
      this.importMode = !this.importMode;
    });
  }

  onChange(value: Array<any>) {
    this.ngDoCheck();

    if (this.config.isMultivalue) {
      if (value.length > this.valuesBeforeChange.length) {
        this.addValue.emit(this.getValueDiff(value, this.valuesBeforeChange));
      } else if (value.length < this.valuesBeforeChange.length) {
        this.removeValue.emit(
          this.getValueDiff(this.valuesBeforeChange, value)
        );
      }
      this.valuesBeforeChange = value;
    }

    const result = value.map((v) => {
      if (v.Photo) {
        v.Photo = null;
      }
      return v;
    });
    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(result);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        result
      )
    );
  }

  onDoubleClick(item: BasicResource) {
    if (this.dbClick.observers.length > 0) {
      this.dbClick.emit(item);
    }
  }

  onBulkImport() {
    if (this.idp) {
      if (this.importString) {
        this.idp.loading = true;
        this.isLoading = true;

        const errorLimit = 5;

        const importItems = this.importString.split(';');
        const observableBatch = [];
        let duplicates: string[] = [];
        let notFound: string[] = [];
        const found = [];
        importItems.forEach((s: string) => {
          const item = s.trim();
          if (item) {
            let theQuery =
              this.resolvedExactSearch ?? this.resolvedNormalSearch;
            theQuery = theQuery.replace(/%SearchText%/gi, item);
            observableBatch.push(
              this.resource.getResourceByQuery(theQuery, ['DisplayName'])
            );
          }
        });
        if (observableBatch.length > 0) {
          this.subscription.add(
            forkJoin(observableBatch)
              .pipe(
                tap((result: ResourceSet[]) => {
                  result.forEach((rs, index) => {
                    if (rs.totalCount === 1) {
                      found.push(rs.results[0]);
                    } else if (rs.totalCount === 0) {
                      notFound.push(importItems[index]);
                    } else {
                      duplicates.push(importItems[index]);
                    }
                  });

                  if (this.value) {
                    const valueUpdate = this.value;
                    found.forEach((f: BasicResource) => {
                      if (
                        this.value.findIndex((v: BasicResource) => {
                          return (
                            v.ObjectID &&
                            f.ObjectID &&
                            v.ObjectID.toLowerCase() ===
                              f.ObjectID.toLowerCase()
                          );
                        }) < 0
                      ) {
                        valueUpdate.push(f);
                      }
                    });

                    this.value = valueUpdate;
                  } else {
                    this.value = found;
                  }

                  let errorText = '';
                  if (notFound.length > 0) {
                    let totalNotFound = '';
                    if (notFound.length > errorLimit) {
                      totalNotFound = `<br/>...${this.translate.instant(
                        'key_andTotalObjects'
                      )}: ${notFound.length}`;
                      notFound = notFound.splice(0, errorLimit);
                    }

                    errorText = `${errorText} <br/><b>${this.translate.instant(
                      'key_identitiesNotFound'
                    )}</b>: <br/>${notFound.join('<br/>')} ${
                      totalNotFound ? totalNotFound : ''
                    }`;
                  }
                  if (duplicates.length > 0) {
                    let totalDuplicates = '';
                    if (duplicates.length > errorLimit) {
                      totalDuplicates = `<br/>...${this.translate.instant(
                        'key_andTotalObjects'
                      )}: ${duplicates.length}`;
                      duplicates = duplicates.splice(0, errorLimit);
                    }

                    errorText = `${errorText} <br/><b>${this.translate.instant(
                      'key_identitiesDuplicates'
                    )}</b>: <br/>${duplicates.join('<br/>')} ${
                      totalDuplicates ? totalDuplicates : ''
                    }`;
                  }
                  if (errorText) {
                    errorText = `<b>${this.translate.instant(
                      'key_invalidBulkImportIdentities'
                    )}</b> <br/>${errorText}`;
                    this.modal.show(ModalType.info, 'key_warning', errorText);
                  }
                }),
                finalize(() => {
                  this.idp.loading = false;
                  this.isLoading = false;
                  this.closeBulkImport();
                }),
                catchError((error) => {
                  this.idp.loading = false;
                  this.isLoading = false;
                  this.closeBulkImport();

                  this.modal.show(ModalType.error, 'key_error', error.message);
                  return EMPTY;
                })
              )
              .subscribe()
          );
        } else {
          this.idp.loading = false;
          this.isLoading = false;
          this.closeBulkImport();
        }
      }
    }
  }

  onStartEdit() {
    this.simpleEditing = true;
  }

  // #endregion
}
