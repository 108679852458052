import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EMPTY, Subscription, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';

import { ModalType } from '../core/models/componentContract.model';
import {
  PopupButtonController,
  PopupConfig,
  WizardConfig,
} from '../core/models/dataContract.model';
import { TransService } from '../core/models/translation.model';

import { ConfigService } from '../core/services/config.service';
import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { UtilsService } from '../core/services/utils.service';

import { PopupWizardComponent } from '../core/components/popup-wizard/popup-wizard.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wizard-setting',
  templateUrl: './wizard-setting.component.html',
  styleUrls: ['./wizard-setting.component.scss'],
})
export class WizardSettingComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private tape = [undefined, true, false];

  @ViewChild('popupWizard')
  popupWizard: PopupWizardComponent;

  wizardTypes = ['dialog', 'stepper'];

  type: string;

  showWizard: boolean;
  availableWizards: Array<WizardConfig> = [];
  selectedWizard: WizardConfig;

  get hasAdminRight() {
    return this.resource.isAdminViewSet;
  }

  constructor(
    private resource: ResourceService,
    private config: ConfigService,
    private translate: TransService,
    private modal: ModalService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private utils: UtilsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');

    this.initWizards();

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    this.subscription.add(
      this.resource
        .isUISettingsUpToDate()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initWizards() {
    const viewSetting = this.resource.primaryViewSetting.creationView;
    if (viewSetting) {
      this.availableWizards.splice(0, this.availableWizards.length);
      Object.keys(viewSetting).forEach((setting) => {
        const item: WizardConfig = viewSetting[setting];
        item.name = setting;
        if (this.type === 'creationform' && !item.isCreation) {
          return;
        }
        if (this.type === 'wizard' && item.isCreation) {
          return;
        }
        if (item.isCreation === undefined) {
          item.isCreation = true;
        }
        if (item.maxHeight === undefined) {
          item.maxHeight = 600;
        }
        if (item.canSubmitAtStep === undefined) {
          item.canSubmitAtStep = 0;
        }
        if (item.width === undefined) {
          item.width = 800;
        }
        if (item.displayName === undefined) {
          item.displayName = 'key_creationWizard';
        }
        this.availableWizards.push(item);
      });
    } else {
      this.availableWizards = [];
    }
  }

  onWizardSelectionChange() {
    if (this.selectedWizard) {
      this.showWizard = true;

      const prg = this.modal.show(
        ModalType.progress,
        'key_processing',
        '',
        '300px'
      );

      this.subscription.add(
        this.resource
          .getType(
            this.selectedWizard.objectType,
            this.config.getCulture(this.translate.currentCulture)
          )
          .pipe(
            tap((schema) => {
              if (schema && this.popupWizard) {
                this.popupWizard.initWizard(this.selectedWizard, schema);
              }
            }),
            finalize(() => {
              if (prg) {
                prg.close();
              }
            })
          )
          .subscribe()
      );
    } else {
      this.showWizard = false;
      this.popupWizard.initWizard();
    }
  }

  onAddWizard() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title =
      this.type === 'creationform' ? 'l10n_creationForm' : 'l10n_wizard';
    popupConfig.style = 'outline';
    popupConfig.width = 620;
    // dictionary key order is the display order, javascript orders the keys automatically
    popupConfig.data =
      this.type === 'creationform'
        ? {
            a_isNavigation: {
              text: 'key_navigateToResource',
              value: false,
              type: 'boolean',
              width: 50,
            },
            a_name: {
              text: 'key_name',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-z0-9_.-]*$`,
              required: true,
              focused: true,
            },
            a_nameDisplay: {
              text: 'key_displayName',
              value: '',
              type: 'text',
              width: 50,
              required: true,
            },
            a_resourceType: {
              text: 'key_resourceType',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-zA-Z0-9_.-]*$`,
              required: true,
            },
            a_type: {
              text: 'key_type',
              value: 'dialog',
              options: this.wizardTypes,
              type: 'select',
              width: 50,
            },
            b_navigationKey: {
              text: 'key_navigationKey',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-zA-Z0-9_.-]*$`,
            },
            c_icon: {
              text: 'key_stateCardIconText',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-z0-9_.-]*$`,
            },
            c_width: {
              text: 'key_width',
              value: 800,
              type: 'number',
              width: 50,
              required: true,
            },
            d_saveStep: {
              text: 'key_canSubmitAtStep',
              value: 0,
              type: 'number',
              width: 50,
              required: false,
            },
          }
        : {
            a_name: {
              text: 'key_name',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-z0-9_.-]*$`,
              required: true,
              focused: true,
            },
            a_nameDisplay: {
              text: 'key_displayName',
              value: '',
              type: 'text',
              width: 50,
              required: true,
            },
            a_type: {
              text: 'key_type',
              value: 'dialog',
              options: this.wizardTypes,
              type: 'select',
              width: 50,
            },
            c_icon: {
              text: 'key_stateCardIconText',
              value: '',
              type: 'text',
              width: 50,
              validation: `^[a-z0-9_.-]*$`,
            },
            c_width: {
              text: 'key_width',
              value: 800,
              type: 'number',
              width: 50,
              required: true,
            },
            d_saveStep: {
              text: 'key_canSubmitAtStep',
              value: 0,
              type: 'number',
              width: 50,
              required: false,
            },
          };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              const prg = this.modal.show(
                ModalType.progress,
                'key_processing',
                '',
                '300px'
              );

              const viewSetting = this.resource.primaryViewSetting.creationView;
              if (
                Object.keys(viewSetting).findIndex(
                  (key) =>
                    key.toLowerCase() ===
                    windowResult.a_name.value.toLowerCase()
                ) >= 0
              ) {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'l10n_wizardAlreadyExists'
                );
                if (prg) {
                  prg.close();
                }
                return EMPTY;
              }

              viewSetting[windowResult.a_name.value.toLowerCase()] = {
                displayName: windowResult.a_nameDisplay.value,
                isCreation: this.type === 'creationform',
                navigateToResource: windowResult.a_isNavigation
                  ? windowResult.a_isNavigation.value
                  : false,
                objectType: windowResult.a_resourceType
                  ? windowResult.a_resourceType.value
                  : 'person',
                type: windowResult.a_type.value,
                navigationKey: windowResult.b_navigationKey
                  ? windowResult.b_navigationKey.value
                  : null,
                icon: windowResult.c_icon.value,
                width: windowResult.c_width.value,
                canSubmitAtStep: windowResult.d_saveStep.value,
                sections: [
                  {
                    name: 'general',
                    displayName: 'key_general',
                    attributes: [],
                  },
                ],
              };

              return this.resource.updateUISettings().pipe(
                tap((updateResult: any) => {
                  if (updateResult === 'expired') {
                    this.modal.show(
                      ModalType.error,
                      'key_warning',
                      'key_uiRefreshNeeded'
                    );
                  } else {
                    this.initWizards();
                    this.selectedWizard = this.availableWizards.find(
                      (w) =>
                        w.name.toLowerCase() ===
                        windowResult.a_name.value.toLowerCase()
                    );
                    this.onWizardSelectionChange();
                  }
                }),
                catchError((err: HttpErrorResponse) => {
                  this.modal.show(ModalType.error, 'key_error', err.error);
                  return EMPTY;
                }),
                finalize(() => {
                  if (prg) {
                    prg.close();
                  }
                })
              );
            }

            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onRemoveWizard() {
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'key_confirmDeleteResource'
    );

    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((result) => {
            if (result && result === 'yes') {
              const prg = this.modal.show(
                ModalType.progress,
                'key_processing',
                '',
                '300px'
              );

              const viewSetting = this.resource.primaryViewSetting.creationView;
              delete viewSetting[this.selectedWizard.name.toLowerCase()];

              return this.resource.updateUISettings().pipe(
                tap((updateResult: any) => {
                  if (updateResult === 'expired') {
                    this.modal.show(
                      ModalType.error,
                      'key_warning',
                      'key_uiRefreshNeeded'
                    );
                  } else {
                    this.showWizard = false;
                    this.selectedWizard = null;
                    this.initWizards();
                  }
                }),
                catchError((err: HttpErrorResponse) => {
                  this.modal.show(ModalType.error, 'key_error', err.error);
                  return EMPTY;
                }),
                finalize(() => {
                  if (prg) {
                    prg.close();
                  }
                })
              );
            } else {
              return EMPTY;
            }
          })
        )
        .subscribe()
    );
  }

  onExportWizard() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_export';
    popupConfig.style = 'outline';
    popupConfig.singleButton = true;
    popupConfig.confirmButton = new PopupButtonController(
      'key_export',
      'primary'
    );
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: this.selectedWizard.name.toLowerCase(),
        type: 'text',
        required: true,
        focused: true,
        validation: '^[a-z0-9]*$',
      },
    };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          tap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              const viewSetting = this.resource.primaryViewSetting.creationView;
              const objExport = {
                creationView: {},
              };
              objExport.creationView[windowResult.name.value] =
                viewSetting[this.selectedWizard.name.toLowerCase()];

              this.clipboard.copy(
                this.utils.stringifyComponentConfig(objExport)
              );
            }
          })
        )
        .subscribe()
    );
  }

  onImportWizard() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_import';
    popupConfig.style = 'outline';
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: undefined,
        type: 'text',
        required: false,
        focused: false,
        validation: '^[a-z0-9]*$',
      },
      settings: {
        text: 'key_settings',
        value: '',
        type: 'textarea',
        rows: 6,
        required: true,
        focused: true,
      },
    };

    let settingsToImport: any;
    let keyName = '';
    let customName = '';
    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              if (
                windowResult &&
                windowResult.settings &&
                windowResult.settings.value
              ) {
                settingsToImport = JSON.parse(windowResult.settings.value);
                if (settingsToImport.creationView) {
                  if (Object.keys(settingsToImport.creationView).length === 1) {
                    keyName = Object.keys(settingsToImport.creationView)[0];
                    customName = windowResult.name.value ?? keyName;
                    const keyPos = Object.keys(
                      this.resource.primaryViewSetting.creationView
                    ).findIndex(
                      (key: string) =>
                        key.toLowerCase() === customName.toLowerCase()
                    );
                    if (keyPos >= 0) {
                      return this.modal
                        .show(
                          ModalType.confirm,
                          'key_confirmation',
                          `${this.translate.instant(
                            'key_confirmOverwriteFormSettingsFor'
                          )} ${customName}?`
                        )
                        .afterClosed();
                    } else {
                      return of('yes');
                    }
                  }
                }

                return throwError({
                  message: this.translate.instant('key_invalidSetting'),
                });
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidSetting'
                );
              }
            }
            return EMPTY;
          }),
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              this.resource.primaryViewSetting.creationView[customName] =
                settingsToImport.creationView[keyName];
              process = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              this.initWizards();
              this.selectedWizard = this.availableWizards.find(
                (w) => w.name.toLowerCase() === customName.toLowerCase()
              );
              this.onWizardSelectionChange();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }

  onSetNavigateToResource() {
    const nextValue =
      this.tape[
        (this.tape.indexOf(this.selectedWizard.navigateToResource) + 1) %
          this.tape.length
      ];
    this.selectedWizard.navigateToResource = nextValue;
  }
}
