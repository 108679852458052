<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px">
      <app-editor-text
        fxFlex="100"
        name="txtSimulationActor"
        [(ngModel)]="simulationActor"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_simulationActor',
          customDescription: 'key_simulationActorDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/simulation/simulationactor'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
