<div fxLayout="column" class="full-size-relative">
  <div
    fxFlex="auto"
    ngxUiLoaderBlurred
    [blur]="blurLevel"
    [loaderId]="localConfig.name"
  >
    <kendo-chart
      [seriesColors]="localConfig.seriesColor"
      style="height: 100%; width: 100%"
    >
      <kendo-chart-legend
        [position]="localConfig.legendPosition"
        [visible]="!!(localConfig.enableLegend && localConfig.legendPosition)"
      ></kendo-chart-legend>
      <kendo-chart-series>
        <kendo-chart-title
          [text]="localConfig.chartTitle"
          [visible]="!!localConfig.chartTitle"
        ></kendo-chart-title>
        <kendo-chart-series-item
          *ngFor="let config of localConfig.seriesConfig"
          [name]="getSeriesName(config.name)"
          [type]="localConfig.chartType"
          [data]="config.chartData"
          [field]="config.valueField"
          [categoryField]="config.categoryField"
        >
          <kendo-chart-series-item-tooltip
            [visible]="
              !!(localConfig.enableTooltip && localConfig.tooltipFormat)
            "
          >
            <ng-template let-category="category" let-value="value">
              {{ tooltipContent(category, value, config) }}
            </ng-template>
          </kendo-chart-series-item-tooltip>
          <kendo-chart-series-item-labels
            [visible]="!!(localConfig.enableLabel && localConfig.labelFormat)"
            [content]="labelContent"
            background="none"
            [color]="localConfig.labelColor"
          >
          </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
  <ngx-ui-loader
    [loaderId]="localConfig.name"
    [fgsType]="spinnerType.rectangleBounce"
    [fgsSize]="uiLoader.spinnerSize"
    [fgsColor]="uiLoader.spinnerColor"
    [overlayColor]="uiLoader.overlayColor"
    [pbColor]="uiLoader.progressBarColor"
  ></ngx-ui-loader>
</div>
