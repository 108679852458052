<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group style="max-height: 600px">
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.attribute.systemName"
              />
            </mat-form-field>
            <!-- <mat-form-field fxFlex="1 1 40">
              <input
                matInput
                [placeholder]="'key_componentName' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field> -->
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customErrorMsg' | translate"
                [(ngModel)]="data.config.customErrorMsg"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                type="number"
                min="0"
                [placeholder]="'key_hintLimit' | translate"
                [(ngModel)]="data.config.hintLimit"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_rowNumber' | translate"
                [(ngModel)]="data.config.rows"
              />
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.formatJson"
            >
              {{ 'key_prettifyJson' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 33">
              <mat-select
                [placeholder]="'key_updateOn' | translate"
                [(ngModel)]="data.config.updateOn"
              >
                <mat-option value="undefined">{{
                  'key_default' | translate
                }}</mat-option>
                <mat-option value="change">{{
                  'key_change' | translate
                }}</mat-option>
                <mat-option value="blur">{{
                  'key_blur' | translate
                }}</mat-option>
                <mat-option value="submit">{{
                  'key_submit' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="none" *ngIf="data.creationMode">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_valueExpression' | translate"
                [(ngModel)]="data.config.expression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_valueValidation' | translate"
                [(ngModel)]="data.config.validation"
              />
            </mat-form-field>
            <button
              mat-stroked-button
              style="height: 36px; margin-top: 10px; margin-left: 10px"
              [disabled]="!data.attribute.stringRegex"
              (click)="setDefaultValidation()"
            >
              {{ 'key_setDefault' | translate }}
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
