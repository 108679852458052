<div fxLayout="column" fxLayoutGap="10px" style="padding: 10px">
  <div *ngFor="let entry of localConfig.entries">
    <div [innerHtml]="buildText(entry.html) | safeHtml"></div>
  </div>
</div>
<ng-template #titleBar let-win>
  <div fxLayout="row" fxLayoutAlign="end center" style="width: 100%">
    <span
      class="material-icons-outlined accent-text-color"
      style="cursor: pointer"
      [matTooltip]="'key_logout' | translate"
      (click)="onLogout()"
      >logout</span
    >
  </div>
</ng-template>
