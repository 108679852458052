import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, of } from 'rxjs';
import { tap, switchMap, finalize } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

import { AuthMode, AuthUser, Resource } from '../models/dataContract.model';

import { ResourceService } from './resource.service';
import { UtilsService } from './utils.service';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  mode: AuthMode;
  system: string;
  get authMode() {
    return this.mode;
  }

  user: AuthUser;
  get authUser() {
    return this.user;
  }

  constructor(
    private router: Router,
    private resource: ResourceService,
    private utils: UtilsService,
    private storage: StorageService,
    private msal: MsalService,
    private http: HttpClient,
    private config: ConfigService
  ) {}

  private testMsal() {
    this.http
      .get(
        `https://ocgdataservice.azurewebsites.net/api/v2/Resources/354a7023-bc4a-4f19-99ff-5c27afdcf709`
      )
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.msal.logout();
          }, 10000);
        })
      )
      .subscribe(
        (resultWebApi: any) => {
          console.log(resultWebApi);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  public init() {
    if (this.storage.getItem(this.utils.localStorageLoginMode)) {
      this.mode =
        AuthMode[localStorage.getItem(this.utils.localStorageLoginMode)];
    }
    this.system = this.storage.getItem(this.utils.localStorageLoginSystem);
    if (localStorage.getItem(this.utils.localStorageLoginUser)) {
      this.user = JSON.parse(
        localStorage.getItem(this.utils.localStorageLoginUser)
      );
    }
  }

  public initMsal(userInfo: any) {
    // this.testMsal();
    // return;

    this.user = {
      DisplayName: userInfo.name,
      ObjectID: userInfo.accountIdentifier,
      AccountName: userInfo.userName,
      AuthenticationMode: AuthMode.azure,
      AccessToken: userInfo.userName,
      AccessConnection: '',
    };
    this.mode = AuthMode.azure;
    this.storage.setItem(this.utils.localStorageLoginMode, this.mode);
    this.storage.setItem(
      this.utils.localStorageLoginUser,
      JSON.stringify(this.user)
    );

    // this.router.navigate(['/']).then(() => {
    //   window.location.reload();
    // });
  }

  public login(mode: AuthMode, userName?: string, pwd?: string) {
    if (this.user) {
      return of({});
    }

    const connectionString =
      mode === AuthMode.basic
        ? this.resource.buildConnectionString(userName, pwd)
        : null;

    if (mode === AuthMode.basic || mode === AuthMode.windows) {
      return this.resource.load(connectionString).pipe(
        switchMap(() => {
          return this.resource.getCurrentUser(true).pipe(
            tap((currentUser: Resource) => {
              this.user = {
                DisplayName: currentUser.DisplayName,
                ObjectID: currentUser.ObjectID,
                AccountName: currentUser.AccountName,
                AuthenticationMode: this.resource.authenticationMode,
                AccessToken: this.resource.accessToken,
                AccessConnection: this.resource.accessConnection,
              };
              this.mode = AuthMode[mode];
              this.storage.setItem(this.utils.localStorageLoginMode, mode);
              this.storage.setItem(
                this.utils.localStorageLoginUser,
                JSON.stringify(this.user)
              );
            })
          );
        })
      );
    } else {
      if (this.config.getConfigEx('msalSettings:usePopup', false)) {
        this.msal.loginPopup();
      } else {
        this.msal.loginRedirect();
      }

      // this.msal.instance.handleRedirectPromise().then(() => {
      //   if (this.msal.instance.getAllAccounts().length === 0) {
      //     this.msal.loginPopup().subscribe((response: AuthenticationResult) => {
      //       this.msal.instance.setActiveAccount(response.account);
      //     });
      //   } else {
      //     this.setActiveAccount();
      //   }
      // });

      return EMPTY;
    }
  }

  public logout(deleteCache = false, logoutPath?: string) {
    this.storage.removeItem(this.utils.localStorageLoginMode);
    this.storage.removeItem(this.utils.localStorageLoginUser);
    this.storage.removeItem(this.utils.localStorageLoginSystem);
    if (deleteCache) {
      const id = this.utils.ExtraValue(this.resource.loginUser, 'ObjectID');
      this.storage.removeItem(`${this.utils.localStorageHistory}_${id}`);
      this.storage.removeItem(`${this.utils.localStorageRecent}_${id}`);
    }

    this.resource.clear();
    this.user = undefined;

    this.utils.navigationPath = [];

    if (this.authMode === AuthMode.azure) {
      this.mode = undefined;
      this.msal.logoutRedirect();
    } else {
      this.mode = undefined;
      this.router.navigate([logoutPath ?? '/login']);
    }
  }

  public clear() {
    this.mode = undefined;
    this.user = undefined;

    // this.storage.removeItem(this.utils.localStorageLoginMode);
    // this.storage.removeItem(this.utils.localStorageLoginUser);
    // this.storage.removeItem(this.utils.localStorageLoginSystem);

    this.resource.clear();

    this.utils.navigationPath = [];
  }

  public setActiveAccount() {
    let activeAccount = this.msal.instance.getActiveAccount();
    if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
      activeAccount = this.msal.instance.getAllAccounts()[0];
      this.msal.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }
}
