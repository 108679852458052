<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 10px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="waitForCompletion"
        [ngModelOptions]="{ standalone: true }"
        matTooltip="{{ 'key_waitForCompletionDes' | translate }}"
        >{{ 'key_waitForCompletion' | translate }}</mat-checkbox
      >
    </div>
    <div *ngIf="waitForCompletion" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="200px">
        <input
          matInput
          type="number"
          min="0"
          [placeholder]="'key_maxWaitTime' | translate"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="maxWaitSeconds"
          (change)="onMaxTimeChange()"
          matTooltip="{{ 'key_maxWaitTimeDes' | translate }}"
        />
      </mat-form-field>
      <mat-form-field fxFlex="auto" style="margin-left: 20px">
        <mat-label>{{ 'key_waitForCompletionMessage' | translate }}</mat-label>
        <input
          matInput
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="waitForCompletionMessage"
        />
      </mat-form-field>
    </div>
  </div>
</form>
