<div
  fxLayout="row"
  fxLayoutAlign="center center"
  class="outer-brand"
  [ngClass]="showMenu === true ? 'active' : ''"
>
  <div #anchor class="inner-brand" (click)="onToggle()">
    {{ brandLetter | uppercase }}
  </div>
</div>
<kendo-popup
  #popup
  [anchor]="{ nativeElement: anchor }"
  [margin]="{ horizontal: -260, vertical: 8 }"
  (anchorViewportLeave)="showMenu = false"
  *ngIf="showMenu"
>
  <div class="profile-menu">
    <div fxLayout="column" style="margin: 20px">
      <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="none" class="profile-avatar">
          <img
            *ngIf="attrPhoto"
            [src]="'data:image/png;base64,' + attrPhoto"
            class="profile-avatar-image"
          />
          <div *ngIf="!attrPhoto" class="profile-avatar-initial">
            {{ brandLetter | uppercase }}
          </div>
        </div>
        <div fxFlex="auto" fxLayout="column" fxLayoutGap="10px">
          <div
            fxFlex="none"
            *ngIf="loginUser | examValue: 'DisplayName'"
            class="profile-name"
            [matTooltip]="
              (loginUser | extraValue: 'DisplayName').length > 16
                ? (loginUser | extraValue: 'DisplayName')
                : null
            "
          >
            {{ loginUser | extraValue: 'DisplayName' | ellipsis: 16 }}
          </div>
          <div
            fxFlex="none"
            *ngIf="loginUser | examValue: 'JobTitle'"
            class="profile-info"
          >
            {{ loginUser | extraValue: 'JobTitle' }}
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="profile-footer">
      <hr style="border: 0; border-top: 1px solid #ccc; width: 80%" />
      <div fxLayout="row" fxLayoutGap="10px;" style="margin: 5px 30px">
        <!-- Show all Languages from Config if enabled -->
        <div *ngFor="let lang of allLanguages">
          <div
            *ngIf="lang.enabled"
            [ngClass]="currentLanguage === lang.route ? 'active' : ''"
            class="outer-country"
          >
            <img
              [src]="lang.icon"
              (click)="onChangeLanguage(lang.route)"
              class="inner-country"
            />
          </div>
        </div>
        <div fxFlex="auto"></div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
          style="cursor: pointer; color: #6f6f6f"
          (click)="onLogout()"
        >
          <div>{{ 'key_logout' | translate }}</div>
          <mat-icon>exit_to_app</mat-icon>
        </div>
      </div>
    </div>
  </div>
</kendo-popup>
