<div class="full-size-relative" style="padding: 10px">
  <mat-card
    class="full-height"
    [style.backgroundColor]="localConfig.backgroundColor"
  >
    <mat-card-content class="full-height">
      <div
        class="full-height"
        fxLayout="row wrap"
        fxLayoutAlign="start stretch"
        (click)="onAction($event)"
      >
        <div
          *ngIf="localConfig.icon || localConfig.image"
          class="mouse-link"
          fxFlex="70px"
        >
          <mat-icon
            *ngIf="!localConfig.image"
            style="font-size: 52px"
            [style.color]="localConfig.iconColor"
          >
            {{ localConfig.icon }}</mat-icon
          >
          <img
            *ngIf="localConfig.image"
            [src]="localConfig.image"
            style="width: 45px"
          />
        </div>
        <div
          fxFlex="auto"
          fxLayout="column"
          [style.font-size.px]="localConfig.fontSize"
          class="mouse-link"
        >
          <div
            fxFlex="none"
            [style.color]="localConfig.textColor"
            [style.margin-top.px]="
              localConfig.description !== undefined ? 0 : 12
            "
            style="font-weight: bolder"
          >
            {{ localConfig.text | translate }}
          </div>
          <div
            fxFlex="none"
            [style.color]="localConfig.descriptionColor"
            class="card-desc"
            [innerHTML]="localConfig.description | translate"
          ></div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
