<div fxLayout="column" class="full-size-relative">
  <div
    fxFlex="auto"
    class="identity-grid"
    [ngClass]="localConfig.title ? 'withTitle' : 'withoutTitle'"
  >
    <kendo-grid
      #grid="kendoGrid"
      [ngClass]="{
        'cloud-table': isCloud,
        'no-more-items':
          noMoreItems &&
          noMoreCachedItems &&
          (!localConfig.pageInfo || !allowPageInfo)
      }"
      [style.height.%]="100"
      [style.width.%]="100"
      [style.fontSize.px]="localConfig.fontSize"
      [style.maxHeight.px]="localConfig.tableHeight"
      [style.minHeight.px]="localConfig.tableHeight"
      [data]="gridResources | async"
      [loading]="gridLoading"
      [pageSize]="localConfig.pageSize"
      [skip]="gridState.skip"
      [pageable]="
        localConfig.pageButton && localConfig.scrollMode !== 'virtual'
          ? {
              buttonCount: isCloud ? 1 : localConfig.pageCountNumber,
              info: allowPageInfo && localConfig.pageInfo,
              type: localConfig.pageType,
              previousNext: true
            }
          : false
      "
      [rowHeight]="
        localConfig.scrollMode === 'virtual' ? localConfig.scrollHeight : 0
      "
      [scrollable]="
        localConfig.scrollMode === 'virtual' ? 'virtual' : 'scrollable'
      "
      [filter]="gridState.filter"
      [sort]="gridState.sort"
      [sortable]="{
        allowUnsort: localConfig.allowUnsort,
        mode: localConfig.sortMode
      }"
      [selectable]="gridSelect"
      [filterable]="localConfig.filterMode"
      [resizable]="localConfig.resizable"
      [kendoGridSelectBy]="getSelectionAttribute"
      [selectedKeys]="selection"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="onCellClick($event)"
      (dblclick)="onDoubleClick()"
      (selectionChange)="onSelectionChange($event)"
    >
      <ng-template
        kendoGridToolbarTemplate
        *ngIf="
          localConfig.title ||
          localConfig.exportToPDF ||
          localConfig.exportToExcel ||
          localConfig.exportToClipBoard ||
          enableDataExchange ||
          enableRemoveAll
        "
      >
        <div fxLayout="row" fxLayoutAlign="end center">
          <div fxFlex="1 1 auto" class="table-title">
            {{ localConfig.title | translate }}
          </div>
          <button
            *ngIf="enableRemoveAll"
            fxFlex="0 0 auto"
            mat-icon-button
            fxFlex="0 0 auto"
            [matTooltip]="'key_removeAll' | translate"
            [disableRipple]="true"
            (click)="onRemoveAllData()"
          >
            <mat-icon
              fontSet="material-icons-outlined"
              class="clipboard-icon"
              style="color: red"
              >delete_outline</mat-icon
            >
          </button>
          <div
            *ngIf="
              enableRemoveAll &&
              (localConfig.exportToClipBoard ||
                localConfig.exportToExcel ||
                localConfig.exportToClipBoard ||
                enableDataExchange)
            "
            style="
              height: 20px;
              width: 2px;
              background-color: lightgray;
              margin-top: 3px;
              margin-left: 10px;
            "
          ></div>
          <button
            *ngIf="enableDataExchange"
            fxFlex="0 0 auto"
            mat-icon-button
            fxFlex="0 0 auto"
            [matTooltip]="'key_importContent' | translate"
            [disableRipple]="true"
            (click)="onImportData()"
          >
            <mat-icon fontSet="material-icons-outlined" class="clipboard-icon"
              >file_download</mat-icon
            >
          </button>
          <button
            *ngIf="enableDataExchange"
            fxFlex="0 0 auto"
            mat-icon-button
            fxFlex="0 0 auto"
            [matTooltip]="'key_exportContent' | translate"
            [disableRipple]="true"
            (click)="onExportData()"
          >
            <mat-icon fontSet="material-icons-outlined" class="clipboard-icon"
              >file_upload</mat-icon
            >
          </button>
          <div
            *ngIf="
              enableDataExchange &&
              (localConfig.exportToClipBoard ||
                localConfig.exportToExcel ||
                localConfig.exportToClipBoard)
            "
            style="
              height: 20px;
              width: 2px;
              background-color: lightgray;
              margin-top: 3px;
              margin-left: 10px;
            "
          ></div>
          <button
            fxFlex="0 0 auto"
            mat-icon-button
            fxFlex="0 0 auto"
            [matTooltip]="'key_exportToClipboard' | translate"
            [disableRipple]="true"
            *ngIf="localConfig.exportToClipBoard"
            (click)="onExportToClipboard()"
          >
            <mat-icon fontSet="material-icons-outlined" class="clipboard-icon"
              >file_copy</mat-icon
            >
          </button>
          <button
            fxFlex="0 0 auto"
            class="export-button"
            kendoGridPDFCommand
            icon="file-pdf"
            [matTooltip]="'key_exportToPDF' | translate"
            *ngIf="false"
          ></button>
          <button
            fxFlex="0 0 auto"
            class="export-button"
            kendoGridExcelCommand
            icon="file-excel"
            [matTooltip]="'key_exportToExcel' | translate"
            *ngIf="localConfig.exportToExcel"
          ></button>
          <div
            fxFlex="none"
            *ngIf="
              localConfig.exportToPDF ||
              localConfig.exportToExcel ||
              localConfig.exportToClipBoard
            "
          >
            <mat-slide-toggle
              [(ngModel)]="localConfig.exportAllPages"
              style="
                font-size: 10px;
                font-weight: 400;
                padding-top: 8px;
                margin-left: 10px;
              "
              [matTooltip]="'key_exportAllPages' | translate"
            ></mat-slide-toggle>
          </div>
        </div>
      </ng-template>
      <ng-template kendoGridNoRecordsTemplate>
        <div *ngIf="errorMessage; else noData" class="warn-color">
          {{ errorMessage }}
        </div>
        <ng-template #noData>
          <div
            *ngIf="showRefreshButton && !refreshButtonClicked; else dataEmpty"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <div style="font-size: 1.2em; margin-bottom: -3px">
              {{ 'key_clickToLoadData' | translate }}
            </div>
            <button
              mat-icon-button
              [matTooltip]="'key_refresh' | translate"
              [disableRipple]="true"
              (click)="onLoadData()"
            >
              <mat-icon
                fontSet="material-icons-outlined"
                class="clipboard-icon"
                style="font-size: 1.6em"
                >refresh</mat-icon
              >
            </button>
          </div>
          <ng-template #dataEmpty>
            <div>
              {{ 'key_noData' | translate }}
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
      <!-- checkbox column -->
      <kendo-grid-column
        *ngIf="gridSelect && editable !== false && hasData"
        [width]="45"
        [headerStyle]="{
          'padding-top': localConfig.cellPadding.toString() + 'px',
          'padding-bottom': localConfig.cellPadding.toString() + 'px'
        }"
        [style]="{
          'vertical-align': 'top',
          'padding-top': localConfig.cellPadding.toString() + 'px',
          'padding-bottom': localConfig.cellPadding.toString() + 'px'
        }"
      >
        <ng-template
          kendoGridHeaderTemplate
          *ngIf="
            localConfig.selectMode === 'multiple' &&
            localConfig.selectAllEnabled !== false
          "
        >
          <input
            class="k-checkbox"
            id="selectAllCheckboxId"
            kendoGridSelectAllCheckbox
            [state]="selectAllState"
            (selectAllChange)="onSelectAllChange($event)"
          />
          <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div
            *ngIf="
              dataItem && localConfig.queryEditableItems && !dataItem.editable
            "
          >
            <mat-icon
              [matTooltip]="localConfig.lockText | translate"
              fontSet="material-icons-outlined"
              class="row-icon"
              [ngClass]="{
                'primary-color': localConfig.lockColor === 'primary',
                'accent-color': localConfig.lockColor === 'accent',
                'warn-color': localConfig.lockColor === 'warn',
                'accent-text-color': localConfig.lockColor === 'disabled'
              }"
              style="line-height: 22px"
              >{{ localConfig.lockIcon }}</mat-icon
            >
          </div>
          <div
            *ngIf="
              dataItem && (!localConfig.queryEditableItems || dataItem.editable)
            "
          >
            <input
              type="checkbox"
              class="k-checkbox"
              id="checkbox-{{ rowIndex }}"
              [kendoGridSelectionCheckbox]="rowIndex"
            />
            <label
              class="k-checkbox-label"
              for="checkbox-{{ rowIndex }}"
            ></label>
          </div>
        </ng-template>
      </kendo-grid-column>
      <!-- object id column -->
      <kendo-grid-column
        title="ObjectID"
        field="ObjectID"
        [sortable]="false"
        [filterable]="false"
        [width]="100"
        [hidden]="true"
      ></kendo-grid-column>
      <!-- other columns -->
      <kendo-grid-column
        *ngFor="let col of allColumns; let i = index"
        [title]="col.title"
        [field]="col.field"
        [sortable]="col.sortable"
        [filterable]="col.filterable"
        [filter]="col.filter"
        [width]="col.width === 0 ? null : col.width < 0 ? 0 : col.width"
        [hidden]="col.width < 0"
        [locked]="col.locked"
        [headerStyle]="{
          'padding-top': localConfig.cellPadding.toString() + 'px',
          'padding-bottom': localConfig.cellPadding.toString() + 'px',
          'background-color':
            col.reportType === 'historical'
              ? historicalReportBackColor
              : 'transparent',
          color:
            col.reportType === 'historical'
              ? historicalReportFrontColor
              : 'inherit'
        }"
        [style]="{
          'vertical-align': col.verticalAlign ?? 'top',
          'padding-top': localConfig.cellPadding.toString() + 'px',
          'padding-bottom': localConfig.cellPadding.toString() + 'px',
          'background-color':
            col.reportType === 'historical'
              ? historicalReportBackColor
              : 'transparent',
          color:
            col.reportType === 'historical'
              ? historicalReportFrontColor
              : 'inherit'
        }"
      >
        <ng-template kendoGridHeaderTemplate let-column>
          <div
            *ngIf="localConfig.objectType && !column.title; else explicitColumn"
            fxLayout="row"
            fxLayoutAlign="start center"
            [style.fontSize.px]="localConfig.fontSize"
            [style.fontWeight]="localConfig.fontWeight + 100"
          >
            <div>
              {{
                column.field
                  | translateAttribute
                    : localConfig.objectType
                    : translationTrigger
                  | async
              }}
            </div>
            <div
              *ngIf="col.reportType === 'historical'"
              fxFlex="none"
              style="height: 18px; margin-left: 20px"
            >
              <span class="material-icons-outlined" style="font-size: 18px"
                >date_range</span
              >
            </div>
          </div>
          <ng-template #explicitColumn>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              [style.fontSize.px]="localConfig.fontSize"
              [style.fontWeight]="localConfig.fontWeight + 100"
            >
              <div>
                {{ column.title | translate }}
              </div>
              <div
                *ngIf="col.reportType === 'historical'"
                fxFlex="none"
                style="height: 18px; margin-left: 20px"
              >
                <span class="material-icons-outlined" style="font-size: 18px"
                  >date_range</span
                >
              </div>
            </div>
          </ng-template>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            *ngIf="i === 0"
            [style.fontWeight]="localConfig.fontWeight"
            [ngClass]="{ 'cell-no-wrap': localConfig.cellNoWrap }"
            [matTooltip]="
              col.tooltipField
                ? (dataItem | extraValue: col.tooltipField)
                : null
            "
          >
            <app-identity-link
              [identity]="{
                ObjectType: dataItem | extraValue: 'ObjectType',
                ObjectID: dataItem | extraValue: 'ObjectID',
                DisplayName: dataItem | extraValue: col.field + ':DisplayName'
              }"
              [defaultAction]="localConfig.defaultLinkAction"
              [linkActions]="localConfig.linkActions"
              [scope]="localConfig.linkScope"
              [navigationKey]="localConfig.navigationKey"
              [noneForm]="localConfig.linkNoneForm"
              [disabled]="localConfig.linkDisabled"
              [historyTimestamp]="historyTimestamp"
              [historyReportName]="historyReportName"
            ></app-identity-link>
          </div>
          <div
            *ngIf="i > 0"
            [style.fontWeight]="localConfig.fontWeight"
            [ngClass]="{ 'cell-no-wrap': localConfig.cellNoWrap }"
          >
            <div
              *ngIf="
                dataItemIsArray(dataItem, {
                  value: col.field,
                  isHistorical: col.reportType === 'historical'
                });
                else singleField
              "
              [matTooltip]="
                col.tooltipField
                  ? (dataItem | extraValue: col.tooltipField)
                  : null
              "
            >
              <div
                *ngIf="
                  dataItemHasMorePages(dataItem, {
                    value: col.field,
                    isHistorical: col.reportType === 'historical'
                  });
                  else resolveField
                "
              >
                <div
                  *ngFor="
                    let element of dataItem
                      | extraValue
                        : {
                            value: col.field,
                            isHistorical: col.reportType === 'historical'
                          }
                  "
                >
                  {{ element | ellipsis: countBeforeEllipsis }}
                </div>
              </div>
              <ng-template #resolveField>
                <div
                  *ngFor="
                    let element of dataItem
                      | extraValue
                        : {
                            value: col.field,
                            isHistorical: col.reportType === 'historical'
                          }
                  "
                >
                  <div
                    *ngIf="
                      element | examValue: 'DisplayName';
                      else guidMultivalueField
                    "
                  >
                    <app-identity-link
                      [identity]="element"
                      [defaultAction]="localConfig.defaultLinkAction"
                      [linkActions]="localConfig.linkActions"
                      [scope]="localConfig.linkScope"
                      [navigationKey]="localConfig.navigationKey"
                      [disabled]="localConfig.linkDisabled"
                      [historyTimestamp]="historyTimestamp"
                    >
                    </app-identity-link>
                  </div>
                  <ng-template #guidMultivalueField>
                    <div *ngIf="isGuid(element); else normalMultiField">
                      <app-identity-link
                        [identity]="{
                          ObjectID: element
                        }"
                        [defaultAction]="localConfig.defaultLinkAction"
                        [linkActions]="localConfig.linkActions"
                        [scope]="localConfig.linkScope"
                        [navigationKey]="localConfig.navigationKey"
                        [disabled]="localConfig.linkDisabled"
                        [historyTimestamp]="historyTimestamp"
                      >
                      </app-identity-link>
                    </div>
                  </ng-template>
                  <ng-template #normalMultiField>
                    <div
                      [matTooltip]="
                        element &&
                        countBeforeEllipsis > 0 &&
                        element.length > countBeforeEllipsis
                          ? element
                          : null
                      "
                    >
                      {{ element | ellipsis: countBeforeEllipsis }}
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </div>
            <ng-template #singleField>
              <div
                *ngIf="
                  dataValueIsReference(dataItem, {
                    value: col.field,
                    isHistorical: col.reportType === 'historical'
                  });
                  else guidField
                "
                [matTooltip]="
                  col.tooltipField
                    ? (dataItem | extraValue: col.tooltipField)
                    : null
                "
              >
                <app-identity-link
                  [identity]="
                    dataItem
                      | extraValue
                        : {
                            value: col.field,
                            isHistorical: col.reportType === 'historical'
                          }
                  "
                  [defaultAction]="localConfig.defaultLinkAction"
                  [linkActions]="localConfig.linkActions"
                  [scope]="localConfig.linkScope"
                  [navigationKey]="localConfig.navigationKey"
                  [disabled]="localConfig.linkDisabled"
                  [historyTimestamp]="historyTimestamp"
                >
                </app-identity-link>
              </div>
              <ng-template #guidField>
                <div
                  *ngIf="
                    dataValueIsGuid(dataItem, {
                      value: col.field,
                      isHistorical: col.reportType === 'historical'
                    });
                    else normalField
                  "
                  [matTooltip]="
                    col.tooltipField
                      ? (dataItem | extraValue: col.tooltipField)
                      : null
                  "
                >
                  <app-identity-link
                    [identity]="{
                      ObjectID:
                        (dataItem
                        | extraValue
                          : {
                              value: col.field,
                              isHistorical: col.reportType === 'historical'
                            })
                    }"
                    [defaultAction]="localConfig.defaultLinkAction"
                    [linkActions]="localConfig.linkActions"
                    [scope]="localConfig.linkScope"
                    [navigationKey]="localConfig.navigationKey"
                    [disabled]="localConfig.linkDisabled"
                    [historyTimestamp]="historyTimestamp"
                  >
                  </app-identity-link>
                </div>
              </ng-template>
              <ng-template #normalField>
                <div
                  *ngIf="!col.showStatus; else statusColumn"
                  [matTooltip]="getTooltip(col, dataItem)"
                >
                  {{
                    dataItem
                      | extraValue
                        : {
                            value: col.field + ':value',
                            format: localConfig.datetimeFormat,
                            isHistorical: col.reportType === 'historical'
                          }
                      | ellipsis: countBeforeEllipsis
                  }}
                </div>
                <ng-template #statusColumn>
                  <div
                    fxLayout="row"
                    fxLayourAlign="start center"
                    [matTooltip]="
                      col.tooltipField
                        ? (dataItem | extraValue: col.tooltipField)
                        : null
                    "
                  >
                    <div
                      *ngIf="getStatusSetting(dataItem, col).color"
                      fxFlex="none"
                      style="
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-top: 5px;
                        margin-right: 10px;
                      "
                      [style.backgroundColor]="
                        getStatusSetting(dataItem, col).color
                      "
                    ></div>
                    <div
                      *ngIf="
                        getStatusSetting(dataItem, col).text !== null &&
                          getStatusSetting(dataItem, col).text !== undefined;
                        else statusValue
                      "
                    >
                      {{ getStatusSetting(dataItem, col).text | translate }}
                    </div>
                    <ng-template #statusValue>
                      <div fxFlex="auto">
                        {{
                          dataItem
                            | extraValue
                              : {
                                  value: col.field + ':value',
                                  format: localConfig.datetimeFormat,
                                  isHistorical: col.reportType === 'historical'
                                }
                        }}
                      </div>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          *ngIf="col.filterable"
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <app-grid-filter
            [field]="column.field"
            [filterService]="filterService"
            [currentFilter]="filter"
            [filterType]="col.filter"
            [dateFormat]="localConfig.datetimeFormat"
          ></app-grid-filter>
          <!-- <kendo-grid-string-filter-menu
            *ngIf="col.filter === 'text'"
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
            operator="startswith"
          >
            <kendo-grid-filter-eq-operator
              *ngIf="showOperator(col, 'eq')"
            ></kendo-grid-filter-eq-operator>
            <kendo-grid-filter-neq-operator
              *ngIf="showOperator(col, 'neq')"
            ></kendo-grid-filter-neq-operator>
            <kendo-grid-filter-startswith-operator
              *ngIf="showOperator(col, 'startswith')"
            ></kendo-grid-filter-startswith-operator>
            <kendo-grid-filter-endswith-operator
              *ngIf="showOperator(col, 'endswith')"
            ></kendo-grid-filter-endswith-operator>
            <kendo-grid-filter-contains-operator
              *ngIf="showOperator(col, 'contains')"
            ></kendo-grid-filter-contains-operator>
            <kendo-grid-filter-isempty-operator
              *ngIf="showOperator(col, 'isempty')"
            ></kendo-grid-filter-isempty-operator>
            <kendo-grid-filter-isnotempty-operator
              *ngIf="showOperator(col, 'isnotempty')"
            ></kendo-grid-filter-isnotempty-operator>
          </kendo-grid-string-filter-menu>
          <kendo-grid-boolean-filter-menu
            *ngIf="col.filter === 'boolean'"
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
          </kendo-grid-boolean-filter-menu>
          <kendo-grid-numeric-filter-menu
            *ngIf="col.filter === 'numeric'"
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-grid-filter-eq-operator
              *ngIf="showOperator(col, 'eq')"
            ></kendo-grid-filter-eq-operator>
            <kendo-grid-filter-neq-operator
              *ngIf="showOperator(col, 'neq')"
            ></kendo-grid-filter-neq-operator>
            <kendo-grid-filter-gt-operator
              *ngIf="showOperator(col, 'gt')"
            ></kendo-grid-filter-gt-operator>
            <kendo-grid-filter-lt-operator
              *ngIf="showOperator(col, 'lt')"
            ></kendo-grid-filter-lt-operator>
          </kendo-grid-numeric-filter-menu>
          <kendo-grid-date-filter-menu
            *ngIf="col.filter === 'date'"
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
            operator="lt"
          >
            <kendo-grid-filter-eq-operator
              *ngIf="showOperator(col, 'eq')"
            ></kendo-grid-filter-eq-operator>
            <kendo-grid-filter-neq-operator
              *ngIf="showOperator(col, 'neq')"
            ></kendo-grid-filter-neq-operator>
            <kendo-grid-filter-before-operator
              *ngIf="showOperator(col, 'before')"
            ></kendo-grid-filter-before-operator>
            <kendo-grid-filter-after-operator
              *ngIf="showOperator(col, 'after')"
            ></kendo-grid-filter-after-operator>
          </kendo-grid-date-filter-menu> -->
        </ng-template>
      </kendo-grid-column>
      <!-- master detail -->
      <ng-template
        *ngIf="
          localConfig.detailType === 'table' ||
          localConfig.detailType === 'component'
        "
        kendoGridDetailTemplate
        let-dataItem
        [kendoGridDetailTemplateShowIf]="showMasterDetail"
      >
        <app-resource-table-detail
          [tableItem]="dataItem"
          [tableConfig]="localConfig"
        ></app-resource-table-detail>
      </ng-template>
      <kendo-grid-pdf
        *ngIf="localConfig.exportToPDF"
        fileName="export.pdf"
        [allPages]="localConfig.exportAllPages"
      >
        <kendo-grid-pdf-margin
          top="1cm"
          left="1cm"
          right="1cm"
          bottom="1cm"
        ></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-excel
        *ngIf="localConfig.exportToExcel"
        fileName="export.xlsx"
        [fetchData]="allData"
      >
      </kendo-grid-excel>
    </kendo-grid>
    <div
      *ngIf="localConfig.scrollMode === 'virtual' && virtualTotalItem"
      class="virtual-pager"
    >
      {{ 'key_totalCount' | translate }}: {{ virtualTotalItem }}
    </div>
  </div>
</div>
