<div style="margin-top: 10px">
  <mat-accordion>
    <mat-expansion-panel
      *ngIf="!hideTarget"
      [expanded]="step === 0"
      [disabled]="launchDisabled"
      (opened)="onSetStep(0)"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [ngClass]="{
              'primary-color': !launchDisabled,
              'disabled-text-color': launchDisabled
            }"
          >
            <div fxFlex="36px">
              <span class="material-icons-outlined">add_task</span>
            </div>
            <div class="expansion-title">
              {{ 'key_selectTarget' | translate }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form autocomplete="off" #targetForm="ngForm">
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row">
            <div>
              <mat-radio-group name="rdoTargetType" [(ngModel)]="targetType">
                <mat-radio-button value="xpb" style="margin-right: 20px"
                  >XPath builder</mat-radio-button
                >
                <mat-radio-button value="idp">Identity picker</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div *ngIf="targetType === 'xpb'; else withIdentityPicker">
            <app-editor-xpath
              #xpbTarget
              name="xpbTarget"
              [config]="xpbConfig"
              [(ngModel)]="xpbAttribute"
              [appXpathValidator]="xpbConfig"
            ></app-editor-xpath>
          </div>
          <ng-template #withIdentityPicker>
            <app-editor-identity
              name="idpTarget"
              [config]="idpConfig"
              [(ngModel)]="idpAttribute"
              (openBrowser)="onOpenIdentityBrowser()"
              (closeBrowser)="onCloseIdentityBrowser()"
            ></app-editor-identity>
          </ng-template>
        </div>
      </form>
      <mat-action-row>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="launchDisabled"
          (click)="onNextStep()"
        >
          {{ 'key_next' | translate }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="!hideWorkflow"
      [expanded]="step === 1"
      [disabled]="launchDisabled"
      (opened)="onSetStep(1)"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [ngClass]="{
              'primary-color': !launchDisabled,
              'disabled-text-color': launchDisabled
            }"
          >
            <div fxFlex="36px">
              <span class="material-icons-outlined">verified</span>
            </div>
            <div class="expansion-title">
              {{ 'key_selectWorkflow' | translate }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-editor-identity
        [config]="idpWorkflowConfig"
        [(ngModel)]="idpWorkflow"
        (openBrowser)="onOpenIdentityBrowser()"
        (closeBrowser)="onCloseIdentityBrowser()"
      ></app-editor-identity>
      <mat-action-row>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="launchDisabled"
          (click)="onNextStep()"
        >
          {{ 'key_next' | translate }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="step === 2"
      [disabled]="launchDisabled"
      (opened)="onSetStep(2)"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [ngClass]="{
              'primary-color': !launchDisabled,
              'disabled-text-color': launchDisabled
            }"
          >
            <div fxFlex="36px">
              <span class="material-icons-outlined">view_in_ar</span>
            </div>
            <div class="expansion-title">
              {{ 'key_launchWorkflow' | translate }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div style="margin-bottom: 20px; display: none">
          <mat-radio-group [(ngModel)]="launchMode">
            <mat-radio-button value="async" style="margin-right: 20px">{{
              'key_runWorkflowAsync' | translate
            }}</mat-radio-button>
            <mat-radio-button value="sync">{{
              'key_runWorkflowSync' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          *ngIf="errorMsg"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="warn-color"
        >
          <div fxFlex="36px">
            <span class="material-icons-outlined">report_problem</span>
          </div>
          <div class="expansion-title">{{ errorMsg | translate }}</div>
        </div>
        <div
          *ngIf="showConfirmation"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="confirmation-frame"
        >
          <div fxFlex="36px" class="primary-color">
            <span class="material-icons-outlined">fmd_bad</span>
          </div>
          <div class="expansion-title primary-color" style="margin-right: 10px">
            {{ 'key_confirmWorkflowLaunch' | translate }}
          </div>
          <button
            mat-icon-button
            color="primary"
            style="margin-top: -5px"
            (click)="onConfirmLaunch()"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
          <button
            mat-icon-button
            style="margin-top: -5px"
            color="warn"
            (click)="onCancelLaunch()"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <div
          *ngIf="showLoading"
          fxLayout="row"
          fxLayoutAlign="start center"
          style="margin-top: 20px"
        >
          <div fxFlex="none" style="margin-right: 20px">
            <mat-spinner [diameter]="32"></mat-spinner>
          </div>
          <button
            mat-stroked-button
            mat-stroked-button
            (click)="onSendToBackground()"
          >
            {{ 'key_sendToBackground' | translate }}
          </button>
        </div>
        <div *ngIf="showResult">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            style="margin-top: 20px"
          >
            <div fxFlex="36px" class="primary-color">
              <span class="material-icons-outlined">check_circle</span>
            </div>
            <div
              class="expansion-title primary-color"
              style="margin-right: 10px"
            >
              {{ 'key_launchWorkflowFinished' | translate }}
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            style="margin-top: 20px"
          >
            <button
              fxFlex="50"
              mat-stroked-button
              style="margin-right: 20px"
              (click)="onNavigateToEvent()"
            >
              {{ 'key_openExecutionEvent' | translate }}
            </button>
            <button
              fxFlex="50"
              mat-stroked-button
              (click)="onNavigateToEventView()"
            >
              {{ 'key_navigateToEventList' | translate }}
            </button>
          </div>
        </div>
      </div>
      <mat-action-row>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="launchDisabled"
          (click)="onLaunchWorkflow()"
        >
          {{ 'key_launch' | translate }}
        </button>
        <button
          fxFlex="none"
          mat-stroked-button
          color="warn"
          (click)="onCancel()"
        >
          {{ 'key_close' | translate }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_launchWorkflow' | translate"
  ></app-popup-title>
</ng-template>
