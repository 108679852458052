<div
  class="page-cover"
  [style.background]="bgImage"
  [style.backgroundSize]="'cover'"
></div>
<div class="page-filter" [style.opacity]="bgFilterOpacity"></div>
<div class="content" [class.mat-elevation-z8]="true">
  <mat-card style="height: 400px">
    <mat-card-content>
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        class="accent-text-color"
      >
        <div
          style="
            font-size: 26px;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 50px;
          "
        >
          {{ 'l10n_pageUnderMaintenance' | translate }}
        </div>
        <mat-icon fxFlex="none" style="font-size: 200px; margin-left: -200px"
          >web</mat-icon
        >
        <mat-icon
          fxFlex="none"
          style="font-size: 80px; margin-left: 160px; margin-top: -50px"
          >settings</mat-icon
        >
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          style="margin-top: 220px; width: 100%"
        >
          <div fxFlex="auto"></div>
          <button mat-stroked-button color="primary" (click)="onLogin()">
            {{ 'l10n_exceptionalLogin' | translate }}
          </button>
        </div>
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="end center"
          style="width: 100%"
        >
          <div
            class="accent-text-color"
            style="margin-top: 5px; font-weight: 400"
          >
            {{ 'l10n_exceptionalLoginDesc' | translate }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
