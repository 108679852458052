import { Component, Injector, AfterViewInit } from '@angular/core';

import { DragulaService } from 'ng2-dragula';

import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';

import { IdentityEditorConfig } from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';
import { ResourceColumnConfig } from '../../models/componentContract.model';

@Component({
  selector: 'app-editor-identity-config',
  templateUrl: './editor-identity-config.component.html',
  styleUrls: ['./editor-identity-config.component.scss'],
})
export class EditorIdentityConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  attributeName: string;

  data: {
    component: DynamicEditor;
    config: IdentityEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  public selectedColumnAttribute: ResourceColumnConfig;
  public columnToAdd: string;

  constructor(injector: Injector, private dragula: DragulaService) {
    super(injector);

    try {
      this.dragula.createGroup('ATTRIBUTESTOSHOW', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngAfterViewInit() {
    this.initComponent();
  }

  onSelectColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedColumnAttribute = ca;
  }

  onAddColumn() {
    if (this.columnToAdd) {
      const pos = this.data.config.attributesToShow.findIndex(
        (item: ResourceColumnConfig) =>
          item.field.toLowerCase() === this.columnToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.attributesToShow.push({
          field: this.columnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        });
      }
    }
    this.columnToAdd = undefined;
  }

  onDeleteColumn(column: ResourceColumnConfig) {
    const index = this.data.config.attributesToShow.findIndex(
      (c) => c.field === column.field
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.config.attributesToShow.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (this.data.config.attributesToShow[lastColumnIndex].locked) {
          this.data.config.attributesToShow[lastColumnIndex].locked = false;
        }
      }

      // remove column
      this.data.config.attributesToShow.splice(index, 1);

      // set focus
      if (column.field === this.selectedColumnAttribute.field) {
        if (this.data.config.attributesToShow.length > 0) {
          this.selectedColumnAttribute = this.data.config.attributesToShow[0];
        } else {
          this.selectedColumnAttribute = undefined;
        }
      }
    }
  }
}
