import { Component, Input, OnInit } from '@angular/core';
import { ReportSeparatorConfig } from '../../models/componentContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { UtilsService } from '../../services/utils.service';
import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';
import { ReportSeparatorConfigComponent } from '../report-separator-config/report-separator-config.component';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { ConfigService } from '../../services/config.service';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-report-separator',
  templateUrl: './report-separator.component.html',
  styleUrls: ['./report-separator.component.scss'],
})
export class ReportSeparatorComponent implements OnInit, DynamicComponent {
  @Input()
  config: ReportSeparatorConfig;

  localConfig: ReportSeparatorConfig;

  displayComponent = false;

  constructor(
    private utils: UtilsService,
    private resource: ResourceService,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent() {
    this.localConfig = new ReportSeparatorConfig();

    this.utils.CopyInto(this.config, this.localConfig, true, true);

    if (!this.localConfig.permissionSets) {
      this.displayComponent = true;
    } else {
      this.displayComponent = this.resource.inPermissionSets(
        this.localConfig.permissionSets
      );
    }

    return this.localConfig;
  }

  resize() {}

  updateDataSource() {}

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: ReportSeparatorConfigComponent,
      width: 800,
      height: 680,
      top: this.configService.getConfig('wizardTopPosition', null),
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = this.localConfig;

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.localConfig = configCopy;
        }
      }),
      switchMap(() => {
        return of(this.localConfig);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }
}
