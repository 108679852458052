import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faWindows } from '@fortawesome/free-brands-svg-icons';
import {
  faCloud,
  faUserAlt,
  faUserCircle,
  faUnlockAlt,
  faCompress,
  faExpandArrowsAlt,
  faEdit,
  faSignOutAlt,
  faSpinner,
  faUsers,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';

import { DragulaModule } from 'ng2-dragula';

import {
  MsalModule,
  MsalInterceptor,
  MsalService,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, UploadInterceptor } from './app.component';

import { CoreModule } from './core/modules/core.module';
import { CustomModule } from './customisation/custom.module';

import 'hammerjs';

import '@progress/kendo-angular-intl/locales/de/calendar';
import '@progress/kendo-angular-intl/locales/zh-Hans/calendar';

import {
  AddtionalTab1Directive,
  AddtionalTab2Directive,
} from './tab-view/tab-view.directive';

import { DemoTeamCreationComponent } from './demo-team-creation/demo-team-creation.component';
import { DemoTeamEditionComponent } from './demo-team-edition/demo-team-edition.component';

import { TestComponent } from './test/test.component';
import { LoginComponent } from './login/login.component';
import { SplashComponent } from './splash/splash.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { ResourcesComponent } from './resources/resources.component';

import { TabViewComponent } from './tab-view/tab-view.component';
import { GridsterViewComponent } from './gridster-view/gridster-view.component';
import { BrandViewComponent } from './brand-view/brand-view.component';
import { OrgStructureComponent } from './org-structure/org-structure.component';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { StructureViewComponent } from './structure-view/structure-view.component';
import { B2bCreationComponent } from './b2b-creation/b2b-creation.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PopupAssignmentComponent } from './popup-assignment/popup-assignment.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { TimeMachineComponent } from './time-machine/time-machine.component';
import { EventComponent } from './event/event.component';
import { EventListComponent } from './event-list/event-list.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WizardSettingComponent } from './wizard-setting/wizard-setting.component';
import { ViewSettingComponent } from './view-setting/view-setting.component';
import { FormViewComponent } from './form-view/form-view.component';
import { ConfigConverterComponent } from './config-converter/config-converter.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './report/report.component';
import { ExpiredPageComponent } from './expired-page/expired-page.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { AllResourcesComponent } from './all-resources/all-resources.component';
import { InpageErrorComponent } from './inpage-error/inpage-error.component';
import { HistoricalStatusComponent } from './historical-status/historical-status.component';
import { ManualTasksComponent } from './manual-tasks/manual-tasks.component';
import { FunctionExpressionComponent } from './function-expression/function-expression.component';

@NgModule({
  declarations: [
    AddtionalTab1Directive,
    AddtionalTab2Directive,

    AppComponent,
    TestComponent,
    LoginComponent,
    SplashComponent,
    SidebarComponent,
    HomeComponent,
    DashboardComponent,
    SettingsComponent,
    ResourcesComponent,

    DemoTeamCreationComponent,
    DemoTeamEditionComponent,

    TabViewComponent,
    GridsterViewComponent,
    BrandViewComponent,
    OrgStructureComponent,
    ResourceListComponent,
    StructureViewComponent,
    B2bCreationComponent,
    ErrorPageComponent,
    PopupAssignmentComponent,
    AdvancedSearchComponent,
    TimeMachineComponent,
    EventComponent,
    EventListComponent,
    WizardSettingComponent,
    ViewSettingComponent,
    FormViewComponent,
    ConfigConverterComponent,
    ApprovalsComponent,
    ReportsComponent,
    ReportComponent,
    ExpiredPageComponent,
    MaintenancePageComponent,
    AllResourcesComponent,
    InpageErrorComponent,
    HistoricalStatusComponent,
    ManualTasksComponent,
    FunctionExpressionComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    DragulaModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    CustomModule,
    MsalModule,
    BrowserAnimationsModule,
    ImageCropperModule,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faWindows,
      faCloud,
      faUnlockAlt,
      faUserAlt,
      faUserCircle,
      faCompress,
      faExpandArrowsAlt,
      faEdit,
      faSignOutAlt,
      faSpinner,
      faUsers,
      faSitemap
    );
  }
}
