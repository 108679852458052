import { Component, OnInit } from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-report-creator',
  templateUrl: './report-creator.component.html',
  styleUrls: ['./report-creator.component.scss'],
})
export class ReportCreatorComponent implements OnInit {
  components: Array<any> = [
    {
      icon: 'analytics',
      name: 'key_report',
      description: 'key_reportDesc',
      buttonIcon: 'add',
    },
    {
      icon: 'align_vertical_center',
      name: 'key_separator',
      description: 'key_separatorDesc',
      buttonIcon: 'add',
    },
    {
      icon: 'download',
      name: 'key_exportReports',
      description: 'key_exportReportsDesc',
      buttonIcon: 'arrow_forward',
    },
    {
      icon: 'upload',
      name: 'key_importReports',
      description: 'key_importReportsDesc',
      buttonIcon: 'arrow_forward',
    },
  ];

  constructor(private windowRef: WindowRef) {}

  ngOnInit(): void {
    console.log();
  }

  onClose(result: any) {
    if (this.windowRef) {
      this.windowRef.close(result);
    }
  }
}
