import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityApprovalComponent } from '../activity-approval/activity-approval.component';
import { IterablesEditorConfig } from 'src/app/core/models/editorContract.model';
import { ModelUpdateMode } from 'src/app/core/models/dataContract.model';

@Component({
  selector: 'app-activity-manual-task',
  templateUrl: './activity-manual-task.component.html',
  styleUrls: ['./activity-manual-task.component.scss'],
})
export class ActivityManualTaskComponent
  extends ActivityApprovalComponent
  implements OnInit
{
  showImmediately: boolean;
  doNotWait: boolean;
  manualTaskType: string;
  taskMessage: string;
  formName: string;
  formD: { [name: string]: string };
  resKey: string;

  attrFormData = {
    dataType: 'Dictionary',
    displayName: 'key_formData',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrFormData',
    value: null,
  };
  configFormData = new IterablesEditorConfig();

  @Input()
  get showFormImmediately() {
    return this.showImmediately;
  }
  set showFormImmediately(value: boolean) {
    this.showImmediately = value;
    this.showFormImmediatelyChange.emit(this.showImmediately);
  }
  @Output()
  showFormImmediatelyChange = new EventEmitter();

  @Input()
  get doNotWaitForResponse() {
    return this.doNotWait;
  }
  set doNotWaitForResponse(value: boolean) {
    this.doNotWait = value;
    this.doNotWaitForResponseChange.emit(this.doNotWait);
  }
  @Output()
  doNotWaitForResponseChange = new EventEmitter();

  @Input()
  get taskType() {
    return this.manualTaskType;
  }
  set taskType(value: string) {
    this.manualTaskType = value;
    this.taskTypeChange.emit(this.manualTaskType);
  }
  @Output()
  taskTypeChange = new EventEmitter();

  @Input()
  get taskStartMessage() {
    return this.taskMessage;
  }
  set taskStartMessage(value: string) {
    this.taskMessage = value;
    this.taskStartMessageChange.emit(this.taskMessage);
  }
  @Output()
  taskStartMessageChange = new EventEmitter();

  @Input()
  get formId() {
    return this.formName;
  }
  set formId(value: string) {
    this.formName = value;
    this.formIdChange.emit(this.formName);
  }
  @Output()
  formIdChange = new EventEmitter();

  @Input()
  get formData() {
    return this.formD;
  }
  set formData(value: { [name: string]: string }) {
    this.formD = value;
    this.formDataChange.emit(this.formD);
  }
  @Output()
  formDataChange = new EventEmitter();

  @Input()
  get responseKey() {
    return this.resKey;
  }
  set responseKey(value: string) {
    this.resKey = value;
    this.responseKeyChange.emit(this.resKey);
  }
  @Output()
  responseKeyChange = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.showFormImmediately = true;

    this.configFormData.saveAs = 'object';
    this.configFormData.iterableType = 'object';
    this.configFormData.hintLimit = 80;
    this.configFormData.updateOn = ModelUpdateMode.blur;
    this.configFormData.customDescription = 'key_formDataDesManualTask';
    this.configFormData.properties = [
      {
        name: 'name',
        displayName: 'key_key',
        type: 'text',
        isKey: true,
        required: true,
        width: 30,
        validation: '^[a-zA-Z0-9_]+$',
      },
      {
        name: 'value',
        displayName: 'key_value',
        type: 'textarea',
        isKey: false,
        required: true,
        width: 70,
      },
    ];

    if (this.formData) {
      this.attrFormData.value = this.formData;
    }
  }

  onFormDataChange() {
    this.formData = this.attrFormData.value;
  }

  onEnterKey(event: any) {
    let tagName = '';
    try {
      tagName = event.target.tagName.toLowerCase();
    } catch {}
    if (tagName !== 'textarea') {
      return false;
    }
  }
}
