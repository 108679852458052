import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { StateCardConfig } from '../../models/componentContract.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-state-card-config',
  templateUrl: './state-card-config.component.html',
  styleUrls: ['./state-card-config.component.scss'],
})
export class StateCardConfigComponent {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: DynamicComponent;
      config: StateCardConfig;
    }
  ) {}

  onRefresh() {
    this.data.component.updateDataSource();
  }

  onAddAllowedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!this.data.config.permissionSets) {
      this.data.config.permissionSets = [];
    }

    if ((value || '').trim()) {
      const index = this.data.config.permissionSets.indexOf(value.trim());
      if (index < 0) {
        this.data.config.permissionSets.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string) {
    const index = this.data.config.permissionSets.indexOf(setName);
    if (index >= 0) {
      this.data.config.permissionSets.splice(index, 1);
    }
  }
}
