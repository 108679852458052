<div fxLayout="column" fxLayoutAlign="center stretch" style="height: 100%">
  <div fxFlex="auto"></div>
  <div
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
    [style.fontSize.px]="localConfig.fontSize"
    style="margin: 0 15px"
  >
    <div fxFlex="none">
      {{ localConfig.text | translate }}
    </div>
    <mat-divider
      *ngIf="localConfig.showDivider"
      fxFlex="auto"
      style="margin-left: 10px"
    ></mat-divider>
  </div>
  <div fxFlex="auto"></div>
</div>
