<!-- View-Setting -->
<div *ngIf="hasAdminRight" fxLayout="column">
  <!-- Header -->
  <div fxFlex="auto" class="setting-header">
    {{ 'l10n_editingForm' | translate }}
  </div>

  <!-- Card -->
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start start">
          <!-- Form-Selector -->
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <!-- Info -->
            <div fxFlex="200px" class="profile-info">
              {{ 'l10n_availableForm' | translate }}
            </div>

            <!-- Selector -->
            <div fxFlex="auto" style="margin-right: 30px; width: 260px">
              <mat-form-field style="width: 100%">
                <mat-label>{{ 'l10n_selectForm' | translate }}</mat-label>
                <mat-select
                  [(value)]="selectedForm"
                  (selectionChange)="onFormSelectionChange()"
                  style="font-size: 16px"
                >
                  <mat-option>--</mat-option>
                  <mat-option
                    *ngFor="let form of availableForms"
                    [value]="form"
                  >
                    {{ form.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
              <button
                mat-stroked-button
                [disabled]="!selectedForm || availableForms.length <= 1"
                (click)="onRemoveForm()"
                color="warn"
              >
                {{ 'key_delete' | translate }}
              </button>
              <button
                mat-stroked-button
                (click)="onAddForm()"
                color="primary"
                style="margin-left: 30px"
              >
                {{ 'key_add' | translate }}
              </button>
              <mat-divider [vertical]="true" style="height: 30px"></mat-divider>
              <button
                mat-stroked-button
                [disabled]="!selectedForm || availableForms.length <= 1"
                color="primary"
                (click)="onExportForm()"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <div>{{ 'key_exportToClipboard' | translate }}</div>
                  <span class="material-icons-outlined">download</span>
                </div>
              </button>
              <button
                mat-stroked-button
                color="primary"
                (click)="onImportForm()"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <div>{{ 'key_importFromClipboard' | translate }}</div>
                  <span class="material-icons-outlined">upload</span>
                </div>
              </button>
            </div>
          </div>

          <!-- Divider -->
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>

          <!-- Form-Options -->
          <div
            *ngIf="showForm"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <!-- Resource type -->
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_resourceType' | translate }}</mat-label>
                <input matInput disabled [value]="selectedForm.objectType" />
              </mat-form-field>
            </div>

            <!-- Type -->
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_type' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedForm.type">
                  <mat-option
                    *ngFor="let option of formTypes"
                    [value]="option"
                    >{{ option }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Icon text -->
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_stateCardIconText' | translate }}</mat-label>
                <input matInput [(ngModel)]="selectedForm.icon" />
              </mat-form-field>
            </div>
          </div>

          <!-- Form Attributes to load -->
          <div
            fxFlex="auto"
            *ngIf="showForm"
            style="margin-top: 20px; width: 640px"
          >
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAttributesToLoad>
                <mat-chip
                  *ngFor="let attr of selectedForm.attributesToLoad"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAttributeToLoad(attr)"
                  style="font-size: 12px"
                >
                  {{ attr }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_attributesToLoad' | translate"
                  [matChipInputFor]="clAttributesToLoad"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAttributeToLoad($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>

          <!-- Add status -->
          <div
            *ngIf="showForm"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div fxFlex="200px">
              <button
                mat-stroked-button
                (click)="onAddStatus()"
                style="margin-top: 12px"
              >
                {{ 'l10n_addStatus' | translate }}
              </button>
            </div>
            <div fxFlex="auto" fxLayout="column">
              <div
                dragula="STATUSATTRIBUTES"
                [(dragulaModel)]="selectedForm.statuses"
              >
                <div
                  *ngFor="let status of selectedForm.statuses; let i = index"
                  fxLayout="row wrap"
                  fxLayoutGap="20px"
                >
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{
                        'key_attributeName' | translate
                      }}</mat-label>
                      <input matInput [(ngModel)]="status.attributeName" />
                    </mat-form-field>
                  </div>
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{
                        'key_attributeValue' | translate
                      }}</mat-label>
                      <input matInput [(ngModel)]="status.attributeValue" />
                    </mat-form-field>
                  </div>
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'l10n_text' | translate }}</mat-label>
                      <input matInput [(ngModel)]="status.text" />
                    </mat-form-field>
                  </div>
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'key_color' | translate }}</mat-label>
                      <mat-select [(value)]="status.color">
                        <mat-option value="primary">{{
                          'key_primary' | translate
                        }}</mat-option>
                        <mat-option value="accent">{{
                          'key_accent' | translate
                        }}</mat-option>
                        <mat-option value="warn">{{
                          'key_warn' | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div fxFlex="0 0 20px" class="handle">
                    <mat-icon
                      style="margin-top: 10px; cursor: pointer; color: darkgray"
                      >menu</mat-icon
                    >
                  </div>
                  <button
                    mat-icon-button
                    color="warn"
                    style="margin-top: 10px"
                    (click)="onRemoveStatus(i)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Structure view -->
          <div
            *ngIf="showForm"
            fxFlex="auto"
            fxLayout="column"
            style="margin-top: 20px"
          >
            <div fxLayout="row" fxLayoutGap="20px">
              <div fxFlex="200px" style="font-weight: 400; padding-top: 15px">
                <mat-checkbox
                  [(ngModel)]="selectedForm.structureView.enabled"
                  >{{ 'l10n_structureView' | translate }}</mat-checkbox
                >
              </div>
              <div fxFlex="860px">
                <mat-form-field [appearance]="'outline'" style="width: 100%">
                  <mat-label>{{ 'l10n_initQuery' | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="selectedForm.structureView.initQuery"
                    [disabled]="!selectedForm.structureView.enabled"
                  />
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
              <div fxFlex="200px"></div>
              <div fxFlex="860px">
                <mat-form-field [appearance]="'outline'" style="width: 100%">
                  <mat-label>{{ 'l10n_childrenQuery' | translate }}</mat-label>
                  <input
                    matInput
                    [(ngModel)]="selectedForm.structureView.childrenQuery"
                    [disabled]="!selectedForm.structureView.enabled"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- Brand view -->
          <div
            *ngIf="showForm"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <!-- Enabled/Disabled -->
            <div fxFlex="200px" style="font-weight: 400; padding-top: 15px">
              <mat-checkbox [(ngModel)]="selectedForm.brandView.enabled">{{
                'l10n_brandView' | translate
              }}</mat-checkbox>
            </div>

            <!-- Show menu -->
            <div fxFlex="200px" style="font-weight: 400; padding-top: 15px">
              <mat-checkbox
                [(ngModel)]="selectedForm.brandView.showButtons"
                [disabled]="!selectedForm.brandView.enabled"
                >{{ 'l10n_showMenu' | translate }}</mat-checkbox
              >
            </div>

            <!-- Use custom component -->
            <div fxFlex="200px" style="font-weight: 400; padding-top: 15px">
              <mat-checkbox
                [(ngModel)]="selectedForm.brandView.useCustomComponent"
                [disabled]="!selectedForm.brandView.enabled"
                >{{ 'l10n_useCustomComponent' | translate }}</mat-checkbox
              >
            </div>
          </div>

          <!-- Custom component settings -->
          <div
            *ngIf="
              showForm && selectedForm.brandView.useCustomComponent === true
            "
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            fxLayoutAlign="start start"
            style="margin-top: 20px"
          >
            <mat-form-field fxFlex="200px">
              <mat-label>{{ 'key_componentID' | translate }}</mat-label>
              <input
                matInput
                [(ngModel)]="selectedForm.brandView.customComponentID"
              />
            </mat-form-field>
            <mat-form-field fxFlex="600px" [appearance]="'fill'">
              <mat-label>{{ 'key_data' | translate }}</mat-label>
              <textarea
                matInput
                [(ngModel)]="selectedForm.brandView.customComponentData"
                [rows]="5"
              ></textarea>
            </mat-form-field>
          </div>

          <!-- Brand view attributes -->
          <div
            *ngIf="
              showForm && selectedForm.brandView.useCustomComponent !== true
            "
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div fxFlex="200px">
              <button
                mat-stroked-button
                [disabled]="!selectedForm.brandView.enabled"
                (click)="onAddBrandAttribute()"
                style="margin-top: 12px"
              >
                {{ 'l10n_addAttribute' | translate }}
              </button>
            </div>

            <!-- Photo attribute -->
            <div fxFlex="200px">
              <mat-form-field [appearance]="'outline'" style="width: 100%">
                <mat-label>{{ 'key_photoAttribute' | translate }}</mat-label>
                <input
                  matInput
                  [(ngModel)]="selectedForm.brandView.photoAttribute"
                  [disabled]="!selectedForm.brandView.enabled"
                />
              </mat-form-field>
            </div>

            <!-- Main attribute -->
            <div fxFlex="200px">
              <mat-form-field [appearance]="'outline'" style="width: 100%">
                <mat-label>{{ 'l10n_mainAttribute' | translate }}</mat-label>
                <input
                  matInput
                  [(ngModel)]="selectedForm.brandView.mainAttribute"
                  [disabled]="!selectedForm.brandView.enabled"
                />
              </mat-form-field>
            </div>

            <!-- Secondary attribute -->
            <div fxFlex="200px">
              <mat-form-field [appearance]="'outline'" style="width: 100%">
                <mat-label>{{
                  'l10n_secondaryAttribute' | translate
                }}</mat-label>
                <input
                  matInput
                  [(ngModel)]="selectedForm.brandView.secondaryAttribute"
                  [disabled]="!selectedForm.brandView.enabled"
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="
              showForm && selectedForm.brandView.useCustomComponent !== true
            "
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div fxFlex="200px"></div>
            <div fxFlex="auto" fxLayout="column">
              <div
                dragula="BRANDATTRIBUTES"
                [(dragulaModel)]="selectedForm.brandView.attributes"
              >
                <div
                  *ngFor="
                    let attribute of selectedForm.brandView.attributes;
                    let i = index
                  "
                  fxLayout="row wrap"
                  fxLayoutGap="20px"
                >
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'key_name' | translate }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="attribute.name"
                        [disabled]="!selectedForm.brandView.enabled"
                      />
                    </mat-form-field>
                  </div>
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'l10n_icon' | translate }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="attribute.icon"
                        [disabled]="!selectedForm.brandView.enabled"
                      />
                    </mat-form-field>
                  </div>
                  <div fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'l10n_iconAlt' | translate }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="attribute.iconAlt"
                        [disabled]="!selectedForm.brandView.enabled"
                      />
                    </mat-form-field>
                  </div>
                  <div *ngIf="attribute.iconAlt" fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'l10n_text' | translate }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="attribute.text"
                        [disabled]="!selectedForm.brandView.enabled"
                      />
                    </mat-form-field>
                  </div>
                  <div *ngIf="attribute.iconAlt" fxFlex="200px">
                    <mat-form-field
                      [appearance]="'outline'"
                      style="width: 100%"
                    >
                      <mat-label>{{ 'l10n_textAlt' | translate }}</mat-label>
                      <input
                        matInput
                        [(ngModel)]="attribute.textAlt"
                        [disabled]="!selectedForm.brandView.enabled"
                      />
                    </mat-form-field>
                  </div>
                  <div
                    fxFlex="100px"
                    style="font-weight: 400; padding-top: 15px"
                  >
                    <mat-checkbox
                      [(ngModel)]="attribute.hidden"
                      [disabled]="!selectedForm.brandView.enabled"
                      >{{ 'key_hidden' | translate }}</mat-checkbox
                    >
                  </div>
                  <div fxFlex="0 0 20px" class="handle">
                    <mat-icon
                      style="margin-top: 10px; cursor: pointer; color: darkgray"
                      >menu</mat-icon
                    >
                  </div>
                  <button
                    mat-icon-button
                    color="warn"
                    style="margin-top: 10px"
                    (click)="onRemoveBrandAttribute(i)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Save -->
          <div
            *ngIf="showForm"
            fxFlex="auto"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-top: 40px; margin-bottom: 10px"
          >
            <button
              mat-stroked-button
              (click)="onSaveForm()"
              color="primary"
              style="margin-left: 30px"
            >
              {{ 'key_save' | translate }}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
