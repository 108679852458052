<div class="full-size-relative">
  <div
    *ngIf="!hasData && !showPlaceHolder"
    fxLayout="row"
    [style.height.px]="height"
    style="color: lightslategray; width: 100%; height: 100%"
  >
    <div fxFlex="auto"></div>
    <div fxFlex="none" fxLayout="column">
      <div fxFlex="auto"></div>
      <fa-icon
        [icon]="['fas', 'spinner']"
        size="3x"
        [fixedWidth]="true"
        [pulse]="true"
      ></fa-icon>
      <div fxFlex="auto"></div>
    </div>
    <div fxFlex="auto"></div>
  </div>
  <div
    *ngIf="!hasData && showPlaceHolder"
    fxLayout="column"
    fxLayoutAlign="center center"
    [style.height.px]="height"
    style="width: 100%; height: 100%"
  >
    <mat-icon
      fxFlex="none"
      style="font-size: 100px; width: 100px; color: #cccccc"
      >widgets</mat-icon
    >
    <div
      fxFlex="none"
      style="
        margin-top: 50px;
        padding-top: 50px;
        font-size: 18px;
        color: #b0b0b0;
      "
    >
      {{ 'key_noTagsFound' | translate }}
    </div>
  </div>
  <div
    *ngIf="hasData"
    echarts
    [options]="options"
    [style.height.px]="height"
    (chartClick)="onChartClick($event)"
  ></div>
</div>
