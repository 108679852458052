<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_frameViewConfig' | translate }}
  </h3>
  <div
    mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      class="first-dialog-item"
    >
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_componentID' | translate"
          [(ngModel)]="data.config.componentID"
        />
      </mat-form-field>
      <div fxFlex="50"></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        style="margin-top: 20px"
      >
        <mat-form-field fxFlex="25">
          <input
            matInput
            type="number"
            min="0"
            max="30"
            [placeholder]="'key_paddingLeft' | translate"
            [(ngModel)]="data.config.paddingLeft"
          />
        </mat-form-field>
        <mat-form-field fxFlex="25">
          <input
            matInput
            type="number"
            min="0"
            max="30"
            [placeholder]="'key_paddingRight' | translate"
            [(ngModel)]="data.config.paddingRight"
          />
        </mat-form-field>
        <mat-form-field fxFlex="25">
          <input
            matInput
            type="number"
            min="0"
            max="30"
            [placeholder]="'key_paddingTop' | translate"
            [(ngModel)]="data.config.paddingTop"
          />
        </mat-form-field>
        <mat-form-field fxFlex="25">
          <input
            matInput
            type="number"
            min="0"
            max="30"
            [placeholder]="'key_paddingBottom' | translate"
            [(ngModel)]="data.config.paddingBottom"
          />
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="100" [appearance]="'fill'">
        <mat-label>{{ 'key_data' | translate }}</mat-label>
        <textarea matInput [(ngModel)]="data.config.data" [rows]="5"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button fxFlex="none" mat-stroked-button (click)="onRefresh()">
      {{ 'key_apply' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
