import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { MatDialogRef } from '@angular/material/dialog';
import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import * as moment from 'moment';

import { ModalType } from '../core/models/componentContract.model';
import {
  BasicResource,
  BroadcastEvent,
  EditorEvent,
  MenuEvent,
  ObjectViewEvent,
  Resource,
  ResourceSet,
  TabViewEvent,
  WizardEvent,
} from '../core/models/dataContract.model';

import { ConfigService } from '../core/services/config.service';
import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';
import { SwapService } from '../core/services/swap.service';
import { UtilsService } from '../core/services/utils.service';
import { WizardService } from '../core/services/wizard.service';
import { CommonService } from './common.service';
import { ComponentService } from '../core/services/component.service';

import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';
import { EditorTextComponent } from '../core/components/editor-text/editor-text.component';
import { ModalComponent } from '../core/components/modal/modal.component';
import { PopupWizardComponent } from '../core/components/popup-wizard/popup-wizard.component';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { OrgStructureComponent } from '../org-structure/org-structure.component';
import { PopupAssignmentComponent } from '../popup-assignment/popup-assignment.component';
import { EditorIdentitiesComponent } from '../core/components/editor-identities/editor-identities.component';
import { EditorXpathComponent } from '../core/components/editor-xpath/editor-xpath.component';
import { HelperService } from '../core/services/helper.service';
import { TransService } from '../core/models/translation.model';
import { WorkflowStarterComponent } from '../core/components/workflow-starter/workflow-starter.component';
import { EditorIterablesComponent } from '../core/components/editor-iterables/editor-iterables.component';

@Injectable({
  providedIn: 'root',
})
export class EventDispatcherService implements OnDestroy {
  private subscription = new Subscription();

  private tsViewEditorEvent: { time: moment.Moment } = { time: null };
  private tsViewAfterInit: { time: moment.Moment } = { time: null };
  private tsViewBeforeSave: { time: moment.Moment } = { time: null };
  private tsViewAddIdentities: { time: moment.Moment } = { time: null };
  private tsViewRemoveIdentities: { time: moment.Moment } = { time: null };
  private tsViewRemoveAllIdentities: { time: moment.Moment } = { time: null };
  private tsTabAfterInit: { time: moment.Moment } = { time: null };

  private isSameEvent(timestamp: { time: moment.Moment }) {
    let retVal = false;
    if (timestamp.time && moment().diff(timestamp.time) < 100) {
      retVal = true;
    }
    timestamp.time = moment();
    return retVal;
  }

  // Object view events
  private onViewEditorEvent(param: ObjectViewEvent) {
    if (this.isSameEvent(this.tsViewEditorEvent)) {
      return;
    }

    if (this.com.callCustomFunction('onViewEditorEvent', param)) {
      return;
    }

    if (param && param.tabView) {
      switch (param.tabView.formName) {
        case 'person':
          {
            if (param.editorEvent) {
              switch (param.editorEvent.attributeName) {
                case 'EditorControl':
                  {
                    // if click event is fired and it is not creation
                    if (
                      param.editorEvent.name === 'click' &&
                      param.editorEvent.resourceId
                    ) {
                      if (param.tabView) {
                        const txtFirstName = param.tabView.getEditor(
                          'FirstName',
                          'userGeneral'
                        );
                        if (txtFirstName) {
                          // read, write value
                          txtFirstName.value = 'Editor Control Test';
                          // set required
                          txtFirstName.config.required = true;
                          // set error
                          txtFirstName.setError(true, 'custom error');
                          // validate value
                          txtFirstName.validateValue();
                          // // set units
                          // txtFirstName.config.units = 6;
                          // // set readonly
                          // txtFirstName.config.readOnly = true;
                          // // set regExp
                          // txtFirstName.config.validation =
                          //   '^[^"/\\[]:;|=,+/*?<>]{1,6}$';
                          // // set isHidden
                          // txtFirstName.config.isHidden = true;
                          // // set access
                          // // txtFirstName.config.accessUsedFor = 'visibility'; // or editability
                          // // txtFirstName.config.accessAllowed = ['test'];
                          // // apply display settings
                          // txtFirstName.applyConfig();
                        }
                      }
                    }
                  }
                  break;
                case 'SaveButtonControl':
                  {
                    if (
                      param.editorEvent.name === 'click' &&
                      param.editorEvent.resourceId
                    ) {
                      // disable save button for 3 seconds
                      this.swap.broadcast({ name: 'disable-save' });
                      setTimeout(() => {
                        this.swap.broadcast({ name: 'enable-save' });
                      }, 3000);
                    }
                  }
                  break;
                case 'WizardControl':
                  {
                    // start a wizard
                    if (param.tabView) {
                      const txtDisplayName = param.tabView.getEditor(
                        'DisplayName',
                        'userGeneral'
                      );
                      const txtDescription = param.tabView.getEditor(
                        'Description',
                        'userGeneral'
                      );
                      if (txtDisplayName && txtDescription) {
                        this.wizard
                          .open('demowizard', {
                            predefinedDisplayName: txtDisplayName.value,
                          })
                          .subscribe((result: any) => {
                            if (result && result.resource) {
                              txtDescription.value = this.utils.ExtraValue(
                                result.resource,
                                'Description:value'
                              );
                            }
                          });
                      }
                    }
                  }
                  break;
                case 'ServiceControl':
                  {
                    const txtAccountName = param.tabView.getEditor(
                      'accountname',
                      'general'
                    ) as EditorTextComponent;
                    if (
                      txtAccountName &&
                      param.editorEvent &&
                      param.editorEvent.resourceId
                    ) {
                      txtAccountName.setAutoCompleteOptions(
                        this.resource
                          .executeWorkflow(
                            '',
                            'sync',
                            [param.editorEvent.resourceId],
                            '',
                            '006d29ad-c19e-4450-848d-20e0cf35f75c'
                          )
                          .pipe(
                            switchMap((result: any) => {
                              if (result && result.resourceResponses) {
                                const keys = Object.keys(
                                  result.resourceResponses
                                );
                                if (keys.length === 1) {
                                  const resp =
                                    result.resourceResponses[keys[0]];
                                  if (
                                    resp &&
                                    resp.workflowData &&
                                    resp.workflowData.availableNames
                                  ) {
                                    txtAccountName.openAutoComplete();

                                    const names: string[] =
                                      resp.workflowData.availableNames;
                                    const nameOptions = names.map(
                                      (name: string) => {
                                        return { text: name, value: name };
                                      }
                                    );
                                    return of(nameOptions);
                                  }
                                }
                              }
                              return EMPTY;
                            }),
                            catchError((err: HttpErrorResponse) => {
                              this.modal.show(
                                ModalType.error,
                                'key_error',
                                this.utils.getServiceError(err)
                              );
                              return EMPTY;
                            })
                          )
                      );
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
          break;
        case 'ocgpamgroup':
          {
            if (param.editorEvent) {
              switch (param.editorEvent.attributeName) {
                case 'btnAccess':
                  {
                    if (
                      param.tabView &&
                      param.editorEvent.name === 'click' &&
                      param.editorEvent.resourceId
                    ) {
                      if (param.tabView) {
                        const idpCandidates = param.tabView.getEditor(
                          'ocgCandidateRefs',
                          'access'
                        );
                        if (idpCandidates) {
                          const candidates =
                            idpCandidates.value as Array<BasicResource>;
                          const loginID = this.utils.ExtraValue(
                            this.resource.loginUser,
                            'ObjectID'
                          );
                          if (loginID && candidates && candidates.length > 0) {
                            if (
                              candidates.findIndex(
                                (c) =>
                                  this.utils
                                    .ExtraValue(c, 'ObjectID')
                                    .toLowerCase() === loginID.toLowerCase()
                              ) >= 0
                            ) {
                              const userName = this.utils.ExtraValue(
                                this.resource.loginUser,
                                'AccountName:value'
                              );
                              const groupID = this.utils.ExtraValue(
                                param.tabView.currentResource,
                                'ObjectID:value'
                              );
                              const txtGroupName = param.tabView.getEditor(
                                'AccountName',
                                'access'
                              );
                              if (
                                userName &&
                                groupID &&
                                txtGroupName &&
                                txtGroupName.value
                              ) {
                                let savingProgress: MatDialogRef<
                                  ModalComponent,
                                  any
                                >;
                                this.subscription.add(
                                  this.wizard
                                    .open('pamaccess')
                                    .pipe(
                                      switchMap((result: any) => {
                                        if (result && result.resource) {
                                          const resourceToSave =
                                            this.utils.ToSaveResource(
                                              result.resource
                                            );
                                          resourceToSave.DisplayName = `${txtGroupName.value} -> ${userName}`;
                                          resourceToSave.ocgObjectType =
                                            'PAMAssignment';
                                          resourceToSave.ocgLinkSourceRef =
                                            groupID;
                                          resourceToSave.ocgLinkTargetRef =
                                            this.utils.ExtraValue(
                                              this.resource.loginUser,
                                              'ObjectID'
                                            );
                                          resourceToSave.ocgObjectID =
                                            txtGroupName.value;
                                          resourceToSave.ocgADDN = userName;
                                          if (
                                            this.utils.ExtraValue(
                                              resourceToSave,
                                              'ocgIsActive'
                                            )
                                          ) {
                                            const dtStart = moment();
                                            resourceToSave.ocgValidFrom =
                                              dtStart.format();
                                            const dtEnd = dtStart.add(
                                              parseInt(
                                                this.utils.ExtraValue(
                                                  resourceToSave,
                                                  'ocgObjectSource'
                                                ),
                                                10
                                              ),
                                              'm'
                                            );
                                            resourceToSave.ocgValidTo =
                                              dtEnd.format();
                                          } else {
                                            const dtEnd = moment(
                                              this.utils.ExtraValue(
                                                resourceToSave,
                                                'ocgValidFrom'
                                              )
                                            ).add(
                                              parseInt(
                                                this.utils.ExtraValue(
                                                  resourceToSave,
                                                  'ocgObjectSource'
                                                ),
                                                10
                                              ),
                                              'm'
                                            );
                                            resourceToSave.ocgValidTo =
                                              dtEnd.format();
                                          }
                                          savingProgress = this.modal.show(
                                            ModalType.progress,
                                            'key_savingChanges',
                                            '',
                                            '300px'
                                          );
                                          return this.resource.createResource(
                                            resourceToSave
                                          );
                                        } else {
                                          return EMPTY;
                                        }
                                      }),
                                      tap(() => {
                                        const idlAccess =
                                          param.tabView.getEditor(
                                            'idlAccess',
                                            'access'
                                          ) as EditorIdentitiesComponent;
                                        if (idlAccess) {
                                          idlAccess.refresh();
                                        }
                                      }),
                                      catchError((error: HttpErrorResponse) => {
                                        this.modal.show(
                                          ModalType.error,
                                          'key_error',
                                          this.utils.parseErrorMessage(
                                            error.error
                                          )
                                        );
                                        if (savingProgress) {
                                          savingProgress.close();
                                        }
                                        return EMPTY;
                                      }),
                                      finalize(() => {
                                        if (savingProgress) {
                                          savingProgress.close();
                                        }
                                      })
                                    )
                                    .subscribe()
                                );
                              }
                            } else {
                              this.modal.show(
                                ModalType.info,
                                'key_warning',
                                'l10n_candidatesDes'
                              );
                            }
                          } else {
                            this.modal.show(
                              ModalType.info,
                              'key_warning',
                              'l10n_candidatesDes'
                            );
                          }
                        }
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
          break;
        case 'permissionrule':
          {
            if (param.editorEvent) {
              const arrPermissions = param.tabView.getEditor(
                'permissions',
                'permissinrule_permission'
              );
              if (arrPermissions) {
                const resultArray = arrPermissions.value
                  ? Array.from(arrPermissions.value)
                  : [];
                switch (param.editorEvent.attributeName) {
                  case 'operationRead#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Read');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Read'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  case 'operationCreate#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Create');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Create'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  case 'operationModify#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Modify');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Modify'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  case 'operationAdd#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Add');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Add'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  case 'operationRemove#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Remove');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Remove'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  case 'operationDelete#':
                    {
                      if (param.editorEvent.parameter === true) {
                        arrPermissions.value = resultArray.concat('Delete');
                      } else if (param.editorEvent.parameter === false) {
                        const pos = resultArray.findIndex(
                          (p: string) => p === 'Delete'
                        );
                        if (pos >= 0) {
                          resultArray.splice(pos, 1);
                          arrPermissions.value = resultArray;
                        }
                      }
                    }
                    break;
                  default:
                    break;
                }
              }
              switch (param.editorEvent.attributeName) {
                case 'requestorxpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'requestorxpath',
                      'permissinrule_permission'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isrequestorxpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourcebeforexpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourcebeforexpath',
                      'permissinrule_permission'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourcebeforexpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourceafterxpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourceafterxpath',
                      'permissinrule_permission'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourceafterxpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'requestorxpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'requestorxpathtemplate',
                      'permissinrule_permission'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isrequestorxpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourcebeforexpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourcebeforexpathtemplate',
                      'permissinrule_permission'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourcebeforexpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourceafterxpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourceafterxpathtemplate',
                      'permissinrule_permission'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourceafterxpathnegated',
                      'permissinrule_permission'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
          break;
        case 'requestbasedworkflowtrigger':
          {
            if (param.editorEvent) {
              let changeType = '';
              const addChangeType = param.editorEvent.parameter;
              switch (param.editorEvent.attributeName) {
                case 'changeTypeCreate#':
                  {
                    changeType = 'Create';
                  }
                  break;
                case 'changeTypeRead#':
                  {
                    changeType = 'Read';
                  }
                  break;
                case 'changeTypeModify#':
                  {
                    changeType = 'Modify';
                  }
                  break;
                case 'changeTypeDelete#':
                  {
                    changeType = 'Delete';
                  }
                  break;
                case 'changeTypeAdd#':
                  {
                    changeType = 'Add';
                  }
                  break;
                case 'changeTypeRemove#':
                  {
                    changeType = 'Remove';
                  }
                  break;
                case 'requestorxpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'requestorxpath',
                      'triggerCondition'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isrequestorxpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourcebeforexpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourcebeforexpath',
                      'triggerCondition'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourcebeforexpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourceafterxpathtemplate':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourceafterxpath',
                      'triggerCondition'
                    );
                    if (txtTarget && txtTarget.value) {
                      txtTarget.value = '';
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourceafterxpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'requestorxpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'requestorxpathtemplate',
                      'triggerCondition'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isrequestorxpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourcebeforexpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourcebeforexpathtemplate',
                      'triggerCondition'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourcebeforexpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'resourceafterxpath':
                  {
                    const txtTarget = param.tabView.getEditor(
                      'resourceafterxpathtemplate',
                      'triggerCondition'
                    );
                    if (
                      txtTarget &&
                      txtTarget.value &&
                      txtTarget.value.length !== 0
                    ) {
                      txtTarget.value = [];
                    }
                    const chkTarget = param.tabView.getEditor(
                      'isresourceafterxpathnegated',
                      'triggerCondition'
                    );
                    if (chkTarget && chkTarget.value) {
                      chkTarget.value = false;
                    }
                  }
                  break;
                case 'allattributes':
                  {
                    const txtChangeAttributes = param.tabView.getEditor(
                      'changedattributes',
                      'triggerCondition'
                    );
                    if (txtChangeAttributes) {
                      if (param.editorEvent.parameter === true) {
                        if (
                          txtChangeAttributes.value &&
                          txtChangeAttributes.value.length > 0
                        ) {
                          txtChangeAttributes.value = [];
                        }
                        txtChangeAttributes.config.readOnly = true;
                        txtChangeAttributes.applyConfig();
                      } else {
                        txtChangeAttributes.config.readOnly = false;
                        txtChangeAttributes.applyConfig();
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
              if (changeType !== '') {
                const txtChangeTypes = param.tabView.getEditor(
                  'resourcechangedtypes',
                  'general'
                );
                if (txtChangeTypes) {
                  if (addChangeType) {
                    if (!txtChangeTypes.value) {
                      txtChangeTypes.value = [changeType];
                    } else {
                      const arr: Array<string> = txtChangeTypes.value;
                      if (arr) {
                        arr.push(changeType);
                        txtChangeTypes.value = arr;
                      }
                    }
                  } else {
                    if (txtChangeTypes.value) {
                      const arr: Array<string> = txtChangeTypes.value;
                      if (arr) {
                        const pos = arr.indexOf(changeType);
                        if (pos >= 0) {
                          arr.splice(pos, 1);
                          txtChangeTypes.value = arr;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case 'timedworkflowtrigger':
          {
            if (param.editorEvent) {
              switch (param.editorEvent.attributeName) {
                case 'xpath':
                  {
                    const target = param.tabView.getEditor(
                      'xpathtemplate',
                      'general'
                    );
                    if (target && target.value && target.value.length !== 0) {
                      target.value = [];
                    }
                  }
                  break;
                case 'xpathtemplate':
                  {
                    const target = param.tabView.getEditor('xpath', 'general');
                    if (target && target.value) {
                      target.value = '';
                    }
                  }
                  break;
                case 'btnResetRuns':
                  {
                    if (param.editorEvent.resourceId) {
                      const confirm = this.modal.show(
                        ModalType.confirm,
                        'key_confirmation',
                        'key_confirmResetTWT'
                      );
                      this.subscription.add(
                        confirm
                          .afterClosed()
                          .pipe(
                            switchMap((confirmResult: string) => {
                              if (confirmResult && confirmResult === 'yes') {
                                return this.resource.resetTriggerRuns(
                                  param.editorEvent.resourceId
                                );
                              }
                              return EMPTY;
                            }),
                            tap(() => {
                              this.swap.broadcast({
                                name: 'refresh-attribute',
                                parameter: 'status',
                              });
                            }),
                            catchError((err) => {
                              this.modal.show(
                                ModalType.error,
                                'key_error',
                                err.error ?? err.message ?? 'key_error'
                              );
                              return EMPTY;
                            })
                          )
                          .subscribe()
                      );
                    }
                  }
                  break;
                case 'btnLaunchWorkflow':
                  {
                    const txtXpath = param.tabView.getEditor(
                      'xpath',
                      'executions'
                    );
                    const idpXpathTemplate = param.tabView.getEditor(
                      'xpathtemplate',
                      'executions'
                    );
                    const idpWorkflows = param.tabView.getEditor(
                      'workflows',
                      'executions'
                    );

                    let errMsg = '';
                    let workflowId = '';
                    let xpath = '';
                    let usingTemplate = false;
                    if (idpWorkflows && idpWorkflows.value) {
                      if (idpWorkflows.value.length === 1) {
                        workflowId = this.utils.ExtraValue(
                          idpWorkflows.value[0],
                          'ObjectID'
                        );
                        if (workflowId) {
                          if (txtXpath && txtXpath.value) {
                            xpath = txtXpath.value;
                          } else if (
                            idpXpathTemplate &&
                            idpXpathTemplate.value &&
                            idpXpathTemplate.value.length === 1
                          ) {
                            usingTemplate = true;
                            const templateId = this.utils.ExtraValue(
                              idpXpathTemplate.value[0],
                              'ObjectID'
                            );
                            if (templateId) {
                              this.subscription.add(
                                this.resource
                                  .getResourceByID(templateId, ['xpath'])
                                  .pipe(
                                    switchMap((result: Resource) => {
                                      if (result.xpath) {
                                        return this.helper.launchWorkflow(
                                          null,
                                          result.xpath,
                                          workflowId
                                        );
                                      } else {
                                        this.modal.show(
                                          ModalType.error,
                                          'key_error',
                                          'key_targetNotFound'
                                        );
                                        return EMPTY;
                                      }
                                    })
                                  )
                                  .subscribe()
                              );
                            } else {
                              errMsg = 'key_targetNotFound';
                            }
                          } else {
                            errMsg = 'key_targetNotFound';
                          }
                        } else {
                          errMsg = 'key_workflowNotFound';
                        }
                      } else {
                        errMsg = 'key_executeOnlyOneWorkflow';
                      }
                    } else {
                      errMsg = 'key_workflowNotFound';
                    }

                    if (!errMsg) {
                      if (!usingTemplate) {
                        this.subscription.add(
                          this.helper
                            .launchWorkflow(null, xpath, workflowId)
                            .subscribe()
                        );
                      }
                    } else {
                      this.modal.show(ModalType.error, 'key_error', errMsg);
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
          break;
        case 'dataflowrule':
          {
            if (param.editorEvent) {
              switch (param.editorEvent.attributeName) {
                case 'recalculation#':
                  {
                    if (param.editorEvent.resourceId) {
                      this.subscription.add(
                        this.modal
                          .show(
                            ModalType.confirm,
                            'key_confirmation',
                            'l10n_recalculateDataFlowRule'
                          )
                          .afterClosed()
                          .pipe(
                            switchMap((confirmResult: string) => {
                              if (confirmResult && confirmResult === 'yes') {
                                return this.resource.recalculateDataFlowRule(
                                  param.editorEvent.resourceId
                                );
                              }
                              return EMPTY;
                            })
                          )
                          .subscribe()
                      );
                    }
                  }
                  break;
                case 'attributeflowspecifications':
                  if (
                    param.editorEvent.payload &&
                    param.editorEvent.payload.pos >= 0
                  ) {
                    const dfData = param.editorEvent.parameter;
                    const dfPos = param.editorEvent.payload.pos;
                    const iteSpecification = param.tabView.getEditor(
                      param.editorEvent.attributeName,
                      param.tabView.getCurrentTabName()
                    ) as EditorIterablesComponent;
                    if (iteSpecification) {
                      switch (param.editorEvent.payload.property) {
                        case 'nullwhendisconnected':
                          if (dfData[dfPos].nullwhendisconnected === true) {
                            dfData[dfPos].allowoverwrite = true;
                            dfData[dfPos].allownulloverwrite = true;
                            iteSpecification.value = dfData;
                          }
                          break;
                        case 'allownulloverwrite':
                          if (dfData[dfPos].allownulloverwrite === true) {
                            dfData[dfPos].allowoverwrite = true;
                            iteSpecification.value = dfData;
                          } else if (
                            dfData[dfPos].allownulloverwrite === false
                          ) {
                            dfData[dfPos].nullwhendisconnected = false;
                            iteSpecification.value = dfData;
                          }
                          break;
                        case 'allowoverwrite':
                          if (dfData[dfPos].allowoverwrite === false) {
                            dfData[dfPos].allownulloverwrite = false;
                            dfData[dfPos].nullwhendisconnected = false;
                            iteSpecification.value = dfData;
                          }
                          break;
                        default:
                          break;
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
          break;
        default:
          break;
      }
    }
  }

  private onViewAfterInit(param: ObjectViewEvent) {
    if (this.isSameEvent(this.tsViewAfterInit)) {
      return;
    }

    if (this.com.callCustomFunction('onViewAfterInit', param)) {
      return;
    }

    // // Example to change city combo box to text box
    // if (param.tabView) {
    //   const cmbCity = param.tabView.getEditor(
    //     'City',
    //     'userGeneral'
    //   ) as EditorSelectComponent;
    //   if (cmbCity) {
    //     cmbCity.config.controlType = 'text';
    //   }
    // }

    if (param && param.tabView) {
      switch (param.tabView.formName) {
        case 'permissionrule':
          {
            const arrPermissions = param.tabView.getEditor(
              'permissions',
              'permissinrule_permission'
            );
            if (arrPermissions) {
              if (arrPermissions.value && Array.isArray(arrPermissions.value)) {
                arrPermissions.value.forEach((p: string) => {
                  switch (p.toLowerCase()) {
                    case 'read':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationRead#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    case 'create':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationCreate#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    case 'modify':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationModify#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    case 'add':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationAdd#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    case 'remove':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationRemove#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    case 'delete':
                      {
                        const targetEditor = param.tabView.getEditor(
                          'operationDelete#',
                          'permissinrule_permission'
                        );
                        if (targetEditor) {
                          targetEditor.value = true;
                        }
                      }
                      break;
                    default:
                      break;
                  }
                });
              }
            }
          }
          break;
        case 'requestbasedworkflowtrigger':
          {
            const txtChangeTypes = param.tabView.getEditor(
              'resourcechangedtypes',
              'general'
            );
            if (txtChangeTypes) {
              if (txtChangeTypes.value && Array.isArray(txtChangeTypes.value)) {
                txtChangeTypes.value.forEach((p: string) => {
                  const editorName = `changeType${p}#`;
                  const targetEditor = param.tabView.getEditor(
                    editorName,
                    'general'
                  );
                  if (targetEditor) {
                    targetEditor.value = true;
                  }
                });
              }
            }
          }
          break;
        case 'timedworkflowtrigger':
          {
            const txtNumberOfRuns = param.tabView.getEditor(
              'numberOfRuns#',
              'executions'
            );
            const txtNextExecutionTime = param.tabView.getEditor(
              'nextExecutionTime#',
              'executions'
            );
            const btnResetRuns = param.tabView.getEditor(
              'btnResetRuns',
              'executions'
            );
            if (
              param.tabView.currentResourceID &&
              txtNumberOfRuns &&
              txtNextExecutionTime &&
              btnResetRuns
            ) {
              const dtFormat = `${this.translate.instant(
                'key_dateFormat'
              )} ${this.translate.instant('key_timeFormat')}`;
              btnResetRuns.config.readOnly = false;
              btnResetRuns.applyConfig();

              this.subscription.add(
                this.resource
                  .getTriggerRunInfo(param.tabView.currentResourceID)
                  .pipe(
                    tap((result: any) => {
                      if (result) {
                        if (
                          result.numberOfRuns !== null &&
                          result.numberOfRuns !== undefined
                        ) {
                          txtNumberOfRuns.value = String(result.numberOfRuns);
                        }
                        if (result.nextExecutionTime) {
                          txtNextExecutionTime.value = moment(
                            result.nextExecutionTime
                          ).format(this.utils.ToMomentFormat(dtFormat));
                        }
                      }
                    })
                  )
                  .subscribe()
              );
            }
          }
          break;
        case 'dataflowrule':
          {
            const iteSpecification = param.tabView.getEditor(
              'attributeflowspecifications',
              param.tabView.getCurrentTabName()
            ) as EditorIterablesComponent;
            if (iteSpecification && iteSpecification.value) {
              iteSpecification.value.forEach((s: any) => {
                if (
                  s.nullwhendisconnected === null ||
                  s.nullwhendisconnected === undefined
                ) {
                  s.nullwhendisconnected = s.allownulloverwrite;
                }
              });
            }
          }
          break;
        default:
          break;
      }
    }
  }

  private onViewBeforeSave(param: ObjectViewEvent) {
    if (this.isSameEvent(this.tsViewBeforeSave)) {
      return;
    }

    if (this.com.callCustomFunction('onViewBeforeSave', param)) {
      return;
    }

    if (param && param.tabView) {
      switch (param.tabView.formName) {
        case 'workflow':
        case 'workflowtemplate':
          {
            if (param.parameter === 'workflowDesign') {
              const editor = param.tabView.getEditor(
                'workflowdescription',
                param.parameter
              );
              if (editor && editor.value) {
                if (editor.value.debuginfotoenable) {
                  this.subscription.add(
                    this.resource
                      .enableWorkflowDetailmessage(
                        editor.value.debuginfotoenable
                      )
                      .subscribe()
                  );
                }
                if (editor.value.debuginfotodisable) {
                  this.subscription.add(
                    this.resource
                      .disableWorkflowDetailmessage(
                        editor.value.debuginfotodisable
                      )
                      .subscribe()
                  );
                }
                delete editor.value.debuginfotoenable;
                delete editor.value.debuginfotodisable;
              }
            }
          }
          break;
        default:
          break;
      }
    }

    // apply statement of xpath-editor
    const observableBatch = [];
    if (param && param.parameter && param.tabView && param.tabView.tabDefs) {
      const pos = param.tabView.tabDefs.findIndex(
        (t) => t.name === param.parameter
      );
      if (pos >= 0) {
        const tabDef = param.tabView.tabDefs[pos];
        if (tabDef.attributes) {
          const xpathDefs: any[] = tabDef.attributes.filter((t) => {
            return t.editorType === 'xpath';
          });
          if (xpathDefs) {
            xpathDefs.forEach((def: any) => {
              const xpathControl = param.tabView.getEditor(
                def.attributeName,
                param.parameter
              ) as EditorXpathComponent;
              if (xpathControl) {
                xpathControl.applyStatement();
                observableBatch.push(xpathControl.onChangeObs());
              }
            });
          }
        }
      }
    }

    // send message to save changes
    if (observableBatch.length > 0) {
      forkJoin(observableBatch)
        .pipe(
          catchError((err: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.parseErrorMessage(err.error)
            );
            return of(EMPTY);
          })
        )
        .subscribe(() => {
          this.swap.broadcast({
            name: 'save-attribute',
            parameter: param.parameter,
          });
        });
    } else {
      this.swap.broadcast({
        name: 'save-attribute',
        parameter: param.parameter,
      });
    }
  }

  private onViewAddIdentities(param: any) {
    if (this.isSameEvent(this.tsViewAddIdentities)) {
      return;
    }

    if (this.com.callCustomFunction('onViewAddIdentities', param)) {
      return;
    }

    if (param && param.resourceType) {
      let handled = false;

      // generic handler
      if (param.editor && param.editor.conf && param.editor.conf.linkType) {
        if (param.editor.conf.linkType === 'intermediat') {
          if (
            param.editor.conf.intermediateObjectType &&
            param.editor.conf.intermediateAttributeForSource &&
            param.editor.conf.intermediateAttributeForTarget
          ) {
            handled = true;
            const objs = param.values.map((v: any) => {
              return {
                DisplayName: this.utils.ExtraValue(v, 'DisplayName'),
                ObjectID: this.utils.ExtraValue(v, 'ObjectID'),
              };
            });
            this.subscription.add(
              this.helper
                .addIntermediateObject(
                  param.editor.conf.intermediateObjectType,
                  param.editor.conf.intermediateObjectName,
                  param.editor.conf.intermediateAttributeForSource,
                  param.editor.conf.intermediateAttributeForTarget,
                  {
                    DisplayName: param.resourceName,
                    ObjectID: param.resourceID,
                  },
                  objs,
                  param.editor.config.intermediateWizardToStart,
                  param.editor.conf.intermediateAttributes,
                  param.editor
                )
                .subscribe()
            );
          }
        } else if (param.editor.config.linkedAttribute) {
          handled = true;
          const ids = param.values.map((v: any) =>
            this.utils.ExtraValue(v, 'ObjectID')
          );
          switch (param.editor.conf.linkType) {
            case 'directlink':
              this.subscription.add(
                this.helper
                  .addDirectMember(
                    param.editor.conf.linkedAttribute,
                    param.resourceID,
                    ids,
                    param.editor
                  )
                  .subscribe()
              );
              break;
            case 'backlink':
              this.subscription.add(
                this.helper
                  .addBackLinkMember(
                    param.editor.conf.linkedAttribute,
                    param.resourceID,
                    ids,
                    param.editor
                  )
                  .subscribe()
              );
              break;
            case 'assignment':
              {
                const objs = param.values.map((v: any) => {
                  return {
                    DisplayName: this.utils.ExtraValue(v, 'DisplayName'),
                    ObjectID: this.utils.ExtraValue(v, 'ObjectID'),
                  };
                });
                if (param.editor.conf.linkedAttribute) {
                  const assignmentParams: string[] =
                    param.editor.config.linkedAttribute.split('|');
                  if (assignmentParams.length === 3) {
                    this.subscription.add(
                      this.helper
                        .addAssignment(
                          {
                            DisplayName: param.resourceName,
                            ObjectID: param.resourceID,
                          },
                          objs,
                          assignmentParams[0].toLowerCase() === 'singlesource',
                          assignmentParams[1],
                          assignmentParams[2],
                          param.editor
                        )
                        .subscribe()
                    );
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
      // deprecated
      else if (param.editor && param.editor.conf && param.editor.conf.name) {
        const editorName: string = param.editor.conf.name;
        if (editorName.toLowerCase().startsWith('#directmember-')) {
          handled = true;
          const ids = param.values.map((v: any) =>
            this.utils.ExtraValue(v, 'ObjectID')
          );
          this.subscription.add(
            this.helper
              .addDirectMember(
                param.attribute,
                param.resourceID,
                ids,
                param.editor
              )
              .subscribe()
          );
        }
        if (editorName.toLowerCase().startsWith('#backlinkmember-')) {
          handled = true;
          const ids = param.values.map((v: any) =>
            this.utils.ExtraValue(v, 'ObjectID')
          );
          this.subscription.add(
            this.helper
              .addBackLinkMember(
                param.attribute,
                param.resourceID,
                ids,
                param.editor
              )
              .subscribe()
          );
        }
      }

      // special handler
      if (!handled) {
        switch (param.resourceType.toLowerCase()) {
          case 'person':
            switch (param.attribute) {
              case 'GroupMember':
              case 'SetMember': {
                const ids = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'ObjectID')
                );
                this.subscription.add(
                  this.helper
                    .addBackLinkMember(
                      'ExplicitMember',
                      param.resourceID,
                      ids,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              case 'RoleMemberDirect': {
                let ids: Array<string>;
                let progress: MatDialogRef<ModalComponent>;

                const windowRef = this.window.open({
                  title: '',
                  content: PopupAssignmentComponent,
                  titleBarContent: null,
                  width: 420,
                });

                this.subscription.add(
                  windowRef.result
                    .pipe(
                      switchMap((windowResult: any) => {
                        this.swap.broadcast({
                          name: 'hide-overlay',
                          parameter: undefined,
                        });

                        if (windowResult instanceof WindowCloseResult) {
                          return EMPTY;
                        } else {
                          progress = this.modal.show(
                            ModalType.progress,
                            'key_savingChanges',
                            '',
                            '300px'
                          );
                          const observableBatch = [];
                          ids = param.values.map((v: any) =>
                            this.utils.ExtraValue(v, 'ObjectID')
                          );
                          const names = param.values.map((v: any) =>
                            this.utils.ExtraValue(v, 'DisplayName')
                          );

                          ids.forEach((id: string, index: number) => {
                            const existanceQuery = `/ocgAssignment[ocgLinkTargetRef='${param.resourceID}' and ocgLinkSourceRef='${id}' and ocgObjectType='RoleAssignment' and ocgObjectScope='person']`;

                            observableBatch.push(
                              this.resource
                                .getResourceCount(existanceQuery)
                                .pipe(
                                  switchMap((count: number) => {
                                    if (!count || count === 0) {
                                      const resource: Resource = {
                                        DisplayName: `Role-Assignment: ${names[index]} -> ${param.resourceName}`,
                                        ObjectType: 'ocgAssignment',
                                        ocgObjectType: 'RoleAssignment',
                                        ocgObjectScope: 'person',
                                        ocgObjectStatus: 'mandatory',
                                        ocgLinkTargetRef: param.resourceID,
                                        ocgLinkSourceRef: id,
                                        ocgPriority: '1',
                                        ocgObjectID: '...',
                                      };
                                      if (windowResult.validFrom) {
                                        resource.ocgValidFrom = moment(
                                          windowResult.validFrom
                                        ).format();
                                      }
                                      if (windowResult.validTo) {
                                        resource.ocgValidTo = moment(
                                          windowResult.validTo
                                        ).format();
                                      }
                                      return this.resource.createResource(
                                        resource
                                      );
                                    } else {
                                      return of(
                                        new HttpErrorResponse({ status: 400 })
                                      );
                                    }
                                  })
                                )
                            );
                          });

                          return forkJoin(observableBatch);
                        }
                      }),
                      switchMap((result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          return of(
                            new HttpErrorResponse({
                              status: 400,
                              error: 'Approval required',
                            })
                          );
                        }
                        return this.resource.addResourceValue(
                          param.resourceID,
                          'ocgResultantRoleRefs',
                          ids
                        );
                      }),
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        if (progress) {
                          progress.close();
                        }
                      })
                    )
                    .subscribe(
                      () => {},
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );

                this.swap.broadcast({
                  name: 'show-overlay',
                  parameter: undefined,
                });

                break;
              }
              default:
                break;
            }
            break;
          case 'group':
            switch (param.attribute) {
              case 'GroupMember': {
                const ids = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'ObjectID')
                );
                this.subscription.add(
                  this.helper
                    .addDirectMember(
                      'ExplicitMember',
                      param.resourceID,
                      ids,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'ocgpamgroup':
            switch (param.attribute) {
              case 'idlCandidates': {
                const ids = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'ObjectID')
                );
                this.subscription.add(
                  this.helper
                    .addDirectMember(
                      'ocgCandidateRefs',
                      param.resourceID,
                      ids,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'ocgorgunit':
            switch (param.attribute) {
              case 'Members': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );

                const observableBatch = [];
                const ids = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'ObjectID')
                );
                const names = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'DisplayName')
                );

                ids.forEach((id: string, index: number) => {
                  const existanceQuery = `/ocgAssignment[ocgLinkSourceRef='${param.resourceID}'
             and ocgLinkTargetRef='${id}' and ocgObjectType='OUAssignment']`;
                  observableBatch.push(
                    this.resource.getResourceCount(existanceQuery).pipe(
                      switchMap((count: number) => {
                        if (!count || count === 0) {
                          const resource: Resource = {
                            DisplayName: `OUAssignment: ${names[index]} -> ${param.resourceName}`,
                            ObjectType: 'ocgAssignment',
                            ocgObjectType: 'OUAssignment',
                            ocgObjectScope: 'Person',
                            ocgLinkSourceRef: param.resourceID,
                            ocgLinkTargetRef: id,
                          };
                          return this.resource.createResource(resource);
                        } else {
                          return of(new HttpErrorResponse({ status: 400 }));
                        }
                      })
                    )
                  );
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      (result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          this.modal.show(
                            ModalType.info,
                            'key_info',
                            'key_approvalRequired'
                          );
                        }
                      },
                      (error) => {
                        this.modal.show(
                          ModalType.error,
                          'key_error',
                          error.error
                        );
                      }
                    )
                );

                break;
              }
              case 'MandatoryRoles':
              case 'OptionalRoles': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );
                const observableBatch = [];
                const ids = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'ObjectID')
                );
                const names = param.values.map((v: any) =>
                  this.utils.ExtraValue(v, 'DisplayName')
                );

                ids.forEach((id: string, index: number) => {
                  const existanceQuery = `/ocgAssignment[ocgLinkTargetRef='${param.resourceID}'
             and ocgLinkSourceRef='${id}' and ocgObjectType='RoleAssignment']`;

                  observableBatch.push(
                    this.resource.getResourceCount(existanceQuery).pipe(
                      switchMap((count: number) => {
                        if (!count || count === 0) {
                          const resource: Resource = {
                            DisplayName: `Role-Assignment: ${names[index]} -> ${param.resourceName}`,
                            ObjectType: 'ocgAssignment',
                            ocgObjectType: 'RoleAssignment',
                            ocgObjectScope: 'OU',
                            ocgObjectStatus:
                              param.attribute === 'MandatoryRoles'
                                ? 'mandatory'
                                : 'optional',
                            ocgPriority: 1,
                            ocgLinkTargetRef: param.resourceID,
                            ocgLinkSourceRef: id,
                          };
                          return this.resource.createResource(resource);
                        } else {
                          return of(new HttpErrorResponse({ status: 400 }));
                        }
                      })
                    )
                  );
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      switchMap((result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          return of(
                            new HttpErrorResponse({
                              status: 400,
                              error: 'Approval required',
                            })
                          );
                        }
                        return EMPTY;
                      }),
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      () => {},
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );
                break;
              }
              case 'OUTeams':
                {
                  const ids = param.values.map((v: any) =>
                    this.utils.ExtraValue(v, 'ObjectID')
                  );
                  this.subscription.add(
                    this.helper
                      .addDirectMember(
                        'Teams',
                        param.resourceID,
                        ids,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              case 'OUGroups':
                {
                  const ids = param.values.map((v: any) =>
                    this.utils.ExtraValue(v, 'ObjectID')
                  );
                  this.subscription.add(
                    this.helper
                      .addDirectMember(
                        'Groups',
                        param.resourceID,
                        ids,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              default:
                break;
            }
            break;
          case 'ocgrole':
            switch (param.attribute) {
              case 'PermissionMember':
                {
                  const ids = param.values.map((v: any) =>
                    this.utils.ExtraValue(v, 'ObjectID')
                  );
                  this.subscription.add(
                    this.helper
                      .addDirectMember(
                        'ocgPermissionRefs',
                        param.resourceID,
                        ids,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              case 'AssignedRoles': {
                let ids: Array<string>;
                let progress: MatDialogRef<ModalComponent>;

                const windowRef = this.window.open({
                  title: '',
                  content: PopupAssignmentComponent,
                  width: 420,
                });

                this.subscription.add(
                  windowRef.result
                    .pipe(
                      switchMap((windowResult: any) => {
                        this.swap.broadcast({
                          name: 'hide-overlay',
                          parameter: undefined,
                        });

                        if (windowResult instanceof WindowCloseResult) {
                          return EMPTY;
                        } else {
                          progress = this.modal.show(
                            ModalType.progress,
                            'key_savingChanges',
                            '',
                            '300px'
                          );
                          const observableBatch = [];
                          ids = param.values.map((v: any) =>
                            this.utils.ExtraValue(v, 'ObjectID')
                          );
                          const names = param.values.map((v: any) =>
                            this.utils.ExtraValue(v, 'DisplayName')
                          );

                          ids.forEach((id: string, index: number) => {
                            const existanceQuery = `/ocgAssignment[ocgLinkTargetRef='${id}' and ocgLinkSourceRef='${param.resourceID}' and ocgObjectType='RoleAssignment' and ocgObjectScope='person']`;

                            observableBatch.push(
                              this.resource
                                .getResourceCount(existanceQuery)
                                .pipe(
                                  switchMap((count: number) => {
                                    if (!count || count === 0) {
                                      const resource: Resource = {
                                        DisplayName: `RoleAssignment: ${param.resourceName} -> ${names[index]}`,
                                        ObjectType: 'ocgAssignment',
                                        ocgObjectType: 'RoleAssignment',
                                        ocgObjectScope: 'person',
                                        ocgObjectStatus: 'mandatory',
                                        ocgLinkTargetRef: id,
                                        ocgLinkSourceRef: param.resourceID,
                                        ocgPriority: '1',
                                        ocgObjectID: '...',
                                      };
                                      if (windowResult.validFrom) {
                                        resource.ocgValidFrom = moment(
                                          windowResult.validFrom
                                        ).format();
                                      }
                                      if (windowResult.validTo) {
                                        resource.ocgValidTo = moment(
                                          windowResult.validTo
                                        ).format();
                                      }
                                      return this.resource.createResource(
                                        resource
                                      );
                                    } else {
                                      return of(
                                        new HttpErrorResponse({ status: 400 })
                                      );
                                    }
                                  })
                                )
                            );
                          });

                          return forkJoin(observableBatch);
                        }
                      }),
                      switchMap((result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          return of(
                            new HttpErrorResponse({
                              status: 400,
                              error: 'Approval required',
                            })
                          );
                        } else {
                          return of(result);
                        }
                      }),
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        if (progress) {
                          progress.close();
                        }
                      })
                    )
                    .subscribe(
                      () => {},
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );

                this.swap.broadcast({
                  name: 'show-overlay',
                  parameter: undefined,
                });

                break;
              }
              default:
                break;
            }
            break;
          case 'xpathtemplate':
            switch (param.attribute) {
              case 'xpathmember':
                {
                  const ids = param.values.map((v: any) =>
                    this.utils.ExtraValue(v, 'ObjectID')
                  );
                  this.subscription.add(
                    this.helper
                      .addDirectMember(
                        'staticmembers',
                        param.resourceID,
                        ids,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  private onViewRemoveIdentities(param: any) {
    if (this.isSameEvent(this.tsViewRemoveIdentities)) {
      return;
    }

    // Important: custom function should completly overwrite this event handler
    if (this.com.callCustomFunction('onViewRemoveIdentities', param)) {
      return;
    }

    if (param && param.resourceType) {
      let handled = false;

      // generic handler
      if (param.editor && param.editor.conf && param.editor.conf.linkType) {
        if (param.editor.conf.linkType === 'intermediat') {
          handled = true;
          if (
            param.editor.conf.intermediateObjectType &&
            param.editor.conf.intermediateAttributeForSource &&
            param.editor.conf.intermediateAttributeForTarget
          ) {
            this.subscription.add(
              this.helper
                .removeIntermediateObject(
                  param.editor.conf.intermediateObjectType,
                  param.editor.conf.intermediateAttributeForSource,
                  param.editor.conf.intermediateAttributeForTarget,
                  param.resourceID,
                  param.values,
                  param.editor
                )
                .subscribe()
            );
          }
        } else if (param.editor.conf.linkedAttribute) {
          switch (param.editor.conf.linkType) {
            case 'directlink':
              this.subscription.add(
                this.helper
                  .removeDirectMember(
                    param.editor.conf.linkedAttribute,
                    param.resourceID,
                    param.values,
                    param.editor
                  )
                  .subscribe()
              );
              break;
            case 'backlink':
              this.subscription.add(
                this.helper
                  .removeBackLinkMember(
                    param.editor.conf.linkedAttribute,
                    param.resourceID,
                    param.values,
                    param.editor
                  )
                  .subscribe()
              );
              break;
            case 'assignment':
              {
                const assignmentParams: string[] =
                  param.editor.config.linkedAttribute.split('|');
                if (assignmentParams.length === 3) {
                  this.subscription.add(
                    this.helper
                      .removeAssignment(
                        param.resourceID,
                        param.values,
                        assignmentParams[0].toLowerCase() === 'singlesource',
                        param.editor
                      )
                      .subscribe()
                  );
                }
              }
              break;
            default:
              break;
          }
        }
      }
      // deprecated
      else if (param.editor && param.editor.conf && param.editor.conf.name) {
        const editorName: string = param.editor.conf.name;
        if (editorName.toLowerCase().startsWith('#directmember-')) {
          handled = true;
          this.subscription.add(
            this.helper
              .removeDirectMember(
                param.attribute,
                param.resourceID,
                param.values,
                param.editor
              )
              .subscribe()
          );
        }
        if (editorName.toLowerCase().startsWith('#backlinkmember-')) {
          handled = true;
          this.subscription.add(
            this.helper
              .removeBackLinkMember(
                param.attribute,
                param.resourceID,
                param.values,
                param.editor
              )
              .subscribe()
          );
        }
      }

      // special handler
      if (!handled) {
        switch (param.resourceType.toLowerCase()) {
          case 'person':
            switch (param.attribute) {
              case 'SetMember':
              case 'GroupMember': {
                this.subscription.add(
                  this.helper
                    .removeBackLinkMember(
                      'ExplicitMember',
                      param.resourceID,
                      param.values,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              case 'RoleMemberDirect': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );
                const observableBatch = [];
                const ids = param.values.map((v: any) => v);

                param.values.forEach((id) => {
                  observableBatch.push(this.resource.deleteResource(id));
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      switchMap((result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          return of(
                            new HttpErrorResponse({
                              status: 400,
                              error: 'Approval required',
                            })
                          );
                        }
                        return this.resource.removeResourceValue(
                          param.resourceID,
                          'ocgResultantRoleRefs',
                          ids
                        );
                      }),
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      () => {},
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'group':
            switch (param.attribute) {
              case 'GroupMember': {
                this.subscription.add(
                  this.helper
                    .removeDirectMember(
                      'ExplicitMember',
                      param.resourceID,
                      param.values,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'ocgpamgroup':
            switch (param.attribute) {
              case 'idlCandidates': {
                this.subscription.add(
                  this.helper
                    .removeDirectMember(
                      'ocgCandidateRefs',
                      param.resourceID,
                      param.values,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'ocgorgunit':
            switch (param.attribute) {
              case 'Members': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );

                const observableBatch = [];

                param.values.forEach((id) => {
                  const existanceQuery = `/ocgAssignment[ocgLinkSourceRef='${param.resourceID}'
             and ocgLinkTargetRef='${id}' and ocgObjectType='OUAssignment']`;
                  observableBatch.push(
                    this.resource.getResourceByQuery(existanceQuery, []).pipe(
                      switchMap((result: ResourceSet) => {
                        if (
                          !result ||
                          (result.results && result.results.length > 0)
                        ) {
                          const idToDelete = this.utils.ExtraValue(
                            result.results[0],
                            'ObjectID'
                          );
                          return this.resource.deleteResource(idToDelete);
                        } else {
                          return of(new HttpErrorResponse({ status: 400 }));
                        }
                      })
                    )
                  );
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      (result: Array<HttpResponse<void>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          this.modal.show(
                            ModalType.info,
                            'key_info',
                            'key_approvalRequired'
                          );
                        }
                      },
                      (error) => {
                        this.modal.show(
                          ModalType.error,
                          'key_error',
                          error.error
                        );
                      }
                    )
                );

                break;
              }
              case 'MandatoryRoles':
              case 'OptionalRoles': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );
                const observableBatch = [];

                param.values.forEach((id) => {
                  const existanceQuery = `/ocgAssignment[ocgLinkTargetRef='${param.resourceID}'
             and ocgLinkSourceRef='${id}' and ocgObjectType='RoleAssignment']`;
                  observableBatch.push(
                    this.resource.getResourceByQuery(existanceQuery, []).pipe(
                      switchMap((result: ResourceSet) => {
                        if (
                          !result ||
                          (result.results && result.results.length > 0)
                        ) {
                          const idToUpdate = this.utils.ExtraValue(
                            result.results[0],
                            'ObjectID'
                          );
                          const resourceToUpdate = {
                            ObjectID: idToUpdate,
                            ObjectType: 'ocgAssignment',
                            ocgLinkSourceRef: null,
                          };
                          return this.resource.updateResource(resourceToUpdate);
                        } else {
                          return of(new HttpErrorResponse({ status: 400 }));
                        }
                      })
                    )
                  );
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      switchMap((result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          return of(
                            new HttpErrorResponse({
                              status: 400,
                              error: 'Approval required',
                            })
                          );
                        }
                        return EMPTY;
                      }),
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      () => {},
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );
                break;
              }
              case 'OUTeams':
                {
                  this.subscription.add(
                    this.helper
                      .removeDirectMember(
                        'Teams',
                        param.resourceID,
                        param.values,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              case 'OUGroups':
                {
                  this.subscription.add(
                    this.helper
                      .removeDirectMember(
                        'Groups',
                        param.resourceID,
                        param.values,
                        param.editor
                      )
                      .subscribe()
                  );
                }
                break;
              default:
                break;
            }
            break;
          case 'ocgrole':
            switch (param.attribute) {
              case 'PermissionMember': {
                this.subscription.add(
                  this.helper
                    .removeDirectMember(
                      'ocgPermissionRefs',
                      param.resourceID,
                      param.values,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              case 'AssignedRoles': {
                const progress = this.modal.show(
                  ModalType.progress,
                  'key_savingChanges',
                  '',
                  '300px'
                );
                const observableBatch = [];
                const ids = param.values.map((v: any) => v);

                param.values.forEach((id) => {
                  observableBatch.push(this.resource.deleteResource(id));
                });

                this.subscription.add(
                  forkJoin(observableBatch)
                    .pipe(
                      finalize(() => {
                        if (param.editor) {
                          param.editor.refresh();
                        }
                        progress.close();
                      })
                    )
                    .subscribe(
                      (result: Array<HttpResponse<string>>) => {
                        let approvalNeeded = false;
                        result.forEach((r) => {
                          if (r && r.status === 202) {
                            approvalNeeded = true;
                          }
                        });
                        if (approvalNeeded) {
                          this.modal.show(
                            ModalType.info,
                            'key_info',
                            'key_approvalRequired'
                          );
                        }
                      },
                      (error) => {
                        this.modal.show(
                          ModalType.info,
                          'key_warning',
                          error.error
                        );
                      }
                    )
                );
                break;
              }
              default:
                break;
            }
            break;
          case 'xpathtemplate':
            switch (param.attribute) {
              case 'xpathmember': {
                this.subscription.add(
                  this.helper
                    .removeDirectMember(
                      'staticmembers',
                      param.resourceID,
                      param.values,
                      param.editor
                    )
                    .subscribe()
                );
                break;
              }
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  private onViewRemoveAllIdentities(param: any) {
    if (this.isSameEvent(this.tsViewRemoveAllIdentities)) {
      return;
    }

    if (this.com.callCustomFunction('onViewRemoveAllIdentities', param)) {
      return;
    }

    if (param && param.editor && param.editor.resolvedQuery) {
      if (
        param.attribute === 'RoleMemberDirect' &&
        param.resourceType &&
        param.resourceType.toLowerCase() === 'person'
      ) {
        const savingProgress = this.modal.show(
          ModalType.progress,
          'key_savingChanges',
          '',
          '300px'
        );
        this.subscription.add(
          this.resource
            .deleteResourceByQuery(param.editor.resolvedQuery)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  this.utils.parseErrorMessage(error.error)
                );
                if (savingProgress) {
                  savingProgress.close();
                }
                return EMPTY;
              }),
              finalize(() => {
                if (savingProgress) {
                  savingProgress.close();
                }
                param.editor.refresh();
              })
            )
            .subscribe()
        );
      }
    }
  }

  // Tab view events
  private onTabAfterInit(param: TabViewEvent) {
    if (this.isSameEvent(this.tsTabAfterInit)) {
      return;
    }

    if (this.com.callCustomFunction('onTabAfterInit', param)) {
      return;
    }

    // Example for hiding tabs accroding to user status
    // Warning: this leads to a second call on ngOnInit function in attribute-view component

    // if (param && param.tabView) {
    //   const tabView = param.tabView as TabViewComponent;
    //   if (tabView && tabView.currentResource && tabView.formName === 'person') {
    //     const status = this.utils.ExtraValue(
    //       tabView.currentResource,
    //       'ocgObjectStatus'
    //     );
    //     if (status !== 'risky') {
    //       tabView.hideTabs(['userDeactivated']);
    //     } else {
    //       tabView.hideTabs([]);
    //     }
    //   }
    // }
  }

  // Menu events
  private onBuildMenuEvent(event: MenuEvent) {
    if (this.com.callCustomFunction('onBuildMenuEvent', event)) {
      return;
    }

    switch (event.viewName) {
      case 'brandview':
        if (event.parameter) {
          const type = this.utils.ExtraValue(
            event.parameter,
            'ObjectType:value'
          );
          if (type) {
            const menu = event.menu as ActionMenuComponent;
            if (menu) {
              const items = this.config.getConfigEx(
                `menus:${type.toLowerCase()}`,
                null
              );
              menu.menuItems =
                items ?? this.config.getConfigEx(`menus:default`, []);
            }
          }
        }
        break;
      default:
        if (event.viewName) {
          const menu = event.menu as ActionMenuComponent;
          if (menu) {
            menu.menuItems = this.config.getConfigEx(
              `menus:${event.viewName}`,
              []
            );
          }
        }
        break;
    }
  }

  private onOpenMenuEvent(event: MenuEvent) {
    if (this.com.callCustomFunction('onOpenMenuEvent', event)) {
      return;
    }

    switch (event.viewName) {
      case 'resourceList':
        {
          const menu = event.menu as ActionMenuComponent;
          if (menu) {
            const indexDelete = menu.menuItems.findIndex(
              (m) => m.name === 'delete'
            );
            if (indexDelete >= 0) {
              if (event.parameter) {
                menu.menuItems[indexDelete].disabled = false;
              } else {
                menu.menuItems[indexDelete].disabled = true;
              }
            }
            const indexLaunchWorkflow = menu.menuItems.findIndex(
              (m) => m.name === 'launchWorkflow'
            );
            if (indexLaunchWorkflow >= 0) {
              if (event.parameter && event.parameter.length === 1) {
                menu.menuItems[indexLaunchWorkflow].disabled = false;
              } else {
                menu.menuItems[indexLaunchWorkflow].disabled = true;
              }
            }
          }
        }
        break;
      case 'search':
      case 'report':
        {
          const menu = event.menu as ActionMenuComponent;
          if (menu) {
            const indexDelete = menu.menuItems.findIndex(
              (m) => m.name === 'delete'
            );
            if (indexDelete >= 0) {
              if (event.parameter) {
                menu.menuItems[indexDelete].disabled = false;
              } else {
                menu.menuItems[indexDelete].disabled = true;
              }
            }
            const indexLaunchWorkflow = menu.menuItems.findIndex(
              (m) => m.name === 'launchWorkflow'
            );
            if (indexLaunchWorkflow >= 0) {
              if (event.parameter) {
                menu.menuItems[indexLaunchWorkflow].disabled = false;
              } else {
                menu.menuItems[indexLaunchWorkflow].disabled = true;
              }
            }
          }
        }
        break;
      case 'orgStructure':
        {
          if (event.parameter && event.menu) {
            const menu = event.menu as ActionMenuComponent;
            if (menu) {
              menu.isLoading = true;
              const query = `/ocgOrgUnit[ocgParentRef='${event.parameter}']`;
              this.subscription.add(
                this.resource
                  .getResourceCount(query)
                  .pipe(
                    tap((result: number) => {
                      const index = menu.menuItems.findIndex(
                        (m) => m.name === 'deleteOU'
                      );
                      if (index >= 0) {
                        if (result > 0) {
                          menu.menuItems[index].disabled = true;
                        } else {
                          menu.menuItems[index].disabled = false;
                        }
                      }
                    }),
                    finalize(() => {
                      menu.isLoading = false;
                    })
                  )
                  .subscribe()
              );
            }
          }
        }
        break;
      default:
        break;
    }
  }

  private onMenuEvent(event: MenuEvent) {
    if (this.com.callCustomFunction('onMenuEvent', event)) {
      return;
    }

    switch (event.viewName) {
      case 'brandview':
        switch (event.itemName) {
          case 'timemachine':
            if (event.parameter) {
              const id = this.utils.ExtraValue(
                event.parameter,
                'ObjectID:value'
              );
              if (id) {
                this.swap.broadcast({
                  name: 'close-sidenav',
                  parameter: {
                    path: 'app/timemachine',
                    queryParams: {
                      id,
                      mode: 'event',
                    },
                  },
                });
              }
            }
            break;
          case 'launchWorkflow':
            {
              if (event.parameter) {
                this.swap.broadcast({
                  name: 'show-overlay',
                  parameter: undefined,
                });

                const windowRef = this.window.open({
                  content: WorkflowStarterComponent,
                  width: 800,
                  top: 50,
                });
                const windowIns: WorkflowStarterComponent =
                  windowRef.content.instance;
                windowIns.hideWorkflow = true;
                windowIns.launchWorkflowID = this.utils.ExtraValue(
                  event.parameter,
                  'ObjectID:value'
                );

                this.subscription.add(
                  windowRef.result
                    .pipe(
                      finalize(() => {
                        this.swap.broadcast({
                          name: 'hide-overlay',
                          parameter: undefined,
                        });
                      })
                    )
                    .subscribe()
                );
              }
            }
            break;
          case 'delete':
            {
              const id = this.utils.ExtraValue(
                event.parameter,
                'ObjectID:value'
              );
              if (id) {
                const confirm = this.modal.show(
                  ModalType.confirm,
                  'key_confirmation',
                  'key_confirmDeleteResource'
                );
                let progress: MatDialogRef<ModalComponent, any>;
                this.subscription.add(
                  confirm
                    .afterClosed()
                    .pipe(
                      switchMap((confirmResult) => {
                        if (confirmResult && confirmResult === 'yes') {
                          progress = this.modal.show(
                            ModalType.progress,
                            'key_savingChanges',
                            '',
                            '300px'
                          );
                          return this.resource.deleteResource(id);
                        } else {
                          return EMPTY;
                        }
                      }),
                      switchMap((result: HttpResponse<void>) => {
                        if (progress) {
                          progress.close();
                        }
                        return this.helper.handleResourceChangeResponse(result);
                      }),
                      catchError((err: HttpErrorResponse) => {
                        this.modal.show(
                          ModalType.error,
                          'key_error',
                          err.error
                        );
                        return EMPTY;
                      }),
                      finalize(() => {
                        if (progress) {
                          progress.close();
                        }
                        if (this.utils.hasSideView()) {
                          this.swap.broadcast({ name: 'close-sidenav' });
                          this.swap.broadcast({ name: 'refresh-list' });
                        } else {
                          this.router.navigate(['app/resources']);
                        }
                      })
                    )
                    .subscribe()
                );
              }
            }
            break;
          default:
            break;
        }
        break;
      case 'resourceList':
        if (event.parameter && event.parameter.scope && event.parameter.table) {
          const table = event.parameter.table as ResourceTableComponent;
          if (table) {
            switch (event.itemName) {
              case 'addNew':
                {
                  if (event.parameter.scope === 'b2b') {
                    this.subscription.add(
                      this.common
                        .createB2BUser()
                        .pipe(
                          finalize(() => {
                            this.swap.broadcast({
                              name: 'hide-overlay',
                              parameter: undefined,
                            });
                          })
                        )
                        .subscribe((result: Array<HttpResponse<string>>) => {
                          let approvalNeeded = false;
                          result.forEach((r) => {
                            if (r && r.status === 202) {
                              approvalNeeded = true;
                            }
                          });
                          if (approvalNeeded) {
                            this.modal.show(
                              ModalType.info,
                              'key_info',
                              'key_approvalRequired'
                            );
                          }
                        })
                    );

                    this.swap.broadcast({
                      name: 'show-overlay',
                      parameter: undefined,
                    });

                    return;
                  }

                  this.subscription.add(
                    this.wizard
                      .open(
                        event.parameter.scope.toLowerCase(),
                        event.parameter.scope,
                        true
                      )
                      .subscribe((result: any) => {
                        table.resetSelection();
                        table.updateDataSource();
                      })
                  );
                }
                break;
              case 'refresh':
                table.resetSelection();
                table.updateDataSource();
                break;
              case 'launchWorkflow':
                {
                  if (event.parameter) {
                    this.swap.broadcast({
                      name: 'show-overlay',
                      parameter: undefined,
                    });

                    const windowRef = this.window.open({
                      content: WorkflowStarterComponent,
                      width: 800,
                      top: 50,
                    });
                    const windowIns: WorkflowStarterComponent =
                      windowRef.content.instance;
                    if (event.resourceName === 'workflow') {
                      windowIns.hideWorkflow = true;
                      windowIns.launchWorkflowID =
                        event.parameter.selectedItems[0];
                    } else {
                      windowIns.hideTarget = true;
                      windowIns.launchTargetIDs = event.parameter.selectedItems;
                    }

                    this.subscription.add(
                      windowRef.result
                        .pipe(
                          finalize(() => {
                            this.swap.broadcast({
                              name: 'hide-overlay',
                              parameter: undefined,
                            });
                          })
                        )
                        .subscribe()
                    );
                  }
                }
                break;
              case 'delete':
                {
                  if (event.parameter.selectedItems) {
                    const confirm = this.modal.show(
                      ModalType.confirm,
                      'key_confirmation',
                      'key_confirmDeleteResource'
                    );
                    confirm.afterClosed().subscribe((result) => {
                      if (result && result === 'yes') {
                        const progress = this.modal.show(
                          ModalType.progress,
                          'key_savingChanges',
                          '',
                          '300px'
                        );

                        const observableBatch = [];

                        event.parameter.selectedItems.forEach((id: string) => {
                          observableBatch.push(
                            this.resource.deleteResource(id)
                          );
                        });

                        this.subscription.add(
                          forkJoin(observableBatch)
                            .pipe(
                              tap(() => {
                                if (progress) {
                                  progress.close();
                                }
                              }),
                              switchMap(
                                (result: Array<HttpResponse<string>>) => {
                                  return this.helper.handleResourceChangeResponse(
                                    result
                                  );
                                }
                              ),
                              catchError((err: any) => {
                                this.modal.show(
                                  ModalType.error,
                                  'key_error',
                                  this.utils.parseErrorMessage(
                                    this.utils.getServiceError(err)
                                  )
                                );
                                return EMPTY;
                              }),
                              finalize(() => {
                                if (table) {
                                  this.router.navigate(
                                    [
                                      `app/resourcelist/${event.parameter.scope}/${event.parameter.searchSkip}`,
                                    ],
                                    {
                                      queryParams: event.parameter.params,
                                    }
                                  );
                                  table.resetSelection();
                                  table.updateDataSource();
                                }
                                if (progress) {
                                  progress.close();
                                }
                              })
                            )
                            .subscribe()
                        );
                      }
                    });
                  }
                }
                break;
              default:
                break;
            }
          }
        }
        break;
      case 'search':
      case 'report':
        if (event.parameter && event.parameter.table) {
          const table = event.parameter.table as ResourceTableComponent;
          if (table) {
            switch (event.itemName) {
              case 'refresh':
                table.resetSelection();
                table.updateDataSource();
                break;
              case 'launchWorkflow':
                {
                  if (event.parameter) {
                    this.swap.broadcast({
                      name: 'show-overlay',
                      parameter: undefined,
                    });

                    const windowRef = this.window.open({
                      content: WorkflowStarterComponent,
                      width: 800,
                      top: 50,
                    });
                    const windowIns: WorkflowStarterComponent =
                      windowRef.content.instance;
                    windowIns.hideTarget = true;
                    windowIns.launchTargetIDs = event.parameter.selectedItems;

                    this.subscription.add(
                      windowRef.result
                        .pipe(
                          finalize(() => {
                            this.swap.broadcast({
                              name: 'hide-overlay',
                              parameter: undefined,
                            });
                          })
                        )
                        .subscribe()
                    );
                  }
                }
                break;
              case 'delete':
                {
                  if (event.parameter.selectedItems) {
                    const confirm = this.modal.show(
                      ModalType.confirm,
                      'key_confirmation',
                      'key_confirmDeleteResource'
                    );
                    confirm.afterClosed().subscribe((result) => {
                      if (result && result === 'yes') {
                        const progress = this.modal.show(
                          ModalType.progress,
                          'key_savingChanges',
                          '',
                          '300px'
                        );

                        const observableBatch = [];

                        event.parameter.selectedItems.forEach((id: string) => {
                          observableBatch.push(
                            this.resource.deleteResource(id)
                          );
                        });

                        this.subscription.add(
                          forkJoin(observableBatch)
                            .pipe(
                              tap(() => {
                                if (progress) {
                                  progress.close();
                                }
                              }),
                              switchMap(
                                (result: Array<HttpResponse<string>>) => {
                                  return this.helper.handleResourceChangeResponse(
                                    result
                                  );
                                }
                              ),
                              catchError((err: any) => {
                                this.modal.show(
                                  ModalType.error,
                                  'key_error',
                                  this.utils.parseErrorMessage(
                                    this.utils.getServiceError(err)
                                  )
                                );
                                return EMPTY;
                              }),
                              finalize(() => {
                                if (table) {
                                  table.resetSelection();
                                  table.updateDataSource();
                                }
                                if (progress) {
                                  progress.close();
                                }
                              })
                            )
                            .subscribe()
                        );
                      }
                    });
                  }
                }
                break;
              default:
                break;
            }
          }
        }
        break;
      case 'orgStructure':
        {
          const tree = event.parameter.tree as OrgStructureComponent;
          if (tree) {
            let objectType = '';
            switch (event.itemName) {
              case 'addNewUser':
                objectType = 'Person';
                break;
              case 'addNewGroup':
                objectType = 'Group';
                break;
              case 'addNewOU':
                objectType = 'ocgOrgUnit';
                break;
              case 'deleteOU':
                {
                  const confirm = this.modal.show(
                    ModalType.confirm,
                    'key_confirmation',
                    'key_confirmDeleteResource'
                  );
                  confirm.afterClosed().subscribe((result) => {
                    if (result && result === 'yes') {
                      const progress = this.modal.show(
                        ModalType.progress,
                        'key_savingChanges',
                        '',
                        '300px'
                      );

                      this.subscription.add(
                        this.resource
                          .deleteResource(event.parameter.currentID)
                          .pipe(
                            tap(() => {
                              if (progress) {
                                progress.close();
                              }
                            }),
                            switchMap((result: HttpResponse<void>) => {
                              return this.helper.handleResourceChangeResponse(
                                result
                              );
                            }),
                            catchError((err: any) => {
                              this.modal.show(
                                ModalType.error,
                                'key_error',
                                this.utils.parseErrorMessage(
                                  this.utils.getServiceError(err)
                                )
                              );
                              return EMPTY;
                            }),
                            finalize(() => {
                              if (progress) {
                                progress.close();
                              }
                            })
                          )
                          .subscribe()
                      );
                    }
                  });
                }
                break;
              default:
                break;
            }
            if (objectType) {
              this.subscription.add(
                this.wizard
                  .open(objectType.toLowerCase(), {
                    predefinedDepartment: event.parameter.currentID,
                  })
                  .subscribe()
              );
            }
          }
        }
        break;
      default:
        break;
    }
  }

  // Wizard events
  private recusiveCheckAccountName(
    sourceArray: string[],
    targetArray: Array<{ text: string; value: string }>,
    pos: number,
    counter: number
  ): Observable<Array<{ text: string; value: string }>> {
    if (targetArray.length === counter || pos === sourceArray.length) {
      return of(targetArray);
    } else {
      return this.resource
        .getResourceCount(`/Person[AccountName='${sourceArray[pos]}']`)
        .pipe(
          switchMap((c: number) => {
            if (c === 0) {
              targetArray.push({
                text: sourceArray[pos].toLowerCase(),
                value: sourceArray[pos].toLowerCase(),
              });
            }
            if (targetArray.length === counter) {
              return of(targetArray);
            } else {
              return this.recusiveCheckAccountName(
                sourceArray,
                targetArray,
                pos + 1,
                counter
              );
            }
          })
        );
    }
  }

  private onWizardAfterInit(event: WizardEvent) {
    if (this.com.callCustomFunction('onWizardAfterInit', event)) {
      return;
    }
  }

  private onWizardBeforeClose(event: WizardEvent) {
    if (this.com.callCustomFunction('onWizardBeforeClose', event)) {
      return;
    }

    if (
      event &&
      event.wizard &&
      event.wizard.viewSetting &&
      event.wizard.viewSetting.isCreation === true
    ) {
      if (event.parameter) {
        if (!(event.parameter instanceof WindowCloseResult)) {
          const theResource = event.parameter.resource as Resource;
          if (theResource) {
            const objectType: string = this.utils.ExtraValue(
              theResource,
              'ObjectType:value'
            );
            const displayName: string = this.utils.ExtraValue(
              theResource,
              'DisplayName:value'
            );
            if (objectType) {
              const savingProgress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              const resource: Resource = {
                ObjectType: objectType,
              };

              Object.keys(theResource).forEach((key) => {
                const value = this.utils.ToSaveValue(theResource[key]);
                if (value || value === false) {
                  resource[key] = value;
                }
              });

              // ObjectType-specific statical injected attribute values
              switch (objectType) {
                case 'Group':
                  // Inject fixed Domain from Config-File
                  resource.Domain = this.config.getConfig('domain');

                  // Inject XPath-Filter Lead & Suffix
                  if (resource.MembershipLocked === 'True') {
                    if (
                      resource.Filter &&
                      !resource.Filter.toLowerCase().startsWith('<filter ')
                    ) {
                      resource.Filter = String(this.utils.xmlFilter).replace(
                        '%Filter%',
                        resource.Filter
                      );
                    }
                  } else {
                    delete resource.Filter;
                  }
                  break;
                case 'Person':
                  // Inject fixed Domain from Config-File
                  resource.Domain = this.config.getConfig('domain');
                  break;
                case 'Set':
                  // Inject XPath-Filter Lead & Suffix
                  if (
                    resource.Filter &&
                    !resource.Filter.toLowerCase().startsWith('<filter ')
                  ) {
                    resource.Filter = String(this.utils.xmlFilter).replace(
                      '%Filter%',
                      resource.Filter
                    );
                  }
                  break;
                case 'workflow':
                  {
                    resource.workflowdescription = {
                      activities: [],
                      continueonerror: true,
                      description: '',
                      displayname: 'Sequential',
                      executioncondition: '',
                      isenabled: true,
                      type: 'Sequential',
                    };
                  }
                  break;
                case 'workflowtemplate':
                  {
                    if (!resource.inputparameters) {
                      resource.inputparameters = null;
                    }
                    resource.workflowdescription = {
                      activities: [],
                      continueonerror: true,
                      description: '',
                      displayname: 'Sequential',
                      executioncondition: '',
                      isenabled: true,
                      type: 'Sequential',
                    };
                  }
                  break;
                case 'dataflowrule':
                  {
                    resource.targetsrequirerecomputationonsourcevalueremovals =
                      true;
                    switch (resource.dataflowtype) {
                      case 'SourceReferenceInTarget':
                        delete resource.intermediateobjectobjecttype;
                        delete resource.intermediatereferenceattribute;
                        delete resource.intermediateObjectXPath;
                        delete resource.targetreferenceattribute;
                        break;
                      case 'TargetReferenceInSource':
                        delete resource.intermediateobjectobjecttype;
                        delete resource.intermediatereferenceattribute;
                        delete resource.intermediateObjectXPath;
                        delete resource.sourcereferenceattribute;
                        break;
                      case 'SourceReferenceInIntermediate':
                        delete resource.intermediatereferenceattribute;
                        break;
                      case 'IntermediateReferenceInSource':
                        delete resource.sourcereferenceattribute;
                        break;
                      default:
                        break;
                    }
                  }
                  break;
                case 'requestbasedworkflowtrigger':
                  {
                    resource.resourcechangedtypes = ['Create'];
                  }
                  break;
                default:
                  break;
              }

              let hasCallError = false;
              let response: HttpResponse<any> = null;
              if (resource.ocgParentRef && objectType === 'ocgOrgUnit') {
                this.subscription.add(
                  this.resource
                    .getResourceByID(resource.ocgParentRef, ['ocgADDN'])
                    .pipe(
                      switchMap((parentOU) => {
                        resource.ocgParentADDN = parentOU.ocgADDN;
                        return this.resource.createResource(resource);
                      }),
                      tap((creationResult: HttpResponse<any>) => {
                        response = creationResult;
                        if (creationResult.status !== 202) {
                          if (event.parameter.navigate && creationResult.ok) {
                            const createdId =
                              creationResult.body &&
                              creationResult.body.objectid
                                ? creationResult.body.objectid
                                : creationResult.body;
                            this.utils.NavigateToIdentity({
                              DisplayName: displayName,
                              ObjectType: objectType,
                              ObjectID: createdId,
                            });
                          }
                        }
                      }),
                      catchError((error: HttpErrorResponse) => {
                        hasCallError = true;
                        this.modal.show(
                          ModalType.error,
                          'key_error',
                          this.utils.parseErrorMessage(error.error)
                        );
                        if (savingProgress) {
                          savingProgress.close();
                        }
                        return EMPTY;
                      }),
                      finalize(() => {
                        if (savingProgress) {
                          savingProgress.close();
                        }
                        if (event.windowRef && !hasCallError) {
                          event.windowRef.close(event.parameter);
                          this.swap.wizardEvent(
                            new WizardEvent('afterClose', event.wizard, null, {
                              beforeCloseResponse: response,
                            })
                          );
                        }
                      })
                    )
                    .subscribe()
                );
              } else {
                this.subscription.add(
                  this.resource
                    .createResource(resource)
                    .pipe(
                      tap((creationResult: HttpResponse<any>) => {
                        response = creationResult;
                        if (creationResult.status !== 202) {
                          if (event.parameter.navigate && creationResult.ok) {
                            const createdId =
                              creationResult.body &&
                              creationResult.body.objectid
                                ? creationResult.body.objectid
                                : creationResult.body;
                            this.utils.NavigateToIdentity(
                              {
                                DisplayName: displayName,
                                ObjectType: objectType,
                                ObjectID: createdId,
                              },
                              undefined,
                              event.parameter.navigationKey
                            );
                          }
                        }
                      }),
                      catchError((error: HttpErrorResponse) => {
                        hasCallError = true;
                        this.modal.show(
                          ModalType.error,
                          'key_error',
                          this.utils.parseErrorMessage(error.error)
                        );
                        if (savingProgress) {
                          savingProgress.close();
                        }
                        return EMPTY;
                      }),
                      finalize(() => {
                        if (savingProgress) {
                          savingProgress.close();
                        }
                        if (event.windowRef && !hasCallError) {
                          event.windowRef.close(event.parameter);
                          this.swap.wizardEvent(
                            new WizardEvent('afterClose', event.wizard, null, {
                              beforeCloseResponse: response,
                            })
                          );
                        }
                      })
                    )
                    .subscribe()
                );
              }
            }
          }
        }
      }
    } else {
      if (event.windowRef) {
        event.windowRef.close(event.parameter);
        this.swap.wizardEvent(new WizardEvent('afterClose', event.wizard));
      }
    }
  }

  private onWizardAfterClose(event: WizardEvent) {
    if (this.com.callCustomFunction('onWizardAfterClose', event)) {
      return;
    }

    if (event && event.parameter) {
      if (event.parameter.beforeCloseResponse) {
        this.subscription.add(
          this.helper
            .handleResourceChangeResponse(event.parameter.beforeCloseResponse)
            .subscribe()
        );
      }
      if (event.parameter.resource) {
        const typeName = this.utils.ExtraValue(
          event.parameter.resource,
          'ObjectType:value'
        );
        if (typeName) {
          this.swap.broadcast({ name: 'refresh-after-change' });
        }
      }
    }
  }

  private onWizardEditorEvent(event: WizardEvent) {
    if (this.com.callCustomFunction('onWizardEditorEvent', event)) {
      return;
    }

    const wizard = event.wizard as PopupWizardComponent;
    const editorEvent = event.parameter as EditorEvent;
    if (wizard && editorEvent) {
      if (
        wizard.data &&
        wizard.data.config &&
        wizard.data.config.isCreation &&
        wizard.data.config.objectType === 'Person' &&
        editorEvent.name === 'change'
      ) {
        if (
          editorEvent.attributeName === 'FirstName' ||
          editorEvent.attributeName === 'LastName'
        ) {
          if (wizard.attributeViews) {
            const edtFirstName = wizard.getEditor('FirstName');
            const edtLastName = wizard.getEditor('LastName');
            const edtAccountName = wizard.getEditor(
              'AccountName'
            ) as EditorTextComponent;
            if (
              edtFirstName &&
              edtFirstName.value &&
              edtLastName &&
              edtLastName.value &&
              edtAccountName &&
              edtAccountName.config.autoComplete
            ) {
              const char0: string = String(edtFirstName.value).substr(0, 2);
              const char1: string = String(edtFirstName.value).substr(0, 1);
              const char2: string = String(edtLastName.value).substr(0, 1);
              const arrSource: string[] = [
                `${char0}${char2}`,
                `${char1}${char2}1`,
                `${char1}${char2}2`,
                `${char1}${char2}3`,
                `${char1}${char2}4`,
                `${char1}${char2}5`,
              ];
              const arrTarget: Array<{ text: string; value: string }> = [];

              edtAccountName.setAutoCompleteOptions(
                this.recusiveCheckAccountName(arrSource, arrTarget, 0, 3)
              );
            }
          }
        }
      }

      if (
        wizard.data &&
        wizard.data.config &&
        wizard.data.config.name === 'permissionrule'
      ) {
        const arrPermissions = wizard.getEditor('permissions');
        if (arrPermissions) {
          const resultArray = arrPermissions.value
            ? Array.from(arrPermissions.value)
            : [];
          switch (editorEvent.attributeName) {
            case 'operationRead#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Read');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex(
                    (p: string) => p === 'Read'
                  );
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            case 'operationCreate#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Create');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex(
                    (p: string) => p === 'Create'
                  );
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            case 'operationModify#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Modify');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex(
                    (p: string) => p === 'Modify'
                  );
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            case 'operationAdd#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Add');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex((p: string) => p === 'Add');
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            case 'operationRemove#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Remove');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex(
                    (p: string) => p === 'Remove'
                  );
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            case 'operationDelete#':
              {
                if (editorEvent.parameter === true) {
                  arrPermissions.value = resultArray.concat('Delete');
                } else if (editorEvent.parameter === false) {
                  const pos = resultArray.findIndex(
                    (p: string) => p === 'Delete'
                  );
                  if (pos >= 0) {
                    resultArray.splice(pos, 1);
                    arrPermissions.value = resultArray;
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
    }
  }

  // Sidebar events
  private onSidebarEvent(event: BroadcastEvent) {
    if (this.com.callCustomFunction('onSidebarEvent', event)) {
      return;
    }

    switch (event.name) {
      case 'profile':
        {
          const id = this.utils.ExtraValue(this.resource.loginUser, 'ObjectID');
          if (id) {
            this.router.navigate([`/app/form/person/${id}`]);
          }
        }
        break;
      default:
        if (event.parameter) {
          this.router.navigate([`/app/${event.parameter}`]);
        }
        break;
    }
  }

  // Card events
  private onCardEvent(event: BroadcastEvent) {
    if (this.com.callCustomFunction('onCardEvent', event)) {
      return;
    }

    switch (event.parameter) {
      case 'primary':
        switch (event.name) {
          case 'ou-view':
            this.router.navigate(['app/orgstructure/0']);
            break;
          case 'wf-view':
            this.router.navigate(['app/workflowoverview']);
            break;
          case 'Request':
            this.router.navigate(['app/eventlist']);
            break;
          default:
            this.router.navigate([`app/resourcelist/${event.name}/0`]);
            break;
        }
        break;
      case 'secondary':
        switch (event.name) {
          case 'teams-creation':
            // {
            //   this.subscription.add(
            //     this.dialog
            //       .open(DemoTeamCreationComponent, {
            //         minWidth: '580px',
            //         maxWidth: '580px',
            //         data: {},
            //       })
            //       .afterClosed()
            //       .subscribe((result) => {
            //         if (result && result !== 'cancel') {
            //           const progress = this.modal.show(
            //             ModalType.progress,
            //             'Saving changes',
            //             '',
            //             '300px'
            //           );

            //           result.owners = result.owners.map((o) => o.objectid);
            //           result.members = result.members.map((m) => m.objectid);
            //           result.set = result.set ? result.set.objectid : null;

            //           this.resource.createResource(result).subscribe(
            //             (id) => {
            //               progress.close();
            //             },
            //             (error) => {
            //               progress.close();
            //               this.modal.show(ModalType.error, 'Error', error.error, '300px');
            //             }
            //           );
            //         }
            //       })
            //   );
            // }
            break;
          case 'b2b-invitation':
            {
              this.subscription.add(
                this.common
                  .createB2BUser()
                  .pipe(
                    finalize(() => {
                      this.swap.broadcast({
                        name: 'hide-overlay',
                        parameter: undefined,
                      });
                    })
                  )
                  .subscribe((result: Array<HttpResponse<string>>) => {
                    let approvalNeeded = false;
                    result.forEach((r) => {
                      if (r && r.status === 202) {
                        approvalNeeded = true;
                      }
                    });
                    if (approvalNeeded) {
                      this.modal.show(
                        ModalType.info,
                        'key_info',
                        'key_approvalRequired'
                      );
                    }
                  })
              );

              this.swap.broadcast({
                name: 'show-overlay',
                parameter: undefined,
              });
            }
            break;
          default:
            this.subscription.add(
              this.wizard
                .open(
                  event.name.toLowerCase(),
                  event.payload ? event.payload : event.name,
                  true
                )
                .subscribe()
            );
            break;
        }
        break;
      default:
        break;
    }
  }

  // Link events
  private onLinkEvent(event: BroadcastEvent) {
    if (this.com.callCustomFunction('onLinkEvent', event)) {
      return;
    }

    if (event) {
      // manual tasks
      if (
        event.payload &&
        event.payload.scope === 'manualtasks' &&
        this.utils.ExtraValue(event.parameter, 'ObjectType') === 'manualtask'
      ) {
        if (event.parameter) {
          const taskId = this.utils.ExtraValue(event.parameter, 'ObjectID');
          if (taskId) {
            this.subscription.add(
              this.resource
                .getResourceByID(taskId, ['formid', 'formdata'])
                .pipe(
                  switchMap((result: Resource) => {
                    if (result && result.formid) {
                      const res = new HttpResponse<any>({
                        status: 202,
                        body: {
                          formid: result.formid,
                          manualtaskid: taskId,
                          formdata: result.formdata ?? {},
                          message: 'start message still missing',
                        },
                      });
                      return this.helper.handleResourceChangeResponse(res);
                    }
                    return EMPTY;
                  }),
                  // tap(() => {
                  //   setTimeout(() => {
                  //     this.swap.broadcast({
                  //       name: 'refresh-list',
                  //     });
                  //   }, this.config.getConfig('intervalSmall', 300));
                  // }),
                  catchError((err: any) => {
                    this.modal.show(
                      ModalType.info,
                      'key_error',
                      this.utils.getServiceError(err)
                    );
                    return EMPTY;
                  })
                )
                .subscribe()
            );
          }
        }

        return;
      }

      // generic handler
      let name = event.name;
      if (this.utils.hasSideView() && event.payload.defaultAction) {
        name = 'sideView';
      }

      switch (name) {
        case 'native':
          {
            const portalUrl = this.config.getConfig(
              'portalUrl',
              'http://localhost/identitymanagement'
            );
            const windowParameter =
              'scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,location=no,status=no';
            // const targetType = this.utils.ExtraValue(
            //   this.identity,
            //   'ObjectType:value'
            // );
            // const targetID = this.utils.ExtraValue(
            //   this.identity,
            //   'ObjectID:value'
            // );
            // const targetUrl = `${portalUrl}/aspx/customized/CustomizedObjects.aspx?type=${targetType}&popupFromClipboard=%2Fidentitymanagement%2Faspx%2Fcustomized%2FEditCustomizedObject.aspx%3Fid%3D${targetID}%26type%3D${targetType}`;
            // change the first parameter to targetUrl for launch a funcational popup but with mim home page
            window.open(
              `${portalUrl}/aspx/customized/EditCustomizedObject.aspx?id=${this.utils.ExtraValue(
                event.parameter,
                'ObjectID:value'
              )}&type=${this.utils.ExtraValue(
                event.parameter,
                'ObjectType:value'
              )}`,
              '_blank',
              windowParameter
            );
          }
          break;
        case 'navigate':
          {
            if (
              event.parameter.ObjectType &&
              event.parameter.ObjectType === 'schema'
            ) {
              this.utils.NavigateToRoute(
                `app/resourcelist/${event.parameter.ObjectID}/0`
              );
            } else {
              this.utils.NavigateToIdentity(
                event.parameter,
                undefined,
                event.payload.navigationKey,
                event.payload.noneForm,
                event.payload.readOnly
              );
            }
          }
          break;
        case 'popup':
          break;
        case 'sideView':
          this.swap.broadcast({
            name: 'show-sidenav',
            parameter: {
              navigationKey: event.payload.navigationKey,
              identity: event.parameter,
              noneForm: event.payload.noneForm,
              readOnly: event.payload.readOnly,
            },
          });
          break;
        case 'history':
          this.swap.broadcast({
            name: 'history-data',
            parameter: {
              identity: event.parameter,
              timestamp: event.payload.historyTimestamp,
              reportName: event.payload.historyReportName,
            },
          });
          break;
        default:
          break;
      }
    }
  }

  constructor(
    private swap: SwapService,
    private wizard: WizardService,
    private utils: UtilsService,
    private modal: ModalService,
    private resource: ResourceService,
    private config: ConfigService,
    private window: WindowService,
    private router: Router,
    private common: CommonService,
    private com: ComponentService,
    private helper: HelperService,
    private translate: TransService
  ) {
    // Subscribe object view events
    this.subscription.add(
      this.swap.onObjectViewEvent.subscribe((event: ObjectViewEvent) => {
        if (event) {
          switch (event.type) {
            case 'editor':
              this.onViewEditorEvent(event);
              break;
            case 'afterInit':
              this.onViewAfterInit(event);
              break;
            case 'beforeSave':
              this.onViewBeforeSave(event);
              break;
            case 'addIdentities':
              this.onViewAddIdentities(event.parameter);
              break;
            case 'removeIdentities':
              this.onViewRemoveIdentities(event.parameter);
              break;
            case 'removeAllIdentities':
              this.onViewRemoveAllIdentities(event.parameter);
              break;
            default:
              break;
          }
        }
      })
    );

    this.subscription.add(
      this.swap.onTabViewEvent.subscribe((event: TabViewEvent) => {
        if (event) {
          switch (event.type) {
            case 'afterInit':
              this.onTabAfterInit(event);
              break;
            default:
              break;
          }
        }
      })
    );

    // Subscribe menu events
    this.subscription.add(
      this.swap.onMenuEvent.subscribe((event: MenuEvent) => {
        if (event) {
          switch (event.type) {
            case 'action':
              this.onMenuEvent(event);
              break;
            case 'build':
              this.onBuildMenuEvent(event);
              break;
            case 'open':
              this.onOpenMenuEvent(event);
              break;
            default:
              break;
          }
        }
      })
    );

    // Subscribe wizard events
    this.subscription.add(
      this.swap.onWizardEvent.subscribe((event: WizardEvent) => {
        if (event) {
          switch (event.type) {
            case 'afterInit':
              this.onWizardAfterInit(event);
              break;
            case 'beforeClose':
              this.onWizardBeforeClose(event);
              break;
            case 'afterClose':
              this.onWizardAfterClose(event);
              break;
            case 'editor':
              this.onWizardEditorEvent(event);
              break;
            default:
              break;
          }
        }
      })
    );

    // Subscribe sidebar events
    this.subscription.add(
      this.swap.onSidebarEvent.subscribe((event: BroadcastEvent) => {
        if (event) {
          this.onSidebarEvent(event);
        }
      })
    );

    // Subscribe card events
    this.subscription.add(
      this.swap.onCardEvent.subscribe((event: BroadcastEvent) => {
        if (event) {
          this.onCardEvent(event);
        }
      })
    );

    // Subscribe link events
    this.subscription.add(
      this.swap.onLinkEvent.subscribe((event: BroadcastEvent) => {
        if (event) {
          this.onLinkEvent(event);
        }
      })
    );
  }

  init() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
