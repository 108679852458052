import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  ViewChild,
} from '@angular/core';
import { DynamicContainerDirective } from '../../directives/dynamic-container.directive';
import {
  ComponentItem,
  ResourceTableConfig,
} from '../../models/componentContract.model';
import { CustomComponent } from '../../models/dynamicEditor.interface';
import { ComponentService } from '../../services/component.service';
import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { ResourceTableComponent } from '../resource-table/resource-table.component';

@Component({
  selector: 'app-resource-table-detail',
  templateUrl: './resource-table-detail.component.html',
  styleUrls: ['./resource-table-detail.component.scss'],
})
export class ResourceTableDetailComponent implements AfterViewInit {
  componentItem: ComponentItem;
  componentRef: ComponentRef<any>;

  @Input() tableItem: any;

  @Input() tableConfig: ResourceTableConfig;

  @ViewChild(DynamicContainerDirective)
  componentContainer: DynamicContainerDirective;

  @ViewChild('detailTable') detailTable: ResourceTableComponent;

  constructor(
    private com: ComponentService,
    private cfr: ComponentFactoryResolver,
    private utils: UtilsService,
    private resource: ResourceService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.tableConfig &&
        this.tableConfig.detailType === 'component' &&
        this.tableConfig.detailComponentID
      ) {
        this.componentItem = this.com.getCustomComponent(
          this.tableConfig.detailComponentID
        );
        if (
          this.componentContainer &&
          this.componentItem &&
          this.componentItem.component
        ) {
          // get component
          const componentFactory = this.cfr.resolveComponentFactory(
            this.componentItem.component
          );
          // get container
          const viewContainerRef = this.componentContainer.viewContainerRef;
          viewContainerRef.clear();
          // load component
          this.componentRef =
            viewContainerRef.createComponent<CustomComponent>(componentFactory);

          // init component with attribute value
          if (this.componentRef.instance) {
            this.componentRef.instance.data =
              this.tableConfig.detailComponentData;
            this.componentRef.instance.componentValue = this.tableItem;
          }
        }
      }

      if (this.tableConfig && this.tableConfig.detailType === 'table') {
        if (
          this.tableItem &&
          this.tableConfig.detailAttribute &&
          this.tableConfig.detailQuery
        ) {
          const attributeValue = this.utils.ExtraValue(
            this.tableItem,
            `${this.tableConfig.detailAttribute}:ObjectID`
          );
          if (attributeValue) {
            this.tableConfig.detailTableConfig.query =
              this.tableConfig.detailQuery
                .replace(/%AttributeValue%/gi, attributeValue)
                .replace(
                  /\[#LoginID\]/gi,
                  this.utils.ExtraValue(
                    this.resource.loginUser,
                    'ObjectID:value'
                  )
                );
            if (this.detailTable) {
              this.detailTable.updateDataSource(true);
            }
          }
        }
      }
    });
  }
}
