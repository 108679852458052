<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-bottom: 20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtListExpression"
        [creationMode]="true"
        [(ngModel)]="listExpression"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 100,
          customDisplayName: 'key_listExpression',
          customDescription: 'key_listExpressionDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/foreach/listexpression'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-bottom: 20px"
    >
      <app-editor-text
        fxFlex="100"
        name="txtCurrentItemKey"
        [(ngModel)]="currentItemKey"
        [config]="{
          isSimpleValue: true,
          hintLimit: 100,
          customDisplayName: 'key_currentItemKey',
          customDescription: 'key_currentItemKeyDes',
          updateOn: updateOnBlure,
          validation: '^[a-z0-9]+$'
        }"
      ></app-editor-text>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <app-editor-text
        fxFlex="100"
        name="txtBreakExpression"
        [(ngModel)]="breakExpression"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          hintLimit: 100,
          customDisplayName: 'key_breakExpression',
          customDescription: 'key_breakExpressionDes',
          updateOn: updateOnBlure,
          validationKey: 'workflow/foreach/breakexpression'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
