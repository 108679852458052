import { Injectable } from '@angular/core';
import {
  ActivityDescription,
  WFActivityAddDelay,
  WFActivityApproval,
  WFActivityCreateResource,
  WFActivityDataFlow,
  WFActivityDeleteResource,
  WFActivityForEach,
  WFActivityIfThenElse,
  WFActivityManualTask,
  WFActivityPlaceholder,
  WFActivityRestApiCall,
  WFActivitySendEmail,
  WFActivitySequential,
  WFActivitySimulation,
  WFActivitySimulationUpdate,
  WFActivityStatus,
  WFActivityTemplate,
  WFActivityUpdateResource,
  WorkflowDescription,
  WFActivityWorkflowData,
} from '../models/dataContract.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityIndexService {
  activityIndex: {
    [id: string]: ActivityDescription;
  } = {
    AddDelay: {
      name: 'Add Delay',
      description: 'key_activityAddDelayDes',
      icon: 'hourglass_top',
      def: new WFActivityAddDelay(),
      forbiddenInSimulation: true,
    },
    Approval: {
      name: 'Approval',
      description: 'key_activityApprovalDes',
      icon: 'flaky',
      def: new WFActivityApproval(),
      forbiddenInSimulation: true,
    },
    CreateResource: {
      name: 'Create Resource',
      description: 'key_activityCreateResourceDes',
      icon: 'add_box',
      def: new WFActivityCreateResource(),
    },
    DeleteResources: {
      name: 'Delete Resource',
      description: 'key_activityDeleteResourceDes',
      icon: 'delete_sweep',
      def: new WFActivityDeleteResource(),
    },
    ForEach: {
      name: 'For Each Loop',
      description: 'key_activityForEachDes',
      icon: 'loop',
      isContainer: true,
      multiActivities: true,
      def: new WFActivityForEach(),
    },
    IfThenElse: {
      name: 'If Then Else',
      description: 'key_activityIfThenElseDes',
      icon: 'share',
      isConditional: true,
      def: new WFActivityIfThenElse(),
    },
    Placeholder: {
      name: 'Placeholder',
      description: 'key_activityPlaceholder',
      icon: 'pin_drop',
      def: new WFActivityPlaceholder(),
      disabled: true,
    },
    ManualTask: {
      name: 'Manual Task',
      description: 'key_manualTaskDes',
      icon: 'task',
      def: new WFActivityManualTask(),
      forbiddenInSimulation: true,
    },
    Dataflow: {
      name: 'Recalculate Dataflow Rule',
      description: 'key_dataflowDes',
      icon: 'sync_alt',
      def: new WFActivityDataFlow(),
    },
    SendEmail: {
      name: 'Send Email',
      description: 'key_activitySendEmailDes',
      icon: 'forward_to_inbox',
      def: new WFActivitySendEmail(),
      forbiddenInSimulation: true,
    },
    Sequential: {
      name: 'Sequential',
      description: 'key_sequentialDes',
      icon: 'vertical_align_bottom',
      isContainer: true,
      multiActivities: true,
      def: new WFActivitySequential(),
    },
    Simulation: {
      name: 'Simulation',
      description: 'key_wfSimulationDes',
      icon: 'preview',
      isContainer: true,
      multiActivities: true,
      def: new WFActivitySimulation(),
      forbiddenInSimulation: true,
    },
    SimulateRequestedUpdate: {
      name: 'Simulate Requested Update',
      description: 'key_wfSimulateUpdatesDes',
      icon: 'launch',
      def: new WFActivitySimulationUpdate(),
      onlyInSimulation: true,
    },
    Status: {
      name: 'Status',
      description: 'key_statusDes',
      icon: 'tune',
      def: new WFActivityStatus(),
      forbiddenInSimulation: true,
    },
    UpdateResources: {
      name: 'Update Resource',
      description: 'key_activityUpdateResourceDes',
      icon: 'drive_file_rename_outline',
      def: new WFActivityUpdateResource(),
    },
    RestApiCall: {
      name: 'Web API Call',
      description: 'key_activityRestApiCallDes',
      icon: 'settings_applications',
      def: new WFActivityRestApiCall(),
      forbiddenInSimulation: true,
    },
    Workflow: {
      name: 'Workflow Template',
      description: 'key_wfTemplateDes',
      icon: 'alt_route',
      def: new WFActivityTemplate(),
      forbiddenInSimulation: true,
    },
    WorkflowData: {
      name: 'WorkflowData',
      description: 'key_activityWorkflowDataDes',
      icon: 'post_add',
      def: new WFActivityWorkflowData(),
    },
  };

  constructor() {}

  public getName(name: string): string {
    if (!name) {
      return '';
    }

    const activity = this.activityIndex[name];
    if (activity && activity.name) {
      return activity.name;
    }

    return '';
  }

  public getIcon(name: string): string {
    if (!name) {
      return '';
    }

    const activity = this.activityIndex[name];
    if (activity && activity.icon) {
      return activity.icon;
    }

    return '';
  }

  public isContainer(name: string): boolean {
    if (!name) {
      return false;
    }

    const activity = this.activityIndex[name];
    if (activity && activity.isContainer === true) {
      return true;
    }

    return false;
  }

  public isConditional(name: string): boolean {
    if (!name) {
      return false;
    }

    const activity = this.activityIndex[name];
    if (activity && activity.isConditional === true) {
      return true;
    }

    return false;
  }

  public hasMultiActivities(name: string): boolean {
    if (!name) {
      return false;
    }

    const activity = this.activityIndex[name];
    if (activity && activity.multiActivities === true) {
      return true;
    }

    return false;
  }

  public canAddNew(workflow: WorkflowDescription) {
    if (!workflow) {
      return false;
    }

    if (this.isContainer(workflow.type)) {
      if (workflow.activities) {
        if (workflow.activities.length === 0) {
          return true;
        } else {
          const activity = this.activityIndex[workflow.type];
          if (activity && activity.multiActivities) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }

    return false;
  }
}
