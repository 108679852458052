import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-for-each',
  templateUrl: './activity-for-each.component.html',
  styleUrls: ['./activity-for-each.component.scss'],
})
export class ActivityForEachComponent extends WorkflowActivityView {
  expression: string;
  itemKey: string;
  breakExp: string;

  @Input()
  get listExpression() {
    return this.expression;
  }
  set listExpression(value: string) {
    this.expression = value;
    this.listExpressionChange.emit(this.expression);
  }
  @Output()
  listExpressionChange = new EventEmitter();

  @Input()
  get currentItemKey() {
    return this.itemKey;
  }
  set currentItemKey(value) {
    this.itemKey = value;
    this.currentItemKeyChange.emit(this.itemKey);
  }
  @Output()
  currentItemKeyChange = new EventEmitter();

  @Input()
  get breakExpression() {
    return this.breakExp;
  }
  set breakExpression(value: string) {
    this.breakExp = value;
    this.breakExpressionChange.emit(this.breakExp);
  }
  @Output()
  breakExpressionChange = new EventEmitter();

  constructor() {
    super();
  }
}
