import {
  Component,
  OnInit,
  Inject,
  LOCALE_ID,
  Injector,
  AfterViewInit,
} from '@angular/core';

import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';

import {
  DynamicEditor,
  AttributeEditorConfig,
} from '../../models/dynamicEditor.interface';

import { DateEditorConfig } from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';

import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-editor-date-config',
  templateUrl: './editor-date-config.component.html',
  styleUrls: ['./editor-date-config.component.scss'],
})
export class EditorDateConfigComponent
  extends AttributeEditorConfig
  implements OnInit, AfterViewInit
{
  rangeMin = new Date(1600, 0, 1);
  rangeMax = new Date(2999, 11, 31);

  data: {
    component: DynamicEditor;
    config: DateEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  constructor(
    injector: Injector,
    private configService: ConfigService,
    public intlService: IntlService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    super(injector);
  }

  ngOnInit() {
    (this.intlService as CldrIntlService).localeId =
      this.configService.getCulture(this.translate.currentCulture);
  }

  ngAfterViewInit() {
    this.initComponent();
  }

  get dateFormat() {
    return this.translate.instant('key_dateFormat');
  }

  get timeFormat() {
    return this.translate.instant('key_timeFormat');
  }
}
