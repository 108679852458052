import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalData, ModalType } from '../../models/componentContract.model';
import { Subscription } from 'rxjs';
import { SwapService } from '../../services/swap.service';
import { tap } from 'rxjs/operators';
import { BroadcastEvent } from '../../models/dataContract.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  type = ModalType;

  title: string;
  content: string;

  barColor: string;
  iconName: string;

  constructor(
    private swap: SwapService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;

    switch (this.data.type) {
      case ModalType.confirm:
        this.barColor = 'cornflowerblue';
        this.iconName = 'question_answer';
        break;
      case ModalType.error:
        this.barColor = 'coral';
        this.iconName = 'error';
        break;
      case ModalType.info:
        this.barColor = 'darkseagreen';
        this.iconName = 'info';
        break;
      default:
        break;
    }

    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          tap((event: BroadcastEvent) => {
            if (event && event.name && event.parameter) {
              const arrUpdates: Array<{ key: string; value: string }> =
                event.parameter;
              if (
                arrUpdates &&
                Array.isArray(arrUpdates) &&
                arrUpdates.length > 0
              ) {
                arrUpdates.forEach((update: { key: string; value: string }) => {
                  switch (event.name) {
                    case 'modal-title-update':
                      this.title = this.data.title.replace(
                        new RegExp(update.key, 'gi'),
                        update.value
                      );
                      break;
                    case 'modal-content-update':
                      this.content = this.data.content.replace(
                        new RegExp(update.key, 'gi'),
                        update.value
                      );
                      break;
                    default:
                      break;
                  }
                });
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
