import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

import * as moment from 'moment';

import {
  XPathQuery,
  TypeResource,
  XPathStep,
  XPathParameter,
  XPathAttribute,
  AttributeResource,
  AuthMode,
} from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';

import { ResourceService } from '../../services/resource.service';
import { ConfigService } from '../../services/config.service';
import { Observable, of, Subscription, forkJoin, EMPTY } from 'rxjs';
import { switchMap, tap, finalize } from 'rxjs/operators';
import { ModalType } from '../../models/componentContract.model';
import { ModalService } from '../../services/modal.service';
import { UtilsService } from '../../services/utils.service';
import { IdentityEditorConfig } from '../../models/editorContract.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-xpath-builder',
  templateUrl: './xpath-builder.component.html',
  styleUrls: ['./xpath-builder.component.scss'],
})
export class XpathBuilderComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private schemaDic: {
    [id: string]: { [name: string]: AttributeResource };
  } = {
    '*': {
      CreatedTime: {
        displayName: 'key_createdTime',
        systemName: 'CreatedTime',
        dataType: 'DateTime',
      },
      DisplayName: {
        displayName: 'key_displayName',
        systemName: 'DisplayName',
        dataType: 'String',
      },
      Description: {
        displayName: 'key_description',
        systemName: 'Description',
        dataType: 'String',
      },
      ExpirationTime: {
        displayName: 'key_expirationTime',
        systemName: 'ExpirationTime',
        dataType: 'DateTime',
      },
      ObjectID: {
        displayName: 'key_objectID',
        systemName: 'ObjectID',
        dataType: 'Reference',
      },
      ObjectType: {
        displayName: 'key_objectType',
        systemName: 'ObjectType',
        dataType: 'String',
      },
    },
  };

  private operatorDic: { [type: string]: { [id: string]: string } } = {
    string: {
      '=': '=',
      '!=': '!=',
      contains: 'key_contains',
      'ends-with': 'key_endsWith',
      'starts-with': 'key_startsWith',
    },
    boolean: {
      '=': '=',
      '!=': '!=',
    },
    integer: {
      '<': '<',
      '<=': '<=',
      '>': '>',
      '>=': '>=',
      '=': '=',
      '!=': '!=',
    },
    datetime: {
      '<': '<',
      '>': '>',
      '=': '=',
    },
    reference: {
      '=': '=',
      '!=': '!=',
    },
  };

  private allOperators: { [id: string]: string } = {
    '<': '<',
    '<=': '<=',
    '>': '>',
    '>=': '>=',
    '=': '=',
    '!=': '!=',
    contains: 'key_contains',
    'ends-with': 'key_endsWith',
    'starts-with': 'key_startsWith',
  };

  private encodeXpathQuery(input: XPathQuery) {
    if (input && input.steps) {
      input.steps.forEach((s: XPathStep) => {
        this.encodeXpathParameter(s.predicate);
      });
    }
    return input;
  }

  private encodeXpathParameter(input: XPathParameter) {
    if (input && input.parameters) {
      input.parameters.forEach((p, index) => {
        if (p.hasOwnProperty('operator')) {
          this.encodeXpathParameter(p as XPathParameter);
        } else if (p.hasOwnProperty('steps')) {
          this.encodeXpathQuery(p as XPathQuery);
        } else if (typeof p === 'string') {
          if (!p.startsWith('op:')) {
            input.parameters[index] = this.utils.encodeSearchText(p);
          }
        }
      });
    }
  }

  @Input()
  query: XPathQuery;

  @Input()
  readOnly = false;

  @Input()
  valueChangeOnly = false;

  @Input()
  schema: string | { [name: string]: Array<string> } = null;

  @Output()
  queryChange = new EventEmitter<XPathQuery>();

  logics: { [id: string]: string } = {
    and: 'key_xpathAll',
    or: 'key_xpathAny',
  };

  booleanItems: Array<{ text: string; value: boolean }> = [
    { text: this.translate.instant('key_true'), value: true },
    { text: this.translate.instant('key_false'), value: false },
  ];

  dateTypes: Array<{ text: string; value: string }> = [
    { text: this.translate.instant('key_enterDate'), value: 'date' },
    { text: this.translate.instant('key_today'), value: 'today' },
    { text: this.translate.instant('key_xDaysAgo'), value: 'ago' },
    { text: this.translate.instant('key_xDaysFromToday'), value: 'from' },
  ];

  types: Array<Array<any>> = [];
  attributes: Array<Array<any>> = [];
  operators: { [id: string]: string } = {};

  canParse = true;

  margin = 40;
  hasError = false;
  currentStep: XPathStep;
  currentParameter: XPathParameter;
  currentType: string;
  currentDateType: string;

  idpConfig: IdentityEditorConfig = {
    showPhoto: true,
    popupWidth: 460,
    queryNormalSearch: `/*[starts-with(DisplayName,'%SearchText%')]`,
    queryEmptySearch: '',
    browserShowTypePicker: true,
    browserDefaultType: 'Person',
    allowEmptySearch: true,
    attributesToShow: [
      {
        field: 'DisplayName',
        width: 0,
        sortable: true,
        filterable: true,
        filter: 'text',
      },
      {
        field: 'ObjectType',
        width: 0,
        sortable: true,
        filterable: true,
        filter: 'text',
      },
    ],
  };
  idpPlaceholderConfig: IdentityEditorConfig = {
    readOnly: true,
    allowEmptySearch: false,
  };

  dtFormat: string;

  private getTypesInParameters(
    parameters: Array<XPathParameter>,
    result: Array<string>
  ): Array<string> {
    if (parameters && parameters.length > 0) {
      parameters.forEach((parameter: any) => {
        if (parameter.steps) {
          parameter.steps.forEach((step: XPathStep) => {
            if (step.name && result.indexOf(step.name) < 0) {
              result.push(step.name);
            }
            if (step.predicate) {
              return this.getTypesInParameters(
                step.predicate.parameters as Array<XPathParameter>,
                result
              );
            }
          });
        } else {
          return this.getTypesInParameters(
            parameter.parameters as Array<XPathParameter>,
            result
          );
        }
      });
    }

    return result;
  }

  private getTypesInQuery(query: XPathQuery): Array<string> {
    const result: Array<string> = [];

    if (query && query.steps) {
      query.steps.forEach((step: XPathStep) => {
        if (step.name && result.indexOf(step.name) < 0) {
          result.push(step.name);
        }
        if (step.predicate) {
          const parameters = step.predicate.parameters as Array<XPathParameter>;
          if (parameters) {
            const typesInParameters = this.getTypesInParameters(parameters, []);
            typesInParameters.forEach((t) => {
              if (result.indexOf(t) < 0) {
                result.push(t);
              }
            });
          }
        }
      });
    }

    return result;
  }

  private getPathInParameter(
    parameters: Array<XPathParameter>,
    searchFor: Array<XPathParameter>,
    path: Array<string>
  ): Array<string> {
    if (parameters && parameters.length > 0) {
      parameters.forEach((parameter) => {
        if (parameter && parameter.operator) {
          path.push(parameter.operator);
          if (parameter.parameters === searchFor) {
            return this.getPathInParameter(null, searchFor, path);
          } else {
            return this.getPathInParameter(
              parameter.parameters as Array<XPathParameter>,
              searchFor,
              path
            );
          }
        } else {
          return this.getPathInParameter(null, searchFor, []);
        }
      });
    }

    return path;
  }

  private getPathInStep(
    parameters: Array<XPathParameter>,
    step: XPathStep
  ): Array<string> {
    let result = [];

    if (step && step.predicate && step.predicate.parameters) {
      if (step.predicate.parameters === parameters) {
        result.push(step.predicate.operator);
      } else {
        result = this.getPathInParameter(
          step.predicate.parameters as Array<XPathParameter>,
          parameters,
          result
        );
        result.unshift(step.predicate.operator);
      }
    }

    return result;
  }

  private getAttributeType(parameter: XPathParameter, step: XPathStep): string {
    if (step && step.name) {
      if (this.schemaDic.hasOwnProperty(step.name) && parameter.parameters) {
        const leftSide = parameter.parameters[0] as XPathAttribute;
        if (leftSide && leftSide.attributeName) {
          const defAttribute =
            this.schemaDic[step.name][leftSide.attributeName];
          if (defAttribute && defAttribute.dataType) {
            return defAttribute.dataType.toLowerCase();
          }
        }
      }
    }

    return null;
  }

  private replaceAt(text: string, index: number, replacement: string): string {
    const replacemangeLength =
      replacement.length === 0 ? 1 : replacement.length;
    const seg1 = text.substr(0, index);
    const seg2 = text.substr(index + replacemangeLength);
    return seg1 + replacement + seg2;
  }

  private fixQueryFunction(query: string): string {
    const posFn = query.indexOf(`'fn:`);
    const posOp = query.indexOf(`"op:`);
    if (posFn >= 0) {
      query = this.replaceAt(query, posFn, '');
      const posEnd = query.indexOf(`'`, posFn);
      query = this.replaceAt(query, posEnd, '');
      return this.fixQueryFunction(query);
    }
    if (posOp >= 0) {
      query = this.replaceAt(query, posOp, '');
      const posEnd = query.indexOf(`"`, posOp);
      query = this.replaceAt(query, posEnd, '');
      return this.fixQueryFunction(query);
    }

    return query;
  }

  private getAllowedSchema(): { [name: string]: Array<string> } {
    return typeof this.schema === 'string'
      ? this.config.getConfig(this.schema, null)
      : this.schema;
  }

  private getAllowedTypes(types: Array<Array<any>>): Array<Array<any>> {
    const allowedSchema = this.getAllowedSchema();
    if (allowedSchema) {
      return types.filter((t: Array<any>) => {
        if (t.length === 2) {
          return (
            Object.keys(allowedSchema).findIndex(
              (s: string) => s.toLowerCase() === t[0].toLowerCase()
            ) >= 0
          );
        }
      });
    } else {
      return types;
    }
  }

  private getAllowedAttributes(
    typeName: string,
    attributes: Array<Array<any>>
  ): Array<Array<any>> {
    const allowedSchema = this.getAllowedSchema();
    if (allowedSchema) {
      let allowedType: string[] = null;
      Object.keys(allowedSchema).forEach((key: string) => {
        if (key.toLowerCase() === typeName.toLowerCase()) {
          allowedType = allowedSchema[key];
        }
      });
      if (allowedType) {
        return attributes.filter((t: Array<any>) => {
          if (t.length === 2) {
            return (
              allowedType.findIndex(
                (s: string) => s.toLowerCase() === t[0].toLowerCase()
              ) >= 0
            );
          }
        });
      } else {
        return attributes;
      }
    } else {
      return attributes;
    }
  }

  constructor(
    private resource: ResourceService,
    private config: ConfigService,
    private translate: TransService,
    private modal: ModalService,
    private utils: UtilsService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.canParse = true;

    if (!this.query) {
      this.query = new XPathQuery();
      // this.query.steps.push({
      //   name: '*',
      //   predicate: { operator: 'and', parameters: [] },
      // });

      this.query.isNull = true;
      this.query.steps.push({
        name: '*',
        predicate: null,
      });
    }

    this.operators = this.allOperators;

    this.dtFormat = `${this.translate.instant(
      'key_dateFormat'
    )} ${this.translate.instant('key_timeFormat')}`;

    const process = this.modal.show(
      ModalType.progress,
      'key_processing',
      '',
      '300px'
    );

    const observableBatch = [];
    this.getTypesInQuery(this.query).forEach((typeName: string) => {
      if (typeName !== '*') {
        observableBatch.push(
          this.resource
            .getType(
              typeName,
              this.config.getCulture(this.translate.currentCulture)
            )
            .pipe(
              tap((result) => {
                this.schemaDic[typeName] = result;
              })
            )
        );
      }
    });

    this.subscription.add(
      forkJoin(observableBatch)
        .pipe(
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.resource
        .getTypes(this.config.getCulture(this.translate.currentCulture))
        .pipe(
          tap((result: { [id: string]: TypeResource }) => {
            result['*'] = {
              displayName: this.translate.instant('key_allResources'),
              systemName: '*',
              description: this.translate.instant('key_allResources'),
            };
            this.types = this.utils.sortDictionaryByValue(
              result,
              'displayName'
            );
            if (this.schema) {
              this.types = this.getAllowedTypes(this.types);
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isNegation(parameters: Array<XPathParameter>, step: XPathStep): boolean {
    const path = this.getPathInStep(parameters, step);
    return path && path.length > 0 && path[path.length - 1] === 'not';
  }

  getTypeName(step: XPathStep) {
    if (this.query && this.query.isNull) {
      return `<${this.translate.instant('key_resourceTypes')}>`;
    }

    let retVal: string = null;
    if (this.types) {
      const typeFound = this.types.find((type) => {
        return this.auth.authMode === AuthMode.azure
          ? step.name &&
              type[0] &&
              type[0].toLowerCase() === step.name.toLowerCase()
          : type[0] === step.name;
      });
      retVal = typeFound ? typeFound[1].displayName : undefined;
    }

    if (step && step.name !== '*') {
      if (this.types && this.types.length > 0) {
        if (!retVal) {
          this.canParse = false;
        }
      }
    }

    return retVal;
  }

  getLogicName(logic: string) {
    if (!logic) {
      return this.logics.and;
    } else {
      return this.logics[logic];
    }

    // if (!step.logic) {
    //   return 'all';
    // } else if (step.logic === 'and') {
    //   return 'all';
    // } else if (step.logic === 'or') {
    //   return 'any';
    // } else {
    //   return 'all';
    // }
  }

  getStepLogicName(step: XPathStep) {
    if (step) {
      if (!step.predicate) {
        return this.logics.and;
      } else {
        if (
          step.predicate.operator === 'and' ||
          step.predicate.operator === 'or'
        ) {
          return this.logics[step.predicate.operator];
        } else {
          return this.logics.and;
        }
      }
    } else {
      return this.logics.and;
    }
  }

  getParameterLeft(parameter: XPathParameter, step: XPathStep) {
    if (
      !parameter ||
      !parameter.parameters ||
      parameter.parameters.length !== 2
    ) {
      this.hasError = true;
    }
    const leftSide = parameter.parameters[0] as XPathAttribute;
    if (
      !leftSide ||
      leftSide.attributeName === null ||
      leftSide.attributeName === undefined
    ) {
      this.hasError = true;
    }

    if (this.hasError) {
      this.canParse = false;
      return undefined;
    }

    if (step && step.name) {
      if (this.schemaDic.hasOwnProperty(step.name)) {
        const defType = this.schemaDic[step.name];
        if (!leftSide.attributeName) {
          return 'key_clickToSelectAttribute';
        } else {
          if (defType[leftSide.attributeName]) {
            return defType[leftSide.attributeName].displayName;
          } else if (
            this.auth.authMode === AuthMode.azure &&
            defType[leftSide.attributeName.toLowerCase()]
          ) {
            return defType[leftSide.attributeName.toLowerCase()].displayName;
          } else {
            this.canParse = false;
            return undefined;
          }
        }
      }
    }

    return leftSide.attributeName;
  }

  getParameterRight(parameter: XPathParameter) {
    if (
      !parameter ||
      !parameter.parameters ||
      parameter.parameters.length !== 2
    ) {
      this.canParse = false;
      return undefined;
    }

    let retVal: string;

    if (parameter.parameters.length > 1) {
      if (
        parameter.parameters[1] !== null &&
        parameter.parameters[1] !== undefined &&
        parameter.parameters[1] !== ''
      ) {
        switch (typeof parameter.parameters[1]) {
          case 'string':
            {
              const term: string = parameter.parameters[1] as string;
              if (term === 'fn:current-dateTime()') {
                retVal = 'key_today';
              } else if (term.startsWith('op:add')) {
                const regEx = /P\d+D/;
                const matchs = regEx.exec(term);
                if (matchs.length === 1) {
                  retVal = `${matchs[0].substring(
                    1,
                    matchs[0].length - 1
                  )} ${this.translate.instant('key_daysFromToday')}`;
                }
              } else if (term.startsWith('op:subtract')) {
                const regEx = /P\d+D/;
                const matchs = regEx.exec(term);
                if (matchs.length === 1) {
                  retVal = `${matchs[0].substring(
                    1,
                    matchs[0].length - 1
                  )} ${this.translate.instant('key_daysAgo')}`;
                }
              } else {
                const regEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}$/g;
                if (regEx.test(term)) {
                  retVal = moment(term, 'YYYY-MM-DDTHH:mm:ss.SSS').format(
                    this.utils.ToMomentFormat(this.dtFormat)
                  );
                } else {
                  retVal = term;
                }
              }
            }
            break;
          case 'boolean':
          case 'number':
            retVal = String(parameter.parameters[1]);
            break;
          default: {
            const xparam: XPathParameter = parameter
              .parameters[1] as XPathParameter;
            if (xparam) {
              switch (xparam.operator) {
                case 'current-datetime':
                  retVal = 'key_today';
                  break;
                case 'subtract-daytimeduration-from-datetime':
                  {
                    if (xparam.parameters && xparam.parameters[1]) {
                      const xxparam = xparam.parameters[1] as XPathParameter;
                      if (
                        xxparam &&
                        xxparam.parameters &&
                        xxparam.parameters[0]
                      ) {
                        const rparam = String(xxparam.parameters[0]);
                        retVal = `${rparam.substring(
                          1,
                          rparam.length - 1
                        )} ${this.translate.instant('key_daysAgo')}`;
                      }
                    }
                  }
                  break;
                case 'add-daytimeduration-to-datetime':
                  {
                    if (xparam.parameters && xparam.parameters[1]) {
                      const xxparam = xparam.parameters[1] as XPathParameter;
                      if (
                        xxparam &&
                        xxparam.parameters &&
                        xxparam.parameters[0]
                      ) {
                        const rparam = String(xxparam.parameters[0]);
                        retVal = `${rparam.substring(
                          1,
                          rparam.length - 1
                        )} ${this.translate.instant('key_daysFromToday')}`;
                      }
                    }
                  }
                  break;
                default: {
                  const term: any = parameter.parameters[1];
                  if (
                    term.attributeName &&
                    typeof term.attributeName === 'string'
                  ) {
                    retVal = term.attributeName;
                  }
                }
              }
            } else {
              const term: any = parameter.parameters[1];
              if (
                term.attributeName &&
                typeof term.attributeName === 'string'
              ) {
                retVal = term.attributeName;
              }
            }
          }
        }
      } else {
        retVal = 'key_clickToSelectValue';
      }
    }

    if (retVal === undefined) {
      this.canParse = false;
    }

    return retVal;
  }

  getParameterRightType(parameter: XPathParameter) {
    if (
      !parameter ||
      !parameter.parameters ||
      parameter.parameters.length !== 2
    ) {
      this.canParse = false;
      return null;
    }

    if (
      parameter.parameters[1] === null ||
      parameter.parameters[1] === undefined
    ) {
      this.canParse = false;
      return null;
    }

    if (parameter.parameters[1].hasOwnProperty('steps')) {
      return 'step';
    } else {
      return 'value';
    }
  }

  getParameterOperator(parameter: XPathParameter) {
    if (!parameter || !parameter.operator) {
      return '=';
    }

    return this.operators[parameter.operator]
      ? this.operators[parameter.operator]
      : '=';
  }

  getXpath(): Observable<string> {
    if (!this.query || this.query.isNull) {
      return of(null);
    } else {
      const encodedQuery = this.encodeXpathQuery(
        this.utils.DeepCopy(this.query)
      );
      return this.resource.jsonToXPath(JSON.stringify(encodedQuery)).pipe(
        switchMap((xpath: string) => {
          return of(this.fixQueryFunction(xpath));
        })
      );
    }
  }

  getQuery(): XPathQuery {
    return this.query;
  }

  getType(): string {
    if (this.query && this.query.steps && this.query.steps.length > 0) {
      return this.query.steps[0].name;
    }
    return null;
  }

  getDateParameter(parameter: XPathParameter) {
    if (parameter && parameter.parameters && parameter.parameters.length > 1) {
      const value = parameter.parameters[1] as string;
      const match = value.match(/P\d{1,}D/);
      if (match && match.length > 0) {
        return match[0].substring(1, match[0].length - 1);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getNumberParameter(parameter: XPathParameter) {
    if (parameter && parameter.parameters && parameter.parameters.length > 1) {
      return parameter.parameters[1];
    } else {
      return null;
    }
  }

  convertToDate(text: string) {
    if (text) {
      const dt = moment(text, 'YYYY-MM-DDTHH:mm:ss.SSS').toDate();
      if (isNaN(dt.getDate())) {
        return null;
      } else {
        return dt;
      }
    } else {
      return null;
    }
  }

  isGuid(text: string) {
    return this.utils.IsGuid(text);
  }

  prepareIdentity(parameter: XPathParameter) {
    const result = {
      dataType: 'Reference',
      displayName: '',
      multivalued: false,
      permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
      systemName: 'TargetResource',
      value: {
        ObjectID: this.getParameterRight(parameter),
        ObjectType: 'Person',
        DisplayName: 'TESt',
      },
    };

    return result;
  }

  onTypeClick(type: TypeResource, step: XPathStep) {
    this.query.isNull = false;

    step.name = type.systemName;

    if (!this.schemaDic.hasOwnProperty(type.systemName)) {
      const process = this.modal.show(
        ModalType.progress,
        'key_processing',
        '',
        '300px'
      );

      this.subscription.add(
        this.resource
          .getType(
            type.systemName,
            this.config.getCulture(this.translate.currentCulture)
          )
          .pipe(
            tap((result) => {
              this.schemaDic[type.systemName] = result;
            }),
            finalize(() => {
              if (process) {
                process.close();
              }
            })
          )
          .subscribe()
      );
    }

    this.queryChange.emit(this.query);
  }

  onStepLogicClick(logic: string, step: XPathStep) {
    if (!step.predicate) {
      step.predicate = { operator: logic, parameters: [] };
    } else {
      step.predicate.operator = logic;
    }

    this.queryChange.emit(this.query);
  }

  onParameterLogicClick(logic: string, parameter: XPathParameter) {
    parameter.operator = logic;

    this.queryChange.emit(this.query);
  }

  onOperatorClick(operator: string, parameter: XPathParameter) {
    parameter.operator = operator;

    this.queryChange.emit(this.query);
  }

  onAddStatement(parameters: Array<XPathParameter>, step: XPathStep) {
    this.query.isNull = false;
    if (parameters) {
      parameters.push({
        operator: '=',
        parameters: [
          {
            attributeName: '',
          },
          '',
        ],
      });
    } else if (step && !step.predicate) {
      step.predicate = {
        operator: 'and',
        parameters: [
          {
            operator: '=',
            parameters: [
              {
                attributeName: '',
              },
              '',
            ],
          },
        ],
      };
    }

    this.queryChange.emit(this.query);
  }

  onAddSubCondition(parameters: Array<XPathParameter>, step: XPathStep) {
    // const lastParameter = parameters[parameters.length - 1];
    // if (
    //   lastParameter &&
    //   lastParameter.operator !== 'and' &&
    //   lastParameter.operator !== 'or'
    // ) {
    //   parameters.push({
    //     operator: 'and',
    //     parameters: [],
    //   });
    // }

    this.query.isNull = false;
    if (parameters) {
      parameters.push({
        operator: 'and',
        parameters: [],
      });
    } else if (step && !step.predicate) {
      step.predicate = {
        operator: 'and',
        parameters: [
          {
            operator: 'and',
            parameters: [],
          },
        ],
      };
    }

    this.queryChange.emit(this.query);
  }

  onAddNegation(parameters: Array<XPathParameter>, step: XPathStep) {
    this.query.isNull = false;
    if (parameters) {
      parameters.push({
        operator: 'not',
        parameters: [],
      });
    } else if (step && !step.predicate) {
      step.predicate = {
        operator: 'and',
        parameters: [
          {
            operator: 'not',
            parameters: [],
          },
        ],
      };
    }

    this.queryChange.emit(this.query);
  }

  onRemoveStatement(
    parameters: Array<XPathParameter>,
    parameter: XPathParameter
  ) {
    parameters.splice(parameters.indexOf(parameter), 1);

    this.queryChange.emit(this.query);
  }

  onShowAttributes(parameter: XPathParameter, step: XPathStep) {
    this.currentParameter = parameter;
    this.currentType = null;
    this.currentDateType = null;

    if (step && step.name) {
      if (this.schemaDic.hasOwnProperty(step.name)) {
        this.attributes = this.utils.sortDictionaryByValueCaseInsensitive(
          this.schemaDic[step.name],
          'displayName'
        );
        if (this.schema) {
          this.attributes = this.getAllowedAttributes(
            step.name,
            this.attributes
          );
        }
      }
    }
  }

  onAttributeClick(attributeName: string, parameter: XPathParameter) {
    parameter.operator = '=';
    if (parameter.parameters && parameter.parameters.length > 1) {
      parameter.parameters[1] = '';
    }
    this.currentType = null;

    if (parameter.parameters && parameter.parameters.length > 0) {
      const attrParameter = parameter.parameters[0] as XPathAttribute;
      if (attrParameter) {
        attrParameter.attributeName = attributeName;
      }
    }

    this.queryChange.emit(this.query);
  }

  onShowOperator(parameter: XPathParameter, step: XPathStep) {
    this.currentParameter = parameter;

    this.operators = this.allOperators;

    const attributeType = this.getAttributeType(parameter, step);
    if (attributeType && this.operatorDic.hasOwnProperty(attributeType)) {
      this.operators = this.operatorDic[attributeType];
    }
  }

  onClickParameterRight(
    parameter: XPathParameter,
    step: XPathStep,
    reset = false
  ) {
    this.currentParameter = parameter;

    let type = this.getAttributeType(parameter, step);

    if (
      type === 'reference' &&
      parameter.parameters &&
      parameter.parameters.length > 1
    ) {
      if (
        parameter.parameters[1] &&
        !parameter.parameters[1].hasOwnProperty('steps')
      ) {
        type = 'string';
      }
      if (reset) {
        parameter.parameters[1] = '';
      }
    }
    this.currentType = type;
  }

  onInputID(parameter: XPathParameter) {
    this.currentParameter = parameter;
    this.currentType = 'identity';
  }

  onAddStep(parameter: XPathParameter) {
    if (parameter && parameter.parameters && parameter.parameters.length > 1) {
      parameter.parameters[1] = {
        steps: [
          {
            name: '*',
            predicate: null,
          },
        ],
      };
    }

    this.queryChange.emit(this.query);
  }

  onDateTimeChange(event: Date, parameter: XPathParameter) {
    const dtMom = moment(event);
    const dtStr = dtMom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    if (parameter && parameter.parameters && parameter.parameters.length > 1) {
      parameter.parameters[1] = dtStr;
    }
  }

  onDateTimeTypeChange(
    event: { text?: string; value: string },
    parameter: XPathParameter
  ) {
    this.currentDateType = event.value;

    if (parameter && parameter.parameters && parameter.parameters.length > 1) {
      parameter.parameters[1] = '';

      if (this.currentDateType === 'today') {
        parameter.parameters[1] = 'fn:current-dateTime()';
      }
    }
  }

  onDaysChange(event: any, parameter: XPathParameter) {
    if (
      event &&
      event.target &&
      parameter &&
      parameter.parameters &&
      parameter.parameters.length > 1
    ) {
      if (!event.target.value) {
        parameter.parameters[1] = '';
      } else {
        if (this.currentDateType === 'ago') {
          parameter.parameters[1] = `op:subtract-dayTimeDuration-from-dateTime(fn:current-dateTime(), xs:dayTimeDuration('P${event.target.value}D'))`;
        }
        if (this.currentDateType === 'from') {
          parameter.parameters[1] = `op:add-dayTimeDuration-to-dateTime(fn:current-dateTime(), xs:dayTimeDuration('P${event.target.value}D'))`;
        }
      }
    }
  }

  onNumberChange(event: any, parameter: XPathParameter) {
    if (
      event &&
      event.target &&
      parameter &&
      parameter.parameters &&
      parameter.parameters.length > 1
    ) {
      if (!event.target.value) {
        parameter.parameters[1] = '';
      } else {
        parameter.parameters[1] = Number(event.target.value);
      }
    }
  }

  onApplyStatement() {
    this.currentParameter = null;
    this.currentType = null;

    this.queryChange.emit(this.query);
  }

  onIdentityChange(event: any, parameter: XPathParameter) {
    if (event && event.length === 1) {
      const result = this.utils.ExtraValue(event[0], 'ObjectID');
      if (result) {
        parameter.parameters[1] = result;
      } else {
        parameter.parameters[1] = '';
      }
    } else {
      parameter.parameters[1] = '';
    }
  }
}
