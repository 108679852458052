<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" style="font-weight: 400">
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="disablePreupdateTrigger"
        [ngModelOptions]="{ standalone: true }"
        matTooltip="{{ 'key_disablePreupdateTriggerDes' | translate }}"
        >{{ 'key_disablePreupdateTrigger' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="disablePostupdateTrigger"
        [ngModelOptions]="{ standalone: true }"
        matTooltip="{{ 'key_disablePostupdateTriggerDes' | translate }}"
        >{{ 'key_disablePostupdateTrigger' | translate }}</mat-checkbox
      >
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 15px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="useRequestorAsActor"
        [ngModelOptions]="{ standalone: true }"
        (change)="onImpersonationChange()"
        matTooltip="{{ 'key_useRequestorAsActorDes' | translate }}"
        >{{ 'key_useRequestorAsActor' | translate }}</mat-checkbox
      >
      <mat-checkbox
        *ngIf="useRequestorAsActor"
        fxFlex="50"
        [(ngModel)]="skipPermissionCheck"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_skipPermissionCheck' | translate }}</mat-checkbox
      >
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 10px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="waitForCompletion"
        [ngModelOptions]="{ standalone: true }"
        matTooltip="{{ 'key_waitForCompletionDes' | translate }}"
        >{{ 'key_waitForCompletion' | translate }}</mat-checkbox
      >
    </div>
    <div *ngIf="waitForCompletion" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="200px">
        <input
          matInput
          type="number"
          min="0"
          [placeholder]="'key_maxWaitTime' | translate"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="maxWaitSeconds"
          (change)="onMaxTimeChange()"
          matTooltip="{{ 'key_maxWaitTimeDes' | translate }}"
        />
      </mat-form-field>
      <mat-form-field fxFlex="auto" style="margin-left: 20px">
        <mat-label>{{ 'key_waitForCompletionMessage' | translate }}</mat-label>
        <input
          matInput
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="waitForCompletionMessage"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px">
      <app-editor-text
        fxFlex="100"
        name="txtDeletionTarget"
        [creationMode]="true"
        [(ngModel)]="deletionTarget"
        [config]="{
          isSimpleValue: true,
          isCode: true,
          required: true,
          hintLimit: 100,
          customDisplayName: 'key_deletionTarget',
          customDescription: 'key_deletionTargetDesc',
          updateOn: updateOnBlure,
          validationKey: 'workflow/deleteresources/deletiontarget'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
