import { AfterViewInit, Component, Injector } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { ModalType } from '../../models/componentContract.model';
import { AttributeResource } from '../../models/dataContract.model';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import {
  IterablesEditorConfig,
  IterablesProperty,
} from '../../models/editorContract.model';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-editor-iterables-config',
  templateUrl: './editor-iterables-config.component.html',
  styleUrls: ['./editor-iterables-config.component.scss'],
})
export class EditorIterablesConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: IterablesEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  public selectedProperty: IterablesProperty;
  public propertyToAdd: string;

  public optionTextToAdd = '';
  public optionValueToAdd = '';

  constructor(
    injector: Injector,
    private dragula: DragulaService,
    private modal: ModalService
  ) {
    super(injector);

    try {
      this.dragula.createGroup('PROPERTIESTOSHOW', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngAfterViewInit(): void {
    this.initComponent();
  }

  onAddProperty() {
    if (this.propertyToAdd) {
      const pos = this.data.config.properties.findIndex(
        (item: IterablesProperty) =>
          item.name.toLowerCase() === this.propertyToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.properties.push({
          name: this.propertyToAdd,
          displayName: this.propertyToAdd,
          type: 'text',
          isKey: false,
          width: 0,
          options: [],
        });
      }
    }
    this.propertyToAdd = undefined;
  }

  onDeleteProperty(property: IterablesProperty) {
    const index = this.data.config.properties.findIndex(
      (c) => c.name === property.name
    );

    if (index >= 0) {
      if (this.data.config.properties.length === 1) {
        this.modal.show(
          ModalType.info,
          'key_info',
          'key_canNotDeleteLastEntry'
        );
        return;
      }
      if (property.isKey) {
        this.modal.show(
          ModalType.info,
          'key_info',
          'key_canNotDeleteKeyProperty'
        );
        return;
      }

      // remove column
      this.data.config.properties.splice(index, 1);

      // set focus
      if (property.name === this.selectedProperty.name) {
        if (this.data.config.properties.length > 0) {
          this.selectedProperty = this.data.config.properties[0];
        } else {
          this.selectedProperty = undefined;
        }
      }
    }
  }

  onSelectProperty(property: IterablesProperty) {
    if (this.selectedProperty && this.selectedProperty.name !== property.name) {
      this.optionTextToAdd = '';
      this.optionValueToAdd = '';
    }

    this.selectedProperty = property;
  }

  onAddOption() {
    const pos = this.selectedProperty.options.findIndex(
      (o) => o.value === this.optionValueToAdd
    );
    if (pos < 0) {
      this.selectedProperty.options.push({
        text: this.optionTextToAdd,
        value: this.optionValueToAdd,
      });
    }

    this.optionTextToAdd = '';
    this.optionValueToAdd = '';
  }

  onRemoveOption(pos: number) {
    this.selectedProperty.options.splice(pos, 1);
  }

  onSetKeyProperty() {
    this.data.config.properties.forEach((p) => {
      if (p.name !== this.selectedProperty.name) {
        p.isKey = false;
      }
    });
  }
}
