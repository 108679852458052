import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { DragulaService } from 'ng2-dragula';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { ModalType } from '../../models/componentContract.model';
import { Resource, WorkflowDescription } from '../../models/dataContract.model';

import { CustomComponent } from '../../models/dynamicEditor.interface';
import { ActivityIndexService } from '../../services/activity-index.service';
import { ConfigService } from '../../services/config.service';
import { ModalService } from '../../services/modal.service';
import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { ActivityGeneralComponent } from '../activities/activity-general/activity-general.component';
import { ActivityCreatorComponent } from '../activity-creator/activity-creator.component';

@Component({
  selector: 'app-workflow-design',
  templateUrl: './workflow-design.component.html',
  styleUrls: ['./workflow-design.component.scss'],
})
export class WorkflowDesignComponent
  extends CustomComponent
  implements OnInit, OnDestroy
{
  currentWorkflow: Resource;
  workflowDescription: WorkflowDescription;

  private uiToApi(activity: any) {
    if (activity.xpathqueries) {
      activity.xpathqueries = activity.xpathqueries.filter(
        (item: any) => item.key !== null && item.key !== undefined
      );
    }
    if (activity.workflowdataexpressions) {
      activity.workflowdataexpressions =
        activity.workflowdataexpressions.filter(
          (item: any) => item.key !== null && item.key !== undefined
        );
    }

    switch (activity.type) {
      // case 'RestApiCall':
      //   {
      //     if (
      //       activity.bodyexpression &&
      //       typeof activity.bodyexpression === 'string'
      //     ) {
      //       const bdExpression: string = activity.bodyexpression;
      //       if (
      //         (bdExpression &&
      //           bdExpression.startsWith('{') &&
      //           bdExpression.endsWith('}')) ||
      //         (bdExpression.startsWith('[') && bdExpression.endsWith(']'))
      //       ) {
      //         try {
      //           activity.bodyexpression = JSON.parse(activity.bodyexpression);
      //         } catch {}
      //       }
      //     }
      //   }
      //   break;
      case 'IfThenElse':
        {
          if (activity.activitiestrue && activity.activitiestrue.length > 0) {
            activity.truebranch = activity.activitiestrue[0];
          } else {
            activity.truebranch = null;
          }
          if (activity.activitiesfalse && activity.activitiesfalse.length > 0) {
            activity.falsebranch = activity.activitiesfalse[0];
          } else {
            activity.falsebranch = null;
          }
        }
        break;
      default:
        break;
    }

    // if (
    //   activity.type === 'RestApiCall' &&
    //   activity.bodyexpression &&
    //   typeof activity.bodyexpression === 'string'
    // ) {
    //   const bdExpression: string = activity.bodyexpression;
    //   if (
    //     (bdExpression &&
    //       bdExpression.startsWith('{') &&
    //       bdExpression.endsWith('}')) ||
    //     (bdExpression.startsWith('[') && bdExpression.endsWith(']'))
    //   ) {
    //     try {
    //       activity.bodyexpression = JSON.parse(activity.bodyexpression);
    //     } catch {}
    //   }
    // }
    // if (activity.type === 'IfThenElse') {
    //   if (activity.activitiestrue && activity.activitiestrue.length > 0) {
    //     activity.truebranch = activity.activitiestrue[0];
    //   } else {
    //     activity.truebranch = null;
    //   }
    //   if (activity.activitiesfalse && activity.activitiesfalse.length > 0) {
    //     activity.falsebranch = activity.activitiesfalse[0];
    //   } else {
    //     activity.falsebranch = null;
    //   }
    // }
  }

  private apiToUi(activity: any) {
    if (activity.type === 'IfThenElse') {
      activity.activitiestrue = activity.truebranch
        ? [activity.truebranch]
        : [];
      activity.activitiesfalse = activity.falsebranch
        ? [activity.falsebranch]
        : [];
    }
  }

  private applyToUi(workflow: WorkflowDescription) {
    if (workflow) {
      this.apiToUi(workflow);

      if (workflow.activities && workflow.activities.length > 0) {
        workflow.activities.forEach((activity: any) => {
          this.apiToUi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToUi(activity);
          }
        });
      }
      if (workflow.activitiestrue && workflow.activitiestrue.length > 0) {
        workflow.activitiestrue.forEach((activity: any) => {
          this.apiToUi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToUi(activity);
          }
        });
      }
      if (workflow.activitiesfalse && workflow.activitiesfalse.length > 0) {
        workflow.activitiesfalse.forEach((activity: any) => {
          this.apiToUi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToUi(activity);
          }
        });
      }
    }
  }

  private applyToApi(workflow: WorkflowDescription) {
    if (workflow) {
      this.uiToApi(workflow);

      if (workflow.activities && workflow.activities.length > 0) {
        workflow.activities.forEach((activity: any) => {
          this.uiToApi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToApi(activity);
          }
        });
      }
      if (workflow.activitiestrue && workflow.activitiestrue.length > 0) {
        workflow.activitiestrue.forEach((activity: any) => {
          this.uiToApi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToApi(activity);
          }
        });
      }
      if (workflow.activitiesfalse && workflow.activitiesfalse.length > 0) {
        workflow.activitiesfalse.forEach((activity: any) => {
          this.uiToApi(activity);
          if (
            (activity.activities && activity.activities.length > 0) ||
            (activity.activitiestrue && activity.activitiestrue.length > 0) ||
            (activity.activitiesfalse && activity.activitiesfalse.length > 0)
          ) {
            this.applyToApi(activity);
          }
        });
      }
    }
  }

  private applyChange() {
    this.applyToApi(this.workflowDescription);
    this.componentValue = this.workflowDescription;
  }

  private initComponent() {
    const workflowID: string = this.utils.ExtraValue(
      this.currentResource,
      'ObjectID:value'
    );

    if (workflowID) {
      this.spinner.startLoader('spinner_home');
      this.subscription.add(
        this.resource
          .getResourceByID(workflowID)
          .pipe(
            tap((result) => {
              if (result) {
                this.currentWorkflow = result;
                this.workflowDescription =
                  this.currentWorkflow.workflowdescription;
                this.applyToUi(this.workflowDescription);
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.spinner.stopLoader('spinner_home');
              this.modal.show(ModalType.error, 'key_error', error.error);
              return EMPTY;
            }),
            finalize(() => {
              this.spinner.stopLoader('spinner_home');
            })
          )
          .subscribe()
      );
    }
  }

  private findItemToDelete(id: string, workflow: WorkflowDescription) {
    if (!workflow) {
      return;
    }

    if (workflow.activities) {
      const pos = workflow.activities.findIndex((a) => a.id === id);
      if (pos >= 0) {
        workflow.activities.splice(pos, 1);
      } else {
        workflow.activities.forEach((a) => {
          this.findItemToDelete(id, a);
        });
      }
    }

    if (workflow.activitiestrue) {
      const pos = workflow.activitiestrue.findIndex((a) => a.id === id);
      if (pos >= 0) {
        workflow.activitiestrue.splice(pos, 1);
      } else {
        workflow.activitiestrue.forEach((a) => {
          this.findItemToDelete(id, a);
        });
      }
    }

    if (workflow.activitiesfalse) {
      const pos = workflow.activitiesfalse.findIndex((a) => a.id === id);
      if (pos >= 0) {
        workflow.activitiesfalse.splice(pos, 1);
      } else {
        workflow.activitiesfalse.forEach((a) => {
          this.findItemToDelete(id, a);
        });
      }
    }
  }

  private findItem(
    id: string,
    workflow: WorkflowDescription
  ): { pos: number; activities: WorkflowDescription[] } {
    if (!workflow) {
      return;
    }

    if (workflow.id === id) {
      return { pos: 0, activities: null };
    }

    if (workflow.activities) {
      const pos = workflow.activities.findIndex((a) => a.id === id);
      if (pos >= 0) {
        return { pos, activities: workflow.activities };
      } else {
        workflow.activities.forEach((a) => {
          this.findItem(id, a);
        });
      }
    }

    if (workflow.activitiestrue) {
      const pos = workflow.activitiestrue.findIndex((a) => a.id === id);
      if (pos >= 0) {
        return { pos, activities: workflow.activities };
      } else {
        workflow.activitiestrue.forEach((a) => {
          this.findItem(id, a);
        });
      }
    }

    if (workflow.activitiesfalse) {
      const pos = workflow.activitiesfalse.findIndex((a) => a.id === id);
      if (pos >= 0) {
        return { pos, activities: workflow.activities };
      } else {
        workflow.activitiesfalse.forEach((a) => {
          this.findItem(id, a);
        });
      }
    }
  }

  private findActivity(
    id: string,
    workflow: WorkflowDescription
  ): WorkflowDescription {
    if (id === workflow.id) {
      return workflow;
    }
    if (workflow.activities) {
      for (const act of workflow.activities) {
        const result = this.findActivity(id, act);
        if (result) {
          return result;
        }
      }
    }
    if (workflow.activitiestrue) {
      for (const act of workflow.activitiestrue) {
        const result = this.findActivity(id, act);
        if (result) {
          return result;
        }
      }
    }
    if (workflow.activitiesfalse) {
      for (const act of workflow.activitiesfalse) {
        const result = this.findActivity(id, act);
        if (result) {
          return result;
        }
      }
    }
  }

  private enableDebugInfo(id: string) {
    if (this.workflowDescription) {
      if (this.workflowDescription.debuginfotodisable) {
        const pos = this.workflowDescription.debuginfotodisable.indexOf(id);
        if (pos >= 0) {
          this.workflowDescription.debuginfotodisable.splice(pos, 1);
        }
      }

      if (this.workflowDescription.debuginfotoenable) {
        if (this.workflowDescription.debuginfotoenable.indexOf(id) < 0) {
          this.workflowDescription.debuginfotoenable.push(id);
        }
      } else {
        this.workflowDescription.debuginfotoenable = [id];
      }
    }
  }

  private disableDebugInfo(id: string) {
    if (this.workflowDescription) {
      if (this.workflowDescription.debuginfotoenable) {
        const pos = this.workflowDescription.debuginfotoenable.indexOf(id);
        if (pos >= 0) {
          this.workflowDescription.debuginfotoenable.splice(pos, 1);
        }
      }

      if (this.workflowDescription.debuginfotodisable) {
        if (this.workflowDescription.debuginfotodisable.indexOf(id) < 0) {
          this.workflowDescription.debuginfotodisable.push(id);
        }
      } else {
        this.workflowDescription.debuginfotodisable = [id];
      }
    }
  }

  private changeDisplayForAllNodes(
    workflow: WorkflowDescription,
    hideContent: boolean
  ) {
    workflow.hideContent = hideContent;
    if (workflow) {
      if (workflow.activities) {
        workflow.activities.forEach((a: WorkflowDescription) =>
          this.changeDisplayForAllNodes(a, hideContent)
        );
      }
      if (workflow.activitiestrue) {
        workflow.activitiestrue.forEach((a: WorkflowDescription) =>
          this.changeDisplayForAllNodes(a, hideContent)
        );
      }
      if (workflow.activitiesfalse) {
        workflow.activitiesfalse.forEach((a: WorkflowDescription) =>
          this.changeDisplayForAllNodes(a, hideContent)
        );
      }
    }
  }

  private getWorkflowTypePath(
    id: string,
    workflow: WorkflowDescription,
    path: string[],
    excludeSelf = true
  ): string[] {
    path.push(workflow.type);
    if (workflow.id === id) {
      if (excludeSelf) {
        path.pop();
      }
      return path;
    } else if (workflow.activities) {
      for (const activity of workflow.activities) {
        const result = this.getWorkflowTypePath(
          id,
          activity,
          path,
          excludeSelf
        );
        if (result) {
          return result;
        }
      }
    } else {
      if (workflow.activitiesfalse && workflow.activitiesfalse.length > 0) {
        const result = this.getWorkflowTypePath(
          id,
          workflow.activitiesfalse[0],
          path,
          excludeSelf
        );
        if (result) {
          return result;
        }
      }
      if (workflow.activitiestrue && workflow.activitiestrue.length > 0) {
        const result = this.getWorkflowTypePath(
          id,
          workflow.activitiestrue[0],
          path,
          excludeSelf
        );
        if (result) {
          return result;
        }
      }
    }
    path.pop();
    return null;
  }

  private canDrop(target: WorkflowDescription[], item: WorkflowDescription) {
    if (target.length >= 2 && item) {
      const def = this.activityIndex.activityIndex[item.type];
      if (def.onlyInSimulation || def.forbiddenInSimulation) {
        if (item.id) {
          const typePath = this.getWorkflowTypePath(
            item.id,
            this.workflowDescription,
            [],
            true
          );
          if (typePath && typePath.indexOf('Simulation') >= 0) {
            return def.forbiddenInSimulation !== true;
          } else {
            return def.onlyInSimulation !== true;
          }
        }
      }
    }

    return true;
  }

  constructor(
    injector: Injector,
    private utils: UtilsService,
    private resource: ResourceService,
    private modal: ModalService,
    private spinner: NgxUiLoaderService,
    private activityIndex: ActivityIndexService,
    private window: WindowService,
    private dragula: DragulaService,
    private config: ConfigService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.initComponent();

    this.subscription.add(
      this.dragula
        .drag('ACTIVITIES')
        .pipe(
          tap(({ el }) => {
            const dragId = el.querySelector('div.activity-id').innerHTML;
            const dragActivity = this.findActivity(
              dragId,
              this.workflowDescription
            );
            if (dragActivity) {
              dragActivity.hideContent = true;
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.dragula
        .dropModel('ACTIVITIES')
        .pipe(
          tap(({ sourceModel, targetModel, item }) => {
            setTimeout(() => {
              if (this.canDrop(targetModel, item)) {
                this.applyChange();
              } else {
                this.dragula.find('ACTIVITIES').drake.cancel(true);
                sourceModel.push(
                  targetModel.splice(targetModel.indexOf(item), 1)[0]
                );
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidWFContainer'
                );
              }
            }, 0);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.spinner.stopLoader('spinner_home');
    this.subscription.unsubscribe();
  }

  getIcon(name: string) {
    return this.activityIndex.getIcon(name);
  }

  getTooltip(workflow: WorkflowDescription) {
    let retVal = null;
    if (workflow) {
      if (workflow.type) {
        retVal = this.translate.instant(
          this.activityIndex.getName(workflow.type)
        );
        retVal = `${retVal} - ${
          workflow.isenabled
            ? this.translate.instant('key_enabled')
            : this.translate.instant('key_disabled')
        }`;
      } else {
        retVal = `${
          workflow.isenabled
            ? this.translate.instant('key_enabled')
            : this.translate.instant('key_disabled')
        }`;
      }
    }
    return retVal;
  }

  isContainer(name: string) {
    return this.activityIndex.isContainer(name);
  }

  isConditional(name: string) {
    return this.activityIndex.isConditional(name);
  }

  hasMultiActivities(name: string) {
    return this.activityIndex.hasMultiActivities(name);
  }

  canAddNew(workflow: WorkflowDescription) {
    return this.activityIndex.canAddNew(workflow);
  }

  toggleEnable(workflow: WorkflowDescription) {
    if (workflow) {
      workflow.isenabled = !workflow.isenabled;

      this.applyChange();
    }
  }

  onAddActivity(workflow: WorkflowDescription, condition = false) {
    const typePath: string[] = workflow
      ? this.getWorkflowTypePath(
          workflow.id,
          this.workflowDescription,
          [],
          false
        )
      : [];

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: ActivityCreatorComponent,
      width: 600,
      height: 500,
      top: this.config.getConfig('wizardTopPosition', null),
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      inSimulationContainer: typePath.indexOf('Simulation') >= 0,
    };

    let wfDef: WorkflowDescription = null;

    this.subscription.add(
      windowRef.result
        .pipe(
          switchMap((result: any) => {
            if (!(result instanceof WindowCloseResult)) {
              const def = this.utils.DeepCopy(result);
              def.def.id = uuid();
              wfDef = def.def;

              const configRef = this.window.open({
                content: ActivityGeneralComponent,
                width: 780,
                height: 650,
                top: this.config.getConfig('wizardTopPosition', null),
              });

              const windowIns = configRef.content.instance;
              windowIns.data = wfDef;
              windowIns.debugInfoToEnable =
                this.workflowDescription.debuginfotoenable;
              windowIns.debugInfoToDisable =
                this.workflowDescription.debuginfotodisable;

              return configRef.result;
            } else {
              return of(null);
            }
          }),
          tap((result: any) => {
            if (result && !(result instanceof WindowCloseResult)) {
              if (workflow) {
                if (workflow.activities) {
                  workflow.activities.push(result);
                  this.applyChange();
                }
                if (workflow.activitiestrue && condition === true) {
                  workflow.activitiestrue.push(result);
                  this.applyChange();
                }
                if (workflow.activitiesfalse && condition === false) {
                  workflow.activitiesfalse.push(result);
                  this.applyChange();
                }
              } else {
                this.currentWorkflow.workflowdescription = result;
                this.workflowDescription =
                  this.currentWorkflow.workflowdescription;
                this.applyChange();
              }
            }
          }),
          finalize(() => {
            this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
          })
        )
        .subscribe()
    );
  }

  onRemoveActivity(workflow: WorkflowDescription) {
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_deleteWorkflowActivity'
    );
    this.subscription.add(
      confirm.afterClosed().subscribe((result) => {
        if (result && result === 'yes') {
          if (
            this.currentWorkflow &&
            this.currentWorkflow.workflowdescription &&
            workflow.id === this.currentWorkflow.workflowdescription.id
          ) {
            this.currentWorkflow.workflowdescription = null;
            this.workflowDescription = null;
            this.applyChange();
          } else {
            this.findItemToDelete(workflow.id, this.workflowDescription);
            this.applyChange();
          }
        }
      })
    );
  }

  configure(workflow: WorkflowDescription) {
    const activityCopy = this.utils.DeepCopy(workflow);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: ActivityGeneralComponent,
      width: 780,
      height: 650,
      top: this.config.getConfig('wizardTopPosition', null),
    });

    const windowIns = windowRef.content.instance;
    windowIns.data = workflow;
    windowIns.debugInfoToEnable = this.workflowDescription.debuginfotoenable;
    windowIns.debugInfoToDisable = this.workflowDescription.debuginfotodisable;

    this.subscription.add(
      windowRef.result
        .pipe(
          tap((result: any) => {
            if (result instanceof WindowCloseResult) {
              const itemFound = this.findItem(
                workflow.id,
                this.workflowDescription
              );
              if (itemFound) {
                if (itemFound.activities === null && itemFound.pos === 0) {
                  this.workflowDescription = activityCopy;
                } else {
                  itemFound.activities[itemFound.pos] = activityCopy;
                }
              }
            } else {
              if (result.showdebuginfo === true) {
                this.enableDebugInfo(result.id);
              } else if (result.showdebuginfo === false) {
                this.disableDebugInfo(result.id);
              }
              delete result.showdebuginfo;

              this.applyChange();
            }
          }),
          finalize(() => {
            this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
          })
        )
        .subscribe()
    );
  }

  onAction() {
    console.log(this.workflowDescription);
    // this.subscription.add(
    //   this.resource
    //     .callApi('get', 'event/3c431bec-f576-4316-9adc-93b71bbbe430', {
    //       slimFormat: 'false',
    //     })
    //     .pipe(
    //       tap((result: any) => {
    //         console.log(result);
    //       })
    //     )
    //     .subscribe()
    // );
  }

  onExpandAll() {
    this.changeDisplayForAllNodes(this.workflowDescription, false);
  }

  onCollapseAll() {
    this.changeDisplayForAllNodes(this.workflowDescription, true);
    this.workflowDescription.hideContent = false;
  }
}
