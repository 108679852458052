import {
  AfterViewInit,
  Component,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import {
  ResourceColumnConfig,
  ResourceTableConfig,
} from '../../models/componentContract.model';
import { AttributeResource, AuthMode } from '../../models/dataContract.model';
import { IdentityEditorConfig } from '../../models/editorContract.model';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';
import { UtilsService } from '../../services/utils.service';
import { AttributePickerComponent } from '../attribute-picker/attribute-picker.component';
import { ResourceTableComponent } from '../resource-table/resource-table.component';
import { TypePickerComponent } from '../type-picker/type-picker.component';

@Component({
  selector: 'app-identity-browser',
  templateUrl: './identity-browser.component.html',
  styleUrls: ['./identity-browser.component.scss'],
})
export class IdentityBrowserComponent implements OnInit, AfterViewInit {
  private windowRef: WindowRef;

  private lettersToTrigger: number = this.config.getConfig(
    'lettersToTriggerSearch',
    3
  );

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @ViewChild('typePicker')
  typePicker: TypePickerComponent;

  @ViewChild('attributePicker')
  atp: AttributePickerComponent;

  @ViewChild('identityTable')
  idt: ResourceTableComponent;

  data: {
    query: string;
    config: IdentityEditorConfig;
  };

  typeName = '';

  tableConfig: ResourceTableConfig = new ResourceTableConfig();

  searchText = '';
  showSearchText = false;

  constructor(
    private injector: Injector,
    private auth: AuthService,
    private utils: UtilsService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.query) {
      this.showSearchText = /%searchText%/i.test(this.data.query);
    }

    if (!this.showSearchText) {
      this.tableConfig.query = this.data.query;
    }
    this.tableConfig.objectType = this.data.config.objectType;
    this.tableConfig.tableHeight = 480;
    this.tableConfig.selectAllEnabled = false;
    this.tableConfig.pageSize =
      this.data.config && this.data.config.emptySearchMaxCount
        ? this.data.config.emptySearchMaxCount
        : 50;
    this.tableConfig.scrollMode = this.data.config.browserScrollMode;
    this.tableConfig.scrollHeight = this.data.config.browserScrollHeight;
    this.tableConfig.selectable = true;
    this.tableConfig.selectMode = this.data.config.isMultivalue
      ? 'multiple'
      : 'single';
    this.tableConfig.linkDisabled = true;
    this.tableConfig.fontSize = 14;
    this.tableConfig.cellPadding = 4;
    this.tableConfig.columns = this.utils.DeepCopy(
      this.data.config.attributesToShow
    );
  }

  ngAfterViewInit() {
    this.windowRef = this.injector.get(WindowRef);
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }

    if (
      this.data.config &&
      this.data.config.browserShowTypePicker &&
      this.data.config.browserDefaultType
    ) {
      if (this.idt) {
        setTimeout(() => {
          this.typeName =
            this.auth.authMode === AuthMode.azure
              ? this.data.config.browserDefaultType.toLowerCase()
              : this.data.config.browserDefaultType;
          this.tableConfig.query = `/${this.data.config.browserDefaultType}`;
          this.idt.updateDataSource(true);
          if (this.atp) {
            this.atp.preSelectedItems = this.data.config.attributesToShow.map(
              (a: ResourceColumnConfig) => a.field
            );
            setTimeout(() => {
              this.atp.refresh();
            });
          }
        });
      }
    }
  }

  onTypeChange(type: string) {
    if (type) {
      this.typeName = type;
      if (this.atp) {
        this.atp.preSelectedItems = this.data.config.attributesToShow.map(
          (a: ResourceColumnConfig) => a.field
        );
        setTimeout(() => {
          this.atp.refresh();
        });
      }
      if (this.idt) {
        this.tableConfig.query = `/${type}`;
        this.tableConfig.columns = this.utils.DeepCopy(
          this.data.config.attributesToShow
        );
        this.idt.updateDataSource(true);
      }
    }
  }

  onAttributeChange(attributes: Array<AttributeResource>) {
    if (!this.idt) {
      return;
    }

    attributes.forEach((attribute) => {
      if (
        this.tableConfig.columns.findIndex(
          (col) =>
            col.field.toLowerCase() === attribute.systemName.toLowerCase()
        ) < 0
      ) {
        this.tableConfig.columns.push({
          field: attribute.systemName,
          width: 0,
          sortable: true,
        });
      }
    });

    this.tableConfig.columns = this.tableConfig.columns.filter(
      (col) =>
        attributes.findIndex(
          (a) => a.systemName.toLowerCase() === col.field.toLowerCase()
        ) >= 0
    );

    this.idt.updateDataSource(true);
  }

  onKeyUp() {
    if (!this.searchText) {
      this.tableConfig.query = this.data.query;
      if (this.idt) {
        this.idt.updateDataSource(true);
      }
    }

    const searchDef = this.utils.GetSearchDef(
      this.utils.encodeSearchText(this.searchText),
      this.lettersToTrigger
    );
    if (searchDef) {
      this.tableConfig.query = this.data.query.replace(
        /%SearchText%/gi,
        searchDef.text
      );
      if (this.idt) {
        this.idt.updateDataSource(true);
      }
    }
  }

  onSubmit() {
    if (this.windowRef && this.idt) {
      this.windowRef.close(this.idt.selectedItems);
    }
  }
}
