import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-send-email',
  templateUrl: './activity-send-email.component.html',
  styleUrls: ['./activity-send-email.component.scss'],
})
export class ActivitySendEmailComponent extends WorkflowActivityView {
  template: string;
  importanceRate: string;
  to: string;
  cc: string;
  bcc: string;

  @Input()
  get emailTemplate() {
    return this.template;
  }
  set emailTemplate(value: string) {
    this.template = value;
    this.emailTemplateChange.emit(this.template);
  }
  @Output()
  emailTemplateChange = new EventEmitter();

  @Input()
  get importance() {
    return this.importanceRate;
  }
  set importance(value: string) {
    this.importanceRate = value;
    this.importanceChange.emit(this.importanceRate);
  }
  @Output()
  importanceChange = new EventEmitter();

  @Input()
  get sendTo() {
    return this.to;
  }
  set sendTo(value: string) {
    this.to = value;
    this.sendToChange.emit(this.to);
  }
  @Output()
  sendToChange = new EventEmitter();

  @Input()
  get sendCc() {
    return this.cc;
  }
  set sendCc(value: string) {
    this.cc = value;
    this.sendCcChange.emit(this.cc);
  }
  @Output()
  sendCcChange = new EventEmitter();

  @Input()
  get sendBcc() {
    return this.bcc;
  }
  set sendBcc(value: string) {
    this.bcc = value;
    this.sendBccChange.emit(this.bcc);
  }
  @Output()
  sendBccChange = new EventEmitter();

  constructor() {
    super();
  }
}
