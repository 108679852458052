<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            class="first-dialog-item"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.attribute.systemName"
              />
            </mat-form-field>
            <!-- <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_componentName' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field> -->
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <div
              fxFlex="0 0 50"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <mat-form-field fxFlex="1 1 50">
                <input
                  matInput
                  disabled
                  [placeholder]="'key_type' | translate"
                  [(ngModel)]="data.config.name"
                />
              </mat-form-field>
              <mat-form-field fxFlex="0 0 45">
                <input
                  matInput
                  type="number"
                  min="0"
                  [placeholder]="'key_hintLimit' | translate"
                  [(ngModel)]="data.config.hintLimit"
                />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="0 0 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <mat-form-field fxFlex="1 1 25">
              <mat-select
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.iterableType"
              >
                <mat-option value="array">Array</mat-option>
                <mat-option value="dictionary">Dictionary</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 25">
              <mat-select
                [placeholder]="'key_returnType' | translate"
                [(ngModel)]="data.config.saveAs"
              >
                <mat-option value="text">Text</mat-option>
                <mat-option value="object">Object</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 25">
              <mat-select
                [placeholder]="'key_updateOn' | translate"
                [(ngModel)]="data.config.updateOn"
              >
                <mat-option value="undefined">{{
                  'key_default' | translate
                }}</mat-option>
                <mat-option value="change">{{
                  'key_change' | translate
                }}</mat-option>
                <mat-option value="blur">{{
                  'key_blur' | translate
                }}</mat-option>
                <mat-option value="submit">{{
                  'key_submit' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="1 1 25"
              [(ngModel)]="data.config.editable"
              style="margin-top: -25px"
            >
              {{ 'key_editable' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            style="font-weight: 400; margin-bottom: 20px; margin-top: 20px"
          >
            {{ 'key_propertyDefinition' | translate }}
          </div>
          <div fxLayout="row" style="margin-bottom: 40px">
            <div fxFlex="40" fxLayout="column">
              <div
                fxFlex="auto"
                dragula="PROPERTIESTOSHOW"
                [(dragulaModel)]="data.config.properties"
                style="overflow-y: auto"
              >
                <div
                  *ngFor="let property of data.config.properties"
                  fxLayout="row"
                  [ngClass]="{
                    'attribute-property-selected':
                      selectedProperty &&
                      property.name === selectedProperty.name
                  }"
                  class="attribute-property"
                  style="padding-left: 10px; margin: 5px 0"
                >
                  <div
                    fxFlex="auto"
                    style="line-height: 34px; cursor: pointer"
                    (click)="onSelectProperty(property)"
                  >
                    {{ property.name }}
                  </div>
                  <button
                    fxFlex="none"
                    mat-icon-button
                    color="warn"
                    [disableRipple]="true"
                    [matTooltip]="'key_delete' | translate"
                    (click)="onDeleteProperty(property)"
                  >
                    <mat-icon style="font-size: 20px; margin-top: -8px"
                      >delete_outline</mat-icon
                    >
                  </button>
                  <div
                    fxFlex="none"
                    class="handle"
                    style="width: 36px; cursor: pointer; color: lightgray"
                  >
                    <mat-icon style="line-height: 34px">menu</mat-icon>
                  </div>
                </div>
              </div>
              <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
                <mat-form-field
                  fxFlex="auto"
                  style="margin-bottom: -20px; margin-right: 0"
                >
                  <input
                    matInput
                    [placeholder]="'key_addProperty' | translate"
                    [(ngModel)]="propertyToAdd"
                  />
                </mat-form-field>
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="!propertyToAdd"
                  (click)="onAddProperty()"
                >
                  <mat-icon>playlist_add</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex="60" class="attribute-property-setting">
              <div
                *ngIf="selectedProperty"
                fxLayout="column"
                style="font-size: 12px; padding: 10px"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      [placeholder]="'key_name' | translate"
                      [(ngModel)]="selectedProperty.name"
                    />
                  </mat-form-field>
                  <div fxFlex="50">
                    <mat-checkbox
                      [disabled]="selectedProperty.isKey"
                      [(ngModel)]="selectedProperty.isKey"
                      (change)="onSetKeyProperty()"
                      >{{ 'key_keyProperty' | translate }}</mat-checkbox
                    >
                  </div>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      [placeholder]="'key_displayName' | translate"
                      [(ngModel)]="selectedProperty.displayName"
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="50">
                    <mat-select
                      [placeholder]="'key_type' | translate"
                      [(ngModel)]="selectedProperty.type"
                    >
                      <mat-option value="text">text</mat-option>
                      <mat-option value="textarea">text area</mat-option>
                      <mat-option value="multi">multivalued text</mat-option>
                      <mat-option value="boolean">boolean</mat-option>
                      <mat-option value="select">select</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <mat-form-field fxFlex="100">
                    <input
                      matInput
                      [placeholder]="'key_description' | translate"
                      [(ngModel)]="selectedProperty.description"
                    />
                  </mat-form-field>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      [placeholder]="'key_default' | translate"
                      [(ngModel)]="selectedProperty.default"
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      type="number"
                      min="0"
                      max="100"
                      [placeholder]="'key_width' | translate"
                      [(ngModel)]="selectedProperty.width"
                    />
                  </mat-form-field>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <div fxFlex="50">
                    <mat-checkbox [(ngModel)]="selectedProperty.required">{{
                      'key_required' | translate
                    }}</mat-checkbox>
                  </div>
                  <div fxFlex="50">
                    <mat-checkbox [(ngModel)]="selectedProperty.isHidden">{{
                      'key_hidden' | translate
                    }}</mat-checkbox>
                  </div>
                </div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px grid"
                >
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      [placeholder]="'key_valueValidation' | translate"
                      [(ngModel)]="selectedProperty.validation"
                    />
                  </mat-form-field>
                  <mat-form-field fxFlex="50">
                    <input
                      matInput
                      [placeholder]="'key_validationKey' | translate"
                      [(ngModel)]="selectedProperty.validationKey"
                    />
                  </mat-form-field>
                </div>
                <div
                  *ngIf="selectedProperty.type === 'select'"
                  fxLayout="column"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px grid"
                  >
                    <mat-form-field fxFlex="50">
                      <input
                        matInput
                        [placeholder]="'key_optionText' | translate"
                        [(ngModel)]="optionTextToAdd"
                      />
                    </mat-form-field>
                    <div
                      fxFlex="50"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                    >
                      <mat-form-field fxFlex="auto" style="width: 140px">
                        <input
                          matInput
                          [placeholder]="'key_optionValue' | translate"
                          [(ngModel)]="optionValueToAdd"
                        />
                      </mat-form-field>
                      <button
                        fxFlex="none"
                        mat-icon-button
                        color="primary"
                        [disabled]="!optionTextToAdd || !optionValueToAdd"
                        (click)="onAddOption()"
                      >
                        <mat-icon>playlist_add</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div
                    style="
                      max-height: 150px;
                      overflow-y: auto;
                      overflow-x: hidden;
                    "
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      style="padding: 5px; border: solid 1px gray"
                    >
                      <div fxFlex="50">{{ 'key_text' | translate }}</div>
                      <div fxFlex="50" style="margin-left: 20px">
                        {{ 'key_value' | translate }}
                      </div>
                    </div>
                    <div
                      *ngFor="
                        let option of selectedProperty.options;
                        let i = index
                      "
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      style="
                        padding: 5px;
                        border: solid 1px gray;
                        margin-top: -1px;
                      "
                    >
                      <div fxFlex="50">{{ option.text }}</div>
                      <div
                        fxFlex="50"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        style="margin-left: 20px"
                      >
                        <div fxFlex="auto">
                          {{ option.value }}
                        </div>
                        <mat-icon
                          fxFlex="none"
                          class="warn-color"
                          style="
                            font-size: 16px;
                            cursor: pointer;
                            line-height: 24px;
                          "
                          (click)="onRemoveOption(i)"
                          >close</mat-icon
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
