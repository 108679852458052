import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IterablesEditorConfig } from '../core/models/editorContract.model';
import { UtilsService } from '../core/services/utils.service';
import { ResourceService } from '../core/services/resource.service';
import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransService } from '../core/models/translation.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import {
  FunctionExpressionDef,
  PopupConfig,
} from '../core/models/dataContract.model';
import { ModalService } from '../core/services/modal.service';
import { ModalType } from '../core/models/componentContract.model';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { EditorIterablesComponent } from '../core/components/editor-iterables/editor-iterables.component';
import { EditorIdentityComponent } from '../core/components/editor-identity/editor-identity.component';

@Component({
  selector: 'app-function-expression',
  templateUrl: './function-expression.component.html',
  styleUrls: ['./function-expression.component.scss'],
})
export class FunctionExpressionComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @ViewChild('idpRequestor') idpRequestor: EditorIdentityComponent;
  @ViewChild('idpTarget') idpTarget: EditorIdentityComponent;
  @ViewChild('itrWorkflowData') itrWorkflowData: EditorIterablesComponent;
  @ViewChild('itrDelta') itrDelta: EditorIterablesComponent;
  @ViewChild('itrDeltaBeforeChange')
  itrDeltaBeforeChange: EditorIterablesComponent;

  expression: string;
  expressionResult: any;
  expressionResultString = '';

  isLoading = false;

  targetResource = {
    dataType: 'Reference',
    displayName: 'key_target',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'TargetResource',
    value: null,
    values: [],
  };

  requestorResource = {
    dataType: 'Reference',
    displayName: 'key_requestor',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'RequestorResource',
    value: null,
    values: [],
  };

  workflowDataExpression = {
    dataType: 'Dictionary',
    displayName: 'key_workflowDataExpressions',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrWorkflowData',
    value: null,
  };

  deltaExpression = {
    dataType: 'Dictionary',
    displayName: 'l10n_delta',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrDelta',
    value: null,
  };

  deltaBeforeChangeDataExpression = {
    dataType: 'Dictionary',
    displayName: 'l10n_deltaBeforeChange',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrDeltaBeforeChange',
    value: null,
  };

  configWorkflowDataExpression = new IterablesEditorConfig();
  configDeltaExpression = new IterablesEditorConfig();

  customViewSetting: any;
  expressions: Array<{
    key: string;
    value: {
      expression: string;
      expressionDef: FunctionExpressionDef;
    };
  }> = [];
  savedExpressions: {
    [id: string]: {
      expression: string;
      expressionDef: FunctionExpressionDef;
    };
  } = {};
  selectedExpression: {
    key: string;
    value: {
      expression: string;
      expressionDef: FunctionExpressionDef;
    };
  };

  resultCode: number;
  resultState = '';

  private customFunctionExpressionKey = 'savedExpressions';

  private buildExpressionDef(): FunctionExpressionDef {
    const expressionDef: FunctionExpressionDef = {
      expression: this.expression,
    };

    const requestor = this.utils.ExtraValue(
      this.requestorResource,
      'value:ObjectID'
    );
    if (requestor) {
      expressionDef.requestor = requestor;
    }
    const target = this.utils.ExtraValue(this.targetResource, 'value:ObjectID');
    if (target) {
      expressionDef.target = target;
    }
    const wfData = this.utils.ExtraValue(this.workflowDataExpression, 'value');
    if (wfData) {
      expressionDef.workflowDataExpressions = wfData;
    }
    const delta = this.utils.ExtraValue(this.deltaExpression, 'value');
    if (delta) {
      expressionDef.delta = delta;
    }
    const deltaBeforeChange = this.utils.ExtraValue(
      this.deltaBeforeChangeDataExpression,
      'value'
    );
    if (deltaBeforeChange) {
      expressionDef.deltaBeforeChange = deltaBeforeChange;
    }

    return expressionDef;
  }

  constructor(
    private utils: UtilsService,
    private resource: ResourceService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private translate: TransService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.customViewSetting = this.resource.customViewSetting;
    if (
      this.customViewSetting.hasOwnProperty(this.customFunctionExpressionKey)
    ) {
      this.savedExpressions =
        this.customViewSetting[this.customFunctionExpressionKey];
      Object.entries(this.savedExpressions).forEach(([key, value]) => {
        this.expressions.push({
          key,
          value,
        });
      });
    }

    this.configWorkflowDataExpression.iterableType = 'array';
    this.configWorkflowDataExpression.saveAs = 'object';
    this.configWorkflowDataExpression.properties = [
      {
        name: 'key',
        displayName: 'key_key',
        required: true,
        type: 'text',
        isKey: true,
        width: 30,
        validation: '^[a-zA-Z0-9_]+$',
        options: [],
      },
      {
        name: 'value',
        displayName: 'key_value',
        required: true,
        type: 'text',
        isKey: false,
        width: 70,
        validationKey: 'workflow/addDelay/workflowDataExpressions',
        options: [],
      },
    ];

    this.configDeltaExpression.iterableType = 'object';
    this.configDeltaExpression.saveAs = 'object';
    this.configDeltaExpression.properties = [
      {
        name: 'key',
        displayName: 'key_key',
        required: true,
        type: 'text',
        isKey: true,
        width: 30,
        validation: '^[a-zA-Z0-9_]+$',
        options: [],
      },
      {
        name: 'value',
        displayName: 'key_value',
        required: true,
        type: 'text',
        isKey: false,
        width: 70,
        options: [],
      },
    ];

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onExecute() {
    this.isLoading = true;

    this.expressionResult = undefined;
    this.expressionResultString = '';

    this.resultCode = undefined;
    this.resultState = '';

    const expressionBody = this.buildExpressionDef();

    this.subscription.add(
      this.resource
        .executeExpression(expressionBody)
        .pipe(
          tap((result: HttpResponse<string>) => {
            this.resultCode = result.status;
            this.resultState = 'success';
            if (result && result.body !== null && result.body !== undefined) {
              try {
                this.expressionResult = JSON.parse(result.body);
                this.expressionResultString = JSON.stringify(
                  this.expressionResult
                );
              } catch {
                this.expressionResult = result.body;
                this.expressionResultString = result.body;
              }
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this.resultCode = err.status;
            this.resultState = 'failed';
            this.expressionResultString = this.utils.getServiceError(err);
            return EMPTY;
          }),
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe()
    );
  }

  onSaveExpression() {
    let expressionName = '';
    let savingProgress: MatDialogRef<ModalComponent, any>;

    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.width = 420;
    popupConfig.title = 'l10n_saveExpression';
    popupConfig.style = 'outline';
    popupConfig.data = {
      expressionName: {
        text: 'key_name',
        value: '',
        type: 'text',
        validation: `^[a-zA-Z0-9_.-]*$`,
        required: true,
        focused: true,
      },
    };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              expressionName = windowResult.expressionName.value;
              if (this.savedExpressions.hasOwnProperty(expressionName)) {
                const confirm = this.modal.show(
                  ModalType.confirm,
                  'key_confirmation',
                  'l10n_expressionExists'
                );
                return confirm.afterClosed();
              } else {
                return of('yes');
              }
            }
            return EMPTY;
          }),
          switchMap((confirmResult: string) => {
            if (confirmResult && confirmResult === 'yes') {
              savingProgress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );

              if (
                !this.customViewSetting.hasOwnProperty(
                  this.customFunctionExpressionKey
                )
              ) {
                this.customViewSetting[this.customFunctionExpressionKey] = {};
              }
              this.customViewSetting[this.customFunctionExpressionKey][
                expressionName
              ] = {
                expression: this.expression,
              };
              const def = this.buildExpressionDef();
              if (def && Object.keys(def).length > 0) {
                this.customViewSetting[this.customFunctionExpressionKey][
                  expressionName
                ].expressionDef = def;
              }
              this.savedExpressions =
                this.customViewSetting[this.customFunctionExpressionKey];
              this.expressions = [];
              Object.entries(this.savedExpressions).forEach(([key, value]) => {
                this.expressions.push({
                  key,
                  value,
                });
              });
              this.selectedExpression = this.expressions.find(
                (exp) => exp.key === expressionName
              );

              return this.resource.updateCustomSettings();
            }

            return EMPTY;
          }),
          catchError((err: HttpErrorResponse) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(err)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (savingProgress) {
              savingProgress.close();
            }
          })
        )
        .subscribe()
    );
  }

  onLoadExpression() {
    if (this.selectedExpression && this.selectedExpression.value) {
      this.expression = this.selectedExpression.value.expression;
      if (this.selectedExpression.value.expressionDef) {
        if (
          this.selectedExpression.value.expressionDef.requestor &&
          this.idpRequestor
        ) {
          this.idpRequestor.value =
            this.selectedExpression.value.expressionDef.requestor;
        }
        if (
          this.selectedExpression.value.expressionDef.target &&
          this.idpTarget
        ) {
          this.idpTarget.value =
            this.selectedExpression.value.expressionDef.target;
        }
        if (
          this.selectedExpression.value.expressionDef.workflowDataExpressions &&
          this.itrWorkflowData
        ) {
          this.itrWorkflowData.value =
            this.selectedExpression.value.expressionDef.workflowDataExpressions;
          this.itrWorkflowData.refresh();
        }
        if (
          this.selectedExpression.value.expressionDef.delta &&
          this.itrDelta
        ) {
          this.itrDelta.value =
            this.selectedExpression.value.expressionDef.delta;
          this.itrDelta.refresh();
        }
        if (
          this.selectedExpression.value.expressionDef.deltaBeforeChange &&
          this.itrDeltaBeforeChange
        ) {
          this.itrDeltaBeforeChange.value =
            this.selectedExpression.value.expressionDef.deltaBeforeChange;
          this.itrDeltaBeforeChange.refresh();
        }
      }
    }
  }

  onDeleteExpression() {
    let savingProgress: MatDialogRef<ModalComponent, any>;

    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_deleteExpression'
    );

    confirm
      .afterClosed()
      .pipe(
        switchMap((confirmResult) => {
          if (confirmResult && confirmResult === 'yes') {
            savingProgress = this.modal.show(
              ModalType.progress,
              'key_savingChanges',
              '',
              '300px'
            );

            if (
              this.customViewSetting[
                this.customFunctionExpressionKey
              ].hasOwnProperty(this.selectedExpression.key)
            ) {
              delete this.customViewSetting[this.customFunctionExpressionKey][
                this.selectedExpression.key
              ];
            }
            this.savedExpressions =
              this.customViewSetting[this.customFunctionExpressionKey];
            this.expressions = [];
            Object.entries(this.savedExpressions).forEach(([key, value]) => {
              this.expressions.push({
                key,
                value,
              });
            });
            this.selectedExpression = null;

            return this.resource.updateCustomSettings();
          } else {
            return EMPTY;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.modal.show(
            ModalType.error,
            'key_error',
            this.utils.getServiceError(err)
          );
          return EMPTY;
        }),
        finalize(() => {
          if (savingProgress) {
            savingProgress.close();
          }
        })
      )
      .subscribe();
  }

  onRawData() {
    this.expressionResultString = JSON.stringify(this.expressionResult);
  }

  onJsonData() {
    this.expressionResultString = JSON.stringify(
      this.expressionResult,
      null,
      4
    );
  }

  onCopyData() {
    this.clipboard.copy(this.expressionResultString);
  }
}
