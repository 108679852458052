import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '../core/services/config.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnInit {
  bgImage: string;
  bgFilterOpacity: number;

  constructor(private router: Router, private config: ConfigService) {}

  ngOnInit(): void {
    this.bgImage = this.config.getConfigEx(
      'images:bgLogin',
      'assets/img/img-identity.jpg'
    );
    if (this.bgImage.indexOf('/') >= 0) {
      this.bgImage = `url(${this.bgImage})`;
    }
    this.bgFilterOpacity = this.config.getConfigEx(
      'images:bgLoginFilterOpacity',
      0.6
    );
  }

  onLogin() {
    this.router.navigate(['/login']);
  }
}
