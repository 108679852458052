import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  finalize,
  map,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { TreeListConfig } from 'src/app/core/models/componentContract.model';
import { ResourceSet } from 'src/app/core/models/dataContract.model';
import { ResourceService } from 'src/app/core/services/resource.service';
import { EventGraphComponent } from '../../event-graph/event-graph.component';
import { TreeListComponent } from '../../tree-list/tree-list.component';

@Component({
  selector: 'app-resource-preview',
  templateUrl: './resource-preview.component.html',
  styleUrls: ['./resource-preview.component.scss'],
})
export class ResourcePreviewComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private refreshPreviewToken = new BehaviorSubject(undefined);
  private startPreviewToken = new BehaviorSubject(undefined);

  @ViewChild('eventGraph') eventGraph: EventGraphComponent;
  @ViewChild('eventTree') eventTree: TreeListComponent;

  @Input()
  resourceReadOnly = true;

  @Input()
  action: Observable<HttpResponse<any>>;

  @Input()
  simulationID: string;

  get isLoading() {
    return this.loadingPreview;
  }

  loadingPreview = true;
  previewRootEventID: string;
  previewEventFinished = false;
  previewContent = 'graph';

  treeListConfig: TreeListConfig;

  errorMessage: string;

  constructor(private resource: ResourceService) {}

  adjustRootData = (data: any): Array<any> => {
    if (data && data.length > 0) {
      const treeListData = data;
      treeListData.map((item: any) => {
        if (item.requestorid && item.requestordisplayname) {
          item.requestor = {
            DisplayName: item.requestordisplayname,
            ObjectID: item.requestorid,
            ObjectType: 'person',
          };
        } else {
          item.requestor = null;
        }
      });
      return treeListData;
    }
    return [];
  };

  adjustChildrenData = (data: any): Array<any> => {
    if (data && data.results) {
      const treeListData = data.results;
      treeListData.map((item: any) => {
        if (item.requestorid && item.requestordisplayname) {
          item.requestor = {
            DisplayName: item.requestordisplayname,
            ObjectID: item.requestorid,
            ObjectType: 'person',
          };
        } else {
          item.requestor = null;
        }
      });
      return treeListData;
    }
    return [];
  };

  ngOnInit(): void {
    this.treeListConfig = new TreeListConfig();
    this.treeListConfig.listHeight = 500;
    this.treeListConfig.pageButton = false;
    this.treeListConfig.isExpanded = true;
    this.treeListConfig.defaultLinkAction = 'sideView';
    this.treeListConfig.linkReadOnly = this.resourceReadOnly;
    this.treeListConfig.objectType = 'event';
    this.treeListConfig.idName = 'objectid';
    this.treeListConfig.columns = [
      {
        field: 'DisplayName',
        title: 'Display name',
        width: 300,
        navigationKey: 'event',
        linkNoneForm: true,
      },
      {
        field: 'eventtype',
        title: 'Type',
        width: 80,
      },
      {
        field: 'completedtime',
        title: 'Completed time',
        width: 120,
      },
      {
        field: 'requestor',
        title: 'Requestor',
        width: 80,
      },
      {
        title: 'Status',
        field: 'status',
        width: 80,
        showStatus: {
          success: { color: 'green' },
          failed: { color: 'red' },
        },
        fallbackStatus: {
          color: 'goldenrod',
        },
      },
    ];
    this.treeListConfig.apiChildrenQueryPath = 'param|parentId';
    this.treeListConfig.apiRootResources = {
      method: 'GET',
      path: '',
      param: {
        pageSize: this.treeListConfig.pageSize,
        eventTypes: 'Request',
        orderBy: 'CreationTime',
        sortOrder: 'Descending',
        slimFormat: 'true',
      },
    };
    this.treeListConfig.apiChildrenResources = {
      method: 'GET',
      path: 'event/search',
      param: {
        pageSize: '100',
        slimFormat: 'true',
        parentId: '[#ParentID]',
      },
    };

    this.subscription.add(
      this.startPreviewToken
        .pipe(
          tap(() => {
            this.errorMessage = null;
          }),
          switchMap(() => {
            if (this.simulationID && this.action) {
              return this.action;
            }
            return EMPTY;
          }),
          switchMap((response: HttpResponse<any>) => {
            if (response && response.ok) {
              const retryCount = 6;
              let count = 1;
              return this.refreshPreviewToken.pipe(
                switchMap(() => {
                  return this.resource
                    .searchEvents(1, {
                      eventTypes: 'request',
                      simulationID: this.simulationID,
                    })
                    .pipe(
                      map((eventRS: ResourceSet) => {
                        if (
                          eventRS &&
                          eventRS.results &&
                          eventRS.results.length > 0
                        ) {
                          const event = eventRS.results[0];
                          if (
                            event.status &&
                            (event.status.toLowerCase() === 'success' ||
                              event.status.toLowerCase() === 'failed' ||
                              event.status.toLowerCase() ===
                                'postprocessingfailed')
                          ) {
                            this.previewEventFinished = true;
                            return event;
                          }
                          if (count === retryCount) {
                            return event;
                          }
                        }

                        count++;
                        throw new Error('incompleted');
                      }),
                      retryWhen((error) =>
                        error.pipe(delay(500), take(retryCount))
                      )
                    );
                })
              );
            }
            return EMPTY;
          }),
          tap((requestEvent: any) => {
            if (requestEvent) {
              this.previewRootEventID = requestEvent.id;
              if (this.previewRootEventID) {
                setTimeout(() => {
                  if (this.eventGraph) {
                    this.eventGraph.eventId = this.previewRootEventID;
                    this.eventGraph.refresh();
                  }
                  if (this.eventTree) {
                    this.treeListConfig.apiRootResources.path = `event/${this.previewRootEventID}`;
                    this.treeListConfig.apiChildrenResources.param[
                      'simulationSessionId'
                    ] = this.simulationID;
                    this.eventTree.updateDataSource();
                  }
                });
              }
            }
            this.loadingPreview = false;
          }),
          catchError((error) => {
            if (error.error) {
              this.errorMessage = error.error;
            } else if (error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage = error.statusText;
            }
            return EMPTY;
          }),
          finalize(() => {
            this.loadingPreview = false;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    if (this.simulationID) {
      this.subscription.add(
        this.resource
          .stopSimulation(this.simulationID)
          .pipe(
            finalize(() => {
              this.subscription.unsubscribe();
            })
          )
          .subscribe()
      );
    } else {
      this.subscription.unsubscribe();
    }
  }

  start() {
    this.startPreviewToken.next(undefined);
  }

  refresh() {
    this.refreshPreviewToken.next(undefined);
  }

  stop(): Observable<void> {
    this.loadingPreview = true;
    return this.resource.stopSimulation(this.simulationID).pipe(
      finalize(() => {
        this.loadingPreview = false;
        this.previewRootEventID = undefined;
        this.previewEventFinished = false;
        this.previewContent = 'graph';
        this.action = undefined;
        this.simulationID = undefined;
      })
    );
  }
}
