<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
  <!-- personal settings -->
  <div fxFlex="auto" class="setting-header">
    {{ 'l10n_userSettings' | translate }}
  </div>
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content
        ngxUiLoaderBlurred
        [blur]="blurLevel"
        [loaderId]="loaderUserSettings"
      >
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="85px" class="profile-avatar">
              <img
                *ngIf="attrPhoto"
                [src]="'data:image/png;base64,' + attrPhoto"
                class="profile-avatar-image"
              />
              <div *ngIf="!attrPhoto" class="profile-avatar-initial">
                {{ brandLetter | uppercase }}
              </div>
            </div>
            <div
              fxFlex="165px"
              fxLayout="column"
              fxLayoutGap="10px"
              style="margin-left: 20px"
            >
              <div
                fxFlex="none"
                *ngIf="loginUser | examValue: 'DisplayName'"
                class="profile-name"
              >
                {{ loginUser | extraValue: 'DisplayName' }}
              </div>
              <div
                fxFlex="none"
                *ngIf="loginUser | examValue: 'JobTitle'"
                class="profile-info"
              >
                {{ loginUser | extraValue: 'JobTitle' }}
              </div>
            </div>
            <!-- <div fxFlex="auto" style="margin-left: 50px">
              <kendo-upload
                [saveUrl]="'uploadSaveUrl'"
                [multiple]="false"
                [showFileList]="false"
                (select)="onPhotoSelected($event)"
              >
                <kendo-upload-messages
                  select="{{ 'l10n_changePhoto' | translate }}"
                ></kendo-upload-messages>
              </kendo-upload>
            </div>
            <div fxFlex="auto" style="margin-left: 20px">
              <button
                mat-stroked-button
                color="warn"
                (click)="onPhotoDeleted()"
              >
                {{ 'key_delete' | translate | uppercase }}
              </button>
            </div> -->
          </div>
          <div *ngIf="enableVerifiedId" fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            *ngIf="enableVerifiedId"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start start"
          >
            <div fxFlex="230px">
              <button mat-stroked-button color="basic" (click)="onAcquireVid()">
                {{ 'l10n_acquireVid' | translate }}
              </button>
            </div>
            <mat-spinner
              *ngIf="issuanceLoading"
              style="margin-left: 40px"
            ></mat-spinner>
            <div
              *ngIf="issuanceError"
              style="font-size: 1.2em; margin-top: 5px; margin-left: 40px"
              class="warn-color"
            >
              {{ issuanceError }}
            </div>
            <div
              *ngIf="issuanceState && !issuanceError"
              style="font-size: 1.2em; margin-top: 5px; margin-left: 40px"
            >
              {{ issuanceState }}
            </div>
            <img
              *ngIf="
                issuanceQR &&
                !issuanceError &&
                !issuanceCompleted &&
                !issuanceState
              "
              [src]="issuanceQR"
              style="margin-left: 40px"
            />
          </div>
          <!-- <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            style="margin-left: 40px"
          >
            <div fxFlex="170px" class="profile-info">
              {{ 'key_sharePhoto' | translate }}
            </div>
            <div fxFlex="none" style="margin-left: 40px">
              <mat-slide-toggle
                [(ngModel)]="sharePhotoEnabled"
                (change)="onSharePhotoChanged()"
              >
                <div class="slider-text">
                  {{
                    sharePhotoEnabled
                      ? ('key_enabled' | translate)
                      : ('key_disabled' | translate)
                  }}
                </div>
              </mat-slide-toggle>
            </div>
          </div> -->
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="230px" class="profile-info">
              {{ 'key_language' | translate }}
            </div>
            <div fxFlex="auto" style="margin-left: 40px">
              <div fxLayout="row" fxLayoutGap="30px;">
                <div *ngFor="let lang of allLanguages">
                  <!-- Show all Languages from Config if enabled -->
                  <div
                    *ngIf="lang.enabled"
                    [ngClass]="currentLanguage === lang.route ? 'active' : ''"
                    class="outer-country"
                  >
                    <img
                      [src]="lang.icon"
                      (click)="onLanguageChanged(lang.route)"
                      class="inner-country"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="230px" class="profile-info">
              {{ 'key_cache' | translate }}
            </div>
            <div fxFlex="auto" style="margin-left: 40px">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="none" class="profile-info">
                  {{ 'key_deleteCache' | translate }}
                </div>
                <button
                  fxFlex="auto"
                  mat-stroked-button
                  color="warn"
                  (click)="onDeleteCache()"
                  style="margin-left: 30px"
                >
                  {{ 'key_deleteCacheAndLogout' | translate | uppercase }}
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="isCloud" fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            *ngIf="isCloud"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
          >
            <div fxFlex="230px" class="profile-info">
              {{ 'key_previewByRestore' | translate }}
            </div>
            <div fxFlex="none" style="margin-left: 40px">
              <mat-slide-toggle
                [(ngModel)]="previewEnabled"
                (change)="onPreviewChanged()"
              >
                <div class="slider-text">
                  {{
                    previewEnabled
                      ? ('key_enabled' | translate)
                      : ('key_disabled' | translate)
                  }}
                </div>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <ngx-ui-loader
      [loaderId]="loaderUserSettings"
      [fgsType]="spinnerType.rectangleBounce"
      [fgsSize]="uiLoader.spinnerSize"
      [fgsColor]="uiLoader.spinnerColor"
      [overlayColor]="uiLoader.overlayColor"
      [pbColor]="uiLoader.progressBarColor"
    ></ngx-ui-loader>
  </div>
  <!-- ui groups -->
  <div fxFlex="auto" class="setting-header">
    {{ 'l10n_uiGroups' | translate }}
  </div>
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content
        ngxUiLoaderBlurred
        [blur]="blurLevel"
        [loaderId]="loaderUserSettings"
      >
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="270px" class="profile-info">
              {{ 'l10n_primaryUiGroup' | translate }}
            </div>
            <div fxFlex="auto" class="profile-info">
              {{ primaryViewSet | extraValue: 'DisplayName' }}
            </div>
            <div
              fxFlex="auto"
              [ngStyle]="{ color: colorAdminRightSet }"
              style="margin-left: 20px"
            >
              {{ textAdminRightSet | translate }}
            </div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="270px" class="profile-info">
              {{ 'l10n_availableUiGroup' | translate }}
            </div>
            <div fxFlex="auto" style="width: 200px">
              <mat-form-field>
                <mat-label>{{ 'l10n_selectUiGroup' | translate }}</mat-label>
                <mat-select
                  [(value)]="selectedViewSetID"
                  (selectionChange)="onUiGroupSelectionChanged()"
                  style="font-size: 16px"
                >
                  <mat-option>--</mat-option>
                  <mat-option
                    *ngFor="let group of availableViewSets"
                    [value]="group.ObjectID"
                    [disabled]="group.ObjectID === primaryViewSet.ObjectID"
                  >
                    {{ group.DisplayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              fxFlex="auto"
              *ngIf="selectedViewSetID"
              [ngStyle]="{ color: colorCurrentRightSet }"
              style="margin-left: 20px"
            >
              {{ textCurrentRightSet | translate }}
            </div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            fxLayoutAlign="start center"
          >
            <button
              mat-stroked-button
              [disabled]="!selectedViewSetID"
              (click)="onApplyUiGroup()"
              color="primary"
            >
              {{ 'key_apply' | translate }}
            </button>
            <mat-divider [vertical]="true" style="height: 30px"></mat-divider>
            <button
              mat-stroked-button
              [disabled]="!canManageSelectedRightSet"
              color="primary"
              (click)="onExportSettings()"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="10px"
              >
                <div>{{ 'key_exportToClipboard' | translate }}</div>
                <span class="material-icons-outlined">download</span>
              </div>
            </button>
            <button
              mat-stroked-button
              [disabled]="!hasUIAdminRight"
              color="primary"
              (click)="onImportSettings()"
            >
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="10px"
              >
                <div>{{ 'key_importFromClipboard' | translate }}</div>
                <span class="material-icons-outlined">upload</span>
              </div>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <ngx-ui-loader
      [loaderId]="loaderUiGroups"
      [fgsType]="spinnerType.rectangleBounce"
      [fgsSize]="uiLoader.spinnerSize"
      [fgsColor]="uiLoader.spinnerColor"
      [overlayColor]="uiLoader.overlayColor"
      [pbColor]="uiLoader.progressBarColor"
    ></ngx-ui-loader>
  </div>
  <!-- administrative tasks -->
  <div *ngIf="isAdminUser" fxFlex="auto" class="setting-header">
    {{ 'l10n_administrativeTasks' | translate }}
  </div>
  <div *ngIf="isAdminUser" fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column">
          <div
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="none">
              <button
                mat-stroked-button
                [routerLink]="'/app/allresources'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_allResources' | translate }}
              </button>
            </div>
            <div fxFlex="none">
              <button
                mat-stroked-button
                [routerLink]="'/app/configconverter'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_configurationConverter' | translate }}
              </button>
            </div>
            <div *ngIf="authenticationMode === 'azure'" fxFlex="none">
              <button
                mat-stroked-button
                [routerLink]="'/app/functionexpression'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_functionExpression' | translate }}
              </button>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="none" style="margin: 20px 20px 0 0">
              <button
                mat-stroked-button
                [routerLink]="'/app/viewsetting'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_editingForm' | translate }}
              </button>
            </div>
            <div fxFlex="none" style="margin: 20px 20px 0 0">
              <button
                mat-stroked-button
                [routerLink]="'/app/wizardsetting/creationform'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_creationForm' | translate }}
              </button>
            </div>
            <div fxFlex="none" style="margin: 20px 20px 0 0">
              <button
                mat-stroked-button
                [routerLink]="'/app/wizardsetting/wizard'"
                color="primary"
                style="width: 260px"
              >
                {{ 'l10n_wizard' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- import / export -->
  <!-- <div *ngIf="hasUIAdminRight && !isCloud" fxFlex="auto" class="setting-header">
    {{ 'l10n_importExport' | translate }}
  </div>
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content>
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="20px"
          style="margin-bottom: 20px"
        >
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <kendo-upload
              fxFlex="180px"
              [saveUrl]="'uploadSaveUrl'"
              [disabled]="authenticationMode !== 'azure'"
              [restrictions]="importRestrictions"
              [multiple]="false"
              [showFileList]="false"
              (select)="onImportResources($event)"
            >
              <kendo-upload-messages
                select="{{ 'l10n_import' | translate }}"
              ></kendo-upload-messages>
            </kendo-upload>
            <div fxFlex="auto">{{ 'l10n_importDescription' | translate }}</div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <button
              fxFlex="180px"
              mat-stroked-button
              color="primary"
              [disabled]="!hasExports()"
              (click)="onExportResources()"
            >
              {{ 'l10n_export' | translate }}
            </button>
            <div fxFlex="auto">{{ 'l10n_exportDescription' | translate }}</div>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="180px" class="type-headline"></div>
            <div fxFlex="auto" fxLayout="row wrap">
              <mat-checkbox
                fxFlex="20"
                [(ngModel)]="selectAllTypes"
                (change)="onSelectAllTypes()"
              >
                {{ 'key_selectAll' | translate }}</mat-checkbox
              >
            </div>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="180px" class="type-headline">
              {{ 'l10n_managedResources' | translate }}
            </div>
            <div fxFlex="auto" fxLayout="row wrap">
              <mat-checkbox
                *ngFor="let type of exportResourceTypes"
                fxFlex="20"
                [(ngModel)]="type.selected"
              >
                {{ type.name | translate }}</mat-checkbox
              >
            </div>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="180px" class="type-headline">
              {{ 'l10n_configurationResources' | translate }}
            </div>
            <div fxFlex="auto" fxLayout="row wrap">
              <mat-checkbox
                *ngFor="let type of exportConfigTypes"
                fxFlex="20"
                [(ngModel)]="type.selected"
              >
                {{ type.name | translate }}</mat-checkbox
              >
            </div>
          </div>
          <div
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="180px" class="type-headline">
              {{ 'l10n_schemaResources' | translate }}
            </div>
            <div fxFlex="auto" fxLayout="row wrap">
              <mat-checkbox
                *ngFor="let type of exportSchemaTypes"
                fxFlex="20"
                [(ngModel)]="type.selected"
              >
                {{ type.name | translate }}</mat-checkbox
              >
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div> -->
  <!-- version information -->
  <div fxFlex="auto" class="setting-header">
    {{ 'l10n_versionInfo' | translate }}
  </div>
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
          <div fxFlex="auto" fxLayout="row">
            <div style="width: 200px">
              {{ ('l10n_customVersion' | translate) + ':' }}
            </div>
            <div>{{ uiVersion }}</div>
          </div>
          <div fxFlex="auto" fxLayout="row">
            <div style="width: 200px">
              {{ ('l10n_coreVersion' | translate) + ':' }}
            </div>
            <div>{{ coreVersion }}</div>
          </div>
          <div fxFlex="auto" fxLayout="row">
            <div style="width: 200px">
              {{ ('l10n_engineVersion' | translate) + ':' }}
            </div>
            <div>{{ dsVersion }}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
