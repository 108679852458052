import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { ReportCardConfig } from '../../models/componentContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { ConfigService } from '../../services/config.service';

import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';
import { ReportCardConfigComponent } from './report-card-config.component';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent implements OnInit, DynamicComponent {
  @Input()
  config: ReportCardConfig;

  localConfig: ReportCardConfig;

  displayComponent = false;

  existingReportNames: Array<string> = [];

  constructor(
    private utils: UtilsService,
    private resource: ResourceService,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  initComponent() {
    this.localConfig = new ReportCardConfig();

    this.utils.CopyInto(this.config, this.localConfig, true, true);

    if (!this.localConfig.permissionSets) {
      this.displayComponent = true;
    } else {
      this.displayComponent = this.resource.inPermissionSets(
        this.localConfig.permissionSets
      );
    }

    return this.localConfig;
  }

  resize() {}

  updateDataSource() {}

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: ReportCardConfigComponent,
      width: 800,
      height: 750,
      top: this.configService.getConfig('wizardTopPosition', null),
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = this.localConfig;
    windowIns.existingReportNames = this.existingReportNames;

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.localConfig = configCopy;
        }
      }),
      switchMap(() => {
        return of(this.localConfig);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  onAction(e: Event) {
    if (this.swap.editMode) {
      e.preventDefault();
      return;
    }

    if (this.localConfig.useAsLink && this.localConfig.linkUrl) {
      if (this.localConfig.linkUrl.startsWith('\\')) {
        this.router.navigate([this.localConfig.linkUrl]);
      } else {
        window.open(this.localConfig.linkUrl, '_blank');
      }
      return;
    }

    if (!this.localConfig.isHistorical) {
      this.localConfig.tableConfig.selectable = true;
      this.localConfig.tableConfig.selectMode = 'multiple';
    }

    this.router.navigate([`/app/report/${this.localConfig.name}`], {
      state: this.localConfig,
    });
  }
}
