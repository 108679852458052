// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EMPTY, Subscription } from 'rxjs';
import { ApprovalCheckCloudComponent } from '../core/components/approval-check-cloud/approval-check-cloud.component';
import { ApprovalCheckComponent } from '../core/components/approval-check/approval-check.component';

// Core
import {
  ApprovalViewConfig,
  ModalType,
} from '../core/models/componentContract.model';
import { BroadcastEvent } from '../core/models/dataContract.model';
import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';
import { SwapService } from '../core/services/swap.service';
import { UtilsService } from '../core/services/utils.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

// Component
@Component({
  selector: 'app-approvals',
  styleUrls: ['./approvals.component.scss'],
  templateUrl: './approvals.component.html',
})

// Approvals
export class ApprovalsComponent implements OnDestroy, OnInit {
  isCloud = false;

  // Setup
  approvalViewConfig: any;
  approvalViewDefaults = {
    approvals: {
      mode: 'onprem',
      canApprove: true,
      contentAttribute: 'ocgObjectStatus',
      defaultContent: 'detail',
      limit: 10,
      name: 'approvalsInDetailView',
      queryAllApprovals: `/Request[ComputedActor='[#LoginID]' and ObjectID=/Approval/Request]`,
      queryPendingApprovals: `/Approval[ApprovalStatus='Pending' and Approver='[#LoginID]' and not(ApprovalResponse=/ApprovalResponse[Creator='[#LoginID]'])]/Request`,
      showPendingApprovalsOnly: true,
      sortAttribute: 'CreatedTime',
      sortOrder: 'desc',
      title: 'l10n_myApprovals',
      titleAttribute: 'Description',
    },
    requests: {
      mode: 'onprem',
      canApprove: false,
      contentAttribute: 'ocgObjectStatus',
      defaultContent: 'response',
      limit: 10,
      name: 'requestsInDetailView',
      permissionSets: undefined,
      queryPendingApprovals: `/Approval[ApprovalStatus='Pending' and Approver='[#LoginID]' and not(ApprovalResponse=/ApprovalResponse[Creator='[#LoginID]'])]/Request`,
      queryAllApprovals: `/Request[Creator='[#LoginID]' and ObjectID=/Approval/Request]`,
      showPendingApprovalsOnly: false,
      sortAttribute: 'CreatedTime',
      sortOrder: 'desc',
      title: 'l10n_myRequests',
      titleAttribute: 'Description',
    },
  };
  approvalViewCloudDefaults = {
    approvals: {
      name: 'approval-view-cloud-approver',
      permissionSets: undefined,
      title: 'l10n_myApprovals',
      type: 'approver',
      limit: 10,
      showAll: false,
      canApprove: true,
      defaultContent: 'detail',
      sortAttribute: 'RequestCreationTime',
      sortOrder: 'Descending',
      titleAttribute: '',
      contentAttribute: '',
      reasonRequired: false,
      includeDelegates: true,
    },
    requests: {
      name: 'approval-view-cloud-request',
      permissionSets: undefined,
      title: 'l10n_myRequests',
      type: 'requestor',
      limit: 10,
      showAll: false,
      canApprove: false,
      defaultContent: 'response',
      sortAttribute: 'RequestCreationTime',
      sortOrder: 'Descending',
      titleAttribute: '',
      contentAttribute: '',
      reasonRequired: false,
      includeDelegates: true,
    },
  };
  buttonColor = 'slategray';
  editMode = false;

  // View-Children
  @ViewChild('Approvals') Approvals: ApprovalCheckComponent;
  @ViewChild('Requests') Requests: ApprovalCheckComponent;
  @ViewChild('ApprovalsCloud') ApprovalsCloud: ApprovalCheckCloudComponent;
  @ViewChild('RequestsCloud') RequestsCloud: ApprovalCheckCloudComponent;

  // Privates
  private subscription: Subscription = new Subscription();

  // Constructor
  constructor(
    private modal: ModalService,
    private resource: ResourceService,
    private swap: SwapService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.isCloud =
      this.resource.authenticationMode &&
      this.resource.authenticationMode === 'azure';

    // Set initial values
    this.editMode = this.swap.editMode;

    // Make sure the ApprovalView-Configuration is present and assign it
    if (this.resource.primaryViewSetting.approvalView) {
      // Use configuration
      this.approvalViewConfig = this.utils.parseComponentConfig(
        this.utils.DeepCopy(this.resource.primaryViewString)
      ).approvalView;
    } else {
      // Use defaults
      this.approvalViewConfig = this.isCloud
        ? this.utils.DeepCopy(this.approvalViewCloudDefaults)
        : this.utils.DeepCopy(this.approvalViewDefaults);
    }

    // Attach Edit-Listener
    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'start-edit': // Start edit mode
              this.editMode = this.resource.isAdminViewSet;
              break;
            case 'exit-edit': // Leave editMode without saving and restore config settings
              this.editMode = false;
              this.getConfig();
              break;
            case 'edit-add': // Not applicable to this view
              break;
            case 'edit-save': // Leave editMode and store config settings
              this.editMode = false;
              this.onSave();
              break;
            default:
              break;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    // Get currently loaded primary configuration
    const primaryConfig = this.utils.parseComponentConfig(
      this.utils.DeepCopy(this.resource.primaryViewString)
    ).approvalView;

    // Restore original settings when the page is left without saving
    if (primaryConfig) {
      this.resource.primaryViewSetting.approvalView = primaryConfig;
    }

    // Unsubscribe
    this.subscription.unsubscribe();
  }

  // Widgets
  getConfig() {
    // Get currently loaded primary configuration
    let primaryConfig: ApprovalViewConfig = this.utils.parseComponentConfig(
      this.utils.DeepCopy(this.resource.primaryViewString)
    ).approvalView;

    // Use defaults if primary configuration is not present
    if (!primaryConfig) {
      primaryConfig = this.isCloud
        ? this.utils.DeepCopy(this.approvalViewCloudDefaults)
        : this.utils.DeepCopy(this.approvalViewDefaults);
    }

    // Apply configuration
    this.approvalViewConfig = primaryConfig;

    if (this.isCloud) {
      if (this.ApprovalsCloud) {
        this.ApprovalsCloud.localConfig = this.approvalViewConfig.approvals;
        this.ApprovalsCloud.refresh();
      }
      if (this.RequestsCloud) {
        this.RequestsCloud.localConfig = this.approvalViewConfig.requests;
        this.RequestsCloud.refresh();
      }
    } else {
      if (this.Approvals) {
        this.Approvals.localConfig = this.approvalViewConfig.approvals;
        this.Approvals.refresh();
      }
      if (this.Requests) {
        this.Requests.localConfig = this.approvalViewConfig.requests;
        this.Requests.refresh();
      }
    }
    // this.resource.primaryViewSetting.approvalView = primaryConfig;
  }

  onConfig(event: Event, item: any) {
    event.preventDefault();
    event.stopPropagation();
    this.subscription.add(
      item.configure().subscribe((config: any) => {
        if (config) {
          if (config.defaultContent === 'detail') {
            this.approvalViewConfig.approvals = config;
          } else if (config.defaultContent === 'response') {
            this.approvalViewConfig.requests = config;
          }
          this.resource.primaryViewSetting.approvalView =
            this.approvalViewConfig;
          item.refresh();
        }
      })
    );
  }

  onSave() {
    const process = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );
    this.subscription.add(
      this.resource
        .updateUISettings()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            }
          }),
          catchError((err: HttpErrorResponse) => {
            this.getConfig();
            this.modal.show(ModalType.error, 'key_error', err.error);
            return EMPTY;
          }),
          finalize(() => {
            process.close();
          })
        )
        .subscribe()
    );

    // // Setup
    // this.resource.primaryViewString = this.utils.stringifyComponentConfig(
    //   this.resource.primaryViewSetting
    // );
    // this.resource.primaryViewSet[this.utils.attConfiguration] =
    //   this.resource.primaryViewString;

    // // Show processing dialog
    // const process = this.modal.show(
    //   ModalType.progress,
    //   'key_savingChanges',
    //   '',
    //   '300px'
    // );

    // // Save new primary view settings
    // this.subscription.add(
    //   this.resource
    //     .updateResource(this.resource.primaryViewSet, true)
    //     .subscribe(
    //       () => {
    //         process.close();
    //       },
    //       (err: string) => {
    //         process.close();
    //         this.modal.show(ModalType.error, 'key_error', err, '360px');
    //       }
    //     )
    // );
  }
}
