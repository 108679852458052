import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ResourceService } from '../core/services/resource.service';
import { Subscription, Observable, of } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import {
  ResourceTableConfig,
  ResourceColumnConfig,
  ModalType,
  TreeListConfig,
} from '../core/models/componentContract.model';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { ModalService } from '../core/services/modal.service';
import { MatRadioChange } from '@angular/material/radio';
import { ApiDef, ResourceSet } from '../core/models/dataContract.model';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { UtilsService } from '../core/services/utils.service';
import { ConfigService } from '../core/services/config.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  private eventCount = 100;

  private eventUrl = 'resources/search';

  pageSize = 20;

  includeArchiev = false;

  includeSystemEvents = false;

  allowedRequestTypes = this.config.getConfig('requestTypesToDisplay', [
    'CreateResource',
    'UpdateResource',
    'DeleteResource',
    'BatchResourceCreate',
    'BatchResourceUpdate',
    'BatchResourceDelete',
    'Import',
    'XPathAttribute',
    'TimedWorkflowTrigger',
    'EventUpdate',
    'Cancel',
    'Resume',
    'ReplaceWorkflow',
    'Restore',
    'RevertRequest',
    'RecomputeDataflow',
    'ReportMigration',
    'Backup',
    'Approval',
    'Admin',
    'ExecuteWorkflow',
  ]);

  private allowedResourceEventTypes = this.config.getConfig(
    'resourceTypesToDisplay',
    ['TrustedApp', 'User', 'Workflow', 'Dataflow', 'ReportMigration']
  );

  private queryResourceEvent = `/event[eventtype='resource' and resourceeventtype=values('${this.allowedResourceEventTypes.join(
    `','`
  )}')]`;
  private queryRequestEvent = `/event[eventtype='request' and requesttype=values('${this.allowedRequestTypes.join(
    `','`
  )}')]`;
  private apiResourceTable = {
    method: 'post',
    path: this.eventUrl,
    pathContinue: `${this.eventUrl}/continue`,
    pathRefresh: `${this.eventUrl}/refreshtokens`,
    body: {
      attributes: [
        'displayname',
        'createdtime',
        'starttime',
        'completedtime',
        'requestorid',
        'requestordisplayname',
        'targetid',
        'targetdisplayname',
        'targetobjecttype',
        'status',
        'requesttype',
      ],
      pageSize: this.pageSize,
      orderBy: {
        attribute: 'createdtime',
        order: 'descending',
      },
      includeCount: 'FastOnly',
      includeEventArchive: 'false',
    },
    param: {
      xPathQuery: this.queryResourceEvent,
    },
  };

  private columnResourceEvent: Array<ResourceColumnConfig> = [
    {
      title: 'Display name',
      field: 'displayname',
      width: 300,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Created time',
      field: 'createdtime',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'date',
    },
    {
      title: 'Completed time',
      field: 'completedtime',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'date',
    },
    {
      title: 'Requestor',
      field: 'requestor',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Target',
      field: 'target',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Status',
      field: 'status',
      width: 100,
      sortable: true,
      showStatus: {
        success: { color: 'green' },
        failed: { color: 'red' },
        denied: { color: 'red' },
        postprocessingfailed: { color: 'red' },
        manuallyclosed: { color: 'red' },
      },
      fallbackStatus: {
        color: 'goldenrod',
      },
      filterable: true,
      filter: 'text',
    },
  ];
  private columnRequestEvent: Array<ResourceColumnConfig> = [
    {
      title: 'Display name',
      field: 'displayname',
      width: 300,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Request Type',
      field: 'requesttype',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Created time',
      field: 'createdtime',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'date',
    },
    {
      title: 'Completed time',
      field: 'completedtime',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'date',
    },
    {
      title: 'Requestor',
      field: 'requestor',
      width: 100,
      sortable: true,
      filterable: true,
      filter: 'text',
    },
    {
      title: 'Status',
      field: 'status',
      width: 100,
      sortable: true,
      showStatus: {
        success: { color: 'green' },
        failed: { color: 'red' },
        denied: { color: 'red' },
        postprocessingfailed: { color: 'red' },
        manuallyclosed: { color: 'red' },
      },
      fallbackStatus: {
        color: 'goldenrod',
      },
      filterable: true,
      filter: 'text',
    },
  ];

  requestTypes = [
    'CreateResource',
    'UpdateResource',
    'DeleteResource',
    'BatchResourceCreate',
    'BatchResourceUpdate',
    'BatchResourceDelete',
    'Import',
    'EventUpdate',
    'Cancel',
    'Resume',
    'ReplaceWorkflow',
    'Restore',
    'RevertRequest',
    'RecomputeDataflow',
    'XPathAttribute',
    'TimedWorkflowTrigger',
  ];

  @ViewChild('resourceTable') resourceTable: ResourceTableComponent;

  tableConfig: ResourceTableConfig;

  treeListConfig: TreeListConfig;

  viewMode = 'table';

  eventMode = 'resource';

  rootEvents: Observable<Array<any>>;

  constructor(
    private resource: ResourceService,
    private modal: ModalService,
    private utils: UtilsService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.cellPadding = 10;
    this.tableConfig.pageSize = this.pageSize;
    this.tableConfig.selectBoxWidth = 8;
    this.tableConfig.resizable = true;
    this.tableConfig.exportToClipBoard = true;
    this.tableConfig.exportToExcel = true;
    this.tableConfig.exportToPDF = true;
    this.tableConfig.linkNoneForm = true;
    this.tableConfig.api = this.utils.DeepCopy(this.apiResourceTable);
    const columns: Array<ResourceColumnConfig> = [
      {
        title: 'Display name',
        field: 'displayname',
        width: 300,
        sortable: true,
        filterable: true,
        filter: 'text',
      },
      {
        title: 'Created time',
        field: 'createdtime',
        width: 100,
        sortable: true,
        filterable: true,
        filter: 'date',
      },
      {
        title: 'Completed time',
        field: 'completedtime',
        width: 100,
        sortable: true,
        filterable: true,
        filter: 'date',
      },
      {
        title: 'Requestor',
        field: 'requestor',
        width: 100,
        sortable: true,
        filterable: true,
        filter: 'text',
      },
      {
        title: 'Target',
        field: 'target',
        width: 100,
        sortable: true,
        filterable: true,
        filter: 'text',
      },
      {
        title: 'Status',
        field: 'status',
        width: 100,
        sortable: true,
        showStatus: {
          success: { color: 'green' },
          failed: { color: 'red' },
          denied: { color: 'red' },
          postprocessingfailed: { color: 'red' },
        },
        fallbackStatus: {
          color: 'goldenrod',
        },
        filterable: true,
        filter: 'text',
      },
    ];
    this.tableConfig.columns = columns;
    this.tableConfig.exportAttributes = [
      'displayname',
      'requesttype',
      'createdtime',
      'starttime',
      'completedtime',
      'status',
      'requestor',
      'target',
    ];

    this.treeListConfig = new TreeListConfig();
    this.treeListConfig.objectType = 'event';
    this.treeListConfig.pageSize = this.pageSize;
    this.treeListConfig.idName = 'objectid';
    this.treeListConfig.columns = [
      {
        field: 'displayname',
        title: 'Display name',
        width: 300,
        navigationKey: 'event',
        linkNoneForm: true,
      },
      {
        field: 'eventtype',
        title: 'Type',
        width: 80,
      },
      {
        field: 'createdtime',
        title: 'Created time',
        width: 80,
      },
      {
        field: 'completedtime',
        title: 'Completed time',
        width: 80,
      },
      {
        field: 'requestor',
        title: 'Requestor',
        width: 80,
      },
      {
        title: 'Status',
        field: 'status',
        width: 80,
        showStatus: {
          success: { color: 'green' },
          failed: { color: 'red' },
        },
        fallbackStatus: {
          color: 'goldenrod',
        },
      },
    ];
    this.treeListConfig.apiChildrenQueryPath = 'param|xPathQuery';
    this.treeListConfig.apiRootResources = {
      method: 'post',
      path: this.eventUrl,
      pathContinue: `${this.eventUrl}/continue`,
      pathRefresh: `${this.eventUrl}/refreshtokens`,
      body: {
        attributes: [
          'displayname',
          'eventtype',
          'createdtime',
          'starttime',
          'completedtime',
          'requestorid',
          'requestordisplayname',
          'status',
          'childcount',
        ],
        pageSize: this.pageSize,
        orderBy: {
          attribute: 'createdtime',
          order: 'descending',
        },
        includeCount: 'FastOnly',
      },
      param: {
        xPathQuery: this.queryRequestEvent,
      },
    };
    this.treeListConfig.apiChildrenResources = {
      method: 'post',
      path: this.eventUrl,
      pathContinue: `${this.eventUrl}/continue`,
      pathRefresh: `${this.eventUrl}/refreshtokens`,
      body: {
        attributes: [
          'displayname',
          'eventtype',
          'createdtime',
          'starttime',
          'completedtime',
          'requestorid',
          'requestordisplayname',
          'status',
          'childcount',
        ],
        pageSize: this.pageSize,
        orderBy: {
          attribute: 'createdtime',
          order: 'descending',
        },
        includeCount: 'FastOnly',
      },
      param: {
        xPathQuery: `/event[parenteventid='[#ParentID]']`,
      },
    };

    let process: MatDialogRef<ModalComponent, any>;
    this.rootEvents = of([]).pipe(
      switchMap(() => {
        process = this.modal.show(
          ModalType.progress,
          'key_processing',
          '',
          '300px'
        );

        return this.resource.getRecentEvents(this.eventCount, 'Request');
      }),
      switchMap((result: ResourceSet) => {
        if (result && result.results) {
          return of(result.results);
        } else {
          return of([]);
        }
      }),
      finalize(() => {
        if (process) {
          process.close();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  adjustEventData = (data: GridDataResult): GridDataResult => {
    if (data && data.data) {
      data.data.forEach((element) => {
        element.objecttype = 'event';
        if (element.requestorid && element.requestordisplayname) {
          element.requestor = {
            ObjectID: element.requestorid,
            DisplayName: element.requestordisplayname,
            ObjectType: 'person',
          };
        }
        if (
          element.targetid &&
          element.targetdisplayname &&
          element.targetobjecttype
        ) {
          element.target = {
            ObjectID: element.targetid,
            DisplayName: element.targetdisplayname,
            ObjectType: element.targetobjecttype,
          };
        }
      });
    }
    return data;
  };

  adjustEventPageSize = (pageSize: number, apiDef: ApiDef): ApiDef => {
    apiDef.body.pageSize = pageSize;

    return apiDef;
  };

  adjustEventStateChange = (
    state: DataStateChangeEvent,
    apiDef: ApiDef
  ): ApiDef => {
    if (state && state.sort && state.sort.length > 0 && state.sort[0].dir) {
      const sortAttribute = state.sort[0].field;
      if (sortAttribute === 'requestor') {
        apiDef.body.orderBy.attribute = 'requestordisplayname';
      } else if (sortAttribute === 'target') {
        apiDef.body.orderBy.attribute = 'targetdisplayname';
      } else {
        apiDef.body.orderBy.attribute = sortAttribute;
      }

      apiDef.body.orderBy.order =
        state.sort[0].dir === 'asc' ? 'Ascending' : 'Descending';
    } else {
      apiDef.body.orderBy = {
        attribute: 'createdtime',
        order: 'Descending',
      };
    }

    if (state && state.filter) {
      let filterCondition = this.utils.FilterToXPath(state.filter);
      if (filterCondition) {
        filterCondition = filterCondition
          .replace('requestor', 'requestordisplayname')
          .replace('target', 'targetdisplayname');
        let queryWithFilter = '';
        if (this.eventMode === 'resource') {
          queryWithFilter = this.includeSystemEvents
            ? `/event[eventtype='resource']`
            : this.queryResourceEvent;
        }
        if (this.eventMode === 'request') {
          queryWithFilter = this.includeSystemEvents
            ? `/event[eventtype='request']`
            : this.queryRequestEvent;
        }
        if (queryWithFilter) {
          const posStart = queryWithFilter.indexOf('[');
          if (posStart < 0) {
            queryWithFilter = `${queryWithFilter}[${filterCondition}]`;
          } else {
            const posEnd = queryWithFilter.lastIndexOf(']');
            if (posEnd > 0) {
              queryWithFilter = `${queryWithFilter.substring(
                0,
                posStart + 1
              )}(${queryWithFilter.substring(
                posStart + 1,
                posEnd
              )}) and ${filterCondition}]`;
            }
          }
          apiDef.param.xPathQuery = queryWithFilter;
        }
      } else {
        apiDef.param.xPathQuery =
          this.eventMode === 'resource'
            ? this.queryResourceEvent
            : this.queryRequestEvent;
      }
    }

    if (state.take) {
      const pz =
        state.take > this.tableConfig.exportMaxCount
          ? this.tableConfig.exportMaxCount
          : state.take;
      apiDef.body.pageSize = pz;
    } else {
      apiDef.body.pageSize = this.pageSize;
    }

    return apiDef;
  };

  hasChildren = (item: any): boolean => {
    return item.childcount > 0;
  };

  fetchChildren = (item: any): Observable<Array<any>> => {
    return this.resource.getChildEvents(item.id);
  };

  onViewModeChange(event: MatRadioChange) {
    if (event.value === 'table') {
      this.eventMode = 'resource';
      this.tableConfig.api = this.utils.DeepCopy(this.apiResourceTable);
      this.tableConfig.columns = this.columnResourceEvent;
    }
  }

  onEventModeChange(event: MatRadioChange) {
    if (event.value === 'resource') {
      this.tableConfig.api.param.xPathQuery = this.queryResourceEvent;
      this.tableConfig.columns = this.columnResourceEvent;
    }
    if (event.value === 'request') {
      this.tableConfig.api.param.xPathQuery = this.queryRequestEvent;
      this.tableConfig.columns = this.columnRequestEvent;
    }

    if (this.resourceTable) {
      this.resourceTable.updateDataSource(true);
    }
  }

  onIncludeArchievChange() {
    this.tableConfig.api.body.includeEventArchive = String(this.includeArchiev);
    if (this.resourceTable) {
      this.resourceTable.updateDataSource(true, false, true);
    }
  }

  onIncludeSystemEvents() {
    if (this.includeSystemEvents) {
      if (this.eventMode === 'resource') {
        this.tableConfig.api.param.xPathQuery = `/event[eventtype='resource']`;
        this.tableConfig.columns = this.columnResourceEvent;
      }
      if (this.eventMode === 'request') {
        this.tableConfig.api.param.xPathQuery = `/event[eventtype='request']`;
        this.tableConfig.columns = this.columnRequestEvent;
      }
    } else {
      if (this.eventMode === 'resource') {
        this.tableConfig.api.param.xPathQuery = this.queryResourceEvent;
        this.tableConfig.columns = this.columnResourceEvent;
      }
      if (this.eventMode === 'request') {
        this.tableConfig.api.param.xPathQuery = this.queryRequestEvent;
        this.tableConfig.columns = this.columnRequestEvent;
      }
    }
    if (this.resourceTable) {
      this.resourceTable.updateDataSource(true, false, true);
    }
  }

  onRefresh() {
    if (this.resourceTable) {
      this.resourceTable.updateDataSource(true);
    }
  }

  adjustTreeListData = (data: any): Array<any> => {
    if (data && data.results) {
      const treeListData = data.results;
      treeListData.map((item: any) => {
        if (item.requestorid && item.requestordisplayname) {
          item.requestor = {
            DisplayName: item.requestordisplayname,
            ObjectID: item.requestorid,
            ObjectType: 'person',
          };
        } else {
          item.requestor = null;
        }
        if (item.id) {
          item.ObjectID = item.id;
        }
        item.ObjectType = 'event';
      });
      return treeListData;
    }
    return [];
  };
}
