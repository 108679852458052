import { Pipe, PipeTransform } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { TransService } from '../models/translation.model';
import { AttributeResource } from '../models/dataContract.model';

import { ResourceService } from '../services/resource.service';
import { ConfigService } from '../services/config.service';

@Pipe({
  name: 'translateAttribute',
})
export class TranslateAttributePipe implements PipeTransform {
  constructor(
    private translate: TransService,
    private resource: ResourceService,
    private config: ConfigService
  ) {}

  transform(value: any, typeName: string, trigger: number): any {
    if (value && typeName) {
      const retVal = this.resource.getAttributeDisplayName(
        typeName,
        value,
        false
      );
      if (retVal) {
        return of(retVal);
      } else {
        return this.resource
          .getAttribute(
            typeName,
            value,
            this.config.getCulture(this.translate.currentLang)
          )
          .pipe(
            switchMap((attribute: AttributeResource) => {
              if (attribute && attribute.displayName) {
                return of(attribute.displayName);
              } else {
                return of(value);
              }
            }),
            catchError(() => {
              return of(value);
            })
          );
      }
    }

    return of(value);
  }
}
