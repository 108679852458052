<kendo-treelist
  kendoTreeListExpandable
  [data]="rootResources | async"
  [fetchChildren]="fetchChildren"
  [hasChildren]="hasChildren"
  [resizable]="true"
  [height]="localConfig.listHeight"
  [initiallyExpanded]="localConfig.isExpanded"
  [loading]="treeListLoading"
  [pageSize]="localConfig.pageSize"
  [pageable]="
    localConfig.pageButton && localConfig.scrollMode !== 'virtual'
      ? {
          buttonCount: isCloud ? 1 : localConfig.pageCountNumber,
          info: localConfig.pageInfo,
          type: localConfig.pageType,
          previousNext: true
        }
      : false
  "
  (dataStateChange)="dataStateChange($event)"
>
  <ng-template kendoTreeListNoRecordsTemplate>
    {{ 'key_noData' | translate }}
  </ng-template>

  <kendo-treelist-column
    *ngFor="let col of allColumns; let i = index"
    [expandable]="i === 0"
    [title]="col.title"
    [field]="col.field"
    [width]="col.width === 0 ? null : col.width < 0 ? 0 : col.width"
    [hidden]="col.display === false"
    [style]="{
      'padding-top': localConfig.cellPadding.toString() + 'px',
      'padding-bottom': localConfig.cellPadding.toString() + 'px'
    }"
  >
    <ng-template kendoTreeListHeaderTemplate let-column>
      <div
        *ngIf="localConfig.objectType && !column.title; else explicitColumn"
        [style.fontSize.px]="localConfig.fontSize"
        [style.fontWeight]="config.fontWeight + 100"
      >
        {{
          column.field
            | translateAttribute: localConfig.objectType:translationTrigger
            | async
        }}
      </div>
      <ng-template #explicitColumn>
        <div
          [style.fontSize.px]="localConfig.fontSize"
          [style.fontWeight]="config.fontWeight + 100"
        >
          {{ column.title | translate }}
        </div>
      </ng-template>
    </ng-template>

    <ng-template kendoTreeListCellTemplate let-dataItem>
      <div *ngIf="i === 0" [style.fontWeight]="config.fontWeight">
        <app-identity-link
          [identity]="{
            ObjectType: dataItem | extraValue: 'ObjectType',
            ObjectID: dataItem | extraValue: 'ObjectID',
            DisplayName: dataItem | extraValue: col.field + ':DisplayName'
          }"
          [defaultAction]="localConfig.defaultLinkAction"
          [linkActions]="localConfig.linkActions"
          [readOnly]="localConfig.linkReadOnly"
          [navigationKey]="col.navigationKey"
          [noneForm]="col.linkNoneForm"
        ></app-identity-link>
      </div>
      <div *ngIf="i > 0" [style.fontWeight]="config.fontWeight">
        <div
          *ngIf="
            dataItem[col.field] | examValue: 'DisplayName';
            else normalField
          "
        >
          <app-identity-link
            [identity]="dataItem | extraValue: col.field"
            [defaultAction]="localConfig.defaultLinkAction"
            [linkActions]="localConfig.linkActions"
            [readOnly]="localConfig.linkReadOnly"
            [navigationKey]="col.navigationKey"
            [noneForm]="col.linkNoneForm"
          >
          </app-identity-link>
        </div>
        <ng-template #normalField>
          <div *ngIf="!col.showStatus; else statusColumn">
            {{
              dataItem
                | extraValue
                  : {
                      value: col.field + ':value',
                      format: localConfig.datetimeFormat
                    }
            }}
          </div>
          <ng-template #statusColumn>
            <div fxLayout="row" fxLayourAlign="start center">
              <div
                fxFlex="none"
                style="
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  margin-top: 10px;
                  margin-right: 10px;
                "
                [style.backgroundColor]="getStatusSetting(dataItem, col).color"
              ></div>
              <div
                *ngIf="getStatusSetting(dataItem, col).text; else statusValue"
              >
                {{ getStatusSetting(dataItem, col).text | translate }}
              </div>
              <ng-template #statusValue>
                <div fxFlex="auto">
                  {{ dataItem | extraValue: col.field + ':value' }}
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </kendo-treelist-column>

  <ng-template
    kendoTreeListPagerTemplate
    let-totalPages="totalPages"
    let-currentPage="currentPage"
  >
    <div fxLayout="row">
      <button
        mat-icon-button
        color="primary"
        [disabled]="pageIndex <= 1"
        (click)="onPagePrevious()"
      >
        <mat-icon>arrow_left</mat-icon>
      </button>
      <div class="primary-contrast-color primary-bg-color page-number">
        {{ pageIndex }}
      </div>
      <button mat-icon-button color="primary" (click)="onPageNext()">
        <mat-icon>arrow_right</mat-icon>
      </button>
    </div>
  </ng-template>
</kendo-treelist>
