<div
  [style.width.px]="
    photoAttribute && photoAttribute.value ? photoWidth : photoHeight
  "
  class="photo-layer"
>
  <!-- Photo/Initials -->
  <div [style.height.px]="photoHeight" class="photo">
    <!-- Photo -->
    <img
      *ngIf="photoAttribute && photoAttribute.value; else noPhoto"
      [src]="'data:image/png;base64,' + photoAttribute.value"
      [style.height.px]="photoHeight"
      [style.width.px]="photoWidth"
    />

    <!-- Initials -->
    <ng-template #noPhoto>
      <div
        [style.height.px]="photoHeight"
        [style.width.px]="photoHeight"
        class="photo-initial accent-bg-color primary-contrast-color"
      >
        {{ initials }}
      </div>
    </ng-template>
  </div>

  <!-- Overlay -->
  <div
    *ngIf="photoAttribute"
    [style.display]="canEdit ? '' : 'none'"
    [style.height.px]="photoHeight"
    class="photo-overlay"
  ></div>

  <!-- Buttons -->
  <div
    *ngIf="photoAttribute"
    [style.display]="canEdit ? '' : 'none'"
    class="button-layer"
  >
    <!-- Change button -->
    <button
      [disabled]="!canEdit"
      [title]="'l10n_changePhoto' | translate"
      (click)="onPhotoChange()"
      class="button"
      color="primary"
      mat-icon-button
    >
      <mat-icon>insert_photo</mat-icon>
    </button>

    <!-- Delete button -->
    <button
      [disabled]="!canEdit || !photoAttribute || !photoAttribute.value"
      [title]="'key_delete' | translate"
      (click)="onPhotoDelete()"
      class="button"
      color="warn"
      mat-icon-button
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
