import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SwapService } from './swap.service';

import { mergeWith as _mergeWith } from 'lodash-es';
import { assignInWith as _assignInWith } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configLocation = 'assets/config';
  private customConfigLocation = 'app/customisation/assets/config';

  private config: any = null;

  private loaded = false;
  get isLoaded() {
    return this.loaded;
  }

  private getArrayOverwriteMode(element: any) {
    if (typeof element === 'string') {
      if (/^\s*(overwritemode|op)\s*:\s*replace\s*$/i.test(element)) {
        return 'replace';
      } else if (/^\s*(overwritemode|op)\s*:\s*append\s*$/i.test(element)) {
        return 'append';
      } else if (
        /^\s*(overwritemode|op)\s*:\s*(delete|insert|replace)\s*:\s*\d+$/i.test(
          element
        )
      ) {
        const arr = element.split(':');
        return `${arr[1].trim()}:${arr[2].trim()}`;
      }
    }

    return null;
  }

  private executeMode(
    mode: string,
    arrResult: Array<any>,
    arrMode: Array<any>
  ) {
    if (mode === 'append') {
      return arrResult.concat(arrMode);
    } else if (mode === 'replace') {
      return arrMode;
    } else if (mode.startsWith('insert:')) {
      const pos = parseInt(mode.substring(7));
      arrResult.splice(pos, 0, ...arrMode);
      return arrResult;
    } else if (mode.startsWith('replace:')) {
      const pos = parseInt(mode.substring(8));
      arrResult.splice(pos, 1, ...arrMode);
      return arrResult;
    }
  }

  defaultUiLoader = {
    spinnerSize: 40,
    spinnerColor: 'white',
    overlayColor: 'rgba(50,50,50,.5)',
    progressBarColor: 'white',
  };

  constructor(private http: HttpClient, private swap: SwapService) {}

  public load() {
    if (this.loaded) {
      return of(null);
    }

    const cachedConfig = localStorage.getItem('LS_CONFIGURATION');
    if (cachedConfig) {
      localStorage.removeItem('LS_CONFIGURATION');
      if (cachedConfig === 'LS_CONFIGURATIONERROR') {
        this.swap.setError('key_configError');
        return of(null);
      } else {
        this.config = JSON.parse(cachedConfig);
        this.loaded = true;
        return of(null);
      }
    } else {
      const configFilePath = `${this.configLocation}/config.${environment.env}.json`;
      const customConfigFilePath = `${this.customConfigLocation}/customConfig.${
        environment.env
      }.json?t=${new Date().getTime()}`;
      return this.http.get(configFilePath).pipe(
        tap((config) => {
          this.config = config;
          this.loaded = true;
        }),
        switchMap(() => {
          return this.http.get(customConfigFilePath);
        }),
        tap((customConfig: any) => {
          if (customConfig) {
            if (customConfig.overwriteMode === 'merge') {
              this.config = _mergeWith(
                {},
                this.config,
                customConfig,
                (obj, src) => {
                  if (Array.isArray(src) && src.length > 0) {
                    if (obj) {
                      let copy: Array<any> = JSON.parse(JSON.stringify(obj));
                      const srcCopy: Array<any> = JSON.parse(
                        JSON.stringify(src)
                      );
                      let previousPos = -1;
                      let previousMode = '';

                      srcCopy.forEach((item: any, index: number) => {
                        const mode = this.getArrayOverwriteMode(item);
                        if (mode) {
                          if (previousPos > 0 && previousMode) {
                            copy = this.executeMode(
                              previousMode,
                              copy,
                              srcCopy.slice(previousPos, index)
                            );
                          }
                          if (mode.startsWith('delete')) {
                            previousPos = -1;
                            previousMode = '';
                            copy.splice(parseInt(mode.substring(7)), 1);
                          } else {
                            previousPos = index + 1;
                            previousMode = mode;
                          }
                        }
                      });
                      if (previousPos >= 0 && previousMode) {
                        copy = this.executeMode(
                          previousMode,
                          copy,
                          srcCopy.slice(previousPos)
                        );
                      }

                      if (this.getArrayOverwriteMode(src[0])) {
                        return copy;
                      }
                    }

                    // switch (this.getArrayOverwriteMode(src[0])) {
                    //   case 'replace':
                    //     src.shift();
                    //     return src;
                    //   case 'append':
                    //     src.shift();
                    //     return obj.concat(src);
                    //   case 'merge':
                    //     src.shift();
                    //     break;
                    //   default:
                    //     break;
                    // }
                  }
                }
              );
            } else {
              // this.config = { ...this.config, ...customConfig };
              this.config = _assignInWith(
                this.config,
                customConfig,
                (obj, src) => {
                  if (Array.isArray(src) && src.length > 0) {
                    const mode = this.getArrayOverwriteMode(src[0]);
                    if (mode) {
                      src.shift();
                      return src;
                    }
                  }
                }
              );
            }
          }
        }),
        catchError(() => {
          this.swap.setError('key_configError');
          return of(null);
        })
      );
    }
  }

  public getConfig(key: string, fallback?: any): any {
    if (this.loaded && this.config.hasOwnProperty(key)) {
      return this.config[key];
    } else {
      return fallback ? fallback : undefined;
    }
  }

  public getConfigEx(path: string, fallback?: any): any {
    if (this.loaded && path) {
      const pathArray = path.split(':');
      let value: any;
      for (const p of pathArray) {
        if (value) {
          if (value.hasOwnProperty(p)) {
            value = value[p];
          } else {
            return fallback ? fallback : undefined;
          }
        } else {
          if (this.config.hasOwnProperty(p)) {
            value = this.config[p];
          } else {
            return fallback ? fallback : undefined;
          }
        }
      }
      return value;
    } else {
      return fallback ? fallback : undefined;
    }
  }

  public getCulture(route: string, settingName = 'supportedLanguages') {
    const supportedLanguages: Array<any> = this.getConfig(settingName, []);

    const language = supportedLanguages.find((l) => l.route === route);
    if (language && language.culture) {
      return language.culture;
    }

    return 'en-US';
  }

  public patchConfig(patch: any) {
    if (patch) {
      if (
        patch.overwriteMode &&
        patch.overwriteMode.toLowerCase() === 'merge'
      ) {
        this.config = _mergeWith({}, this.config, patch, (obj, src) => {
          if (Array.isArray(src) && src.length > 0) {
            switch (this.getArrayOverwriteMode(src[0])) {
              case 'replace':
                src.shift();
                return src;
              case 'append':
                src.shift();
                return obj.concat(src);
              case 'merge':
                src.shift();
                break;
              default:
                break;
            }
          }
        });
      } else {
        // Object.keys(patch).forEach((key) => {
        //   if (this.config[key] !== null || this.config[key] !== undefined) {
        //     this.config[key] = patch[key];
        //   }
        // });
        this.config = _assignInWith(this.config, patch, (obj, src) => {
          if (Array.isArray(src) && src.length > 0) {
            const mode = this.getArrayOverwriteMode(src[0]);
            if (mode) {
              src.shift();
              return src;
            }
          }
        });
      }
    }
  }
}
