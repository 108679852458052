import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-workflow-data',
  templateUrl: './activity-workflow-data.component.html',
  styleUrls: ['./activity-workflow-data.component.scss'],
})
export class ActivityWorkflowDataComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
