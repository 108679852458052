<div *ngIf="simpleMode && loadAsReadonly; else fullMode">
  <div *ngIf="simpleEditing; else simpleReading">
    <mat-form-field *ngIf="!config.isMultivalue" style="width: 100%">
      <input
        matInput
        [matTooltip]="tooltip"
        [placeholder]="displayName | translate"
        [(ngModel)]="value"
        [disabled]="config.readOnly"
      />
    </mat-form-field>
    <mat-form-field
      *ngIf="config.isMultivalue"
      style="width: 100%; margin-top: -2px"
    >
      <mat-chip-list #clText [disabled]="config.readOnly">
        <mat-chip
          *ngFor="let valueItem of value"
          [selectable]="true"
          (removed)="onRemoveValue(valueItem)"
          style="font-size: 0.75em; min-height: 24px; background-color: #f0f0f0"
        >
          {{ valueItem }}
          <mat-icon matChipRemove *ngIf="!config.readOnly">cancel</mat-icon>
        </mat-chip>
        <input
          [placeholder]="displayName | translate"
          [matTooltip]="tooltip"
          [matChipInputFor]="clText"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="onAddValue($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <ng-template #simpleReading>
    <div [matTooltip]="tooltip" style="font-weight: 400">
      <div class="accent-text-color" style="font-size: 0.75em">
        {{ displayName | translate }}
      </div>
      <div
        *ngIf="
          value === undefined || value === null || value === '';
          else showValue
        "
        style="cursor: pointer"
        (click)="onStartEdit()"
      >
        {{ 'key_noValuePlaceHolder' | translate }}
      </div>
      <ng-template #showValue>
        <div style="cursor: pointer; overflow-x: auto" (click)="onStartEdit()">
          {{ value }}
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
<ng-template #fullMode>
  <div *ngIf="config.isSimpleValue; else attributeMode">
    <mat-form-field
      *ngIf="!config.isCode"
      fxFlex="auto"
      style="width: 100%; margin-bottom: -6px"
    >
      <mat-label>{{ config.customDisplayName | translate }}</mat-label>
      <input
        matInput
        #editor="ngModel"
        autocomplete="off"
        type="text"
        [placeholder]="config.placeholder | translate"
        [(ngModel)]="value"
        (focus)="onFocuse()"
        (ngModelChange)="onModelChange()"
        [disabled]="config.readOnly || loadingValidationInfo"
        [ngModelOptions]="{ updateOn: modelUpdateOn }"
      />
      <div
        *ngIf="
          validationSetting &&
          validationSetting.tags &&
          validationSetting.tags.length > 0
        "
        matSuffix
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px"
        style="margin-left: 10px"
      >
        <div
          *ngFor="let tag of validationSetting.tags; let i = index"
          [matTooltip]="tag.description | translate"
          [ngClass]="{
            'accent-bg-color': !config.readOnly && tag.isActive,
            'accent-contrast-color': !config.readOnly && tag.isActive,
            'disabled-bg-color': config.readOnly || !tag.isActive,
            'disabled-text-color': config.readOnly
          }"
          class="validation-tag"
          [matMenuTriggerFor]="tagMenu"
          [matMenuTriggerData]="{
            description: tag.descLong,
            url: tag.helpUrl
          }"
          (appDelayedHover)="onDelayedHoverTag(i)"
        >
          <div (click)="onValidationTagClick(tag.name, i, $event)">
            {{ tag.abbreviation }}
          </div>
        </div>
      </div>
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="config.customDescription"
            style="margin-right: 10px"
            [matTooltip]="config.customDescription | translate"
          >
            {{
              config.customDescription | translate | ellipsis: config.hintLimit
            }}
          </div>
        </div>
      </mat-hint>
      <mat-hint
        align="end"
        *ngIf="config.maxLength && (!control || (control && control.valid))"
        >{{ editor.value ? editor.value.length : 0 }} /
        {{ config.maxLength }}</mat-hint
      >
      <mat-hint
        *ngIf="control && control.invalid"
        class="warn-color"
        [matTooltip]="control.errors['message'] | translate"
        >{{
          control.errors['message'] | translate | ellipsis: config.hintLimit
        }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field
      *ngIf="config.isCode"
      fxFlex="auto"
      style="width: 100%; margin-bottom: -6px"
    >
      <mat-label>{{ config.customDisplayName | translate }}</mat-label>
      <textarea
        *ngIf="config.isCode"
        matInput
        #editor="ngModel"
        autocomplete="off"
        type="text"
        [matTextareaAutosize]
        [matAutosizeMinRows]="1"
        [matAutosizeMaxRows]="3"
        [placeholder]="config.placeholder | translate"
        [(ngModel)]="value"
        (focus)="onFocuse()"
        (ngModelChange)="onModelChange()"
        [disabled]="config.readOnly || loadingValidationInfo"
        [ngModelOptions]="{ updateOn: modelUpdateOn }"
      ></textarea>
      <div
        *ngIf="
          validationSetting &&
          validationSetting.tags &&
          validationSetting.tags.length > 0
        "
        matSuffix
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px"
        style="margin-left: 10px"
      >
        <div
          *ngFor="let tag of validationSetting.tags; let i = index"
          [matTooltip]="tag.description | translate"
          [ngClass]="{
            'accent-bg-color': !config.readOnly && tag.isActive,
            'accent-contrast-color': !config.readOnly && tag.isActive,
            'disabled-bg-color': config.readOnly || !tag.isActive,
            'disabled-text-color': config.readOnly
          }"
          class="validation-tag"
          [matMenuTriggerFor]="tagMenu"
          [matMenuTriggerData]="{
            description: tag.descLong,
            url: tag.helpUrl
          }"
          (appDelayedHover)="onDelayedHoverTag(i)"
        >
          <div (click)="onValidationTagClick(tag.name, i, $event)">
            {{ tag.abbreviation }}
          </div>
        </div>
      </div>
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="config.customDescription"
            style="margin-right: 10px"
            [matTooltip]="config.customDescription | translate"
          >
            {{
              config.customDescription | translate | ellipsis: config.hintLimit
            }}
          </div>
        </div>
      </mat-hint>
      <mat-hint
        *ngIf="control && control.invalid"
        class="warn-color"
        [matTooltip]="control.errors['message'] | translate"
        >{{
          control.errors['message'] | translate | ellipsis: config.hintLimit
        }}</mat-hint
      >
    </mat-form-field>
  </div>
  <ng-template #attributeMode>
    <!-- ngIf will not be used any more, use ngClass for display control  -->
    <!-- *ngIf="!config.isMultivalue && (configMode || showEditor(resource.rightSets))" -->
    <mat-form-field
      fxFlex="auto"
      *ngIf="!config.isMultivalue && config.rows === 1 && !config.isCode"
      [ngClass]="{
        'config-hidden': configMode && !showEditor(resource.rightSets),
        'edit-mode':
          !creationMode &&
          config.attributeName &&
          !config.attributeName.endsWith('#')
      }"
      style="width: 100%; margin-bottom: -6px"
    >
      <span *ngIf="config.prefix" matPrefix>{{ config.prefix }} &nbsp;</span>
      <mat-label>{{ displayName | translate }}</mat-label>
      <input
        matInput
        #editor="ngModel"
        autocomplete="off"
        [type]="
          config.isPassword && hidden
            ? 'password'
            : config.isNumber
            ? 'number'
            : 'text'
        "
        [placeholder]="config.placeholder | translate"
        [matTooltip]="tooltip"
        [(ngModel)]="value"
        (focus)="onFocuse()"
        (ngModelChange)="onModelChange()"
        [disabled]="disabled(resource.rightSets) || loadingValidationInfo"
        [attr.maxlength]="config.maxLength"
        [matAutocomplete]="config.autoComplete ? auto : noauto"
        [ngModelOptions]="{ updateOn: modelUpdateOn }"
      />
      <mat-icon
        *ngIf="config.isPassword"
        matSuffix
        (click)="hidden = !hidden"
        style="
          font-size: 1.2em;
          color: gray;
          margin-left: -22px;
          cursor: pointer;
        "
        >{{ hidden ? 'visibility_off' : 'visibility' }}</mat-icon
      >
      <div
        *ngIf="
          !config.isPassword &&
          validationSetting &&
          validationSetting.tags &&
          validationSetting.tags.length > 0
        "
        matSuffix
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px"
        style="margin-left: 10px"
      >
        <div
          *ngFor="let tag of validationSetting.tags; let i = index"
          [matTooltip]="tag.description | translate"
          [ngClass]="{
            'accent-bg-color': !disabled(resource.rightSets) && tag.isActive,
            'accent-contrast-color':
              !disabled(resource.rightSets) && tag.isActive,
            'disabled-bg-color': disabled(resource.rightSets) || !tag.isActive,
            'disabled-text-color': disabled(resource.rightSets)
          }"
          class="validation-tag"
          [matMenuTriggerFor]="tagMenu"
          [matMenuTriggerData]="{
            description: tag.descLong,
            url: tag.helpUrl
          }"
          (appDelayedHover)="onDelayedHoverTag(i)"
        >
          <div (click)="onValidationTagClick(tag.name, i, $event)">
            {{ tag.abbreviation }}
          </div>
        </div>
      </div>
      <mat-autocomplete #noauto></mat-autocomplete>
      <mat-autocomplete #auto="matAutocomplete">
        <div
          *ngIf="
            autoCompleteOptions | async as autoOptions;
            else autoCompleteLoading
          "
        >
          <mat-option *ngFor="let option of autoOptions" [value]="option.value">
            {{ option.text | translate }}
          </mat-option>
        </div>
        <ng-template #autoCompleteLoading>
          <mat-option style="height: 80px">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-spinner [diameter]="40"></mat-spinner>
            </div>
          </mat-option>
        </ng-template>
      </mat-autocomplete>
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="description"
            style="margin-right: 10px"
            [matTooltip]="description | translate"
          >
            {{ description | translate | ellipsis: config.hintLimit }}
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
            style="font-size: 14px; color: #d8bb2e"
          >
            <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
              >error</mat-icon
            >
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoReadAccessMessage && !readAccess"
            style="font-size: 14px; color: red"
          >
            <mat-icon [matTooltip]="'key_noReadAccess' | translate"
              >error</mat-icon
            >
          </div>
        </div>
      </mat-hint>
      <mat-hint
        align="end"
        *ngIf="config.maxLength && (!control || (control && control.valid))"
        >{{ editor.value ? editor.value.length : 0 }} /
        {{ config.maxLength }}</mat-hint
      >
      <mat-hint
        *ngIf="control && control.invalid"
        class="warn-color"
        [matTooltip]="control.errors['message'] | translate"
        >{{
          control.errors['message'] | translate | ellipsis: config.hintLimit
        }}</mat-hint
      >
    </mat-form-field>
    <mat-form-field
      fxFlex="auto"
      *ngIf="!config.isMultivalue && config.rows === 1 && config.isCode"
      [ngClass]="{
        'config-hidden': configMode && !showEditor(resource.rightSets),
        'edit-mode':
          !creationMode &&
          config.attributeName &&
          !config.attributeName.endsWith('#')
      }"
      style="width: 100%; margin-bottom: -6px"
    >
      <span *ngIf="config.prefix" matPrefix>{{ config.prefix }} &nbsp;</span>
      <mat-label>{{ displayName | translate }}</mat-label>
      <textarea
        *ngIf="config.isCode"
        matInput
        #editor="ngModel"
        [matTextareaAutosize]
        [matAutosizeMinRows]="1"
        [matAutosizeMaxRows]="3"
        [placeholder]="config.placeholder | translate"
        [matTooltip]="tooltip"
        [(ngModel)]="value"
        (focus)="onFocuse()"
        (ngModelChange)="onModelChange()"
        [disabled]="disabled(resource.rightSets) || loadingValidationInfo"
        [attr.maxlength]="config.maxLength"
        [matAutocomplete]="config.autoComplete ? auto : noauto"
        [ngModelOptions]="{ updateOn: modelUpdateOn }"
      ></textarea>
      <mat-icon
        *ngIf="config.isPassword"
        matSuffix
        (click)="hidden = !hidden"
        style="
          font-size: 1.2em;
          color: gray;
          margin-left: -22px;
          cursor: pointer;
        "
        >{{ hidden ? 'visibility_off' : 'visibility' }}</mat-icon
      >
      <div
        *ngIf="
          !config.isPassword &&
          validationSetting &&
          validationSetting.tags &&
          validationSetting.tags.length > 0
        "
        matSuffix
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="2px"
        style="margin-left: 10px"
      >
        <div
          *ngFor="let tag of validationSetting.tags; let i = index"
          [matTooltip]="tag.description | translate"
          [ngClass]="{
            'accent-bg-color': !disabled(resource.rightSets) && tag.isActive,
            'accent-contrast-color':
              !disabled(resource.rightSets) && tag.isActive,
            'disabled-bg-color': disabled(resource.rightSets) || !tag.isActive,
            'disabled-text-color': disabled(resource.rightSets)
          }"
          class="validation-tag"
          [matMenuTriggerFor]="tagMenu"
          [matMenuTriggerData]="{
            description: tag.descLong,
            url: tag.helpUrl
          }"
          (appDelayedHover)="onDelayedHoverTag(i)"
        >
          <div (click)="onValidationTagClick(tag.name, i, $event)">
            {{ tag.abbreviation }}
          </div>
        </div>
      </div>
      <mat-autocomplete #noauto></mat-autocomplete>
      <mat-autocomplete #auto="matAutocomplete">
        <div
          *ngIf="
            autoCompleteOptions | async as autoOptions;
            else autoCompleteLoading
          "
        >
          <mat-option *ngFor="let option of autoOptions" [value]="option.value">
            {{ option.text | translate }}
          </mat-option>
        </div>
        <ng-template #autoCompleteLoading>
          <mat-option style="height: 80px">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-spinner [diameter]="40"></mat-spinner>
            </div>
          </mat-option>
        </ng-template>
      </mat-autocomplete>
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="description"
            style="margin-right: 10px"
            [matTooltip]="description | translate"
          >
            {{ description | translate | ellipsis: config.hintLimit }}
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
            style="font-size: 14px; color: #d8bb2e"
          >
            <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
              >error</mat-icon
            >
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoReadAccessMessage && !readAccess"
            style="font-size: 14px; color: red"
          >
            <mat-icon [matTooltip]="'key_noReadAccess' | translate"
              >error</mat-icon
            >
          </div>
        </div>
      </mat-hint>
      <mat-hint
        *ngIf="control && control.invalid"
        class="warn-color"
        [matTooltip]="control.errors['message'] | translate"
        >{{
          control.errors['message'] | translate | ellipsis: config.hintLimit
        }}</mat-hint
      >
    </mat-form-field>
    <div fxFlex="auto" *ngIf="!config.isMultivalue && config.rows > 1">
      <mat-form-field
        [appearance]="'fill'"
        [ngClass]="{
          'config-hidden': configMode && !showEditor(resource.rightSets),
          'edit-mode':
            !creationMode &&
            config.attributeName &&
            !config.attributeName.endsWith('#')
        }"
        style="width: 100%; margin-bottom: -6px"
      >
        <mat-label>{{ displayName | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="config.placeholder | translate"
          [matTooltip]="tooltip"
          [(ngModel)]="value"
          (focus)="onFocuse()"
          (ngModelChange)="onModelChange()"
          [disabled]="disabled(resource.rightSets)"
          [attr.maxlength]="config.maxLength"
          [rows]="config.rows"
          [ngModelOptions]="{ updateOn: modelUpdateOn }"
        ></textarea>
        <mat-hint align="start" *ngIf="!control || (control && control.valid)">
          <div fxLayout="row">
            <div
              fxFlex="auto"
              *ngIf="description"
              style="margin-right: 10px"
              [matTooltip]="description | translate"
            >
              {{ description | translate | ellipsis: config.hintLimit }}
            </div>
            <div
              fxFlex="auto"
              *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
              style="font-size: 14px; color: #d8bb2e"
            >
              <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
                >error</mat-icon
              >
            </div>
            <div
              fxFlex="auto"
              *ngIf="!hideNoReadAccessMessage && !readAccess"
              style="font-size: 14px; color: red"
            >
              <mat-icon [matTooltip]="'key_noReadAccess' | translate"
                >error</mat-icon
              >
            </div>
          </div>
        </mat-hint>
        <mat-hint
          *ngIf="control && control.invalid"
          class="error-message"
          [matTooltip]="control.errors['message'] | translate"
          >{{
            control.errors['message'] | translate | ellipsis: config.hintLimit
          }}</mat-hint
        >
      </mat-form-field>
      <div *ngIf="config.isHtml">
        <div class="html-tag">HTML</div>
        <div
          class="html-body"
          [style.height.px]="config.rows * 18"
          [innerHTML]="value | safeHtml"
        ></div>
      </div>
    </div>
    <mat-form-field
      fxFlex="auto"
      *ngIf="config.isMultivalue"
      [ngClass]="{
        'config-hidden': configMode && !showEditor(resource.rightSets),
        'edit-mode':
          !creationMode &&
          config.attributeName &&
          !config.attributeName.endsWith('#')
      }"
      style="width: 100%; margin-top: -2px"
    >
      <mat-chip-list #clText [disabled]="disabled(resource.rightSets)">
        <mat-chip
          *ngFor="let valueItem of value"
          [selectable]="true"
          [removable]="!disabled(resource.rightSets)"
          (removed)="onRemoveValue(valueItem)"
          style="font-size: 0.75em; min-height: 24px; background-color: #f0f0f0"
        >
          {{ valueItem }}
          <mat-icon *ngIf="!disabled(resource.rightSets)" matChipRemove
            >cancel</mat-icon
          >
        </mat-chip>
        <input
          [placeholder]="displayName | translate"
          [matTooltip]="tooltip"
          [matChipInputFor]="clText"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="onAddValue($event)"
        />
      </mat-chip-list>
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="description"
            style="margin-right: 10px"
            [matTooltip]="description | translate"
          >
            {{ description | translate | ellipsis: config.hintLimit }}
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
            style="font-size: 14px; color: #d8bb2e"
          >
            <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
              >error</mat-icon
            >
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoReadAccessMessage && !readAccess"
            style="font-size: 14px; color: red"
          >
            <mat-icon [matTooltip]="'key_noReadAccess' | translate"
              >error</mat-icon
            >
          </div>
        </div>
      </mat-hint>
      <mat-hint
        *ngIf="control && control.invalid"
        class="error-message"
        [matTooltip]="control.errors['message']"
        >{{
          control.errors['message'] | translate | ellipsis: config.hintLimit
        }}</mat-hint
      >
    </mat-form-field>
  </ng-template>
  <mat-menu #tagMenu="matMenu">
    <ng-template matMenuContent let-description="description" let-url="url">
      <span (mouseleave)="onMouseOutTag()">
        <div
          style="margin: 10px; font-size: 0.85em"
          [innerHtml]="description | translate | safeHtml"
        ></div>
        <div style="width: 100%; height: 30px">
          <div style="float: right">
            <span
              style="font-size: 1.5em; margin-right: 10px; cursor: pointer"
              class="material-icons-outlined accent-text-color"
              [matTooltip]="'key_help' | translate"
              (click)="onHelpClickTag(url)"
              >help_outline</span
            >
          </div>
        </div>
      </span>
    </ng-template>
  </mat-menu>
</ng-template>
