<kendo-treeview
  #treeView
  *ngIf="!isEmpty"
  [nodes]="treeData | async"
  [textField]="['DisplayName']"
  kendoTreeViewExpandable
  [kendoTreeViewSelectable]="true"
  [hasChildren]="hasChildren"
  [children]="fetchChildren"
  [(expandedKeys)]="expandedKeys"
  [(selectedKeys)]="selectedKeys"
  (selectionChange)="onSelectionChange($event)"
  (expand)="onExpand()"
  (collapse)="onCollapse($event)"
  [style.display]="isFiltering ? 'none' : 'block'"
  style="height: 100%"
>
  <ng-template kendoTreeViewNodeTemplate let-dataItem>
    <!-- <fa-icon icon="sitemap" style="margin-right: 10px"></fa-icon> -->
    <mat-icon
      fontSet="material-icons-outlined"
      style="font-size: 18px; padding-top: 3px"
      [style.color]="dataItem.Color"
      [matTooltip]="dataItem.Tip | translate"
      >{{ dataItem.Icon }}</mat-icon
    >
    <div style="font-size: 16px; font-weight: 400">
      {{ dataItem | extraValue: 'DisplayName' }}
    </div>
  </ng-template>
</kendo-treeview>
<div
  *ngIf="isEmpty"
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 160px; margin-top: 40px"
>
  <mat-icon fxFlex="auto" style="font-size: 100px; width: 100px; color: #cccccc"
    >contact_support</mat-icon
  >
  <div fxFlex="auto" style="margin-top: 50px; font-size: 18px; color: #b0b0b0">
    {{ 'key_noData' | translate }}
  </div>
</div>
<div
  fxLayout="column"
  fxLayoutAlign="center center"
  *ngIf="isFiltering"
  style="height: 100%; width: 100%"
>
  <mat-spinner
    [diameter]="40"
    [style.margin-top.px]="spinnerMargin"
  ></mat-spinner>
</div>
