import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { EditorEvent, ModelUpdateMode } from '../../models/dataContract.model';
import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { ObjectEditorConfig } from '../../models/editorContract.model';
import { ConfigService } from '../../services/config.service';
import { SwapService } from '../../services/swap.service';
import { createObjectEditorValidator } from '../../validators/validators';
import { EditorObjectConfigComponent } from './editor-object-config.component';

@Component({
  selector: 'app-editor-object',
  templateUrl: './editor-object.component.html',
  styleUrls: ['./editor-object.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorObjectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorObjectComponent),
      multi: true,
    },
  ],
})
export class EditorObjectComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit
{
  private conf = new ObjectEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  get value() {
    if (!this.editorAttribute || !this.editorAttribute.value) {
      return null;
    }
    if (this.editorAttribute.multivalued) {
      return JSON.stringify(
        this.editorAttribute.values,
        null,
        this.config.formatJson ? 4 : 0
      );
    } else {
      if (typeof this.editorAttribute.value === 'string') {
        return this.editorAttribute.value;
      } else if (typeof this.editorAttribute.value === 'object') {
        return JSON.stringify(
          this.editorAttribute.value,
          null,
          this.config.formatJson ? 4 : 0
        );
      } else {
        return String(this.editorAttribute);
      }
    }
  }
  set value(value) {
    try {
      const parsedValue = JSON.parse(value);
      if (this.editorAttribute.multivalued) {
        if (Array.isArray(parsedValue)) {
          this.editorAttribute.values = parsedValue;
          this.editorAttribute.value = parsedValue[0];
        } else {
          this.editorAttribute.values = parsedValue ? [parsedValue] : [];
          this.editorAttribute.value = parsedValue;
        }
      } else {
        this.editorAttribute.value = parsedValue;
      }
      this.setError(false);
      this.propagateChange(this.editorAttribute);
    } catch {
      this.setError(true, 'key_cannotParseJson');
      this.validateValue();
    }
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  modelUpdateOn: ModelUpdateMode = this.configService.getConfig(
    'modelUpdateOn',
    'change'
  );

  constructor(
    public injector: Injector,
    private configService: ConfigService,
    private swap: SwapService,
    private window: WindowService
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }
    });

    setTimeout(() => {
      this.validationFn = createObjectEditorValidator(this.config);
      this.applyConfig();

      if (this.creationMode && !this.configMode) {
        if (this.config.initExpression) {
          this.value = this.resolveExpression(this.config.initExpression);
          // trigger init value building for wizard view
          // this doesn't apply for editing view because initExpression doesn't exist
          this.swap.editorEvent(
            new EditorEvent(
              'change',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              this.value
            )
          );
        }
      }
    });
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new ObjectEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    if (this.config.updateOn) {
      if (
        String(this.config.updateOn) !== 'null' &&
        String(this.config.updateOn) !== 'undefined'
      ) {
        this.modelUpdateOn = this.config.updateOn;
      }
    }

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorObjectConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createObjectEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    this.propagateTouched();
  }

  onChange() {
    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(this.value);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        this.value
      )
    );
  }

  // #endregion
}
