import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  Subscription,
  Subject,
  BehaviorSubject,
  of,
  forkJoin,
  Observable,
} from 'rxjs';

import { MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, tap } from 'rxjs/operators';
import { Node, Edge } from '@swimlane/ngx-graph';
import * as moment from 'moment';

import {
  ModalType,
  ResourceTableConfig,
} from '../../models/componentContract.model';
import { TransService } from '../../models/translation.model';

import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { ModalService } from '../../services/modal.service';

import { ModalComponent } from '../modal/modal.component';
import {
  ApiDef,
  ApprovalCloudRequest,
  AttributeChange,
  PopupConfig,
} from '../../models/dataContract.model';
import { ResourceTableComponent } from '../resource-table/resource-table.component';

@Component({
  selector: 'app-event-graph',
  templateUrl: './event-graph.component.html',
  styleUrls: ['./event-graph.component.scss'],
})
export class EventGraphComponent implements OnInit, OnDestroy {
  private refreshToken = new BehaviorSubject(undefined);
  private switchToFull = new BehaviorSubject(undefined);
  private subscription = new Subscription();
  private dtFormat: string;

  private ignoredAttributes = ['lastupdatetime', 'internalmodificationcounter'];

  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  update: Subject<boolean> = new Subject();
  center: Subject<boolean> = new Subject();
  zoomToFit: Subject<boolean> = new Subject();

  nodes: Array<Node> = [];
  links: Array<Edge> = [];

  selectedNode: Node;

  graphType = 'simple';

  tableConfig: ResourceTableConfig;

  workflowIconLink = 'assets/img/engineering.svg';

  obsApprovalProcesses: Observable<ApprovalCloudRequest[]>;

  isCompacted = false;

  @Input()
  eventId: string;

  @Input()
  nodeSize = 50;

  @Input()
  graphHeight = 300;

  @Input()
  enableContextView = true;

  @Input()
  defaultLinkAction = 'navigate';

  @Input()
  readOnlyLink = false;

  private prepareAttributeChanges(data: any) {
    let attributeChanges: Array<AttributeChange> = [];

    if (data) {
      const resourceType = data.resourcechangedtype;
      // resource creation
      if (
        data.fullresource &&
        (resourceType === 'Create' || resourceType === 'Delete')
      ) {
        const isCreation = resourceType === 'Create';
        Object.entries(data.fullresource).forEach(([key, value]) => {
          //if (!key.startsWith('$')) {
          if (this.ignoredAttributes.indexOf(key) < 0) {
            attributeChanges.push({
              attribute: key,
              name: this.resource.getAttributeDisplayName(
                data.targetobjecttype,
                key,
                true
              ),
              action: isCreation
                ? this.translate.instant('key_create')
                : this.translate.instant('key_delete'),
              newValue: isCreation ? value : null,
              oldValue: isCreation ? null : value,
            });
          }
          //}
        });
        // resource modification
      } else {
        // single-value
        if (data.attributeassignments) {
          Object.entries(data.attributeassignments).forEach(([key, value]) => {
            //if (!key.startsWith('$')) {
            if (this.ignoredAttributes.indexOf(key) < 0) {
              const attributeChange: AttributeChange = {
                attribute: key,
                name: this.resource.getAttributeDisplayName(
                  data.targetobjecttype,
                  key,
                  true
                ),
                action: this.translate.instant('key_modify'),
                newValue: String(value),
              };
              if (data.previousattributeassignments) {
                let oldValue = data.previousattributeassignments[key];
                if (oldValue === null || oldValue === undefined) {
                  oldValue = 'null';
                }
                attributeChange.oldValue = String(oldValue);
              }
              attributeChanges.push(attributeChange);
            }
            //}
          });
        }
        // multi-value
        if (data.multivalueinsertions) {
          Object.entries(data.multivalueinsertions).forEach(([key, value]) => {
            //if (!key.startsWith('$')) {
            if (this.ignoredAttributes.indexOf(key) < 0) {
              attributeChanges.push({
                attribute: key,
                name: this.resource.getAttributeDisplayName(
                  data.targetobjecttype,
                  key,
                  true
                ),
                action: this.translate.instant('key_add'),
                newValue: value,
              });
            }
            //}
          });
        }
        if (data.multivalueremovals) {
          Object.entries(data.multivalueremovals).forEach(([key, value]) => {
            //if (!key.startsWith('$')) {
            if (this.ignoredAttributes.indexOf(key) < 0) {
              attributeChanges.push({
                attribute: key,
                name: this.resource.getAttributeDisplayName(
                  data.targetobjecttype,
                  key,
                  true
                ),
                action: this.translate.instant('key_remove'),
                newValue: value,
              });
            }
            //}
          });
        }
      }
    }

    attributeChanges = attributeChanges.map((a) => {
      return { ...a, ObjectID: a.attribute };
    });
    attributeChanges = attributeChanges.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    data.attributeChanges = attributeChanges;
  }

  private prepareGraph(events: Array<any>) {
    events.forEach((event) => {
      let iconLink = 'assets/img/notfound.svg';
      let status = 'warn';

      switch (event.eventtype) {
        case 'Request':
          iconLink = 'assets/img/process.svg';
          break;
        case 'Resource':
          iconLink = 'assets/img/editing.svg';
          break;
        case 'WorkflowActivity':
          iconLink = 'assets/img/engineering.svg';
          break;
        case 'SetTransition':
          iconLink = 'assets/img/exchange.svg';
          break;
        case 'Authorization':
          iconLink = 'assets/img/check.svg';
          break;
        default:
          break;
      }
      switch (event.status) {
        case 'Success':
          status = 'success';
          break;
        case 'Failed':
        case 'Denied':
        case 'Canceled':
        case 'PostProcessingFailed':
        case 'ManuallyClosed':
          status = 'error';
          break;
        default:
          break;
      }
      const currentNode: Node = {
        id: event.id,
        label: event.displayname,
        dimension: {
          width: this.nodeSize,
          height: this.nodeSize,
        },
        meta: {
          bgColor: 'snow',
          iconLink,
          status,
          selected: event.id === this.eventId,
          origin: event.id === this.eventId,
        },
        data: event,
      };
      if (currentNode.data.eventtype === 'Resource') {
        this.prepareAttributeChanges(currentNode.data);
      }
      if (event.id === this.eventId) {
        this.selectedNode = currentNode;
        this.showAttributeChanges();
      }
      this.nodes.push(currentNode);

      if (event.parenteventid) {
        this.links.push({
          source: event.parenteventid,
          target: event.id,
          label: event.displayname,
        });
      }

      // if (event.childids) {
      //   const childrenIds = event.childids as Array<string>;
      //   childrenIds.forEach((childId) => {
      //     const childElement = events.find((element) => {
      //       return element.id === childId;
      //     });
      //     if (childElement) {
      //       this.links.push({
      //         source: event.id,
      //         target: childId,
      //         label: childElement.displayname,
      //       });
      //     }
      //   });
      // }
    });

    if (!this.selectedNode && this.nodes.length > 0) {
      this.selectedNode = this.nodes[0];
      this.nodes[0].meta.selected = true;
      this.showAttributeChanges();
    }
  }

  private showAttributeChanges() {
    if (
      this.selectedNode &&
      this.selectedNode.data &&
      this.selectedNode.data.attributeChanges
    ) {
      this.tableConfig.resources = this.selectedNode.data.attributeChanges;
      if (this.resourceTable) {
        setTimeout(() => {
          this.resourceTable.updateDataSource(true);
        });
      }
    }
  }

  private markPlaceholders(events: Array<any>) {
    return;
    if (this.isCompacted) {
      events.forEach((eve) => {
        if (eve.parenteventid) {
          const pos = events.findIndex(
            (e) => e.eventtype === 'Resource' && e.id === eve.parenteventid
          );
          if (pos >= 0) {
            events[pos].showPlaceholder = true;
          }
        }
      });
    }
  }

  constructor(
    private resource: ResourceService,
    private utils: UtilsService,
    private modal: ModalService,
    private translate: TransService
  ) {}

  ngOnInit(): void {
    let process: MatDialogRef<ModalComponent, any>;
    let parentEvents: Array<any>;

    this.dtFormat = `${this.translate.instant(
      'key_dateFormat'
    )} ${this.translate.instant('key_timeFormat')}`;

    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.cellPadding = 5;
    this.tableConfig.selectable = false;
    this.tableConfig.resizable = true;
    this.tableConfig.pageSize = 30;
    this.tableConfig.tableHeight = 300;
    this.tableConfig.linkActions = ['sideView', 'navigate'];
    this.tableConfig.columns = [
      {
        field: 'name',
        tooltipField: 'attribute',
        title: 'key_attribute',
        width: 0,
      },
      {
        field: 'action',
        title: 'key_action',
        width: 120,
      },
      {
        field: 'oldValue',
        title: 'key_oldValue',
        width: 0,
      },
      {
        field: 'newValue',
        title: 'key_newValue',
        width: 0,
      },
    ];

    this.subscription.add(
      this.refreshToken
        .pipe(
          tap(() => {
            process = this.modal.show(
              ModalType.progress,
              'key_processing',
              '',
              '300px'
            );

            this.nodes = [];
            this.links = [];
            this.selectedNode = undefined;
            this.graphType = 'simple';
          }),
          switchMap(() => {
            if (this.eventId) {
              return this.resource.getPathEvents(this.eventId);
            } else {
              return of(null);
            }
          }),
          switchMap((pathEvents: Array<any>) => {
            if (pathEvents && pathEvents.length > 0) {
              if (pathEvents[0].iscompacted) {
                this.isCompacted = true;
              } else {
                this.isCompacted = false;
              }
              parentEvents = pathEvents;
              parentEvents.splice(parentEvents.length - 1, 1);
              return this.resource.getEvent(this.eventId, true, true);
            } else {
              return of(null);
            }
          }),
          tap((events: Array<any>) => {
            if (events && events.length > 0) {
              this.markPlaceholders(events);
              this.prepareGraph(parentEvents.concat(events));
              this.update.next(true);
              this.zoomToFit.next(true);
            }
          })
        )
        .subscribe(
          () => {
            if (process) {
              process.close();
            }
          },
          () => {
            if (process) {
              process.close();
            }
          }
        )
    );

    if (this.enableContextView) {
      this.subscription.add(
        this.switchToFull
          .pipe(
            tap(() => {
              process = this.modal.show(
                ModalType.progress,
                'key_processing',
                '',
                '300px'
              );

              this.nodes = [];
              this.links = [];
              this.selectedNode = undefined;
              this.graphType = 'context';
            }),
            switchMap(() => {
              if (this.eventId) {
                return this.resource.getRootEvent(this.eventId);
              } else {
                return of(null);
              }
            }),
            switchMap((rootEventId: string) => {
              if (rootEventId) {
                return this.resource.getEvent(rootEventId, true, true);
              } else {
                return of(null);
              }
            }),
            tap((events: Array<any>) => {
              if (events && events.length > 0) {
                this.markPlaceholders(events);
                this.prepareGraph(events);
                this.update.next(true);
                this.zoomToFit.next(true);
              }
            })
          )
          .subscribe(
            () => {
              if (process) {
                process.close();
              }
            },
            () => {
              if (process) {
                process.close();
              }
            }
          )
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  refresh() {
    this.refreshToken.next(undefined);
  }

  isGuid(name: string, value: string) {
    return (
      name.toLowerCase() !== 'objectid' &&
      name.toLowerCase() !== 'azureid' &&
      this.utils.IsGuid(value)
    );
  }

  formatDate(dateString: string) {
    if (!dateString) {
      return '';
    }
    if (this.utils.IsDateString(dateString)) {
      const dt = moment(dateString);
      if (dt) {
        return dt.format(this.utils.ToMomentFormat(this.dtFormat));
      } else {
        return dateString;
      }
    } else {
      return dateString;
    }
  }

  onEnterNode(node: Node) {
    node.meta.bgColor = 'bisque';
  }

  onleaveNode(node: Node) {
    node.meta.bgColor = 'snow';
  }

  onNodeClick(node: Node) {
    this.nodes.forEach((n) => {
      if (node.id === n.id) {
        n.meta.selected = true;
      } else {
        n.meta.selected = false;
      }
    });

    this.selectedNode = node;

    this.showAttributeChanges();

    if (
      this.selectedNode.data.approvalprocessids &&
      this.selectedNode.data.approvalprocessids.length > 0
    ) {
      const obsBatch = [];
      this.selectedNode.data.approvalprocessids.forEach((apId: string) => {
        const apApi: ApiDef = {
          method: 'get',
          path: `approval/search/${apId}`,
          param: {
            responseInclusion: 'All',
          },
        };
        obsBatch.push(
          this.resource.callApi(apApi.method, apApi.path, apApi.param).pipe(
            map((item: ApprovalCloudRequest) => {
              item.showDetail = true;
              item.showResponse = true;
              return item;
            })
          )
        );
      });
      this.obsApprovalProcesses = forkJoin(obsBatch);
    }
  }

  onShowFullContext() {
    if (this.graphType === 'simple') {
      this.switchToFull.next(undefined);
    } else if (this.graphType === 'context') {
      this.refreshToken.next(undefined);
    }
  }

  onViewObjectHistory() {
    if (this.selectedNode.data.targetid) {
      this.utils.NavigateToRoute('app/timemachine', {
        id: this.selectedNode.data.targetid,
        mode: 'event',
      });
    }
  }

  onShowMessageInPopup(
    debugInfo: Array<string>,
    title = '',
    isWarning = false
  ) {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = title;
    popupConfig.noButton = true;
    popupConfig.data = {
      debug: {
        value: debugInfo,
        type: 'list',
        maxHeight: 500,
        options: isWarning ? ['warn'] : ['info'],
      },
    };
    this.subscription.add(this.modal.popup(popupConfig).subscribe());
  }

  onCenterGraph() {
    this.center.next(true);
  }
}
