<div
  [fxLayout]="direction"
  [fxLayoutAlign]="align === 'end' ? 'end center' : 'start center'"
  fxLayoutGap="5px"
  class="button-panel"
>
  <div
    [@editMode]="{
      value: editMode,
      params: { inactiveTrans: transInactive, activeTrans: transActive }
    }"
    fxFlex="0 0 auto"
    [fxLayout]="direction"
    [fxLayoutAlign]="align === 'end' ? 'end center' : 'start end'"
    [fxFlexOrder]="align === 'end' ? 1 : 2"
    fxLayoutGap="5px"
    class="expand-menu"
  >
    <button
      mat-icon-button
      *ngIf="buttons.includes('add') && editMode === 'active'"
      fxFlex="0 0 auto"
      class="link-div"
      matTooltip="{{ 'key_add' | translate }}"
      [matTooltipPosition]="direction === 'row' ? 'below' : 'before'"
      [style.color]="buttonColor"
      (click)="onAdd()"
    >
      <mat-icon class="action-icon">add_box</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="buttons.includes('setting') && editMode === 'active'"
      fxFlex="0 0 auto"
      class="link-div"
      matTooltip="{{ 'key_settings' | translate }}"
      [matTooltipPosition]="direction === 'row' ? 'below' : 'before'"
      [style.color]="buttonColor"
      (click)="onSetting()"
    >
      <mat-icon class="action-icon">settings</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="buttons.includes('refresh') && editMode === 'active'"
      fxFlex="0 0 auto"
      class="link-div"
      matTooltip="{{ 'key_refresh' | translate }}"
      [matTooltipPosition]="direction === 'row' ? 'below' : 'before'"
      [style.color]="buttonColor"
      (click)="onRefresh()"
    >
      <mat-icon class="action-icon">refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="buttons.includes('save') && editMode === 'active'"
      fxFlex="0 0 auto"
      class="link-div"
      matTooltip="{{ 'key_save' | translate }}"
      [matTooltipPosition]="direction === 'row' ? 'below' : 'before'"
      [style.color]="buttonColor"
      (click)="onSave()"
    >
      <mat-icon class="action-icon">save</mat-icon>
    </button>
  </div>
  <button
    mat-icon-button
    fxFlex="0 0 auto"
    [fxFlexOrder]="align === 'end' ? 2 : 1"
    class="link-div"
    [ngClass]="{
      'link-div-row': direction === 'row',
      'link-div-column': direction === 'column'
    }"
    matTooltip="{{ mainIconText | translate }}"
    [matTooltipPosition]="direction === 'row' ? 'below' : 'before'"
    [style.color]="buttonColor"
    (click)="toggleEditMode()"
    style="z-index: 100"
  >
    <mat-icon class="brand-icon">{{ mainIcon }}</mat-icon>
  </button>
</div>
