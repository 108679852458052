<kendo-splitter class="splitter">
  <kendo-splitter-pane [collapsible]="true" size="0px"></kendo-splitter-pane>
  <kendo-splitter-pane fxLayout="column">
    <div fxLayout="column" fxFlex="100" style="margin: 0 5px 0 5px">
      <div>
        <app-object-history
          #objectHistory
          [dotSize]="62"
          [targetId]="targetID"
          [targetType]="targetType"
          [statusTime]="timestamp"
          [reportName]="reportName"
          [mode]="'resource'"
          [historySidenav]="true"
        ></app-object-history>
      </div>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>
