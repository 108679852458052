import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Injector,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { PopupWindow } from '../core/models/dynamicEditor.interface';

@Component({
  selector: 'app-b2b-creation',
  templateUrl: './b2b-creation.component.html',
  styleUrls: ['./b2b-creation.component.scss'],
})
export class B2bCreationComponent
  extends PopupWindow
  implements OnInit, AfterViewInit
{
  @ViewChild('txtEmail') emailField: ElementRef;

  invitedDisplayName: string;
  invitedUsers: Array<{
    displayName: string;
    email: string;
    sponsorName: string;
    sponsorID: string;
  }> = [];

  invitationManager: any = {};
  idpConfig = {
    customDisplayName: 'Sponsor',
    popupWidth: 280,
    showPhoto: true,
    queryNormalSearch: `/Person[starts-with(DisplayName,'%SearchText%')]`,
  };

  fcMail = new FormControl('', [Validators.email]);

  constructor(private injector: Injector) {
    super(injector);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initComponent();
  }

  onAddInvitedUser() {
    const invitedEmail = this.fcMail.value;
    const pos = this.invitedUsers.findIndex(
      (item) => item.email.toLowerCase() === invitedEmail.toLowerCase()
    );
    if (pos < 0) {
      this.invitedUsers.push({
        displayName: this.invitedDisplayName
          ? this.invitedDisplayName
          : invitedEmail,
        email: invitedEmail,
        sponsorID: this.invitationManager.value
          ? this.invitationManager.value.ObjectID
          : '',
        sponsorName: this.invitationManager.value
          ? this.invitationManager.value.DisplayName
          : '',
      });
    }

    this.fcMail.setValue('');
    this.invitedDisplayName = '';
    this.invitationManager = {};

    if (this.emailField) {
      this.emailField.nativeElement.focus();
    }
  }

  onRemoveInvitedUser(email: string) {
    const pos = this.invitedUsers.findIndex(
      (item) => item.email.toLowerCase() === email.toLowerCase()
    );
    if (pos >= 0) {
      this.invitedUsers.splice(pos, 1);
    }
  }

  onClose() {
    if (this.windowRef) {
      this.windowRef.close(this.invitedUsers);
    }
  }
}
