import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

import * as signalR from '@microsoft/signalr';
import { from, Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private hubConnection: signalR.HubConnection;

  serverAddress: string;
  connectionId: string;

  constructor(private config: ConfigService) {
    this.serverAddress = this.config.getConfig('signalRServiceUrl', null);
  }

  public startConnection(serverAddress?: string): Observable<void> {
    const address = serverAddress ?? this.serverAddress;

    if (!address) {
      return throwError('no service url was found');
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(address)
      .build();

    return from(this.hubConnection.start());
  }

  public getConnectionId(methodName = 'getconnectionid'): Observable<string> {
    if (!this.hubConnection) {
      return throwError('connection not started');
    }

    return from(this.hubConnection.invoke(methodName)).pipe(
      tap((id: string) => {
        this.connectionId = id;
      })
    );
  }

  // receive data from signalr hub
  public addListener(name: string, callback: (data: any) => void) {
    if (this.hubConnection) {
      this.hubConnection.on(name, callback);
    }
  }

  // remove listener
  public removeListener(name: string) {
    if (this.hubConnection) {
      this.hubConnection.off(name);
    }
  }

  // clse connection
  public close() {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }

  // send data to signalr hub
  public broadcast(signalName: string, data: any) {
    if (this.hubConnection) {
      this.hubConnection
        .invoke(signalName, data)
        .catch((err) => console.log(err));
    }
  }
}
