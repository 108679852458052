<div *ngIf="hasAdminRight" fxLayout="column">
  <div fxFlex="auto" class="setting-header">
    {{
      (type === 'creationform' ? 'l10n_creationForm' : 'l10n_wizard')
        | translate
    }}
  </div>
  <div fxFlex="auto" class="setting-card">
    <mat-card>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="start start">
          <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex="200px" class="profile-info">
              {{ 'l10n_availableWizard' | translate }}
            </div>
            <div fxFlex="auto" style="margin-right: 30px; width: 260px">
              <mat-form-field style="width: 100%">
                <mat-label>{{ 'l10n_selectWizard' | translate }}</mat-label>
                <mat-select
                  [(value)]="selectedWizard"
                  (selectionChange)="onWizardSelectionChange()"
                  style="font-size: 16px"
                >
                  <mat-option>--</mat-option>
                  <mat-option
                    *ngFor="let wizard of availableWizards"
                    [value]="wizard"
                  >
                    {{ wizard.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
              <button
                mat-stroked-button
                [disabled]="!selectedWizard || availableWizards.length <= 1"
                (click)="onRemoveWizard()"
                color="warn"
              >
                {{ 'key_delete' | translate }}
              </button>
              <button
                mat-stroked-button
                (click)="onAddWizard()"
                color="primary"
              >
                {{ 'key_add' | translate }}
              </button>
              <mat-divider [vertical]="true" style="height: 30px"></mat-divider>
              <button
                mat-stroked-button
                [disabled]="!selectedWizard || availableWizards.length <= 1"
                color="primary"
                (click)="onExportWizard()"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <div>{{ 'key_exportToClipboard' | translate }}</div>
                  <span class="material-icons-outlined">download</span>
                </div>
              </button>
              <button
                mat-stroked-button
                color="primary"
                (click)="onImportWizard()"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="10px"
                >
                  <div>{{ 'key_importFromClipboard' | translate }}</div>
                  <span class="material-icons-outlined">upload</span>
                </div>
              </button>
            </div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            *ngIf="showWizard"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <!-- <div
              *ngIf="type === 'creationform'"
              fxFlex="200px"
              style="font-weight: 400; padding-top: 15px"
            >
              <mat-checkbox
                [disabled]="true"
                [(ngModel)]="selectedWizard.isCreation"
                >{{ 'l10n_usedForCreation' | translate }}</mat-checkbox
              >
            </div> -->
            <div fxFlex="200px" style="font-weight: 400; padding-top: 15px">
              <mat-checkbox [(ngModel)]="selectedWizard.hideButtons">{{
                'l10n_hideButtons' | translate
              }}</mat-checkbox>
            </div>
            <div *ngIf="type === 'creationform'" fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_resourceType' | translate }}</mat-label>
                <input matInput disabled [value]="selectedWizard.objectType" />
              </mat-form-field>
            </div>
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_type' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedWizard.type">
                  <mat-option
                    *ngFor="let option of wizardTypes"
                    [value]="option"
                    >{{ option }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_displayName' | translate }}</mat-label>
                <input matInput [(ngModel)]="selectedWizard.displayName" />
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="showWizard"
            fxFlex="auto"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div
              *ngIf="type === 'creationform'"
              fxFlex="200px"
              style="font-weight: 400; padding-top: 15px"
            >
              <mat-checkbox
                [ngModel]="selectedWizard.navigateToResource"
                [indeterminate]="
                  selectedWizard.navigateToResource === undefined ||
                  selectedWizard.navigateToResource === null
                "
                (change)="onSetNavigateToResource()"
                >{{ 'key_navigateToResource' | translate }}</mat-checkbox
              >
            </div>
            <div *ngIf="type === 'creationform'" fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_navigationKey' | translate }}</mat-label>
                <input matInput [(ngModel)]="selectedWizard.navigationKey" />
              </mat-form-field>
            </div>
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_stateCardIconText' | translate }}</mat-label>
                <input matInput [(ngModel)]="selectedWizard.icon" />
              </mat-form-field>
            </div>
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_width' | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  [(ngModel)]="selectedWizard.width"
                />
              </mat-form-field>
            </div>
            <div fxFlex="none">
              <mat-form-field [appearance]="'outline'">
                <mat-label>{{ 'key_canSubmitAtStep' | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  [(ngModel)]="selectedWizard.canSubmitAtStep"
                />
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="auto">
            <mat-divider></mat-divider>
          </div>
          <div
            *ngIf="showWizard; else noConfig"
            fxFex="auto"
            style="
              margin-top: 20px;
              margin-bottom: 60px;
              padding: 20px;
              border: solid 5px #eeeeee;
            "
            [style.width.px]="selectedWizard.width"
          >
            <app-popup-wizard
              #popupWizard
              [dialogMode]="false"
              [configMode]="true"
            ></app-popup-wizard>
          </div>
          <ng-template #noConfig>
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              style="width: 100%; margin: 80px 0"
            >
              <mat-icon
                fxFlex="none"
                style="font-size: 100px; width: 100px; color: #cccccc"
                >contact_support</mat-icon
              >
              <div
                fxFlex="none"
                style="
                  margin-top: 50px;
                  padding-top: 50px;
                  font-size: 18px;
                  color: #b0b0b0;
                "
              >
                {{ 'key_noConfiguration' | translate }}
              </div>
            </div>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
