import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from '../core/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class VerifiedIdService {
  private serviceUrl: string;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.serviceUrl = this.config.getConfig('verifiedIdServiceUrl', '');
  }

  public getVersion(): Observable<string> {
    if (!this.serviceUrl) {
      return throwError('service url cannot be empty');
    }
    return this.http.get<string>(`${this.serviceUrl}/general/version`);
  }

  public issuance(
    apiKey = '',
    credentialType = 'Employee',
    issuanceType = 'VerifiedCredentialExpert'
  ): Observable<VerifiedIdData> {
    if (!this.serviceUrl) {
      return throwError('service url cannot be empty');
    }
    if (!credentialType) {
      return throwError('credential type cannot be empty');
    }
    if (!issuanceType) {
      return throwError('issuance type cannot be empty');
    }

    const params = new HttpParams({
      fromObject: {
        apiKey,
        credentialType,
        issuanceType,
      },
    });

    return this.http.get<VerifiedIdData>(`${this.serviceUrl}/vid/issuance`, {
      params,
    });
  }

  public presentation(
    apiKey = '',
    credentialType = 'Employee',
    issuanceType = 'VerifiedCredentialExpert'
  ): Observable<VerifiedIdData> {
    if (!this.serviceUrl) {
      return throwError('service url cannot be empty');
    }
    if (!credentialType) {
      return throwError('credential type cannot be empty');
    }
    if (!issuanceType) {
      return throwError('issuance type cannot be empty');
    }

    const params = new HttpParams({
      fromObject: {
        apiKey,
        credentialType,
        issuanceType,
      },
    });

    return this.http.get<VerifiedIdData>(
      `${this.serviceUrl}/vid/presentation`,
      {
        params,
      }
    );
  }
}

export class VerifiedIdData {
  expiry?: number;
  id?: string;
  pin?: string;
  qrCode?: string;
  requestId?: string;
  url?: string;
}
