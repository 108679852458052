import {
  AfterViewInit,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { DragulaService } from 'ng2-dragula';

import {
  ReportCardConfig,
  ResourceColumnConfig,
} from '../../models/componentContract.model';
import { Subscription } from 'rxjs';
import { IterablesEditorConfig } from '../../models/editorContract.model';
import { TransService } from '../../models/translation.model';

import * as moment from 'moment';
import {
  ApiValidationConfig,
  ExpressionValidationResult,
  HistoricalReportConfig,
} from '../../models/dataContract.model';
import { ResourceService } from '../../services/resource.service';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-card-config',
  templateUrl: './report-card-config.component.html',
  styleUrls: ['./report-card-config.component.scss'],
})
export class ReportCardConfigComponent
  implements OnInit, AfterViewInit, DoCheck, OnDestroy
{
  private subscription = new Subscription();

  private reportingValidationUrl = 'reporting/validation';

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @ViewChild('reportCardGeneralForm')
  generalForm: NgForm;

  @ViewChild('reportCardParameterForm')
  parameterForm: NgForm;

  data: ReportCardConfig;
  existingReportNames: Array<string> = [];

  formIsValid = false;

  selectedColumnAttribute: ResourceColumnConfig;
  columnToAdd: string;

  selectedDetailColumnAttribute: ResourceColumnConfig;
  detailColumnToAdd: string;

  queryError = '';

  reportDate: Date = undefined;

  parameterConfig = new IterablesEditorConfig();
  attrParameter = {
    dataType: 'Dictionary',
    displayName: 'key_inputParameters',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrParameter',
    value: null,
    values: undefined,
  };

  sortConfig = new IterablesEditorConfig();
  attrSorting = {
    dataType: 'Dictionary',
    displayName: '',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrSorting',
    value: null,
    values: undefined,
  };
  attrDetailSorting = {
    dataType: 'Dictionary',
    displayName: '',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrDetailSorting',
    value: null,
    values: undefined,
  };

  maxDate = new Date();

  get dateFormat() {
    const dFormat = this.translate.instant('key_dateFormat');
    const tFormat = this.translate.instant('key_timeFormat');

    return `${dFormat} ${tFormat}`;
  }

  queryValidationConfig: ApiValidationConfig = {
    url: this.reportingValidationUrl,
    method: 'POST',
    params: {
      xPathQuery: '%ValidationValue%',
    },
    body: {
      attributes: [],
      historicalAttributes: [],
    },
  };

  historicalColumnErrorMessage = '';

  private buildHistoricalReportColumns(): HistoricalReportConfig {
    const retVal = new HistoricalReportConfig();

    if (this.data && this.data.tableConfig && this.data.tableConfig.columns) {
      this.data.tableConfig.columns.forEach((c: ResourceColumnConfig) => {
        if (c.reportType === 'historical') {
          retVal.historicalAttributes.push(c.field);
        } else {
          retVal.attributes.push(c.field);
        }
      });
    }

    return retVal;
  }

  private validateHistoricalReportColumns() {
    const historicalColumns = this.buildHistoricalReportColumns();
    this.subscription.add(
      this.resource
        .validateViaApi({
          url: this.reportingValidationUrl,
          method: 'POST',
          params: new HttpParams({
            fromObject: {
              xPathQuery: '/person',
            },
          }),
          body: historicalColumns,
        })
        .pipe(
          tap((result: ExpressionValidationResult) => {
            if (result.isValid) {
              this.historicalColumnErrorMessage = '';
            } else {
              this.historicalColumnErrorMessage = result.explanation;
            }
          })
        )
        .subscribe()
    );
  }

  constructor(
    private windowRef: WindowRef,
    private dragula: DragulaService,
    private translate: TransService,
    private resource: ResourceService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar
  ) {
    try {
      this.dragula.createGroup('COLUMNS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit(): void {
    this.queryError = '';

    this.parameterConfig.saveAs = 'object';
    this.parameterConfig.iterableType = 'dictionary';
    this.parameterConfig.properties = [
      {
        name: 'name',
        displayName: 'key_name',
        type: 'text',
        isKey: true,
        required: true,
        width: 20,
        validation: '^[a-zA-Z0-9]*$',
      },
      {
        name: 'title',
        displayName: 'key_title',
        type: 'text',
        isKey: false,
        required: true,
        width: 20,
      },
      {
        name: 'type',
        displayName: 'key_type',
        type: 'select',
        isKey: false,
        required: true,
        width: 15,
        default: 'text',
        options: [
          {
            text: 'text',
            value: 'text',
          },
          {
            text: 'number',
            value: 'number',
          },
          {
            text: 'boolean',
            value: 'boolean',
          },
          {
            text: 'reference',
            value: 'reference',
          },
        ],
      },
      {
        name: 'default',
        displayName: 'key_default',
        type: 'text',
        isKey: false,
        width: 30,
      },
      {
        name: 'readonly',
        displayName: 'key_readOnly',
        type: 'boolean',
        isKey: false,
        width: 10,
      },
    ];

    this.sortConfig.saveAs = 'object';
    this.sortConfig.iterableType = 'array';
    this.sortConfig.showDisplayName = false;
    this.sortConfig.properties = [
      {
        name: 'field',
        displayName: 'key_attributeName',
        type: 'text',
        isKey: true,
        required: true,
        width: 60,
        options: [],
      },
      {
        name: 'dir',
        displayName: 'key_order',
        type: 'select',
        isKey: false,
        width: 40,
        default: 'asc',
        options: [
          {
            text: 'key_ascending',
            value: 'asc',
          },
          {
            text: 'key_descending',
            value: 'desc',
          },
        ],
      },
    ];

    if (this.data && this.data.parameterDef) {
      this.attrParameter.value = this.data.parameterDef;
    }

    if (this.data && this.data.absoluteDate) {
      this.reportDate = moment(
        this.data.absoluteDate,
        'YYYY-MM-DDTHH:mm:ss.SSS'
      ).toDate();
    }

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }

    if (this.data && this.data.tableConfig && this.data.tableConfig.initSort) {
      if (Array.isArray(this.data.tableConfig.initSort)) {
        if (this.data.tableConfig.initSort.length > 0) {
          this.attrSorting.value = this.data.tableConfig.initSort[0];
          this.attrSorting.values = this.data.tableConfig.initSort;
        }
      } else {
        this.attrSorting.value = this.data.tableConfig.initSort;
        this.attrSorting.values = [this.data.tableConfig.initSort];
      }
    }

    if (
      this.data &&
      this.data.tableConfig &&
      this.data.tableConfig.detailTableConfig.initSort
    ) {
      if (Array.isArray(this.data.tableConfig.detailTableConfig.initSort)) {
        if (this.data.tableConfig.detailTableConfig.initSort.length > 0) {
          this.attrDetailSorting.value =
            this.data.tableConfig.detailTableConfig.initSort[0];
          this.attrDetailSorting.values =
            this.data.tableConfig.detailTableConfig.initSort;
        }
      } else {
        this.attrDetailSorting.value =
          this.data.tableConfig.detailTableConfig.initSort;
        this.attrDetailSorting.values = [
          this.data.tableConfig.detailTableConfig.initSort,
        ];
      }
    }
  }

  ngDoCheck(): void {
    let generalFormValid = true;
    if (this.generalForm) {
      generalFormValid = this.generalForm.valid;
    }

    let parameterFormValid = true;
    if (this.parameterForm) {
      parameterFormValid = this.parameterForm.valid;
    }

    this.formIsValid =
      generalFormValid &&
      parameterFormValid &&
      !this.historicalColumnErrorMessage;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isReportTypeDisabled(
    field: string,
    type: string,
    columns: ResourceColumnConfig[]
  ) {
    const pos = columns.findIndex((c: ResourceColumnConfig) => {
      if (c.field.toLowerCase() === field.toLowerCase()) {
        if (c.reportType === type) {
          return true;
        }
        if (c.reportType === undefined && type === 'current') {
          return true;
        }
        return false;
      } else {
        return false;
      }
    });
    return pos >= 0;
  }

  reportTypeDisabled(field: string, columns: ResourceColumnConfig[]) {
    const cols = columns.filter((c: ResourceColumnConfig) => {
      return c.field === field;
    });
    return cols.length >= 2;
  }

  onResetToThemeColor() {
    this.data.backgroundColor = undefined;
    this.data.descriptionColor = undefined;
    this.data.iconColor = undefined;
    this.data.textColor = undefined;
  }

  onAddAllowedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!this.data.permissionSets) {
      this.data.permissionSets = [];
    }

    if ((value || '').trim()) {
      const index = this.data.permissionSets.indexOf(value.trim());
      if (index < 0) {
        this.data.permissionSets.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string) {
    const index = this.data.permissionSets.indexOf(setName);
    if (index >= 0) {
      this.data.permissionSets.splice(index, 1);
    }
  }

  onColorChange() {
    if (this.generalForm) {
      this.generalForm.form.markAsDirty();
    }
  }

  onAddLinkAction(event: MatChipInputEvent) {
    if (event && event.value) {
      const inputValue = event.value.trim();

      if (
        this.data.tableConfig.linkActions &&
        this.data.tableConfig.linkActions.length > 0
      ) {
        const index = this.data.tableConfig.linkActions.findIndex(
          (a: string) => {
            return a.toLowerCase() === inputValue.toLowerCase();
          }
        );
        if (index < 0) {
          this.data.tableConfig.linkActions.push(inputValue);
        }
      } else {
        this.data.tableConfig.linkActions = [inputValue];
      }
    }

    if (event.chipInput && event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }
  }

  onRemoveLinkAction(action: string) {
    const index = this.data.tableConfig.linkActions.indexOf(action);
    if (index >= 0) {
      this.data.tableConfig.linkActions.splice(index, 1);
    }
  }

  onSelectColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedColumnAttribute = ca;
  }

  onSelectDetailColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedDetailColumnAttribute = ca;
  }

  onAddColumn() {
    if (this.columnToAdd) {
      const pos = this.data.tableConfig.columns.findIndex(
        (item: ResourceColumnConfig) => {
          if (this.data.isHistorical) {
            return (
              item.field.toLowerCase() === this.columnToAdd.toLowerCase() &&
              (!item.reportType || item.reportType === 'current')
            );
          } else {
            return item.field.toLowerCase() === this.columnToAdd.toLowerCase();
          }
        }
      );
      if (pos < 0) {
        const elem: ResourceColumnConfig = {
          field: this.columnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        };
        if (this.data.isHistorical) {
          elem.reportType = 'current';
        }
        this.data.tableConfig.columns.push(elem);
      }
    }
    this.columnToAdd = undefined;

    if (this.data.isHistorical) {
      this.validateHistoricalReportColumns();
    }
  }

  onAddDetailColumn() {
    if (this.detailColumnToAdd) {
      const pos = this.data.tableConfig.detailTableConfig.columns.findIndex(
        (item: ResourceColumnConfig) => {
          if (this.data.isHistorical) {
            return (
              item.field.toLowerCase() ===
                this.detailColumnToAdd.toLowerCase() &&
              (!item.reportType || item.reportType === 'current')
            );
          } else {
            return (
              item.field.toLowerCase() === this.detailColumnToAdd.toLowerCase()
            );
          }
        }
      );
      if (pos < 0) {
        const elem: ResourceColumnConfig = {
          field: this.detailColumnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        };
        if (this.data.isHistorical) {
          elem.reportType = 'current';
        }
        this.data.tableConfig.detailTableConfig.columns.push(elem);
      }
    }
    this.detailColumnToAdd = undefined;
  }

  onDeleteColumn(column: ResourceColumnConfig) {
    const index = this.data.tableConfig.columns.findIndex((c) => {
      if (this.data.isHistorical) {
        return c.field === column.field && c.reportType === column.reportType;
      } else {
        return c.field === column.field;
      }
    });
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.tableConfig.columns.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (this.data.tableConfig.columns[lastColumnIndex].locked) {
          this.data.tableConfig.columns[lastColumnIndex].locked = false;
        }
      }

      // remove column
      this.data.tableConfig.columns.splice(index, 1);

      // set focus
      if (
        this.selectedColumnAttribute &&
        column.field === this.selectedColumnAttribute.field
      ) {
        if (this.data.tableConfig.columns.length > 0) {
          this.selectedColumnAttribute = this.data.tableConfig.columns[0];
        } else {
          this.selectedColumnAttribute = undefined;
        }
      }
    }

    if (this.data.isHistorical) {
      this.validateHistoricalReportColumns();
    }
  }

  onDeleteDetailColumn(column: ResourceColumnConfig) {
    const index = this.data.tableConfig.detailTableConfig.columns.findIndex(
      (c) => {
        if (this.data.isHistorical) {
          return c.field === column.field && c.reportType === column.reportType;
        } else {
          return c.field === column.field;
        }
      }
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.tableConfig.detailTableConfig.columns.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (
          this.data.tableConfig.detailTableConfig.columns[lastColumnIndex]
            .locked
        ) {
          this.data.tableConfig.detailTableConfig.columns[
            lastColumnIndex
          ].locked = false;
        }
      }

      // remove column
      this.data.tableConfig.detailTableConfig.columns.splice(index, 1);

      // set focus
      if (column.field === this.selectedDetailColumnAttribute.field) {
        if (this.data.tableConfig.detailTableConfig.columns.length > 0) {
          this.selectedDetailColumnAttribute =
            this.data.tableConfig.detailTableConfig.columns[0];
        } else {
          this.selectedDetailColumnAttribute = undefined;
        }
      }
    }
  }

  onReportValueChange(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.reportType === 'historical') {
      selectedColumn.reportType = 'current';
    } else {
      selectedColumn.reportType = 'historical';
    }

    if (this.data.isHistorical) {
      this.validateHistoricalReportColumns();
    }
  }

  onAddExportAttribute(name: string) {
    const douplicates = this.data.tableConfig.exportAttributes.filter(
      (element) => {
        return element.toLowerCase() === name.toLowerCase();
      }
    );
    if (douplicates && douplicates.length > 0) {
      return;
    } else {
      this.data.tableConfig.exportAttributes.push(name);
    }
  }

  onRemoveExportAttribute(attribute: string) {
    const index = this.data.tableConfig.exportAttributes.findIndex(
      (element) => {
        return element.toLowerCase() === attribute.toLowerCase();
      }
    );
    if (index >= 0) {
      this.data.tableConfig.exportAttributes.splice(index, 1);
    }
  }

  onEnableReferenceFilter(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.isReference === true) {
      selectedColumn.sortable = false;
    }
  }

  onEnableSorting(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.sortable) {
      selectedColumn.isReference = false;
    }
  }

  onParameterChanged() {
    this.data.parameterDef = this.attrParameter.value;
  }

  onDateChange(event: Date) {
    const dtMom = moment(event);
    this.data.absoluteDate = dtMom.format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  onSetHistorical() {
    if (this.data.isHistorical) {
      this.onAddLinkAction({
        value: 'history',
        chipInput: null,
        input: null,
      });
    } else {
      this.onRemoveLinkAction('histroy');
    }
  }

  onExportSetting() {
    this.clipboard.copy(JSON.stringify(this.data));
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    if (this.data && this.data.tableConfig) {
      this.data.tableConfig.initSort = this.attrSorting.values;
      this.data.tableConfig.detailTableConfig.initSort =
        this.attrDetailSorting.values;
    }

    if (this.windowRef) {
      this.windowRef.close(this.data);
    }
  }
}
