<div fxLayout="row wrap" fxLayoutGap="10px grid" style="height: 100%">
  <div
    *ngIf="showBrand"
    [fxFlex]="brandWidth"
    fxFlexOrder="2"
    fxFlex.lt-md="100"
    fxFlexOrder.lt-md="1"
  >
    <div
      class="info-body"
      [class.mat-elevation-z1]="true"
      style="padding-bottom: 5px"
    >
      <div class="info-avatar-layer">
        <div class="info-avatar">
          <div class="info-avatar-initial accent-bg-color">
            {{ initial | uppercase }}
          </div>
        </div>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        style="margin-top: 30px"
      >
        <div fxFlex="none" class="info-title">
          {{ 'key_eventType' | translate }}
        </div>
        <div *ngIf="currentEvent.eventtype" fxFlex="none" class="info-name">
          {{ currentEvent.eventtype }}
        </div>
      </div>
      <div fxLayout="row" class="info-text" style="margin-right: 20px">
        <mat-form-field fxFlex="auto">
          <input
            matInput
            [placeholder]="'key_eventName' | translate"
            [(ngModel)]="currentEvent.displayname"
            disabled
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="info-text" style="margin-right: 20px">
        <mat-form-field fxFlex="auto">
          <input
            matInput
            [placeholder]="'key_eventStatus' | translate"
            [(ngModel)]="currentEvent.status"
            disabled
          />
        </mat-form-field>
      </div>
      <div fxLayout="row" class="info-text" style="margin-right: 20px">
        <mat-form-field fxFlex="auto">
          <input
            matInput
            [placeholder]="'key_id' | translate"
            [(ngModel)]="currentEvent.id"
            disabled
          />
        </mat-form-field>
      </div>
      <!-- <div fxLayout="row" fxLayoutAlign="center center" style="margin: 20px">
        <button fxFlex="none" mat-stroked-button color="warn" disabled>
          {{ 'l10n_cancelEvent' | translate }}
        </button>
      </div> -->
    </div>
  </div>
  <div
    [fxFlex]="detailWidth"
    fxFlexOrder="1"
    fxFlex.lt-md="100"
    fxFlexOrder.lt-md="2"
  >
    <div
      class="info-body"
      [class.mat-elevation-z1]="true"
      [ngClass]="{ 'preview-body': previewMode }"
    >
      <div class="info-tab-icon-layer accent-bg-color primary-contrast-color">
        <mat-icon class="info-tab-icon">event_note</mat-icon>
      </div>
      <div
        *ngIf="currentEvent && currentEvent.id"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        style="margin-right: 20px; height: 15px"
      >
        <app-identity-link
          [identity]="{
            ObjectID: currentEvent.id,
            DisplayName: currentEvent.displayname,
            ObjectType: 'event'
          }"
          [defaultAction]="'native'"
          [linkActions]="['native', 'popup']"
          class="info-header accent-color"
        ></app-identity-link>
        <div fxFlex="auto"></div>
        <div
          *ngIf="previewMode"
          class="preview-icon-layer accent-bg-color primary-contrast-color"
        >
          {{ 'key_preview' | translate }}
        </div>
      </div>
      <div [ngClass]="{ 'preview-resource-tab': previewMode }">
        <mat-tab-group
          *ngIf="!advancedView"
          class="info-tab"
          animationDuration="0ms"
          [style.marginTop.px]="40"
          [disableRipple]="true"
          [selectedIndex]="currentTabIndex"
          (selectedIndexChange)="onTabChange($event)"
        >
          <mat-tab [label]="'key_general' | translate">
            <div
              *ngIf="currentEvent && currentEvent.attributes"
              fxLayout="column"
              style="margin: 20px 15px"
            >
              <div fxLayout="row wrap" fxLayoutGap="20px grid">
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-text
                    [ngModel]="currentEvent.attributes.displayname"
                    [config]="{
                      readOnly: true,
                      customDisplayName: 'Display name'
                    }"
                  ></app-editor-text>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-text
                    [ngModel]="currentEvent.attributes.status"
                    [config]="{
                      readOnly: true,
                      customDisplayName: 'Status'
                    }"
                  ></app-editor-text>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-text
                    [ngModel]="currentEvent.attributes.eventtype"
                    [config]="{
                      readOnly: true,
                      customDisplayName: 'Event type'
                    }"
                  ></app-editor-text>
                </div>
                <div
                  *ngIf="currentEvent.attributes.resourcechangedtype"
                  fxFlex="50"
                  [fxFlex.lt-md]="100"
                  class="attribute-row"
                >
                  <app-editor-text
                    [ngModel]="currentEvent.attributes.resourcechangedtype"
                    [config]="{
                      readOnly: true,
                      customDisplayName: 'Resource changed type'
                    }"
                  ></app-editor-text>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-date
                    [ngModel]="currentEvent.attributes.createdtime"
                    [config]="{
                      readOnly: true,
                      showTime: true,
                      customDisplayName: 'Created time'
                    }"
                  ></app-editor-date>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-date
                    [ngModel]="currentEvent.attributes.starttime"
                    [config]="{
                      readOnly: true,
                      showTime: true,
                      customDisplayName: 'Start time'
                    }"
                  >
                  </app-editor-date>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-date
                    [ngModel]="currentEvent.attributes.committedtime"
                    [config]="{
                      readOnly: true,
                      showTime: true,
                      customDisplayName: 'Committed time'
                    }"
                  >
                  </app-editor-date>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-date
                    [ngModel]="currentEvent.attributes.completedtime"
                    [config]="{
                      readOnly: true,
                      showTime: true,
                      customDisplayName: 'Completed time'
                    }"
                  >
                  </app-editor-date>
                </div>
                <div
                  *ngIf="currentEvent.attributes.requestorid"
                  fxFlex="50"
                  [fxFlex.lt-md]="100"
                  fxLayout="column"
                  class="attribute-row"
                >
                  <div class="attribute-label">Requestor</div>
                  <app-identity-link
                    [identity]="{
                      ObjectID: currentEvent.attributes.requestorid.value
                    }"
                    [linkActions]="['popup', 'sideView', 'navigate']"
                  >
                  </app-identity-link>
                </div>
                <div
                  *ngIf="currentEvent.attributes.targetid"
                  fxFlex="50"
                  [fxFlex.lt-md]="100"
                  fxLayout="column"
                  class="attribute-row"
                >
                  <div class="attribute-label">Target</div>
                  <app-identity-link
                    [identity]="{
                      ObjectID: currentEvent.attributes.targetid.value
                    }"
                    [linkActions]="['popup', 'sideView', 'navigate']"
                  >
                  </app-identity-link>
                </div>
                <div fxFlex="50" [fxFlex.lt-md]="100" class="attribute-row">
                  <app-editor-text
                    [ngModel]="currentEvent.attributes.id"
                    [config]="{
                      readOnly: true,
                      customDisplayName: 'Object id'
                    }"
                  ></app-editor-text>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab [label]="'l10n_eventStructure' | translate">
            <app-event-graph #eventGraph></app-event-graph>
            <!-- <ng-template matTabContent>
            </ng-template> -->
          </mat-tab>
        </mat-tab-group>
        <div *ngIf="advancedView" style="margin: 60px 30px 30px 30px">
          <app-attribute-view
            #attributeView
            [viewType]="'tab'"
            [attributeDefs]="advancedAttributeConfig"
            [tabName]="'!advancedView!'"
          ></app-attribute-view>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          style="margin: 40px 20px 0 20px; padding-bottom: 20px"
        >
          <div *ngIf="revertVisible" fxFlex="none">
            <button mat-stroked-button color="basic" (click)="onRevert()">
              {{ 'key_revertRequest' | translate }}
            </button>
          </div>
          <div *ngIf="cancelVisible" fxFlex="none" style="margin-right: 20px">
            <button
              mat-stroked-button
              color="basic"
              [disabled]="!cancelEnabled"
              (click)="onCancel()"
            >
              {{ 'key_abortWF' | translate }}
            </button>
          </div>
          <div *ngIf="resumeVisible" fxFlex="none">
            <button
              mat-stroked-button
              color="basic"
              [disabled]="!resumeEnabled"
              (click)="onResume()"
            >
              {{ 'key_resumeWF' | translate }}
            </button>
          </div>
          <div fxFlex="auto"></div>
          <div
            fxFlex="none"
            *ngIf="advancedViewEnabled && currentTabIndex === 0"
            style="margin-right: 20px"
          >
            <button mat-stroked-button color="basic" (click)="onSwitchView()">
              {{
                (advancedView ? 'key_normalView' : 'key_advancedView')
                  | translate
              }}
            </button>
          </div>
          <div fxFlex="none">
            <button mat-stroked-button color="basic" (click)="onRefresh()">
              {{ 'key_refresh' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="previewMode"
      fxLayout="column"
      class="info-body"
      [class.mat-elevation-z1]="true"
      style="min-height: 360px; margin-top: 20px"
    >
      <app-resource-preview
        fxFlex="100"
        #resourcePreview
      ></app-resource-preview>
      <div
        *ngIf="!resourcePreview.isLoading"
        fxFlex="none"
        fxLayout="row"
        style="margin: 20px 20px 30px 20px"
      >
        <button mat-stroked-button color="warn" (click)="onExitPreview()">
          {{ 'key_exitPreviewWithoutSave' | translate }}
        </button>
        <div fxFlex="auto"></div>
        <button
          mat-stroked-button
          color="primary"
          (click)="onExitPreview(true)"
        >
          {{ 'key_exitPreviewWithSave' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
