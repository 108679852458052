<div>
  <div *ngIf="showSearchText && !data.config.browserReadOnly">
    <mat-form-field style="width: 100%">
      <mat-label>{{ 'key_search' | translate }}</mat-label>
      <input
        appAutoFocus
        matInput
        autocomplete="off"
        [(ngModel)]="searchText"
        style="font-size: 14px"
        (keyup)="onKeyUp()"
      />
    </mat-form-field>
  </div>
  <div
    *ngIf="data.config.browserShowTypePicker && !data.config.browserReadOnly"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >
    <app-type-picker
      fxFlex="50"
      [value]="typeName"
      [placeHolder]="'key_selectObjectType'"
      (typeChange)="onTypeChange($event)"
    ></app-type-picker>
    <app-attribute-picker
      #attributePicker
      fxFlex="50"
      [objectType]="typeName"
      [multiple]="true"
      [placeHolder]="'l10n_selectAttributes' | translate"
      (attributeChange)="onAttributeChange($event)"
    ></app-attribute-picker>
  </div>
  <app-resource-table
    #identityTable
    [config]="tableConfig"
    [editable]="!data.config.browserReadOnly"
  ></app-resource-table>
  <div
    *ngIf="!data.config.browserReadOnly"
    fxLayout="row"
    fxLayoutAlign="end center"
    style="margin-top: 20px"
  >
    <button
      mat-stroked-button
      color="primary"
      [disabled]="!idt || !idt.selection || idt.selection.length === 0"
      (click)="onSubmit()"
    >
      {{ 'key_submit' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="
      data.config.browserReadOnly
        ? 'key_browseIdentities'
        : 'key_selectResources'
    "
  ></app-popup-title>
</ng-template>
