<!-- <a
  *ngIf="identity && identity.ObjectType"
  class="identity-link"
  (click)="onDefaultAction()"
  (appDelayedHover)="onDelayedHover()"
  delay="1500"
  [matMenuTriggerFor]="linkMenu"
>
  {{
    (identity.DisplayName | extraValue: 'DisplayName:value') ||
      ('key_noDisplayNamePlaceHolder' | translate)
  }}
</a> -->
<a
  *ngIf="identity && hasID(identity) && hasType(identity) && !disabled"
  class="identity-link"
  (click)="onDefaultAction()"
  (contextmenu)="onContextMenu($event)"
  [style.color]="color"
  [matMenuTriggerFor]="linkMenu"
  [matTooltip]="getTooltip(identity)"
>
  {{
    (identity
      | extraValue: 'DisplayName:value'
      | ellipsis: countBeforeEllipsis) ||
      ('key_noDisplayNamePlaceHolder' | translate)
  }}
</a>
<div
  *ngIf="identity && hasID(identity) && (!hasType(identity) || disabled)"
  [matTooltip]="getTooltip(identity)"
  [style.color]="color"
>
  <!-- {{
    (identity
      | extraValue: 'DisplayName:value'
      | ellipsis: countBeforeEllipsis) ||
      ('key_noDisplayNamePlaceHolder' | translate)
  }} -->
  {{ getDisplayName() | ellipsis: countBeforeEllipsis }}
</div>
<mat-menu #linkMenu="matMenu">
  <span (mouseleave)="onMouseOutMenu()">
    <div>
      <button
        mat-menu-item
        *ngFor="let action of menuActions"
        (click)="onAction(action)"
        style="margin-bottom: 5px"
      >
        <mat-icon>{{ action.icon }}</mat-icon>
        <span>{{ action.text | translate }}</span>
      </button>
    </div>
  </span>
</mat-menu>
