import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  GridsterConfig,
  GridType,
  CompactType,
  DisplayGrid,
} from 'angular-gridster2';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

import { environment } from '../../environments/environment';

import {
  GridsterComponentItem,
  DynamicComponent,
} from '../core/models/dynamicComponent.interface';
import {
  AttestationInfo,
  AttestationItem,
  Resource,
  SodConflict,
} from '../core/models/dataContract.model';
import { TransService, Language } from '../core/models/translation.model';
import { DynamicContainerDirective } from '../core/directives/dynamic-container.directive';

import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';
import { AuthService } from '../core/services/auth.service';
import { ComponentService } from '../core/services/component.service';

import { StateCardComponent } from '../core/components/state-card/state-card.component';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { ResourceChartComponent } from '../core/components/resource-chart/resource-chart.component';
import { AttributeEditor } from '../core/models/dynamicEditor.interface';
import { EditorSelectComponent } from '../core/components/editor-select/editor-select.component';
import { EditorIdentityComponent } from '../core/components/editor-identity/editor-identity.component';
import { XpathBuilderComponent } from '../core/components/xpath-builder/xpath-builder.component';
import { ObjectHistoryComponent } from '../core/components/object-history/object-history.component';
import { Router } from '@angular/router';
import { EventGraphComponent } from '../core/components/event-graph/event-graph.component';
import {
  ComponentItem,
  ResourceTableConfig,
  TreeListConfig,
} from '../core/models/componentContract.model';
import { EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import { DataStateChangeEvent } from '@progress/kendo-angular-treelist';
import { SignalService } from '../core/services/signal.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TestComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  //#region general members
  env = environment.env;
  startPath = this.config ? this.config.getConfig('startPath') : '';
  // #endregion

  //#region members for translation service
  currentLanguage = this.translate.currentLang;
  languages: Language[] = this.translate.supportedLanguages;
  // #endregion

  //#region members for resource service
  dataServiceInfo = this.resource.showInfo();
  fetchText = '';
  fetchedResources: Resource[] = [];
  testResource: Resource;
  // #endregion

  //#region spinner
  spinnerType = SPINNER;
  // #endregion

  //#region angular gridster
  @ViewChildren(DynamicContainerDirective)
  dynamicContainers: QueryList<DynamicContainerDirective>;

  gdOptions: GridsterConfig = {
    gridType: GridType.Fixed,
    compactType: CompactType.CompactLeftAndUp,
    margin: 10,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 640,
    minCols: 1,
    maxCols: 100,
    minRows: 1,
    maxRows: 100,
    maxItemCols: 100,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    fixedColWidth: 180,
    fixedRowHeight: 180,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: true,
    },
    swap: true,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.Always,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };

  gdItems: Array<GridsterComponentItem> = [
    {
      cols: 2,
      rows: 1,
      y: 0,
      x: 0,
      name: 'scc1',
      componentType: StateCardComponent,
      componentConfig: {
        name: 'scc1',
        title: 'total users',
        query: `/Person[FirstName='eva']`,
        mainText: '{0}',
        iconText: 'person',
      },
    },
    {
      cols: 3,
      rows: 2,
      y: 0,
      x: 2,
      name: 'scc2',
      componentType: ResourceTableComponent,
      componentConfig: { name: 'scc2', query: '/Person' },
    },
    {
      cols: 3,
      rows: 2,
      y: 0,
      x: 5,
      name: 'scc3',
      componentType: ResourceChartComponent,
      componentConfig: {
        name: 'scc3',
        enableLegend: true,
        enableLabel: false,
        enableTooltip: true,
        seriesConfig: [
          {
            name: 'Requests',
            categoryField: 'category',
            valueField: 'value',
            color: undefined,
            data: undefined,
            queryConfig: [
              {
                name: 'completed',
                method: 'counter',
                attribute: '',
                query: `/Request[RequestStatus='completed']`,
                display: true,
              },
              {
                name: 'failed',
                method: 'counter',
                attribute: '',
                query: `/Request[RequestStatus!='completed' and RequestStatus!='pending']`,
                display: true,
              },
              {
                name: 'trigger',
                method: 'attribute',
                attribute: 'ocgTriggerValue',
                query: `/Person[AccountName='mimadmin']`,
                display: true,
              },
            ],
          },
        ],
      },
    },
  ];
  // #endregion

  //#region resource table

  scrollTableConfig: ResourceTableConfig = new ResourceTableConfig();

  //#endregion

  //#region tree list

  tlConfig: TreeListConfig = new TreeListConfig();

  employees: any[] = [
    {
      id: 1,
      name: 'Daryl Sweeney',
      title: 'Chief Executive Officer',
      phone: '(555) 924-9726',
      managerId: null,
      hireDate: new Date('2019-01-15'),
    },
    {
      id: 2,
      name: 'Guy Wooten',
      title: 'Chief Technical Officer',
      phone: '(438) 738-4935',
      managerId: 1,
      hireDate: new Date('2019-02-19'),
    },
    {
      id: 32,
      name: 'Buffy Weber',
      title: 'VP, Engineering',
      phone: '(699) 838-6121',
      managerId: 2,
      hireDate: new Date('2019-04-13'),
    },
    {
      id: 11,
      name: 'Hyacinth Hood',
      title: 'Team Lead',
      phone: '(889) 345-2438',
      managerId: 32,
      hireDate: new Date('2018-01-17'),
    },
    {
      id: 60,
      name: 'Akeem Carr',
      title: 'Junior Software Developer',
      phone: '(738) 136-2814',
      managerId: 11,
      hireDate: new Date('2018-01-18'),
    },
    {
      id: 78,
      name: 'Rinah Simon',
      title: 'Software Developer',
      phone: '(285) 912-5271',
      managerId: 11,
      hireDate: new Date('2018-03-17'),
    },
    {
      id: 42,
      name: 'Gage Daniels',
      title: 'Software Architect',
      phone: '(107) 290-6260',
      managerId: 32,
      hireDate: new Date('2019-03-14'),
    },
    {
      id: 43,
      name: 'Constance Vazquez',
      title: 'Director, Engineering',
      phone: '(800) 301-1978',
      managerId: 32,
      hireDate: new Date('2018-03-18'),
    },
    {
      id: 46,
      name: 'Darrel Solis',
      title: 'Team Lead',
      phone: '(327) 977-0216',
      managerId: 43,
      hireDate: new Date('2019-04-15'),
    },
    {
      id: 47,
      name: 'Brian Yang',
      title: 'Senior Software Developer',
      phone: '(565) 146-5435',
      managerId: 46,
      hireDate: new Date('2019-02-21'),
    },
    {
      id: 50,
      name: 'Lillian Bradshaw',
      title: 'Software Developer',
      phone: '(323) 509-3479',
      managerId: 46,
      hireDate: new Date('2019-05-23'),
    },
    {
      id: 51,
      name: 'Christian Palmer',
      title: 'Technical Lead',
      phone: '(490) 421-8718',
      managerId: 46,
      hireDate: new Date('2019-04-16'),
    },
    {
      id: 55,
      name: 'Summer Mosley',
      title: 'QA Engineer',
      phone: '(784) 962-2301',
      managerId: 46,
      hireDate: new Date('2019-09-21'),
    },
    {
      id: 56,
      name: 'Barry Ayers',
      title: 'Software Developer',
      phone: '(452) 373-9227',
      managerId: 46,
      hireDate: new Date('2018-04-16'),
    },
    {
      id: 59,
      name: 'Keiko Espinoza',
      title: 'Junior QA Engineer',
      phone: '(226) 600-5305',
      managerId: 46,
      hireDate: new Date('2018-01-22'),
    },
    {
      id: 61,
      name: 'Candace Pickett',
      title: 'Support Officer',
      phone: '(120) 117-7475',
      managerId: 46,
      hireDate: new Date('2018-09-18'),
    },
    {
      id: 63,
      name: 'Mia Caldwell',
      title: 'Team Lead',
      phone: '(848) 636-6470',
      managerId: 43,
      hireDate: new Date('2018-07-17'),
    },
    {
      id: 65,
      name: 'Thomas Terry',
      title: 'Senior Enterprise Support Officer',
      phone: '(764) 831-4248',
      managerId: 63,
      hireDate: new Date('2018-07-14'),
    },
    {
      id: 67,
      name: 'Ruth Downs',
      title: 'Senior Software Developer',
      phone: '(138) 991-1440',
      managerId: 63,
      hireDate: new Date('2018-08-14'),
    },
    {
      id: 70,
      name: 'Yasir Wilder',
      title: 'Senior QA Engineer',
      phone: '(759) 701-8665',
      managerId: 63,
      hireDate: new Date('2019-08-17'),
    },
    {
      id: 71,
      name: 'Flavia Short',
      title: 'Support Officer',
      phone: '(370) 133-9238',
      managerId: 63,
      hireDate: new Date('2018-06-15'),
    },
    {
      id: 74,
      name: 'Aaron Roach',
      title: 'Junior Software Developer',
      phone: '(958) 717-9230',
      managerId: 63,
      hireDate: new Date('2018-09-18'),
    },
    {
      id: 75,
      name: 'Eric Russell',
      title: 'Software Developer',
      phone: '(516) 575-8505',
      managerId: 63,
      hireDate: new Date('2019-09-13'),
    },
    {
      id: 76,
      name: 'Cheyenne Olson',
      title: 'Software Developer',
      phone: '(241) 645-0257',
      managerId: 63,
      hireDate: new Date('2018-09-18'),
    },
    {
      id: 77,
      name: 'Shaine Avila',
      title: 'UI Designer',
      phone: '(844) 435-1360',
      managerId: 63,
      hireDate: new Date('2018-01-22'),
    },
    {
      id: 81,
      name: 'Chantale Long',
      title: 'Senior QA Engineer',
      phone: '(252) 419-6891',
      managerId: 63,
      hireDate: new Date('2018-09-14'),
    },
    {
      id: 83,
      name: 'Dane Cruz',
      title: 'Junior Software Developer',
      phone: '(946) 701-6165',
      managerId: 63,
      hireDate: new Date('2019-03-15'),
    },
    {
      id: 84,
      name: 'Regan Patterson',
      title: 'Technical Writer',
      phone: '(265) 946-1765',
      managerId: 63,
      hireDate: new Date('2019-05-17'),
    },
    {
      id: 85,
      name: 'Drew Mckay',
      title: 'Senior Software Developer',
      phone: '(327) 293-0162',
      managerId: 63,
      hireDate: new Date('2019-05-21'),
    },
    {
      id: 88,
      name: 'Bevis Miller',
      title: 'Senior Software Developer',
      phone: '(525) 557-0169',
      managerId: 63,
      hireDate: new Date('2018-08-15'),
    },
    {
      id: 89,
      name: 'Bruce Mccarty',
      title: 'Support Officer',
      phone: '(936) 777-8730',
      managerId: 63,
      hireDate: new Date('2019-10-21'),
    },
    {
      id: 90,
      name: 'Ocean Blair',
      title: 'Team Lead',
      phone: '(343) 586-6614',
      managerId: 43,
      hireDate: new Date('2018-06-20'),
    },
    {
      id: 91,
      name: 'Guinevere Osborn',
      title: 'Software Developer',
      phone: '(424) 741-0006',
      managerId: 90,
      hireDate: new Date('2019-06-17'),
    },
    {
      id: 92,
      name: 'Olga Strong',
      title: 'Graphic Designer',
      phone: '(949) 417-1168',
      managerId: 90,
      hireDate: new Date('2018-06-15'),
    },
    {
      id: 93,
      name: 'Robert Orr',
      title: 'Support Officer',
      phone: '(977) 341-3721',
      managerId: 90,
      hireDate: new Date('2018-06-22'),
    },
    {
      id: 95,
      name: 'Odette Sears',
      title: 'Senior Software Developer',
      phone: '(264) 818-6576',
      managerId: 90,
      hireDate: new Date('2019-05-20'),
    },
    {
      id: 45,
      name: 'Zelda Medina',
      title: 'QA Architect',
      phone: '(563) 359-6023',
      managerId: 32,
      hireDate: new Date('2018-08-16'),
    },
    {
      id: 3,
      name: 'Priscilla Frank',
      title: 'Chief Product Officer',
      phone: '(217) 280-5300',
      managerId: 1,
      hireDate: new Date('2019-04-22'),
    },
    {
      id: 4,
      name: 'Ursula Holmes',
      title: 'EVP, Product Strategy',
      phone: '(370) 983-8796',
      managerId: 3,
      hireDate: new Date('2018-01-15'),
    },
    {
      id: 24,
      name: 'Melvin Carrillo',
      title: 'Director, Developer Relations',
      phone: '(344) 496-9555',
      managerId: 3,
      hireDate: new Date('2018-01-17'),
    },
    {
      id: 29,
      name: 'Martha Chavez',
      title: 'Developer Advocate',
      phone: '(140) 772-7509',
      managerId: 24,
      hireDate: new Date('2018-05-14'),
    },
    {
      id: 30,
      name: 'Oren Fox',
      title: 'Developer Advocate',
      phone: '(714) 284-2408',
      managerId: 24,
      hireDate: new Date('2018-07-19'),
    },
    {
      id: 41,
      name: 'Amos Barr',
      title: 'Developer Advocate',
      phone: '(996) 587-8405',
      managerId: 24,
      hireDate: new Date('2019-01-16'),
    },
  ];

  rootResources = of(this.employees);

  fetchChildren = (item: any): Observable<Array<any>> => {
    if (item && item.id < 100) {
      return of([
        {
          id: 100,
          name: 'Test User 1',
          title: 'Developer Advocate',
          phone: '(996) 587-8405',
          hireDate: new Date('2019-01-16'),
        },
        {
          id: 101,
          name: 'Test User 2',
          title: 'Developer Advocate',
          phone: '(996) 587-8405',
          hireDate: new Date('2019-01-16'),
        },
        {
          id: 102,
          name: 'Test User 3',
          title: 'Developer Advocate',
          phone: '(996) 587-8405',
          hireDate: new Date('2019-01-16'),
        },
      ]);
    }
  };

  hasChildren = (item: any): boolean => {
    return item && item.id < 100;
  };

  dataStateChange(state: DataStateChangeEvent): void {
    console.log(state);
    const sorted = [
      ...this.employees.sort((a, b) => (a.name > b.name ? 1 : -1)),
    ];
    this.rootResources = of(sorted);
  }

  //#endregion

  //#region signalr

  public valueMessages: string[] = [];
  public messageToSend = '';
  public signalRServerAddress = '';
  public signalRConnectionId = '';

  //#endregion

  //#region ngx-graph

  graphNodes = [
    {
      id: 'first',
      label: 'A',
      bgColor: 'yellow',
      link: 'https://image.flaticon.com/icons/svg/145/145812.svg',
      dimension: {
        height: 50,
        width: 50,
      },
    },
    {
      id: 'second',
      label: 'B',
      link: 'https://image.flaticon.com/icons/svg/684/684908.svg',
      dimension: {
        height: 50,
        width: 50,
      },
    },
    {
      id: 'c1',
      label: 'C1',
      link: 'https://image.flaticon.com/icons/svg/145/145812.svg',
      dimension: {
        height: 50,
        width: 50,
      },
      selected: true,
    },
    {
      id: 'c2',
      label: 'C2',
      link: 'https://image.flaticon.com/icons/svg/145/145812.svg',
      dimension: {
        height: 50,
        width: 50,
      },
    },
    {
      id: 'd',
      label: 'D',
      link: 'https://image.flaticon.com/icons/svg/145/145812.svg',
      dimension: {
        height: 50,
        width: 50,
      },
    },
  ];

  graphLinks = [
    {
      id: 'a',
      source: 'first',
      target: 'second',
      label: 'is parent of',
    },
    {
      id: 'b',
      source: 'first',
      target: 'c1',
      label: 'custom label',
    },
    {
      id: 'd',
      source: 'first',
      target: 'c2',
      label: 'custom label',
    },
    {
      id: 'e',
      source: 'c1',
      target: 'd',
      label: 'first link',
    },
    {
      id: 'f',
      source: 'c1',
      target: 'd',
      label: 'second link',
    },
  ];

  // #endregion

  //#region attribute editors

  @ViewChildren('editor') editors: QueryList<AttributeEditor>;

  editorResource: Resource = {};

  configAccountName = {
    attributeName: 'AccountName',
    showDescription: true,
    validation: '^[a-zA-Z.]{1,16}$',
    maxLength: 16,
  };

  configCountry = {
    showDescription: true,
    dataMode: 'query',
    textAttribute: 'ocgObjectSource',
    valueAttribute: 'ocgObjectID',
    query: "/ocgConfiguration[ocgObjectType='Country']",
  };

  configCity = {
    showDescription: true,
    dataMode: 'query',
    textAttribute: 'ocgObjectSource',
    valueAttribute: 'ocgObjectID',
  };

  private getEditor(attributeName: string): AttributeEditor {
    if (this.editors) {
      return this.editors.find(
        (e) => e.attribute && e.attribute.systemName === attributeName
      );
    }
    return null;
  }

  onIdentityChange(changedValue: Array<any>) {
    const identityEditor = this.getEditor('Manager');
    console.log(identityEditor.value);
    console.log(changedValue);
  }

  // #endregion

  //#region conflicts-resolver

  crResults: Array<any> = [];

  crTarget = {
    displayname: 'Jie Li',
    objecttype: 'person',
    objectid: '00999fbe-8c13-4571-b000-f856a661eb09',
  };

  crConflicts: Array<AttestationItem> = [
    {
      target: this.crTarget,
      sods: [
        {
          conflictname: 'Employee Restriction',
          conflictdescription: 'Double employment in DE and UK is not allowed',
          conflictid: '0930d903-574c-4b5f-ab32-45159b982b39',
          conflictingobject1: {
            displayname: 'DE Employee',
            objecttype: 'ocgrole',
            objectid: '092600cd-0c74-479a-ae99-030028426ea6',
          },
          conflictingobject2: {
            displayname: 'UK Employee',
            objecttype: 'ocgrole',
            objectid: '0923b4a2-6b25-4fac-af3d-340ba95b08b1',
          },
          previouscomment: 'Nein, vergiss es',
          previoususer: this.crTarget,
        },
        {
          conflictname: 'Hardware Restriction',
          conflictdescription:
            'Mixed usage of hardwares produced in DE and UK is not allowed',
          conflictid: '09305a39-2f4a-49c1-a744-6badbbbac89e',
          conflictingobject1: {
            displayname: 'DE Hardware',
            objecttype: 'ocgrole',
            objectid: '092c82bb-a931-418f-be3e-165950c53914',
          },
          conflictingobject2: {
            displayname: 'UK Hardware',
            objecttype: 'ocgrole',
            objectid: '092257b5-2c17-403e-9374-6710ab3d134d',
          },
        },
      ],
      roles: [
        {
          displayname: 'DE Hardware',
          objecttype: 'ocgrole',
          objectid: '092c82bb-a931-418f-be3e-165950c53914',
          isdirect: true,
        },
        {
          displayname: 'UK Hardware',
          objecttype: 'ocgrole',
          objectid: '092257b5-2c17-403e-9374-6710ab3d134d',
          isdirect: true,
        },
        {
          displayname: 'DE Employee',
          objecttype: 'ocgrole',
          objectid: '092600cd-0c74-479a-ae99-030028426ea6',
          isdirect: false,
        },
        {
          displayname: 'UK Employee',
          objecttype: 'ocgrole',
          objectid: '0923b4a2-6b25-4fac-af3d-340ba95b08b1',
          isdirect: false,
        },
      ],
    },
    {
      target: {
        displayname: 'Alex Bloom',
        objecttype: 'person',
        objectid: '009b439a-cde3-4946-88d0-0a6964249688',
      },
      sods: [
        {
          conflictname: 'Position Restriction',
          conflictdescription:
            'Accountant and Auditor cannot be the same person',
          conflictid: '0938a653-ca4b-4b61-b94a-6d60c654711b',
          conflictingobject1: {
            displayname: 'Accountant',
            objecttype: 'ocgrole',
            objectid: '0921693b-fd7d-468f-93ca-dc0fd016232f',
          },
          conflictingobject2: {
            displayname: 'Auditor',
            objecttype: 'ocgrole',
            objectid: '0929c09f-a575-47d5-b50a-60d6932de114',
          },
          exception: {
            creator: this.crTarget,
            comment: 'ok for testing',
            createdtime: '2024-08-20 14:56:32',
          },
        },
      ],
    },
  ];

  crInfo: AttestationInfo = {
    items: this.crConflicts,
  };

  //#endregion

  //#region object explorer

  // oeQuery = `/*[tags='onboarding']`;
  // oeLinkedAttributes = [
  //   'DisplayName',
  //   'ocgParentRef',
  //   'requestorXpathTemplate',
  //   'resourceBeforeXpathTemplate',
  //   'resourceAfterXpathTemplate',
  //   'workflows',
  // ];
  oeRoleId = '092600cd-0c74-479a-ae99-030028426ea6';
  oeUserId = '00928236-b07b-490c-9196-957deb608d21';
  oeQuery = `/*[objectid='${this.oeRoleId}' or objectid='00928236-b07b-490c-9196-957deb608d21' or ocgdirectrolerefs='${this.oeRoleId}']`;
  oeTravelQuery = `/*[ocgParentRef='%id%']`;
  oeLinkedAttributes = [
    'DisplayName',
    'ocgdirectrolerefs',
    'ocgParentRef',
    'ocgResultantObjectRefs',
  ];

  //#endregion

  constructor(
    private config: ConfigService,
    private translate: TransService,
    private resource: ResourceService,
    private auth: AuthService,
    private cfr: ComponentFactoryResolver,
    private spinner: NgxUiLoaderService,
    private router: Router,
    private com: ComponentService,
    private signal: SignalService
  ) {}

  ngOnInit() {
    // this.resource
    //   .getResourceByID(id, ['conflictingobject1', 'conflictingobject2'])
    //   .subscribe(
    //     (result: Resource) => {
    //       const conflictid1 = result['conflictingobject1'];
    //       const conflictid2 = result['conflictingobject2'];
    //       const obsarry = [];
    //       obsarry.push(
    //         this.resource.getResourceByID(conflictid1, ['displayname'])
    //       );
    //       obsarry.push(
    //         this.resource.getResourceByID(conflictid2, ['displayname'])
    //       );
    //       forkJoin(obsarry).subscribe(
    //         (arrayresult: any) => {
    //           console.log(arrayresult);
    //         },
    //         () => {}
    //       );
    //     },
    //     (error: any) => {
    //       console.log(error);
    //     }
    //   );

    setTimeout(() => {
      this.spinner.startLoader('loader-01');
    }, 2000);
    setTimeout(() => {
      this.spinner.stopLoader('loader-01');
    }, 5000);

    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;

    this.scrollTableConfig.objectType = 'Person';
    this.scrollTableConfig.query = `/Person`;
    this.scrollTableConfig.queryEditableItems = `/Person[Register=true]`;
    this.scrollTableConfig.tableHeight = 650;
    this.scrollTableConfig.selectable = true;
    this.scrollTableConfig.selectMode = 'multiple';
    this.scrollTableConfig.resizable = true;

    this.scrollTableConfig.columns = [
      {
        field: 'DisplayName',
        width: null,
        filterable: true,
        filter: 'text',
        sortable: true,
        locked: false,
      },
      {
        field: 'Register',
        width: null,
        filterable: true,
        filter: 'boolean',
        sortable: true,
        locked: false,
        showStatus: {
          true: {
            text: '',
            color: 'green',
          },
          false: {
            text: '',
            color: 'red',
          },
        },
      },
    ];

    this.tlConfig.objectType = 'event';
    this.tlConfig.listHeight = 500;
    this.tlConfig.pageSize = 20;
    this.tlConfig.idName = 'id';
    this.tlConfig.columns = [
      {
        field: 'DisplayName',
        title: 'Display name',
        width: 300,
        navigationKey: 'event',
        linkNoneForm: true,
      },
      {
        field: 'eventtype',
        title: 'Type',
        width: 80,
      },
      {
        field: 'creationtime',
        title: 'Creation time',
        width: 80,
      },
      {
        field: 'completedtime',
        title: 'Completed time',
        width: 80,
      },
      {
        field: 'requestor',
        title: 'Requestor',
        width: 80,
      },
      {
        title: 'Status',
        field: 'status',
        width: 80,
        // sortable: true,
        showStatus: {
          success: { color: 'green' },
          failed: { color: 'red' },
        },
        fallbackStatus: {
          color: 'goldenrod',
        },
        // filterable: true,
        // filter: 'text',
      },
    ];
    this.tlConfig.apiChildrenQueryPath = 'param|xPathQuery';
    this.tlConfig.apiRootResources = {
      method: 'post',
      path: 'resources/search',
      pathContinue: 'resources/search/continue',
      body: {
        attributes: [
          'displayname',
          'eventtype',
          'createdtime',
          'completedtime',
          'requestorid',
          'requestordisplayname',
          'status',
          'childcount',
        ],
        pageSize: this.tlConfig.pageSize,
        orderBy: {
          attribute: 'createdtime',
          order: 'descending',
        },
        includeCount: 'FastOnly',
      },
      param: {
        xPathQuery: `/event[eventtype='request']`,
      },
    };
    this.tlConfig.apiChildrenResources = {
      method: 'post',
      path: 'resources/search',
      pathContinue: 'resources/search/continue',
      body: {
        attributes: [
          'displayname',
          'eventtype',
          'createdtime',
          'completedtime',
          'requestorid',
          'requestordisplayname',
          'status',
          'childcount',
        ],
        pageSize: this.tlConfig.pageSize,
        orderBy: {
          attribute: 'createdtime',
          order: 'descending',
        },
        includeCount: 'FastOnly',
      },
      param: {
        xPathQuery: `/event[parenteventid='[#ParentID]']`,
      },
    };

    // #region observable test

    // // observable
    // const obs = new Observable((observer) => {
    //   let idx = 1;
    //   setInterval(() => {
    //     observer.next(`from observable ${idx++}`);
    //   }, 1000);
    // });

    // let sub: Subscription;
    // setTimeout(() => {
    //   sub = obs.subscribe((value) => console.log(value));
    // }, 3000);

    // setTimeout(() => {
    //   sub.unsubscribe();
    // }, 6000);

    // // subject / replaysubject / behaviorsubject
    // const subject = new Subject();
    // subject.next('from subject initializing');
    // subject.next('from subject starting');

    // const sub = subject.subscribe((value) => console.log(value));

    // let idx = 1;
    // const itv = setInterval(() => {
    //   subject.next(`from subject ${idx++}`);
    // }, 1000);

    // setTimeout(() => {
    //   clearInterval(itv);
    //   sub.unsubscribe();
    // }, 6000);

    // #endregion

    //#region signalr

    this.signalRServerAddress = this.signal.serverAddress;

    this.subscription.add(
      this.signal
        .startConnection()
        .pipe(
          tap(() => {
            this.signal.addListener('ValueMessage', (value: string) => {
              this.valueMessages.push(value);
            });
          }),
          catchError((err: any) => {
            console.log(err);
            return EMPTY;
          }),
          switchMap(() => {
            return this.signal.getConnectionId();
          }),
          tap((connectionId: string) => {
            this.signalRConnectionId = connectionId;
          })
        )
        .subscribe()
    );

    //#endregion
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.gdItems.forEach((item: GridsterComponentItem) => {
        const componentFactory = this.cfr.resolveComponentFactory(
          item.componentType
        );
        const container = this.dynamicContainers.find(
          (h) => h.containerName === item.name
        );
        if (container) {
          const viewContainerRef = container.viewContainerRef;
          viewContainerRef.clear();
          const componentRef =
            viewContainerRef.createComponent(componentFactory);
          item.componentInstance = componentRef.instance as DynamicComponent;
          item.componentInstance.config = item.componentConfig;
        }
      });
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeLanguage(language: string) {
    this.currentLanguage = language;
    this.translate.use(language);
  }

  onFetchResource() {
    this.fetchedResources.splice(0, this.fetchedResources.length);
    this.resource
      .getResourceByQuery(
        this.fetchText,
        ['DisplayName', 'AccountName', 'Manager'],
        3,
        0,
        true
      )
      .subscribe(
        (resources) => {
          if (resources && resources.totalCount > 0) {
            this.fetchedResources = resources.results;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  onDataServiceTest() {
    // getResourceByID test
    this.resource
      .getResourceByID(
        'bc61df37-eaef-4aa0-b714-4a809d264a45',
        [
          'DisplayName',
          'AccountName',
          'Manager',
          'Register',
          'ocgObjectScope',
          'EmployeeType',
          'Country',
          'City',
          'EmployeeStartDate',
        ],
        'full',
        'de',
        'true'
      )
      .subscribe((resource) => {
        this.editorResource = resource;
        console.log(resource);
      });

    // schema test
    // this.resource.getType('Person', 'en-US').subscribe(
    //   result => {
    //     console.log(result);
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );

    // this.resource.getAttribute('Person', 'ocgObjectScope', 'en-US').subscribe(
    //   result => {
    //     console.log(result);
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );

    // this.resource
    //   .getTypes(this.config.getCulture(this.translate.currentCulture))
    //   .subscribe((result) => {
    //     console.log(result);
    //   });

    // getResourceByQuery test
    // this.resource
    //   .getResourceByQuery(
    //     `/Person[starts-with(DisplayName,'eva')]`,
    //     ['DisplayName', 'AccountName', 'Manager'],
    //     10,
    //     0,
    //     true
    //   )
    //   .subscribe(resources => {
    //     console.log(resources);
    //   });

    // schema test
    // this.resource.getResourceSchema('Person', 'de').subscribe(schema => {
    //   console.log(schema);
    // });

    // getResourceCount test
    // this.resource.getResourceCount('/Person').subscribe((count: number) => {
    //   console.log(`count of all users: ${count}`);
    // });

    // CRUD
    // createResource test
    // this.resource
    //   .createResource({
    //     DisplayName: 'creation test',
    //     ObjectType: 'Person',
    //     FirstName: 'creation',
    //     LastName: 'test'
    //   })
    //   .pipe(
    //     tap(id => {
    //       console.log(`resource created with ${id}`);
    //     }),
    //     switchMap(id => {
    //       // updateResource test
    //       return this.resource
    //         .updateResource({
    //           ObjectID: id,
    //           ObjectType: 'Person',
    //           FirstName: 'creationtest',
    //           MiddleName: 'CT'
    //         })
    //         .pipe(
    //           tap(() => {
    //             console.log(`resource ${id} updated`);
    //           }),
    //           switchMap(() => {
    //             // deleteResource test
    //             return this.resource.deleteResource(String(id)).pipe(
    //               tap(() => {
    //                 console.log(`resource ${id} deleted`);
    //               })
    //             );
    //           })
    //         );
    //     })
    //   )
    //   .subscribe();

    // add/removeResourceValue test
    // this.resource
    //   .addResourceValue('7fb2b853-24f0-4498-9534-4e10589723c4', 'ProxyAddressCollection', [
    //     'test1@demo.com',
    //     'test2@demo.com'
    //   ])
    //   .pipe(
    //     tap(() => {
    //       console.log('values added');
    //     }),
    //     switchMap(() => {
    //       return this.resource
    //         .removeResourceValue('7fb2b853-24f0-4498-9534-4e10589723c4', 'ProxyAddressCollection', [
    //           'test1@demo.com'
    //         ])
    //         .pipe(
    //           tap(() => {
    //             console.log('values removed');
    //           })
    //         );
    //     })
    //   )
    //   .subscribe();
  }

  onLogout() {
    this.auth.logout();
  }

  onTabChange(event: MatTabChangeEvent) {
    if (
      event.index === 2 &&
      this.gdOptions.api &&
      this.gdOptions.api.optionsChanged
    ) {
      this.gdOptions.api.optionsChanged();
    }
  }

  onGridsterEdit() {
    this.gdOptions.draggable.enabled = true;
    this.gdOptions.resizable.enabled = true;
    this.gdOptions.displayGrid = DisplayGrid.Always;
    this.gdOptions.api.optionsChanged();
  }
  onGridsterAdd() {
    this.gdItems.push({
      x: 0,
      y: 0,
      cols: 2,
      rows: 2,
      name: 'scc-test',
      componentType: StateCardComponent,
      componentConfig: undefined,
    });
  }
  onGridsterCancel() {
    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;
    this.gdOptions.api.optionsChanged();
  }
  onGridsterSave() {
    console.log(this.gdItems);
  }
  onGridsterDelete(event: Event, item: GridsterComponentItem) {
    event.preventDefault();
    event.stopPropagation();
    this.gdItems.splice(this.gdItems.indexOf(item), 1);
  }
  onGridsterConfig(event: Event, item: GridsterComponentItem) {
    event.preventDefault();
    event.stopPropagation();
    item.componentInstance.configure().subscribe((config) => {
      item.componentConfig = config;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit(form: NgForm, editorAccountName: AttributeEditor) {
    /** Set editor value */
    // editor.value = 'test';

    /** Configure editor in 3 different ways */
    // this.configAccountName.validation = undefined;
    // this.configAccountName.showDescription = false;

    // const config = editorAccountName.config as TextEditorConfig;
    // if (config) {
    //   config.showDescription = false;
    //   config.validation = '^[a-zA-Z.?]{1,8}$';
    //   config.maxLength = 8;
    //   config.isHidden = true;
    // }

    // const editorCountry = this.getEditor('Country') as EditorSelectComponent;
    // const editorCity = this.getEditor('City') as EditorSelectComponent;
    // if (editorCity && editorCountry) {
    //   editorCity.config.query = editorCity.config.query.replace('#country#', editorCountry.value);
    //   editorCity.setConfig();
    // }

    /** XPath parser test */
    // this.resource
    //   .xpathToJson(`/Person[starts-with(DisplayName,'test')]`)
    //   .subscribe((json: any) => {
    //     console.log(json);
    //     if (json) {
    //       this.resource
    //         .jsonToXPath(JSON.stringify(json))
    //         .subscribe((xpath: string) => {
    //           console.log(xpath);
    //         });
    //     }
    //   });

    console.log(form);

    const editorManager = this.getEditor('Manager') as EditorIdentityComponent;
    editorManager.value = [
      '7fb2b853-24f0-4498-9534-4e10589723c4',
      '106a2d89-1c16-423d-9104-f1fbdac5fbb7',
    ];
  }

  onCountryChange(value: any) {
    const editorCity = this.getEditor('City') as EditorSelectComponent;
    if (value && editorCity) {
      editorCity.config.query = `/ocgConfiguration[ocgObjectType='city' and ocgObjectScope='${value}']`;
      editorCity.setConfig();
    }

    const editorRegister = this.getEditor('Register');
    if (editorRegister) {
      editorRegister.config.isHidden = value === 'USA' ? true : false;
    }
  }

  onGetXPathQuery(xpathBuilder: XpathBuilderComponent) {
    console.log(JSON.stringify(xpathBuilder.query));
  }

  onRefreshHistory(history: ObjectHistoryComponent) {
    history.targetId = '354a7023-bc4a-4f19-99ff-5c27afdcf709';
    history.refresh();
  }

  onExpandAllHistory(history: ObjectHistoryComponent) {
    history.expandAll();
  }

  onCollapseAllHistory(history: ObjectHistoryComponent) {
    history.collapseAll();
  }

  onGotoTimeMachine() {
    this.router.navigate(['app/timemachine'], {
      queryParams: {
        id: 'd0b44a74-c0ab-4aaa-9a1d-c7c54ea55b09',
        mode: 'resource',
        time: '2020-07-23T00:00:00.000000Z',
      },
    });
  }

  onGraphNodeClick(node: any) {
    console.log(node);
  }

  onGraphLinkClick(link: any) {
    console.log(link);
  }

  onRefreshEventGraph(graph: EventGraphComponent) {
    graph.refresh();
  }

  onLoadEventGrph(graph: EventGraphComponent) {
    graph.eventId = '589c2dcb-0026-48b9-a08c-76a347b6dbac';
    graph.refresh();
  }

  onLoadCustomComponent() {
    const componentItem: ComponentItem =
      this.com.getCustomComponent('customdemo');
    if (componentItem && componentItem.component) {
      // get component
      const componentFactory = this.cfr.resolveComponentFactory(
        componentItem.component
      );
      // get container
      const container = this.dynamicContainers.find(
        (h) => h.containerName === 'dc1'
      );
      if (container) {
        const viewContainerRef = container.viewContainerRef;
        viewContainerRef.clear();
        // load component
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const componentRef = viewContainerRef.createComponent(componentFactory);
      }
    }
  }

  onSendToSignalR() {
    this.signal.broadcast('broadcastvalue', this.messageToSend);
  }

  adjustRootData = (data: any): Array<any> => {
    if (data && data.length > 0) {
      const treeListData = data;
      treeListData.map((item: any) => {
        if (item.requestorid && item.requestordisplayname) {
          item.requestor = {
            DisplayName: item.requestordisplayname,
            ObjectID: item.requestorid,
            ObjectType: 'person',
          };
        } else {
          item.requestor = null;
        }
      });
      return treeListData;
    }
    return [];
  };

  adjustChildrenData = (data: any): Array<any> => {
    if (data && data.results) {
      const treeListData = data.results;
      treeListData.map((item: any) => {
        if (item.requestorid && item.requestordisplayname) {
          item.requestor = {
            DisplayName: item.requestordisplayname,
            ObjectID: item.requestorid,
            ObjectType: 'person',
          };
        } else {
          item.requestor = null;
        }
      });
      return treeListData;
    }
    return [];
  };
}
