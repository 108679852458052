import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { MatChipInputEvent } from '@angular/material/chips';
import { WindowRef } from '@progress/kendo-angular-dialog';

import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-view-configurator',
  templateUrl: './view-configurator.component.html',
  styleUrls: ['./view-configurator.component.scss'],
})
export class ViewConfiguratorComponent implements AfterViewInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  data: any;

  showDisplaySettings = false;

  constructor(private dragula: DragulaService, private windowRef: WindowRef) {
    try {
      this.dragula.createGroup('VIEWSECTIONS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngAfterViewInit() {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  onAddDeniedSet(event: MatChipInputEvent, section: any) {
    const input = event.chipInput.inputElement;
    const value = event.value;
    if ((value || '').trim()) {
      if (!section.accessDenied) {
        section.accessDenied = [];
      }
      const index = section.accessDenied.indexOf(value.trim());
      if (index < 0) {
        section.accessDenied.push(value.trim());
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveDeniedSet(setName: string, section: any) {
    const index = section.accessDenied.indexOf(setName);
    if (index >= 0) {
      section.accessDenied.splice(index, 1);
    }
  }

  onAddAllowedSet(event: MatChipInputEvent, section: any) {
    const input = event.chipInput.inputElement;
    const value = event.value;
    if ((value || '').trim()) {
      if (!section.accessAllowed) {
        section.accessAllowed = [];
      }
      const index = section.accessAllowed.indexOf(value.trim());
      if (index < 0) {
        section.accessAllowed.push(value.trim());
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string, section: any) {
    const index = section.accessAllowed.indexOf(setName);
    if (index >= 0) {
      section.accessAllowed.splice(index, 1);
    }
  }

  onAddTab() {
    if (this.data.creationMode) {
      this.data.viewSetting.sections.push({
        name: '',
        displayName: '',
        attributes: [],
      });
    } else {
      this.data.viewSetting.sections.push({
        cols: 2,
        rows: 2,
        x: 0,
        y: 0,
        name: '',
        displayName: '',
        columnNumber: 1,
        attributes: [],
      });
    }
  }

  onRemoveTab(section: any) {
    const pos = this.data.viewSetting.sections.findIndex(
      (s: any) => s.name === section.name
    );
    this.data.viewSetting.sections.splice(pos, 1);
  }

  sectionsInvalid(): boolean {
    return (
      this.data.viewSetting.sections.findIndex(
        (s: any) => !s.name || !s.displayName
      ) >= 0
    );
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    if (this.windowRef) {
      this.windowRef.close(this.data);
    }
  }
}
