import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    {
      prefix: 'assets/translate/core/',
      suffix: `.json?t=${new Date().getTime()}`,
    },
    {
      prefix: 'assets/translate/shared/',
      suffix: `.json?t=${new Date().getTime()}`,
    },
    {
      prefix: 'app/customisation/assets/translate/',
      suffix: `.json?t=${new Date().getTime()}`,
    },
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  declarations: [],
})
export class LocalizationModule {}
