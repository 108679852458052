import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SwapService } from '../../services/swap.service';
import { Subscription } from 'rxjs';
import { BroadcastEvent } from '../../models/dataContract.model';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss'],
  animations: [
    trigger('editMode', [
      state(
        'inactive',
        style({
          opacity: 0,
          transform: '{{inactiveTrans}}',
        }),
        { params: { inactiveTrans: 'translateX(150%)' } }
      ),
      state(
        'active',
        style({
          opacity: 1,
          transform: '{{activeTrans}}',
        }),
        { params: { activeTrans: 'translateX(0)' } }
      ),
      transition('inactive => active', animate(200)),
      transition('active => inactive', animate(200)),
    ]),
  ],
})
export class EditMenuComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  /** Edit menu direction (row or column) */
  @Input()
  direction = 'row';
  /** Edit menu align (start or end) */
  @Input()
  align = 'start';
  /** Edit menu buttons */
  @Input()
  buttons = ['setting', 'add', 'save'];
  /** Click save button can return to non-edit mode */
  @Input()
  saveAndReturn = true;
  @Input()
  buttonColor: string;

  /** Event emitter for action add */
  @Output()
  add = new EventEmitter();
  /** Event emitter for action cancel */
  @Output()
  cancel = new EventEmitter();
  /** Event emitter for action edit */
  @Output()
  edit = new EventEmitter();
  /** Event emitter for action refresh */
  @Output()
  refresh = new EventEmitter();
  /** Event emitter for action save */
  @Output()
  save = new EventEmitter();
  /** Event emitter for action setting */
  @Output()
  setting = new EventEmitter();

  /** Edit mode (inactive, active) */
  editMode = 'inactive';
  /** Main icon (edit, cancel) */
  mainIcon = 'assignment';
  /** Main icon tooltip (key_edit, key_cancel) */
  mainIconText = 'key_edit';
  /** Transition for inactive state (row: translateX(50%), column: translateY(-50%)) */
  transInactive = 'translateX(150%)';
  /** Transition for active state (row: translateX(0), column: translateY(0)) */
  transActive = 'translateX(0)';

  /** @ignore */
  constructor(private swap: SwapService) {
    this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
      if (event) {
        switch (event.name) {
          case 'toggle-edit-mode':
            this.toggleEditMode(false);
            break;
          case 'exit-edit-mode':
            this.exitEditMode(event.parameter === true);
            break;
          default:
            break;
        }
      }
    });
  }

  /** Set initial state */
  ngOnInit() {
    if (this.direction === 'row') {
      this.transInactive =
        this.align === 'end' ? 'translateX(50%)' : 'translateX(-50%)';
      this.transActive = 'translateX(0)';
    } else {
      this.transInactive =
        this.align === 'end' ? 'translateY(-50%)' : 'translateX(50%)';
      this.transActive = 'translateY(0)';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  exitEditMode(withEvent = true) {
    if (this.editMode === 'active') {
      this.editMode = 'inactive';
      this.mainIcon = 'assignment';
      this.mainIconText = 'key_edit';
      if (withEvent) {
        this.cancel.emit();
      }
    }
  }

  /** Toggle state change between inactive and active */
  toggleEditMode(withEvent = true) {
    this.editMode = this.editMode === 'inactive' ? 'active' : 'inactive';
    this.mainIcon = this.editMode === 'inactive' ? 'assignment' : 'cancel';
    this.mainIconText =
      this.editMode === 'inactive' ? 'key_edit' : 'key_cancel';

    if (withEvent) {
      this.editMode === 'inactive' ? this.cancel.emit() : this.edit.emit();
    }
  }

  /** Emit add event */
  onAdd() {
    this.add.emit();
  }

  /** Emit refresh action */
  onRefresh() {
    this.refresh.emit();
  }

  /** Emit save action */
  onSave() {
    if (this.saveAndReturn) {
      this.toggleEditMode(false);
    }
    this.save.emit();
  }

  /** Emit setting action */
  onSetting() {
    this.setting.emit();
  }
}
