import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApprovalViewCloudConfig } from '../../models/componentContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';

@Component({
  selector: 'app-approval-check-cloud-config',
  templateUrl: './approval-check-cloud-config.component.html',
  styleUrls: ['./approval-check-cloud-config.component.scss'],
})
export class ApprovalCheckCloudConfigComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: DynamicComponent;
      config: ApprovalViewCloudConfig;
    }
  ) {}

  onRefresh() {
    this.data.component.updateDataSource();
  }
}
