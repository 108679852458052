import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, finalize, tap } from 'rxjs/operators';
import {
  ModalType,
  ResourceTableConfig,
} from '../../models/componentContract.model';
import {
  PopupConfig,
  Resource,
  ResourceSet,
} from '../../models/dataContract.model';
import { CustomComponent } from '../../models/dynamicEditor.interface';
import { ModalService } from '../../services/modal.service';
import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-approval-delegation',
  templateUrl: './approval-delegation.component.html',
  styleUrls: ['./approval-delegation.component.scss'],
})
export class ApprovalDelegationComponent
  extends CustomComponent
  implements OnInit, OnDestroy
{
  private currentObjectID: string;

  tableConfig: ResourceTableConfig;

  delegators: Resource[] = [];

  private buildDelegatorList() {
    this.subscription.add(
      this.resource
        .getResourceByQuery(
          `/assignment[assignmentType='ApprovalDelegate' and sourceRef='${this.currentObjectID}']`,
          ['TargetRefs', 'ValidFrom', 'ValidTo', 'IsEnabled'],
          0,
          0,
          true
        )
        .pipe(
          tap((result: ResourceSet) => {
            if (result && result.results && result.results.length > 0) {
              this.delegators = result.results;
            } else {
              this.delegators = [];
            }
          })
        )
        .subscribe()
    );
  }

  constructor(
    injector: Injector,
    private utils: UtilsService,
    private modal: ModalService,
    private resource: ResourceService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.currentObjectID = this.utils.ExtraValue(
      this.currentResource,
      'ObjectID:value'
    );

    this.buildDelegatorList();

    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.objectType = 'assignment';
    this.tableConfig.fontSize = 13;
    this.tableConfig.tableHeight = 500;
    this.tableConfig.resizable = true;
    this.tableConfig.query = `/assignment[assignmentType='ApprovalDelegate' and targetRefs='${this.currentObjectID}']`;
    this.tableConfig.columns = [
      {
        title: '',
        field: 'isenabled',
        width: -1,
      },
      {
        title: 'key_userName',
        field: 'sourceRef',
        width: 0,
        filterable: true,
        filter: 'text',
      },
      {
        field: 'validfrom',
        width: 170,
        filterable: true,
        filter: 'date',
        sortable: true,
      },
      {
        field: 'validto',
        width: 170,
        filterable: true,
        filter: 'date',
        sortable: true,
      },
      {
        title: ' ',
        field: 'isenabled',
        sortable: true,
        width: 50,
        showStatus: {
          true: {
            text: '',
            color: 'green',
          },
        },
        fallbackStatus: {
          text: '',
          color: 'goldenrod',
        },
      },
    ];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAddDelegator() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_addDelegator';
    popupConfig.style = 'fill';
    popupConfig.singleButton = true;
    popupConfig.confirmButton = {
      text: 'key_add',
      color: 'primary',
    };
    popupConfig.data = {
      delegator: {
        text: 'key_delegator',
        type: 'reference',
        required: true,
        value: {
          dataType: 'Reference',
          displayName: 'key_delegator',
          description: '',
          multivalued: false,
          permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
          systemName: 'ApprovalDelegator',
          value: null,
        },
        config: {
          showPhoto: true,
          popupWidth: 460,
          queryNormalSearch: `/Person[not(ObjectID='${this.currentObjectID}') and (starts-with(DisplayName,'%SearchText%') or starts-with(AccountName,'%SearchText%'))]`,
          allowEmptySearch: true,
          queryEmptySearch: `/Person[not(ObjectID='${this.currentObjectID}')]`,
          attributesToShow: [
            {
              field: 'DisplayName',
              sortable: true,
              filterable: true,
              filter: 'text',
              width: 0,
            },
            {
              field: 'AccountName',
              sortable: true,
              filterable: true,
              filter: 'text',
              width: 0,
            },
          ],
        },
      },
      enabled: {
        text: 'key_enabled',
        type: 'boolean',
        value: true,
      },
      validFrom: {
        text: 'key_from',
        type: 'date',
        value: {
          dataType: 'DateTime',
          displayName: 'key_from',
          description: '',
          multivalued: false,
          permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
          systemName: 'ValidFrom',
          value: null,
        },
        config: { showTime: true },
      },
      validTo: {
        text: 'key_to',
        type: 'date',
        value: {
          dataType: 'DateTime',
          displayName: 'key_to',
          description: '',
          multivalued: false,
          permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
          systemName: 'ValidTo',
          value: null,
        },
        config: { showTime: true },
      },
    };

    let savingProgress: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (windowResult instanceof WindowCloseResult) {
              return EMPTY;
            } else {
              savingProgress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );

              const resourceToCreate = {
                displayname: `Approval-Delegation: ${this.utils.ExtraValue(
                  windowResult,
                  'delegator:value:DisplayName'
                )} as delegator for ${this.utils.ExtraValue(
                  this.currentResource,
                  'DisplayName:value'
                )}`,
                objecttype: 'assignment',
                assignmenttype: 'ApprovalDelegate',
                sourceref: this.currentObjectID,
                targetrefs: [
                  this.utils.ExtraValue(
                    windowResult,
                    'delegator:value:ObjectID'
                  ),
                ],
                isenabled: windowResult.enabled.value,
                validfrom: windowResult.validFrom.value,
                validto: windowResult.validTo.value,
              };
              return this.resource.createResource(resourceToCreate);
            }
          }),
          tap(() => {
            this.buildDelegatorList();
          }),
          catchError((error: HttpErrorResponse) => {
            this.modal.show(ModalType.error, 'key_error', error.error);
            if (savingProgress) {
              savingProgress.close();
            }
            return EMPTY;
          }),
          finalize(() => {
            if (savingProgress) {
              savingProgress.close();
            }
          })
        )
        .subscribe()
    );
  }

  onToggleAssignment(item: Resource) {
    if (!item) return;

    const resourceToUpdate = {};
    Object.keys(item).forEach((key) => {
      const keyName = key.toLowerCase();
      switch (keyName) {
        case 'objectid':
        case 'objecttype':
        case 'isenabled':
          resourceToUpdate[key] = item[key];
          break;
        default:
          break;
      }
    });

    const savingProgress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );
    this.subscription.add(
      this.resource
        .updateResource(resourceToUpdate)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.modal.show(ModalType.error, 'key_error', error.error);
            if (savingProgress) {
              savingProgress.close();
            }
            return EMPTY;
          }),
          finalize(() => {
            if (savingProgress) {
              savingProgress.close();
            }
          })
        )
        .subscribe()
    );
  }

  onDeleteAssignment(item: Resource) {
    let savingProgress: MatDialogRef<ModalComponent, any>;

    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'key_confirmDeleteResource'
    );
    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              savingProgress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );

              const idToDelete = this.utils.ExtraValue(item, 'ObjectID');
              if (idToDelete) {
                return this.resource.deleteResource(idToDelete);
              } else {
                return EMPTY;
              }
            } else {
              return EMPTY;
            }
          }),
          tap(() => {
            this.buildDelegatorList();
          }),
          catchError((error: HttpErrorResponse) => {
            this.modal.show(ModalType.error, 'key_error', error.error);
            if (savingProgress) {
              savingProgress.close();
            }
            return EMPTY;
          }),
          finalize(() => {
            if (savingProgress) {
              savingProgress.close();
            }
          })
        )
        .subscribe()
    );
  }
}
