<div [ngSwitch]="filterType">
  <div *ngSwitchCase="'boolean'" fxLayout="column">
    <mat-radio-group [(ngModel)]="filterBoolean" (change)="onBooleanChange()">
      <div fxLayout="column" fxLayoutGap="20">
        <mat-radio-button [value]="true">{{
          'key_true' | translate | uppercase
        }}</mat-radio-button>
        <mat-radio-button [value]="false">{{
          'key_false' | translate | uppercase
        }}</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div *ngSwitchCase="'text'" fxLayout="column">
    <kendo-dropdownlist
      [(ngModel)]="filterOperator"
      [data]="operators"
      textField="text"
      valueField="value"
      (valueChange)="onOperatorChange()"
    ></kendo-dropdownlist>
    <input
      *ngIf="filterType === 'text'"
      class="k-textbox"
      appAutoFocus
      [disabled]="filterTextDisabled"
      [(ngModel)]="filterText"
      (input)="onInput()"
    />
  </div>
  <div *ngSwitchCase="'numeric'" fxLayout="column">
    <kendo-dropdownlist
      [(ngModel)]="filterOperator"
      [data]="operators"
      textField="text"
      valueField="value"
      (valueChange)="onOperatorChange()"
    ></kendo-dropdownlist>
    <input
      type="number"
      class="k-textbox"
      appAutoFocus
      [disabled]="filterTextDisabled"
      [(ngModel)]="filterText"
      (input)="onInput()"
    />
  </div>
  <div *ngSwitchCase="'date'" fxLayout="column">
    <div style="font-weight: 400; margin-top: 20px">
      {{ 'key_from' | translate }}
    </div>
    <kendo-datepicker
      [(value)]="filterDateAfter"
      (valueChange)="onDateChange()"
      [format]="format"
      formatPlaceholder="formatPattern"
      [popupSettings]="popupSettings"
      style="margin-top: -8px"
    >
      <kendo-datepicker-messages
        [today]="'key_calendarToday' | translate"
        [toggle]="'key_calendarToggle' | translate"
      ></kendo-datepicker-messages>
    </kendo-datepicker>
    <div style="font-weight: 400">{{ 'key_to' | translate }}</div>
    <kendo-datepicker
      [(value)]="filterDateBefore"
      (valueChange)="onDateChange()"
      [format]="format"
      formatPlaceholder="formatPattern"
      [popupSettings]="popupSettings"
      style="margin-top: -8px"
    >
      <kendo-datepicker-messages
        [today]="'key_calendarToday' | translate"
        [toggle]="'key_calendarToggle' | translate"
      ></kendo-datepicker-messages>
    </kendo-datepicker>
  </div>
</div>
