// Angular
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ComponentRef,
  AfterViewInit,
  ComponentFactoryResolver,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { tap, switchMap, delay } from 'rxjs/operators';

// Core
import {
  ActionMenuItem,
  Resource,
  AttributeResource,
  MenuEvent,
  BroadcastEvent,
} from '../core/models/dataContract.model';
import { ConfigService } from '../core/services/config.service';
import { ExtraValuePipe } from '../core/pipes/extra-value.pipe';
import { ResourceService } from '../core/services/resource.service';
import { TransService } from '../core/models/translation.model';
import { UtilsService } from '../core/services/utils.service';
import { SwapService } from '../core/services/swap.service';
import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {
  ComponentItem,
  ModalType,
} from '../core/models/componentContract.model';
import { ModalService } from '../core/services/modal.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { DynamicContainerDirective } from '../core/directives/dynamic-container.directive';
import { ComponentService } from '../core/services/component.service';
import { CustomComponent } from '../core/models/dynamicEditor.interface';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';

// Component
@Component({
  selector: 'app-brand-view',
  styleUrls: ['./brand-view.component.scss'],
  templateUrl: './brand-view.component.html',
})

// Brand View
export class BrandViewComponent implements OnInit, AfterViewInit, OnDestroy {
  // Bindings
  @Input() options: {
    attributes: Array<{
      name?: string;
      icon?: string;
      iconAlt?: string;
      text?: string;
      textAlt?: string;
      hidden?: boolean;
      type?: string;
      value?: boolean;
      hasValue?: boolean;
      isRef?: boolean;
    }>;
    mainAttribute: string;
    photoAttribute?: string;
    secondaryAttribute?: string;
    showButtons?: boolean;
    useCustomComponent?: boolean;
    customComponentID?: string;
    customComponentData?: any;
    menuPositionX?: MenuPositionX;
    menuPositionY?: MenuPositionY;
  } = {
    attributes: [],
    mainAttribute: null,
    photoAttribute: null,
    secondaryAttribute: null,
    showButtons: false,
    useCustomComponent: false,
    customComponentID: null,
    customComponentData: {},
  };

  @Input() actionItems: Array<ActionMenuItem> = [];

  @Input() iconMode = false;

  // View-Childs
  @ViewChild('actionMenu') actionMenu: ActionMenuComponent;

  @ViewChild(DynamicContainerDirective)
  componentContainer: DynamicContainerDirective;

  componentItem: ComponentItem;
  componentRef: ComponentRef<any>;

  // Values
  attributesToLoad: Array<string> = ['DisplayName'];
  currentResource: Resource;
  initial: string;

  blurLevel = this.config.getConfig('blurLevel', 1);

  initHidden = true;

  // Privates
  private subscription = new Subscription();

  private refreshToken = new Subject();

  // Constructor
  constructor(
    private resource: ResourceService,
    private route: ActivatedRoute,
    private translate: TransService,
    private extraValuePipe: ExtraValuePipe,
    private config: ConfigService,
    private modal: ModalService,
    private utils: UtilsService,
    private swap: SwapService,
    private com: ComponentService,
    private cfr: ComponentFactoryResolver
  ) {}

  // Helper Functions
  private buildInitialName(resource: Resource) {
    // Setup
    const displayName = this.extraValuePipe.transform(
      resource,
      'DisplayName:value'
    );
    let ret = '?';

    // Build Initials
    if (displayName && displayName.length > 0) {
      ret = (
        displayName.length > 1 ? displayName.substr(0, 2) : displayName[0]
      ).toUpperCase();
    }

    // Return
    return ret;
  }

  // Angular Functions
  ngOnInit() {
    if (this.iconMode) {
      setTimeout(() => {
        this.initHidden = false;
      }, this.config.getConfig('intervalUltra', 2000));
    }

    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          tap((event: BroadcastEvent) => {
            if (event && event.name === 'refresh-attribute') {
              this.refreshToken.next(undefined);
            }
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit(): void {
    this.attributesToLoad = ['DisplayName'];

    this.subscription.add(
      this.refreshToken
        .pipe(
          tap(() => {
            // Attributes to load
            if (!this.options.mainAttribute) {
              this.options.mainAttribute = 'DisplayName';
            }
            if (this.attributesToLoad.indexOf(this.options.mainAttribute) < 0) {
              this.attributesToLoad.push(this.options.mainAttribute);
            }

            if (!this.options.photoAttribute) {
              this.options.photoAttribute = 'Photo';
            }
            if (
              this.attributesToLoad.indexOf(this.options.photoAttribute) < 0
            ) {
              this.attributesToLoad.push(this.options.photoAttribute);
            }

            if (this.options.secondaryAttribute) {
              if (
                this.attributesToLoad.indexOf(this.options.secondaryAttribute) <
                0
              ) {
                this.attributesToLoad.push(this.options.secondaryAttribute);
              }
            }
            if (this.options.attributes) {
              this.options.attributes.forEach((a) => {
                if (a.name && this.attributesToLoad.indexOf(a.name) < 0) {
                  this.attributesToLoad.push(a.name);
                }
              });
            }
          }),
          switchMap(() => {
            const objectID = this.route.snapshot.paramMap.get('id');
            return this.resource.getResourceByID(
              objectID,
              this.attributesToLoad,
              'full',
              this.config.getCulture(this.translate.currentCulture),
              'true'
            );
          }),
          tap((result: Resource) => {
            // Update page properties
            this.currentResource = result;
            this.initial = this.buildInitialName(result);

            // Update attribute values
            this.options.attributes.forEach((a) => {
              const rs: AttributeResource = this.utils.ExtraValue(
                result,
                a.name
              );
              a.hasValue = rs && this.utils.ExamValue(rs, 'value:DisplayName');
              if (rs && rs.dataType) {
                a.type = rs.dataType;
                if (a.type.toLowerCase() === 'boolean') {
                  a.value = this.utils.ExtraValue(rs, 'value');
                }
                if (a.type.toLowerCase() === 'reference') {
                  a.isRef = true;
                }
              }
            });

            setTimeout(() => {
              // Load custom component
              if (
                this.options.useCustomComponent &&
                this.options.customComponentID
              ) {
                this.componentItem = this.com.getCustomComponent(
                  this.options.customComponentID
                );
                if (
                  this.componentContainer &&
                  this.componentItem &&
                  this.componentItem.component
                ) {
                  // get component
                  const componentFactory = this.cfr.resolveComponentFactory(
                    this.componentItem.component
                  );
                  // get container
                  const viewContainerRef =
                    this.componentContainer.viewContainerRef;
                  viewContainerRef.clear();
                  // load component
                  this.componentRef =
                    viewContainerRef.createComponent<CustomComponent>(
                      componentFactory
                    );

                  // init component with attribute value
                  if (this.componentRef.instance) {
                    this.componentRef.instance.data =
                      this.options.customComponentData ??
                      this.componentItem.data;
                    this.componentRef.instance.currentResource =
                      this.currentResource;
                  }
                }
              }
            });

            // Send build menu event
            this.swap.menuEvent(
              new MenuEvent(
                'build',
                'brandview',
                '',
                this.currentResource,
                this.actionMenu,
                this.route.snapshot.paramMap.get('type')
              )
            );
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.route.params
        .pipe(
          delay(0),
          tap(() => {
            this.refreshToken.next(undefined);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // User Functions
  getPhotoAttribute() {
    if (this.currentResource && this.options && this.options.photoAttribute) {
      return this.utils.ExtraValue(
        this.currentResource,
        this.options.photoAttribute
      );
    } else {
      return null;
    }
  }

  onMenuAction(event: any) {
    this.swap.menuEvent(
      new MenuEvent(
        'action',
        'brandview',
        event,
        this.currentResource,
        null,
        this.route.snapshot.paramMap.get('type')
      )
    );
  }

  onMenuOpen() {
    this.swap.menuEvent(
      new MenuEvent(
        'open',
        'brandview',
        '',
        this.currentResource,
        this.actionMenu,
        this.route.snapshot.paramMap.get('type')
      )
    );
  }

  onPhotoDeleted() {
    // Start processing
    const process: MatDialogRef<ModalComponent, any> = this.modal.show(
      ModalType.progress,
      'key_processing',
      '',
      '300px'
    );

    // Setup
    const resourceToUpdate = {
      ObjectID: this.utils.ExtraValue(this.currentResource, 'ObjectID:value'),
      ObjectType: this.utils.ExtraValue(
        this.currentResource,
        'ObjectType:value'
      ),
      Photo: '',
    };

    // Delete photo
    this.subscription.add(
      this.resource.updateResource(resourceToUpdate).subscribe(
        (result: HttpResponse<string>) => {
          // Stop processing
          if (process) {
            process.close();
          }

          // Analyze result
          if (result.status === 202) {
            // Approval required
            this.modal.show(ModalType.info, 'key_info', 'key_approvalRequired');
          } else if (result.status === 200) {
            // Succeed
            const photo = this.utils.ExtraValue(this.currentResource, 'Photo');
            photo.value = '';
          }
        },
        (error: HttpErrorResponse) => {
          // Stop processing
          if (process) {
            process.close();
          }

          // Show error
          this.modal.show(ModalType.error, 'key_error', error.error);
        }
      )
    );
  }

  onPhotoUpdated(base64Image: string) {
    // Setup
    const process: MatDialogRef<ModalComponent, any> = this.modal.show(
      ModalType.progress,
      'key_processing',
      '',
      '300px'
    );
    const resourceToUpdate = {
      ObjectID: this.utils.ExtraValue(this.currentResource, 'ObjectID:value'),
      ObjectType: this.utils.ExtraValue(
        this.currentResource,
        'ObjectType:value'
      ),
      Photo: base64Image.substring(base64Image.indexOf(',') + 1),
    };

    // Upload picture to MIM
    this.subscription.add(
      this.resource.updateResource(resourceToUpdate).subscribe(
        (result: HttpResponse<string>) => {
          // Stop processing
          if (process) {
            process.close();
          }

          // Analyze result
          if (result) {
            if (result.status === 202) {
              // Approval required
              this.modal.show(
                ModalType.info,
                'key_info',
                'key_approvalRequired'
              );
            } else if (result.status === 200) {
              // Succeed
              const photo = this.utils.ExtraValue(
                this.currentResource,
                'Photo'
              );
              photo.value = resourceToUpdate.Photo;
            }
          }
        },
        (error: HttpErrorResponse) => {
          // Stop processing
          if (process) {
            process.close();
          }

          // Error
          this.modal.show(ModalType.error, 'key_error', error.error);
        }
      )
    );
  }
}
