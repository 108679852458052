<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_stateCardConfig' | translate }}
  </h3>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <div
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="first-dialog-item"
    >
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_stateCardIconText' | translate"
          [(ngModel)]="data.config.iconText"
        />
      </mat-form-field>
      <div
        fxFlex="0 0 auto"
        [(colorPicker)]="data.config.iconColor"
        cpPosition="right"
        [style.backgroundColor]="data.config.iconColor"
        class="color-cube"
      ></div>
      <div fxFlex="0 0 20px"></div>
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_stateCardTitle' | translate"
          [(ngModel)]="data.config.title"
        />
      </mat-form-field>
      <div
        fxFlex="0 0 auto"
        [(colorPicker)]="data.config.textColor"
        cpPosition="right"
        [style.backgroundColor]="data.config.textColor"
        class="color-cube"
      ></div>
    </div>
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_stateCardMainText' | translate"
          [(ngModel)]="data.config.mainText"
          required
        />
      </mat-form-field>
      <div
        fxFlex="0 0 auto"
        [(colorPicker)]="data.config.mainTextColor"
        cpPosition="right"
        [style.backgroundColor]="data.config.mainTextColor"
        class="color-cube"
      ></div>
      <div fxFlex="0 0 20px"></div>
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_backgroundColor' | translate"
          disabled
        />
      </mat-form-field>
      <div
        fxFlex="0 0 auto"
        [(colorPicker)]="data.config.backgroundColor"
        cpPosition="right"
        [style.backgroundColor]="data.config.backgroundColor"
        class="color-cube"
      ></div>
    </div>
    <div
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 10px; margin-bottom: 20px"
    >
      <mat-slide-toggle [(ngModel)]="data.config.useThemeColor">{{
        'key_useThemeColor' | translate
      }}</mat-slide-toggle>
    </div>
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
      <mat-radio-group
        [(ngModel)]="data.config.queryMode"
        fxFlex="auto"
        fxLayout="column"
      >
        <mat-radio-button value="counter" fxFlex="none"
          >query counter</mat-radio-button
        >
        <mat-radio-button value="attribute" fxFlex="none"
          >query attribute</mat-radio-button
        >
      </mat-radio-group>
      <mat-form-field fxFlex="auto" style="margin-bottom: -16px">
        <input
          matInput
          [placeholder]="'key_attributeName' | translate"
          [(ngModel)]="data.config.queryAttribute"
          [disabled]="data.config.queryMode === 'counter'"
          required
        />
      </mat-form-field>
    </div>
    <div fxFlex="none" style="margin-top: 30px">
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_query' | translate"
          [(ngModel)]="data.config.query"
        />
      </mat-form-field>
    </div>
    <div fxFlex="none" style="margin-top: 10px">
      <mat-form-field fxFlex="auto">
        <input
          matInput
          [placeholder]="'key_action' | translate"
          [(ngModel)]="data.config.action"
        />
      </mat-form-field>
    </div>
    <div fxFlex="none">
      <mat-form-field fxFlex="auto">
        <mat-chip-list #clAllowedSets>
          <mat-chip
            *ngFor="let alowedSet of data.config.permissionSets"
            [selectable]="true"
            [removable]="true"
            (removed)="onRemoveAllowedSet(alowedSet)"
          >
            {{ alowedSet }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            [placeholder]="'key_allowedSets' | translate"
            [matChipInputFor]="clAllowedSets"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="onAddAllowedSet($event)"
          />
        </mat-chip-list>
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
      [disabled]="
        !data.config.mainText ||
        (data.config.queryMode !== 'counter' && !data.config.queryAttribute)
      "
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      (click)="onRefresh()"
      [disabled]="
        !data.config.mainText ||
        (data.config.queryMode !== 'counter' && !data.config.queryAttribute)
      "
    >
      {{ 'key_apply' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
