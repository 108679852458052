// Angular
import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';

// Extensions
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WindowRef } from '@progress/kendo-angular-dialog';

// Services & Models
import { ConfigService } from '../../services/config.service';
import { ModalType } from '../../models/componentContract.model';
import { ModalService } from '../../services/modal.service';

// Component
@Component({
  selector: 'app-photo-cropper',
  templateUrl: './photo-cropper.component.html',
  styleUrls: ['./photo-cropper.component.scss'],
})

// Class
export class PhotoCropperComponent implements AfterViewInit {
  // Values
  aspectRatio: number = this.config.getConfig('photoWidthToHeightRatio', 1 / 1);
  croppedImage: any = '';
  imageChangedEvent: any = '';

  // View-Children
  @ViewChild('titleBar') titleBar: TemplateRef<any>;

  // Constructor
  constructor(
    private config: ConfigService,
    private modal: ModalService,
    private windowRef: WindowRef
  ) {}

  // Angular
  ngAfterViewInit(): void {
    if (this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  // Cropper Functions
  cropperReady() {
    // Cropper ready
  }
  fileChangeEvent(event: any, Upload): void {
    // Setup
    const file = Upload.files[0];
    if (!file) {
      return;
    }

    // Check file extension
    if (
      !file.name.endsWith('.jpg') &&
      !file.name.endsWith('.jpeg') &&
      !file.name.endsWith('.png')
    ) {
      this.modal.show(ModalType.error, 'key_error', 'l10n_fileTypeNotAllowed');
      return;
    }

    // Event-Sequencer
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  loadImageFailed() {
    console.warn('photo-cropper: load image failed');
  }

  // Window Functions
  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }
  onClose() {
    if (this.windowRef) {
      this.windowRef.close(this.croppedImage);
    }
  }
}
