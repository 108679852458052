<div style="margin-top: 10px">
  <app-editor-identity
    #idpDataflow
    name="idpDataFlowId"
    [creationMode]="true"
    [(ngModel)]="targetDataflow"
    [creationMode]="true"
    [config]="rdpConfig"
    (valueChange)="onDataflowChange($event)"
  ></app-editor-identity>
  <mat-checkbox
    [(ngModel)]="disableFurtherDataflowTriggering"
    style="margin-top: 20px"
    >{{ 'key_disableFurtherDataflowTriggering' | translate }}</mat-checkbox
  >
</div>
