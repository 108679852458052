<div *ngIf="data === 'creation'">
  <mat-radio-group [(ngModel)]="componentValue" (change)="onChange()">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div fxFlex="50" fxLayout="column">
        <img
          fxFlex="none"
          src="../../../../assets/img/dataflow_sit.png"
          class="type-image"
        />
        <mat-radio-button
          fxFlex="50"
          value="SourceReferenceInTarget"
          style="margin-top: 10px"
          >{{ 'key_dataflowSIT' | translate }}</mat-radio-button
        >
      </div>
      <div fxFlex="50" fxLayout="column">
        <img
          fxFlex="none"
          src="../../../../assets/img/dataflow_tis.png"
          class="type-image"
        />
        <mat-radio-button
          fxFlex="50"
          value="TargetReferenceInSource"
          style="margin-top: 10px"
          >{{ 'key_dataflowTIS' | translate }}</mat-radio-button
        >
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      style="margin-top: 20px"
    >
      <div fxFlex="50" fxLayout="column">
        <img
          fxFlex="none"
          src="../../../../assets/img/dataflow_sii.png"
          class="type-image"
        />
        <mat-radio-button
          fxFlex="50"
          value="SourceReferenceInIntermediate"
          style="margin-top: 10px"
          >{{ 'key_dataflowSII' | translate }}</mat-radio-button
        >
      </div>
      <div fxFlex="50" fxLayout="column">
        <img
          fxFlex="none"
          src="../../../../assets/img/dataflow_iis.png"
          class="type-image"
        />
        <mat-radio-button
          fxFlex="50"
          value="IntermediateReferenceInSource"
          style="margin-top: 10px"
          >{{ 'key_dataflowIIS' | translate }}</mat-radio-button
        >
      </div>
    </div>
  </mat-radio-group>
</div>
<div *ngIf="data === 'editing'" fxLayout="row" fxLayoutAlign="center center">
  <div [ngSwitch]="dataflowType">
    <img
      *ngSwitchCase="'SourceReferenceInTarget'"
      fxFlex="none"
      src="../../../../assets/img/dataflow_sit.png"
      class="type-image"
    />
    <img
      *ngSwitchCase="'TargetReferenceInSource'"
      fxFlex="none"
      src="../../../../assets/img/dataflow_tis.png"
      class="type-image"
    />
    <img
      *ngSwitchCase="'SourceReferenceInIntermediate'"
      fxFlex="none"
      src="../../../../assets/img/dataflow_sii.png"
      class="type-image"
    />
    <img
      *ngSwitchCase="'IntermediateReferenceInSource'"
      fxFlex="none"
      src="../../../../assets/img/dataflow_iis.png"
      class="type-image"
    />
  </div>
</div>
