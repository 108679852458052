import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';

import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';

import {
  BasicResource,
  Resource,
  ActionMenuItem,
  BroadcastEvent,
  ResourceSet,
} from '../../models/dataContract.model';

import { ConfigService } from '../../services/config.service';
import { UtilsService } from '../../services/utils.service';
import { SwapService } from '../../services/swap.service';
import { ResourceService } from '../../services/resource.service';
import { TransService } from '../../models/translation.model';

@Component({
  selector: 'app-identity-link[identity]',
  templateUrl: './identity-link.component.html',
  styleUrls: ['./identity-link.component.scss'],
})
export class IdentityLinkComponent implements OnInit, OnChanges, OnDestroy {
  private subscription = new Subscription();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input()
  identity: BasicResource & Resource;

  @Input()
  xpath: string;

  // action mode:
  //  native - open native portal popup
  //  popup - open ui popup
  //  sideView - open side view
  //  navigate - navigate to identity
  @Input()
  defaultAction: string;

  @Input()
  menuActions: Array<ActionMenuItem> = [];

  @Input()
  linkActions: string[] = [];

  @Input()
  navigationKey: string = undefined;

  @Input()
  noneForm = false;

  @Input()
  readOnly = false;

  @Input()
  disabled = false;

  @Input()
  color = '';

  @Input()
  historyTimestamp: string = undefined;

  @Input()
  historyReportName: string = undefined;

  @Input()
  scope: string = undefined;

  @Output()
  action = new EventEmitter();

  countBeforeEllipsis = 50;

  private supportedLinkActions = ['sideView', 'navigate', 'history'];

  private takeAction(name: string, defaultAction = false) {
    if (this.identity) {
      this.swap.linkEvent(
        new BroadcastEvent(name, this.identity, {
          defaultAction,
          scope: this.scope,
          navigationKey: this.navigationKey,
          noneForm: this.noneForm,
          readOnly: this.readOnly,
          historyTimestamp: this.historyTimestamp,
          historyReportName: this.historyReportName,
        })
      );
    }
  }

  hasID(item: BasicResource & Resource): boolean {
    const val = this.utils.ExtraValue(item, 'ObjectID');
    if (val) {
      return true;
    } else {
      return false;
    }
  }

  hasType(item: BasicResource & Resource): boolean {
    const val = this.utils.ExtraValue(item, 'ObjectType');
    if (val) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    private config: ConfigService,
    private utils: UtilsService,
    private swap: SwapService,
    private resource: ResourceService,
    private translate: TransService
  ) {}

  ngOnInit(): void {
    this.countBeforeEllipsis = this.config.getConfig('countBeforeEllipsis', 50);

    if (!this.defaultAction) {
      this.defaultAction = this.config.getConfig(
        'defaultLinkAction',
        'navigate'
      );
    }

    const predefinedActions: string[] = this.config.getConfig(
      'linkActions',
      null
    );
    if (predefinedActions) {
      if (this.linkActions.length === 0) {
        this.linkActions = predefinedActions;
      } else {
        for (const [index, action] of this.linkActions.entries()) {
          if (this.supportedLinkActions.indexOf(action) < 0) {
            this.linkActions.splice(index, 1);
          }
        }
      }
    }

    if (this.linkActions && this.linkActions.length > 0) {
      this.menuActions = this.linkActions.map((a) => {
        if (this.utils.idlActions[a]) {
          return this.utils.idlActions[a];
        }
      });
    }
  }

  ngOnChanges(changes: any) {
    if (changes.identity && this.identity) {
      const dn = this.utils.ExtraValue(this.identity, 'DisplayName');
      if (dn === undefined) {
        const id = this.utils.ExtraValue(this.identity, 'ObjectID:value');
        if (id && typeof id === 'string') {
          this.subscription.add(
            this.resource
              .getResourceByID(id, ['DisplayName'])
              .pipe(
                tap((result: any) => {
                  if (result) {
                    const displayname = this.utils.ExtraValue(
                      result,
                      'DisplayName:value'
                    );
                    if (displayname) {
                      this.identity.DisplayName = displayname;
                    }

                    const objecttype = this.utils.ExtraValue(
                      result,
                      'ObjectType:value'
                    );
                    if (objecttype) {
                      this.identity.ObjectType = objecttype;
                    }
                  }
                }),
                catchError(() => {
                  this.identity.DisplayName = id;
                  return EMPTY;
                })
              )
              .subscribe()
          );
        }
      }
    }
    if (changes.xpath && this.xpath) {
      this.subscription.add(
        this.resource
          .getResourceByQuery(this.xpath, ['DisplayName'])
          .pipe(
            tap((results: ResourceSet) => {
              if (results && results.results && results.results.length > 0) {
                this.identity = results.results[0];
              }
            }),
            catchError(() => {
              return EMPTY;
            })
          )
          .subscribe()
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getDisplayName() {
    if (this.identity) {
      const dn = this.utils.ExtraValue(this.identity, 'DisplayName:value');
      return dn || this.translate.instant('key_noDisplayNamePlaceHolder');
    }
    return '';
  }

  getTooltip(item: any) {
    const value = this.utils.ExtraValue(item, 'DisplayName:value');
    if (
      value &&
      this.countBeforeEllipsis > 0 &&
      value.length > this.countBeforeEllipsis
    ) {
      return value;
    }

    return null;
  }

  onDefaultAction() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }

    if (this.disabled) {
      return;
    }

    this.takeAction(this.defaultAction, true);
  }

  onAction(actionItem: ActionMenuItem) {
    if (this.disabled) {
      return;
    }

    if (this.action.observers.length > 0) {
      this.action.emit(actionItem);
    } else {
      this.takeAction(actionItem.name);
    }
  }

  onDelayedHover() {
    if (this.disabled) {
      return;
    }

    if (this.trigger) {
      if (this.linkActions && this.linkActions.length > 0) {
        this.trigger.openMenu();
      }
    }
  }

  onMouseOutMenu() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  onContextMenu(e: Event) {
    e.preventDefault();

    if (this.disabled) {
      return;
    }

    if (this.trigger) {
      if (this.linkActions && this.linkActions.length > 0) {
        this.trigger.openMenu();
      }
    }
  }
}
