<button
  *ngIf="menuType === 'icon'"
  mat-icon-button
  [color]="menuColor"
  [matMenuTriggerFor]="menu"
  [matTooltip]="menuTooltip"
  (menuOpened)="onMenuOpen()"
  style="height: 100%; width: 100%; line-height: 100%"
>
  <mat-icon
    *ngIf="menuIcon"
    [ngClass]="{ 'icon-mode': iconMode, 'accent-color': iconMode }"
    >{{ menuIcon }}</mat-icon
  >
</button>
<button
  *ngIf="menuType === 'button'"
  mat-stroked-button
  [color]="menuColor"
  [matMenuTriggerFor]="menu"
  [matTooltip]="menuTooltip"
  (menuOpened)="onMenuOpen()"
>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-icon fxFlex="none" *ngIf="menuIcon">{{ menuIcon }}</mat-icon>
    <div fxFlex="auto">
      {{ menuText }}
    </div>
  </div>
</button>
<mat-menu
  #menu="matMenu"
  [xPosition]="xPosition ?? 'after'"
  [yPosition]="yPosition ?? 'below'"
>
  <ng-container>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="isLoading"
      style="height: 100px; width: 100%"
    >
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
      <div *ngFor="let item of menuItems">
        <mat-divider
          *ngIf="item.name === 'mat-divider'; else noDivider"
          style="margin-bottom: 5px"
        ></mat-divider>
        <ng-template #noDivider>
          <button
            *ngIf="
              !(
                item.hidden ||
                (item.hideIfNoPermission && !showItem(item.permissionSets))
              )
            "
            mat-menu-item
            [disabled]="
              item.disabled ||
              (!item.hideIfNoPermission && !showItem(item.permissionSets))
            "
            [matTooltip]="item.tooltip"
            (click)="onMenuItemClick(item.name)"
            [style.marginBottom.px]="item.hint ? 5 : 0"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon
                fxFlex="none"
                *ngIf="item.icon"
                [style.opacity]="item.disabled ? 0.2 : 1"
                [ngClass]="
                  item.color === 'primary'
                    ? 'primary-color'
                    : item.color === 'accent'
                    ? 'accent-color'
                    : item.color === 'warn'
                    ? 'warn-color'
                    : ''
                "
                >{{ item.icon }}</mat-icon
              >
              <div fxFlex="auto" fxLayout="column">
                <div style="line-height: 20px">
                  {{ item.text | translate }}
                </div>
                <div
                  *ngIf="item.hint"
                  style="line-height: 20px; font-size: 12px"
                >
                  {{ item.hint | translate }}
                </div>
              </div>
            </div>
          </button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</mat-menu>
