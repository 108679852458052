<div>
  <h3 *ngIf="!dialogMode" mat-dialog-title cdkDragHandle>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon fxFlex="none" style="margin-right: 10px">{{
        viewSetting ? viewSetting.icon : 'warning'
      }}</mat-icon>
      <div fxFlex="none">
        {{
          (viewSetting && viewSetting.displayName
            ? viewSetting.displayName
            : 'key_creationWizard'
          ) | translate
        }}
      </div>
      <div fxFlex="auto"></div>
      <button
        *ngIf="dialogMode"
        mat-icon-button
        mat-dialog-close="cancel"
        tabindex="-1"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </h3>
  <div
    *ngIf="!hasViewSetting"
    fxLayout="column"
    fxLayoutAlign="center center"
    [style.padding-left.px]="leftPadding"
    style="height: 160px; margin-top: 40px"
  >
    <mat-icon
      fxFlex="auto"
      style="font-size: 100px; width: 100px; color: #cccccc"
      >contact_support</mat-icon
    >
    <div
      fxFlex="auto"
      style="margin-top: 50px; font-size: 18px; color: #b0b0b0"
    >
      {{ 'key_noConfiguration' | translate }}
    </div>
  </div>
  <div
    *ngIf="hasViewSetting"
    [style.padding-left.px]="leftPadding"
    style="margin-top: 10px"
  >
    <mat-horizontal-stepper
      *ngIf="data.config.type === 'stepper'"
      [linear]="true"
      #stepper
    >
      <mat-step
        *ngFor="let stepDef of viewSetting.sections"
        [completed]="!stepHasError()"
      >
        <ng-template matStepLabel>{{
          stepDef.displayName | translate
        }}</ng-template>
        <ng-template matStepContent>
          <app-attribute-view
            #attributeView
            [configMode]="configMode"
            [creationMode]="true"
            [attributeDefs]="stepDef.attributes"
            [columnNumber]="1"
            [tabName]="stepDef.name"
            [parameters]="data.parameters"
            [(attributeArray)]="viewResults[stepDef.name]"
            [(resourceToCreate)]="resourceToCreate"
          ></app-attribute-view>
        </ng-template>
      </mat-step>
    </mat-horizontal-stepper>
    <div *ngIf="data.config.type === 'dialog'" style="margin-left: 20px">
      <app-attribute-view
        #attributeView
        [configMode]="configMode"
        [creationMode]="true"
        [attributeDefs]="viewSetting.sections[0].attributes"
        [columnNumber]="1"
        [tabName]="viewSetting.sections[0].name"
        [parameters]="data.parameters"
        [(attributeArray)]="viewResults[viewSetting.sections[0].name]"
        [(resourceToCreate)]="resourceToCreate"
      ></app-attribute-view>
    </div>
  </div>
  <div
    *ngIf="hasViewSetting"
    fxLayout="row"
    fxLayoutAlign="start center"
    [style.width.px]="configMode ? data.config.width : data.config.width - 80"
    [style.padding-left.px]="configMode ? 0 : leftPadding"
    [ngClass]="{
      'button-normal-mode': !configMode,
      'button-config-mode': configMode
    }"
  >
    <div
      fxFlex="none"
      *ngIf="
        data.config.type === 'stepper' &&
        (configMode || viewSetting.sections.length > 1)
      "
    >
      <button
        mat-stroked-button
        color="basic"
        (click)="onBack()"
        [disabled]="isFirstStep"
        style="margin-right: 20px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="left center"
          style="margin-left: -10px"
        >
          <mat-icon fxFlex="none">chevron_left</mat-icon>
          <div fxFlex="none">
            {{ 'key_back' | translate }}
          </div>
        </div>
      </button>
    </div>
    <div
      fxFlex="none"
      *ngIf="
        data.config.type === 'stepper' &&
        (configMode || viewSetting.sections.length > 1)
      "
    >
      <button
        mat-stroked-button
        color="basic"
        [disabled]="isLastStep || stepHasError()"
        (click)="onNext()"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="right center"
          style="margin-right: -10px"
        >
          <div fxFlex="none">
            {{ 'key_next' | translate }}
          </div>
          <mat-icon fxFlex="none">chevron_right</mat-icon>
        </div>
      </button>
    </div>
    <div fxFlex="auto" *ngIf="data.config.type === 'stepper'"></div>
    <mat-button-toggle-group
      fxFlex="none"
      *ngIf="!configMode && data.config.isCreation"
      style="margin-left: 20px"
      [(ngModel)]="actionAfterCreation"
    >
      <mat-button-toggle
        value="stay"
        [matTooltip]="'key_stayAfterCreation' | translate"
      >
        <mat-icon style="color: darkgray">gps_fixed</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="navigate"
        [matTooltip]="'key_navigateAfterCreation' | translate"
      >
        <mat-icon style="color: darkgray">exit_to_app</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div fxFlex="none" *ngIf="configMode">
      <button
        mat-stroked-button
        color="basic"
        (click)="
          onAddAttribute(data.config.type === 'stepper' ? currentIndex : 0)
        "
      >
        {{ 'key_addComponent' | translate }}
      </button>
    </div>
    <div
      fxFlex="none"
      *ngIf="configMode && data.config.type === 'stepper'"
      style="margin-left: 20px"
    >
      <button mat-stroked-button color="basic" (click)="onSetting(true)">
        {{ 'key_editStep' | translate }}
      </button>
    </div>
    <div fxFlex="auto"></div>
    <div fxFlex="none" *ngIf="configMode" style="margin-right: 20px">
      <button mat-stroked-button color="primary" (click)="onSaveSetting(true)">
        {{ 'key_saveChanges' | translate }}
      </button>
    </div>
    <div fxFlex="none" *ngIf="configMode">
      <button mat-stroked-button color="warn" (click)="onCancelSetting(true)">
        {{ 'key_reset' | translate }}
      </button>
    </div>
    <div fxFlex="none" *ngIf="!configMode && !data.config.hideButtons">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="
          submitDisabled ||
          (data.config.type === 'stepper' && !hasFinished()) ||
          wizardHasError()
        "
        style="margin-right: 20px"
        (click)="onClose()"
      >
        {{
          data.config.submitButtonText ??
            (data.config.isCreation ? 'key_create' : 'key_submit') | translate
        }}
      </button>
    </div>
    <div fxFlex="none" *ngIf="!configMode && !data.config.hideButtons">
      <button mat-stroked-button color="warn" (click)="onCancel()">
        {{ data.config.cancelButtonText ?? 'key_cancel' | translate }}
      </button>
    </div>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="
      viewSetting && viewSetting.displayName
        ? viewSetting.displayName
        : 'key_creationWizard'
    "
    [icon]="viewSetting ? viewSetting.icon : 'warning'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
