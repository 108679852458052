import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CustomComponent } from '../../models/dynamicEditor.interface';
import {
  ModalType,
  ResourceColumnConfig,
  ResourceTableConfig,
} from '../../models/componentContract.model';
import { ConfigService } from '../../services/config.service';
import { ResourceTableComponent } from '../resource-table/resource-table.component';
import {
  BroadcastEvent,
  Resource,
  SearchScope,
} from '../../models/dataContract.model';
import { WizardService } from '../../services/wizard.service';
import { ResourceService } from '../../services/resource.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { EMPTY, forkJoin } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { UtilsService } from '../../services/utils.service';
import type { EChartsOption } from 'echarts';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-sod-matrix',
  templateUrl: './sod-matrix.component.html',
  styleUrls: ['./sod-matrix.component.scss'],
})
export class SodMatrixComponent
  extends CustomComponent
  implements OnInit, OnDestroy
{
  displayMode = 'table';
  noConfig = false;
  options: EChartsOption;
  tableConfig: ResourceTableConfig;
  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;
  searchScope: SearchScope;
  searchSkip: number;
  objectType: string;
  skip: number;
  selectedEntry = false;
  axisRoleNames: Array<string> = [];
  sods: Array<any> = [];

  availableAttributes: Array<string> = [];
  selectedAttributes: Array<string> = [];

  constructor(
    injector: Injector,
    private config: ConfigService,
    private wizard: WizardService,
    private resource: ResourceService,
    private modal: ModalService,
    private utils: UtilsService
  ) {
    super(injector);
  }
  get selectedItems() {
    if (this.resourceTable) {
      return this.resourceTable.selection.length > 0
        ? this.resourceTable.selection
        : undefined;
    } else {
      return undefined;
    }
  }

  refresh() {
    if (this.displayMode === 'heatmap') {
      this.showHeatmap();
    }
    if (this.displayMode === 'table') {
      if (this.resourceTable) {
        this.resourceTable.resetSelection();
        this.resourceTable.updateDataSource();
      }
    }
  }
  showTable() {
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.tableHeight = 630;
    this.tableConfig.cellPadding = 5;
    this.tableConfig.checkboxSelectOnly = false;
    this.tableConfig.selectMode = 'multiple';
    this.tableConfig.selectBoxWidth = 8;
    this.tableConfig.resizable = true;
    this.tableConfig.pageSize = this.config.getConfig('pageSize', 50);
    this.tableConfig.linkActions = this.config.getConfig('linkActions', [
      'navigate',
      'sideView',
    ]);
    this.tableConfig.defaultLinkAction = this.config.getConfig(
      'defaultLinkAction',
      'navigate'
    );
    this.tableConfig.initSort = [{ field: 'ocgRiskLevel', dir: 'desc' }];
    this.tableConfig.allowUnsort = false;
    //this.tableConfig.title = this.translate.instant(this.searchScope.text);
    this.tableConfig.objectType = this.searchScope.type;
    this.tableConfig.selectable = this.searchScope.selectable ?? true;
    this.tableConfig.navigationKey = this.searchScope.navigationKey;

    this.tableConfig.query = this.searchScope.typeQuery;
    if (this.searchScope.attributes && this.searchScope.attributes.length > 0) {
      const columns: Array<ResourceColumnConfig> = [];
      this.searchScope.attributes.forEach((a) => {
        columns.push({
          field: a,
          width: 0,
          sortable: true,
        });
        this.selectedAttributes.push(a);
      });
      this.tableConfig.columns = columns;
    }
  }

  showHeatmap() {
    const datatest = [];
    let xAxis = [];
    const roleObjects: Resource = [];
    const roleDisplaynames: string[] = [];
    let heatmapdata = [];
    this.subscription.add(
      this.resource
        .getResourceByQuery(
          '/ocgsod',
          [
            'displayname',
            'ocgconflictingobject1',
            'ocgconflictingobject2',
            'ocgrisklevel',
          ],
          0,
          100,
          true
        )
        .pipe(
          tap((result) => {
            /**
             * Filters the results array to include only the objects that have both ocgconflictingobject1 and ocgconflictingobject2 properties.
             */
            const sods = result.results.filter(
              (res) =>
                res.ocgconflictingobject1 !== null &&
                res.ocgconflictingobject2 !== null
            );
            this.sods = sods;
            // Iterate over each element in the 'sods' list and add the 'ocgconflictingobject1' and 'ocgconflictingobject2' objects to the 'roleObjects' list.
            sods.forEach((sod) => {
              if (
                sod.ocgconflictingobject1 &&
                !roleObjects.some(
                  (role) => role.objectid === sod.ocgconflictingobject1.objectid
                )
              ) {
                roleObjects.push(sod.ocgconflictingobject1);
                roleDisplaynames.push(sod.ocgconflictingobject1.displayname);
              }
              if (
                sod.ocgconflictingobject2 &&
                !roleObjects.some(
                  (role) => role.objectid === sod.ocgconflictingobject2.objectid
                )
              ) {
                roleObjects.push(sod.ocgconflictingobject2);
                roleDisplaynames.push(sod.ocgconflictingobject2.displayname);
              }
            });

            roleDisplaynames.sort();
            this.axisRoleNames = roleDisplaynames;
            // Iterate over the 'roleDisplaynames' list and create a new list 'datatest' that contains the index of each role and the risk level of the SOD between the two roles.
            for (let i = 0; i < roleDisplaynames.length; i++) {
              for (let j = 0; j < roleDisplaynames.length; j++) {
                // Get the risk level of the SOD between the two roles.
                const displayname = roleDisplaynames[i];
                const displayname2 = roleDisplaynames[j];
                const riskLevel = sods.find(
                  (x) =>
                    (x.ocgconflictingobject1.displayname === displayname &&
                      x.ocgconflictingobject2.displayname === displayname2) ||
                    (x.ocgconflictingobject1.displayname === displayname2 &&
                      x.ocgconflictingobject2.displayname === displayname)
                )?.ocgrisklevel;
                // Add the index of the two roles and the risk level to the 'datatest' list.
                if (riskLevel !== undefined && riskLevel !== null) {
                  datatest.push([i, j, riskLevel]);
                } else {
                  datatest.push([i, j, 0]);
                }
              }
            }
            // Create the 'heatmapdata' list by mapping the 'datatest' list to a new list that contains the index of the two roles and the risk level of the SOD between the two roles.
            heatmapdata = datatest.map(function (item) {
              return [item[0], item[1], item[2] || '-'];
            });

            // Create the 'xAxis' list by copying the 'roleDisplaynames' list and truncating the role names that are longer than 15 characters.
            xAxis = roleDisplaynames.slice();
            xAxis.forEach((role, index) => {
              xAxis[index] =
                role.length > 15 ? role.substring(0, 15) + '...' : role;
            });

            // Set the 'options' property of the component to an object that contains the configuration options for the heatmap chart.
            this.options = {
              dataZoom: [
                {
                  type: 'slider',
                  start: 0,
                  end: 100,
                  width: '80%', // adjust this value to change the scrollbar width
                  height: '10%', // adjust this value to change the scrollbar height
                  xAxisIndex: 0, // specify the x-axis index
                  backgroundColor: 'rgba(0, 0, 0, 0.2)', // customize the scrollbar background color
                  show: false,
                },
              ],
              tooltip: {
                position: 'top',
              },
              grid: {
                height: '70%',
                top: '10%',
                containLabel: true,
              },
              xAxis: {
                type: 'category',
                data: xAxis,
                splitArea: {
                  show: true,
                },
                axisLabel: { rotate: 300, interval: 0 },
                position: 'top',
              },
              yAxis: {
                type: 'category',
                data: roleDisplaynames,
                splitArea: {
                  show: true,
                },
                axisLabel: { interval: 0 },
                inverse: true,
              },
              visualMap: {
                min: 0,
                max: 100,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '5%',
              },
              series: [
                {
                  name: 'SOD',
                  type: 'heatmap',
                  data: heatmapdata,
                  selectedMode: 'single',
                  label: {
                    show: true,
                  },
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                  },
                  tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                      return (
                        //console.log(params),
                        sods.find(
                          (x) =>
                            (x.ocgconflictingobject1.displayname ===
                              xAxis[params.value[params.encode.x[0]]] &&
                              x.ocgconflictingobject2.displayname ===
                                roleDisplaynames[
                                  params.value[params.encode.y[0]]
                                ]) ||
                            (x.ocgconflictingobject1.displayname ===
                              roleDisplaynames[
                                params.value[params.encode.y[0]]
                              ] &&
                              x.ocgconflictingobject2.displayname ===
                                xAxis[params.value[params.encode.x[0]]])
                        )?.displayname +
                        '<br> - ' +
                        xAxis[params.value[params.encode.x[0]]] +
                        '<br> - ' +
                        roleDisplaynames[params.value[params.encode.y[0]]] +
                        '<br>Risklevel: ' +
                        params.value[params.encode.value[0]]
                      );
                    },
                  },
                },
              ],
            };
          })
        )
        .subscribe()
    );
  }

  onChartClick(event) {
    console.log(event);
    if (event && event.value && event.encode) {
      const sodId = this.sods.find(
        (x) =>
          (x.ocgconflictingobject1.displayname ===
            this.axisRoleNames[event.value[event.encode.x[0]]] &&
            x.ocgconflictingobject2.displayname ===
              this.axisRoleNames[event.value[event.encode.y[0]]]) ||
          (x.ocgconflictingobject1.displayname ===
            this.axisRoleNames[event.value[event.encode.y[0]]] &&
            x.ocgconflictingobject2.displayname ===
              this.axisRoleNames[event.value[event.encode.x[0]]])
      )?.objectid;
      console.log(sodId);

      this.swap.linkEvent(
        new BroadcastEvent(
          'sideView',
          {
            ObjectID: sodId,
            ObjectType: 'ocgsod',
          },
          {
            noneForm: false,
            readOnly: false,
            scope: '',
          }
        )
      );
    }
  }

  /**
   * Opens the SOD wizard to add a new SOD.
   *
   * @remarks
   * This method opens the SOD wizard using the `open` method of the `wizard` object.
   * It subscribes to the result of the wizard and checks if the result contains a resource.
   * If a valid result with a resource is received, it performs the following actions:
   * - If the display mode is 'table' and a resource table exists, it resets the selection and updates the data source.
   * - If the display mode is 'heatmap', it shows the heatmap.
   *
   * @returns A subscription to the wizard result
   */
  onAddSod() {
    const formid = this.displayMode === 'table' ? 'sod' : 'sodwithrisklevel';
    this.wizard.open(formid).subscribe((result: any) => {
      if (result && result.resource) {
        if (this.resourceTable && this.displayMode === 'table') {
          this.resourceTable.resetSelection();
          this.resourceTable.updateDataSource();
        } else if (this.displayMode === 'heatmap') {
          this.showHeatmap();
        }
      }
    });
  }

  /**
   * Deletes the selected SODs.
   *
   * @remarks
   * This method deletes the SODs that are currently selected in the component.
   * It sends a delete request for each selected SOD using the `deleteResource` method of the `resource` object.
   * After all delete requests are completed, it resets the selection and updates the data source of the `resourceTable` if it exists.
   * If an error occurs during the delete requests, it shows an error modal with the error message.
   *
   * @returns A subscription to the delete operation.
   */
  onDeleteSod() {
    const selectedSods = this.selectedItems;
    const observableBatch = [];

    selectedSods.forEach((sod) => {
      observableBatch.push(this.resource.deleteResource(sod));
    });

    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'key_confirmDeleteResource'
    );
    let progress: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((confirmResult) => {
            if (confirmResult && confirmResult === 'yes') {
              progress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              return forkJoin(observableBatch);
            } else {
              return EMPTY;
            }
          }),
          tap(() => {
            if (this.resourceTable) {
              this.resourceTable.resetSelection();
              this.resourceTable.updateDataSource();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (progress) {
              progress.close();
            }
          })
        )
        .subscribe()
    );

    // this.subscription.add(
    //   forkJoin(observableBatch)
    //     .pipe(
    //       tap((result) => {
    //         if (this.resourceTable) {
    //           this.resourceTable.resetSelection();
    //           this.resourceTable.updateDataSource();
    //         }
    //       }),
    //       catchError((error: any) => {
    //         this.modal.show(
    //           ModalType.error,
    //           'key_error',
    //           this.utils.getServiceError(error)
    //         );
    //         return EMPTY;
    //       })
    //     )
    //     .subscribe(
    //       () => {},
    //       (error) => {
    //         this.modal.show(ModalType.info, 'key_warning', error.error);
    //       }
    //     )
    // );
  }

  /**
   * Lifecycle hook that is called when the component is about to be destroyed.
   * Unsubscribes from any subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const name = 'ocgsod';
    this.searchScope = new SearchScope({
      name,
      type: name,
      text: 'Segregation of Duties (SoD)',
      query: `/${name}[starts-with(DisplayName,'%SearchText%')]`,
      exactQuery: `/${name}[DisplayName='%SearchText%']`,
      typeQuery: `/${name}`,
      attributes: [
        'DisplayName',
        'ocgconflictingobject1',
        'ocgconflictingobject2',
        'Description',
      ],
    });
    this.objectType = this.searchScope.type;
    this.showTable();
    this.showHeatmap();
  }

  onDisplayModeChange() {
    this.refresh();
  }
}
