<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group style="max-height: 600px">
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.config.attributeName"
              />
            </mat-form-field>
            <!-- <mat-form-field fxFlex="1 1 40">
              <input
                matInput
                [placeholder]="'key_componentName' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field> -->
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customErrorMsg' | translate"
                [(ngModel)]="data.config.customErrorMsg"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                type="number"
                min="0"
                [placeholder]="'key_hintLimit' | translate"
                [(ngModel)]="data.config.hintLimit"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.isPassword"
            >
              {{ 'key_password' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.isNumber"
            >
              {{ 'key_number' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle fxFlex="1 1 33" [(ngModel)]="data.config.isHtml"
              >Html</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <mat-slide-toggle fxFlex="1 1 33" [(ngModel)]="data.config.isCode">
              {{ 'key_code' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 66">
              <input
                matInput
                [placeholder]="'key_placeholder' | translate"
                [(ngModel)]="data.config.placeholder"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 10px"
          >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.isMultivalue"
              (change)="onChangeToMultivalue()"
            >
              {{ 'key_multivalue' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [disabled]="!data.config.isMultivalue"
              [(ngModel)]="data.config.caseSensitive"
            >
              {{ 'key_caseSensitive' | translate }}</mat-slide-toggle
            >
            <div fxFlex="1 1 33"></div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                [placeholder]="'key_prefix' | translate"
                [(ngModel)]="data.config.prefix"
              />
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="1 1 33"
              [disabled]="!data.config.prefix"
              [(ngModel)]="data.config.savePrefix"
            >
              {{ 'key_savePrefix' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [disabled]="!data.config.prefix || !data.config.savePrefix"
              [(ngModel)]="data.config.noPrefixIfEmpty"
            >
              {{ 'key_noPrefixIfEmpty' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px; margin-top: 10px"
          >
            <mat-form-field fxFlex="1 1 33">
              <mat-select
                [placeholder]="'key_updateOn' | translate"
                [(ngModel)]="data.config.updateOn"
              >
                <mat-option value="undefined">{{
                  'key_default' | translate
                }}</mat-option>
                <mat-option value="change">{{
                  'key_change' | translate
                }}</mat-option>
                <mat-option value="blur">{{
                  'key_blur' | translate
                }}</mat-option>
                <mat-option value="submit">{{
                  'key_submit' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="0"
                max="30"
                [placeholder]="'key_rowNumber' | translate"
                [(ngModel)]="data.config.rows"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="0"
                max="256"
                [placeholder]="'key_maxLength' | translate"
                [(ngModel)]="data.config.maxLength"
              />
            </mat-form-field>
          </div>
          <div fxFlex="none" *ngIf="data.creationMode">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_valueExpression' | translate"
                [disabled]="data.config.isMultivalue"
                [(ngModel)]="data.config.expression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_valueValidation' | translate"
                [(ngModel)]="data.config.validation"
              />
            </mat-form-field>
            <button
              mat-stroked-button
              style="height: 36px; margin-top: 10px; margin-left: 10px"
              [disabled]="!data.attribute.stringRegex"
              (click)="setDefaultValidation()"
            >
              {{ 'key_setDefault' | translate }}
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isCloud" [label]="'key_schemaValidation' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div style="margin-bottom: 20px">
            <mat-radio-group
              [value]="validationType"
              (change)="onValidationTypeChange($event)"
            >
              <mat-radio-button value="none" style="margin-right: 20px">{{
                'key_none' | translate
              }}</mat-radio-button>
              <mat-radio-button value="key" style="margin-right: 20px">{{
                'key_keyword' | translate
              }}</mat-radio-button>
              <mat-radio-button value="tags" style="margin-right: 20px">{{
                'key_configuration' | translate
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            *ngIf="
              data.config.validationSetting &&
              data.config.validationSetting.type === 'key'
            "
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <mat-divider style="margin-bottom: 20px"></mat-divider>
            <mat-form-field fxFlex="1 1 100">
              <input
                matInput
                [placeholder]="'key_keyword' | translate"
                [(ngModel)]="data.config.validationSetting.key"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              data.config.validationSetting &&
              data.config.validationSetting.type === 'tags'
            "
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <mat-divider style="margin-bottom: 20px"></mat-divider>
            <div>
              <div
                fxFlexLayout="row"
                fxLayoutGap="20px"
                style="margin-bottom: 20px"
              >
                <mat-checkbox
                  fxFlex="1 1 50"
                  [(ngModel)]="data.config.validationSetting.exclusive"
                  >{{ 'key_exclusive' | translate }}</mat-checkbox
                >
                <mat-checkbox
                  fxFlex="1 1 50"
                  [(ngModel)]="data.config.validationSetting.canDeactivateAll"
                  >{{ 'key_canDeactivateAll' | translate }}</mat-checkbox
                >
              </div>
              <div style="margin: 10px 0">
                {{ 'key_validationTags' | translate }}
              </div>
              <div
                style="font-size: 0.85em; margin-bottom: 5px"
                [innerHtml]="'key_validationTagsDesc' | translate | safeHtml"
              ></div>
              <app-editor-iterables
                [config]="iterablesConfig"
                [(ngModel)]="validationTags"
                (valueChange)="onValidationTagsChange()"
              ></app-editor-iterables>
              <div
                fxLayout="row"
                fxLayoutAlign="start start"
                fxLayoutGap="20px"
                style="margin-top: 20px"
              >
                <app-editor-text
                  fxFlex="1 1 50"
                  [config]="textConfig"
                  [(ngModel)]="allowedLookups"
                  (addValue)="onAllowedLookupsChange()"
                  (removeValue)="onAllowedLookupsChange()"
                ></app-editor-text>
                <app-editor-text
                  fxFlex="1 1 50"
                  [config]="textConfig"
                  [(ngModel)]="allowedStringFormats"
                  (addValue)="onAllowedStringFormatsChange()"
                  (removeValue)="onAllowedStringFormatsChange()"
                ></app-editor-text>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_data' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <mat-slide-toggle
            fxFlex="none"
            [(ngModel)]="data.config.autoComplete"
            style="margin-bottom: 20px"
          >
            {{ 'key_enableAutoComplete' | translate }}</mat-slide-toggle
          >
          <div fxFlex="none" *ngIf="data.config.autoComplete">
            <div fxLayout="column" fxLayoutGap="20px">
              <mat-list fxFlex="none">
                <mat-list-item
                  style="
                    border: solid lightgray;
                    height: 38px;
                    margin-top: -2px;
                    font-weight: 400;
                    color: gray;
                  "
                >
                  <div fxLayout="row" fxLayoutGap="20px" style="width: 100%">
                    <div fxFlex="0 0 20px"></div>
                    <div fxFlex="1 1 50">
                      {{ 'key_text' | translate }}
                    </div>
                    <div fxFlex="1 1 50">
                      {{ 'key_value' | translate }}
                    </div>
                    <div fxFlex="0 0 20px"></div>
                  </div>
                </mat-list-item>
                <div
                  dragula="TEXTAUTOOPTIONS"
                  [(dragulaModel)]="data.config.autoCompleteOptions"
                >
                  <mat-list-item
                    *ngFor="let option of data.config.autoCompleteOptions"
                    style="
                      border: solid lightgray;
                      height: 38px;
                      margin-top: -2px;
                      padding: 5px 0;
                      color: gray;
                    "
                  >
                    <div fxLayout="row" fxLayoutGap="20px" style="width: 100%">
                      <div fxFlex="0 0 20px" class="handle">
                        <mat-icon style="margin-left: -10px; cursor: pointer"
                          >menu</mat-icon
                        >
                      </div>
                      <div fxFlex="1 1 50">
                        {{ option.text }}
                      </div>
                      <div fxFlex="1 1 50">
                        {{ option.value }}
                      </div>
                      <div fxFlex="0 0 20px">
                        <mat-icon
                          (click)="onRemoveOption(option)"
                          style="cursor: pointer"
                          >clear</mat-icon
                        >
                      </div>
                    </div>
                  </mat-list-item>
                </div>
              </mat-list>
              <div
                fxFlex="none"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="20px"
              >
                <mat-form-field fxFlex="1 1 50">
                  <input
                    matInput
                    [placeholder]="'key_text' | translate"
                    [(ngModel)]="textInput"
                  />
                </mat-form-field>
                <mat-form-field fxFlex="1 1 50">
                  <input
                    matInput
                    [placeholder]="'key_value' | translate"
                    [(ngModel)]="valueInput"
                  />
                </mat-form-field>
                <button
                  fxFlex="auto"
                  mat-stroked-button
                  [disabled]="!textInput || !valueInput"
                  (click)="onAddOption()"
                  style="height: 36px"
                >
                  {{ 'key_add' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
