<!-- ngIf will not be used any more, use ngClass for display control  -->
<!-- *ngIf="configMode || showEditor(resource.rightSets)" -->
<div
  fxFlex="auto"
  [ngClass]="{
    'config-hidden': configMode && !showEditor(resource.rightSets),
    'edit-mode':
      !creationMode &&
      config.attributeName &&
      !config.attributeName.endsWith('#')
  }"
>
  <mat-form-field *ngIf="config.controlType === 'combo'" style="width: 100%">
    <mat-select
      [(ngModel)]="value"
      [placeholder]="displayName | translate"
      [matTooltip]="tooltip"
      [disabled]="disabled(resource.rightSets)"
      (focus)="onFocuse()"
      (selectionChange)="onChange()"
    >
      <mat-option *ngIf="config.allowEmpty" [value]="null">{{
        config.emptyText
      }}</mat-option>
      <mat-option
        *ngFor="let option of dataSource | async"
        [value]="option.value"
      >
        {{ option.text | translate }}
      </mat-option>
    </mat-select>
    <mat-hint align="start" *ngIf="!control || (control && control.valid)">
      <div fxLayout="row">
        <div
          fxFlex="auto"
          *ngIf="description"
          style="margin-right: 10px"
          [matTooltip]="description | translate"
        >
          {{ description | translate | ellipsis: config.hintLimit }}
        </div>
        <div
          fxFlex="auto"
          *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
          style="font-size: 14px; color: #d8bb2e"
        >
          <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
            >error</mat-icon
          >
        </div>
        <div
          fxFlex="auto"
          *ngIf="!hideNoReadAccessMessage && !readAccess"
          style="font-size: 14px; color: red"
        >
          <mat-icon [matTooltip]="'key_noReadAccess' | translate"
            >error</mat-icon
          >
        </div>
      </div>
    </mat-hint>
    <mat-hint
      *ngIf="control && control.invalid"
      class="error-message"
      [matTooltip]="control.errors['message'] | translate"
      >{{
        control.errors['message'] | translate | ellipsis: config.hintLimit
      }}</mat-hint
    >
  </mat-form-field>
  <div
    *ngIf="config.controlType === 'radio'"
    fxLayout="column"
    style="margin-top: -8px"
  >
    <div fxFlex="none" class="label-attribute-name">
      {{ displayName | translate }}
    </div>
    <mat-radio-group
      fxFlex="none"
      [(ngModel)]="value"
      [matTooltip]="tooltip"
      [disabled]="disabled(resource.rightSets)"
      (focus)="onFocuse()"
      (change)="onChange()"
    >
      <div
        [fxLayout]="config.radioButtonLayout"
        [fxLayoutGap]="config.radioButtonSpace.toString() + 'px'"
        style="font-weight: 400"
      >
        <mat-radio-button
          fxFlex="none"
          *ngFor="let option of dataSource | async"
          [value]="option.value"
          >{{ option.text | translate }}</mat-radio-button
        >
        <mat-radio-button
          fxFlex="none"
          *ngIf="config.allowEmpty"
          [value]="null"
          >{{ config.emptyText }}</mat-radio-button
        >
      </div>
    </mat-radio-group>
    <div
      *ngIf="!control || (control && control.valid)"
      fxFlex="none"
      class="label-attribute-desc"
    >
      <div fxLayout="row">
        <div
          fxFlex="none"
          *ngIf="description"
          style="margin-right: 10px"
          [matTooltip]="description | translate"
        >
          {{ description | translate | ellipsis: config.hintLimit }}
        </div>
        <div
          fxFlex="none"
          *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
          style="color: #d8bb2e"
        >
          <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
            >error</mat-icon
          >
        </div>
        <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
          <mat-icon
            [matTooltip]="'key_noReadAccess' | translate"
            style="font-size: 14px; color: red; padding-top: 4px"
            >error</mat-icon
          >
        </div>
      </div>
    </div>
    <div
      fxFlex="none"
      *ngIf="control && control.invalid"
      class="label-attribute-desc"
      [matTooltip]="control.errors['message'] | translate"
    >
      {{ control.errors['message'] | translate | ellipsis: config.hintLimit }}
    </div>
  </div>
  <mat-form-field *ngIf="config.controlType === 'text'" style="width: 100%">
    <input
      matInput
      #editor="ngModel"
      [placeholder]="displayName | translate"
      [matTooltip]="tooltip"
      [(ngModel)]="value"
      (focus)="onFocuse()"
      (change)="onChange()"
      [disabled]="disabled(resource.rightSets)"
    />
    <mat-hint align="start" *ngIf="!control || (control && control.valid)">
      <div fxLayout="row">
        <div
          fxFlex="auto"
          *ngIf="description"
          style="margin-right: 10px"
          [matTooltip]="description | translate"
        >
          {{ description | translate | ellipsis: config.hintLimit }}
        </div>
        <div
          fxFlex="auto"
          *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
          style="font-size: 14px; color: #d8bb2e"
        >
          <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
            >error</mat-icon
          >
        </div>
        <div
          fxFlex="auto"
          *ngIf="!hideNoReadAccessMessage && !readAccess"
          style="font-size: 14px; color: red"
        >
          <mat-icon [matTooltip]="'key_noReadAccess' | translate"
            >error</mat-icon
          >
        </div>
      </div>
    </mat-hint>
    <mat-hint
      *ngIf="control && control.invalid"
      class="error-message"
      [matTooltip]="control.errors['message'] | translate"
      >{{
        control.errors['message'] | translate | ellipsis: config.hintLimit
      }}</mat-hint
    >
  </mat-form-field>
</div>
