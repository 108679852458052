import { AfterViewInit, Component, Injector } from '@angular/core';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import { ForEachEditorConfig } from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-for-each-config',
  templateUrl: './editor-for-each-config.component.html',
  styleUrls: ['./editor-for-each-config.component.scss'],
})
export class EditorForEachConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: ForEachEditorConfig;
    attribute: AttributeResource;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.initComponent();
  }
}
