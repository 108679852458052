import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ModalType } from '../models/componentContract.model';
import { ModalService } from './modal.service';
import { ResourceService } from './resource.service';
import { SwapService } from './swap.service';
import { WindowService } from '@progress/kendo-angular-dialog';
import { WorkflowStarterComponent } from '../components/workflow-starter/workflow-starter.component';
import { Resource, ResourceSet } from '../models/dataContract.model';
import { UtilsService } from './utils.service';
import { WizardService } from './wizard.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private resource: ResourceService,
    private modal: ModalService,
    private swap: SwapService,
    private window: WindowService,
    private utils: UtilsService,
    private wizard: WizardService,
    private config: ConfigService
  ) {}

  private createItermediateObject(
    objectType: string,
    objectName: string,
    singleAttributeName: string,
    multiAttributeName: string,
    singleAttributeValue: { DisplayName: string; ObjectID: string },
    multiAttributeValue: Array<{ DisplayName: string; ObjectID: string }>,
    additionalAttributes: any = null,
    wizardAttributes: any = null,
    editor: any = null
  ) {
    const progress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );
    const observableBatch = [];
    multiAttributeValue.forEach((item) => {
      let displayName = `${singleAttributeValue.DisplayName} <-> ${item.DisplayName}`;
      if (objectName) {
        displayName = objectName
          .replace(/\[#self\]/gi, singleAttributeValue.DisplayName)
          .replace(/\[#added\]/gi, item.DisplayName);
      }
      const intermediat: Resource = {
        DisplayName: displayName,
        ObjectType: objectType,
      };
      intermediat[singleAttributeName] = singleAttributeValue.ObjectID;
      intermediat[multiAttributeName] = item.ObjectID;
      for (const key in additionalAttributes) {
        const regEx = /\[#\w+(-\w+)?#?\]/g;
        let resolvedValue: string = additionalAttributes[key].value;
        let matchs = regEx.exec(resolvedValue);
        while (matchs) {
          const name = matchs[0].substring(2, matchs[0].length - 1);
          const value = this.utils.ExtraValue(
            wizardAttributes,
            `${name}:value`,
            false
          );
          if (value !== null && value !== undefined) {
            resolvedValue = resolvedValue.replace(matchs[0], value);
          } else {
            resolvedValue = resolvedValue.replace(matchs[0], '');
          }
          matchs = regEx.exec(resolvedValue);
        }
        if (resolvedValue !== '') {
          intermediat[key] = resolvedValue;
        }
      }
      const existanceQuery = `/${objectType}[${singleAttributeName}='${singleAttributeValue.ObjectID}' and ${multiAttributeName}='${item.ObjectID}']`;
      observableBatch.push(
        this.resource.getResourceByQuery(existanceQuery, ['DisplayName']).pipe(
          switchMap((checkResult: ResourceSet) => {
            if (
              checkResult &&
              checkResult.results &&
              checkResult.results.length > 0
            ) {
              return of(null);
            } else {
              return this.resource.createResource(intermediat);
            }
          })
        )
      );
    });

    return forkJoin(observableBatch).pipe(
      tap(() => {
        if (progress) {
          progress.close();
        }
      }),
      switchMap((result: Array<HttpResponse<string>>) => {
        return this.handleResourceChangeResponse(result);
      }),
      catchError((error) => {
        this.modal.show(
          ModalType.error,
          'key_error',
          this.utils.getServiceError(error)
        );
        return of(null);
      }),
      finalize(() => {
        if (editor) {
          editor.refresh();
        }
        if (progress) {
          progress.close();
        }
      })
    );
  }

  addIntermediateObject(
    objectType: string,
    objectName: string,
    singleAttributeName: string,
    multiAttributeName: string,
    singleAttributeValue: { DisplayName: string; ObjectID: string },
    multiAttributeValue: Array<{ DisplayName: string; ObjectID: string }>,
    wizardToStart: string,
    additionalAttributes: any = null,
    editor: any = null
  ) {
    if (
      objectType &&
      singleAttributeName &&
      multiAttributeName &&
      singleAttributeValue &&
      multiAttributeValue &&
      multiAttributeValue.length > 0
    ) {
      if (wizardToStart) {
        return this.wizard.open(wizardToStart).pipe(
          switchMap((wizardResult: any) => {
            if (wizardResult && wizardResult.resource) {
              return this.createItermediateObject(
                objectType,
                objectName,
                singleAttributeName,
                multiAttributeName,
                singleAttributeValue,
                multiAttributeValue,
                additionalAttributes,
                wizardResult.resource,
                editor
              );
            } else {
              return EMPTY;
            }
          }),
          catchError((error) => {
            this.modal.show(ModalType.error, 'key_error', error);
            return EMPTY;
          })
        );
      } else {
        return this.createItermediateObject(
          objectType,
          objectName,
          singleAttributeName,
          multiAttributeName,
          singleAttributeValue,
          multiAttributeValue,
          additionalAttributes,
          null,
          editor
        );
      }
    }

    return EMPTY;
  }

  // obsolete
  addAssignment(
    singleValue: { DisplayName: string; ObjectID: string },
    multivalue: Array<{ DisplayName: string; ObjectID: string }>,
    singleSource: boolean,
    type: string,
    scope: string,
    editor: any
  ): Observable<any> {
    if (singleValue && multivalue && multivalue.length > 0) {
      const progress = this.modal.show(
        ModalType.progress,
        'key_savingChanges',
        '',
        '300px'
      );
      const observableBatch = [];
      multivalue.forEach((item) => {
        const assignment: Resource = {
          DisplayName: singleSource
            ? `${type}: ${singleValue.DisplayName} -> ${item.DisplayName}`
            : `${type}: ${item.DisplayName} -> ${singleValue.DisplayName}`,
          ObjectType: 'ocgAssignment',
          ocgObjectType: type,
          ocgObjectScope: scope,
          ocgLinkSourceRef: singleSource ? singleValue.ObjectID : item.ObjectID,
          ocgLinkTargetRef: singleSource ? item.ObjectID : singleValue.ObjectID,
        };
        observableBatch.push(this.resource.createResource(assignment));
      });

      return forkJoin(observableBatch).pipe(
        tap((result: Array<HttpResponse<string>>) => {
          let approvalNeeded = false;
          result.forEach((r) => {
            if (r && r.status === 202) {
              approvalNeeded = true;
            }
          });
          if (approvalNeeded) {
            this.modal.show(ModalType.info, 'key_info', 'key_approvalRequired');
          }
        }),
        catchError((error) => {
          this.modal.show(ModalType.error, 'key_error', error.error);
          return of(null);
        }),
        finalize(() => {
          if (editor) {
            editor.refresh();
          }
          progress.close();
        })
      );
    }

    return EMPTY;
  }

  removeIntermediateObject(
    objectType: string,
    singleAttributeName: string,
    multiAttributeName: string,
    singleAttributeValue: string,
    multiAttributeValue: Array<string>,
    editor: any
  ) {
    if (
      singleAttributeName &&
      multiAttributeName &&
      singleAttributeValue &&
      multiAttributeValue &&
      multiAttributeValue.length > 0
    ) {
      const progress = this.modal.show(
        ModalType.progress,
        'key_savingChanges',
        '',
        '300px'
      );

      const obsTypeBatch = [];
      multiAttributeValue.forEach((item) => {
        obsTypeBatch.push(this.resource.getResourceByID(item, ['ObjectType']));
      });

      return forkJoin(obsTypeBatch).pipe(
        switchMap((typeResult: Array<Resource>) => {
          if (typeResult) {
            let types: any = '';
            if (typeResult.length === 1) {
              types = this.utils.ExtraValue(typeResult[0], 'ObjectType');
            } else {
              types = typeResult.reduce((acc, val) => {
                const accType =
                  typeof acc === 'string'
                    ? acc
                    : this.utils.ExtraValue(acc, 'ObjectType');
                const valType = this.utils.ExtraValue(val, 'ObjectType');
                return accType === valType ? accType : `${accType}:${valType}`;
              });
            }
            if (
              typeof types === 'string' &&
              types.toLowerCase() === objectType.toLowerCase()
            ) {
              const obsDeleteBatch = [];
              multiAttributeValue.forEach((item) => {
                obsDeleteBatch.push(this.resource.deleteResource(item));
              });
              return forkJoin(obsDeleteBatch).pipe(
                catchError((error) => {
                  this.modal.show(ModalType.error, 'key_error', error.error);
                  return of(null);
                }),
                finalize(() => {
                  if (editor) {
                    editor.refresh();
                  }
                  progress.close();
                })
              );
            } else {
              const obsSearchBatch = [];
              const obsRemoveBatch = [];

              multiAttributeValue.forEach((item) => {
                const searchQuery = `/${objectType}[${singleAttributeName}='${singleAttributeValue}' and ${multiAttributeName}='${item}']`;
                obsSearchBatch.push(
                  this.resource.getResourceByQuery(searchQuery)
                );
              });

              return forkJoin(obsSearchBatch).pipe(
                switchMap((result: Array<ResourceSet>) => {
                  if (result) {
                    result.forEach((rs: ResourceSet) => {
                      if (rs.results) {
                        rs.results.forEach((r: Resource) => {
                          const objectID = this.utils.ExtraValue(r, 'ObjectID');
                          if (objectID) {
                            obsRemoveBatch.push(
                              this.resource.deleteResource(objectID)
                            );
                          }
                        });
                      }
                    });
                    if (obsRemoveBatch.length > 0) {
                      return forkJoin(obsRemoveBatch);
                    }
                  }
                  return EMPTY;
                }),
                catchError((error) => {
                  this.modal.show(ModalType.error, 'key_error', error.error);
                  return of(null);
                }),
                finalize(() => {
                  if (editor) {
                    editor.refresh();
                  }
                  progress.close();
                })
              );
            }
          }

          return EMPTY;
        })
      );
    }

    return EMPTY;
  }

  // obsolete
  removeAssignment(
    singleValue: string,
    multivalue: string[],
    singleSource: boolean,
    editor: any
  ): Observable<any> {
    if (singleValue && multivalue && multivalue.length > 0) {
      const progress = this.modal.show(
        ModalType.progress,
        'key_savingChanges',
        '',
        '300px'
      );

      const obsSearchBatch = [];
      const obsRemoveBatch = [];

      multivalue.forEach((item) => {
        let searchQuery = '';
        if (singleSource) {
          searchQuery = `/ocgAssignment[ocgLinkSourceRef='${singleValue}' and ocgLinkTargetRef='${item}']`;
        } else {
          searchQuery = `/ocgAssignment[ocgLinkSourceRef='${item}' and ocgLinkTargetRef='${singleValue}']`;
        }
        obsSearchBatch.push(this.resource.getResourceByQuery(searchQuery));
      });

      return forkJoin(obsSearchBatch).pipe(
        switchMap((result: Array<ResourceSet>) => {
          if (result) {
            result.forEach((rs: ResourceSet) => {
              if (rs.results) {
                rs.results.forEach((r: Resource) => {
                  const objectID = this.utils.ExtraValue(r, 'ObjectID');
                  if (objectID) {
                    obsRemoveBatch.push(this.resource.deleteResource(objectID));
                  }
                });
              }
            });
            if (obsRemoveBatch.length > 0) {
              return forkJoin(obsRemoveBatch);
            }
          }
          return EMPTY;
        }),
        catchError((error) => {
          this.modal.show(ModalType.error, 'key_error', error.error);
          return of(null);
        }),
        finalize(() => {
          if (editor) {
            editor.refresh();
          }
          progress.close();
        })
      );
    }

    return EMPTY;
  }

  addDirectMember(
    attributeName: string,
    targetID: string,
    idsToAdd: string[],
    editor: any
  ): Observable<any> {
    const progress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );

    return this.resource
      .addResourceValue(targetID, attributeName, idsToAdd)
      .pipe(
        tap(() => {
          if (progress) {
            progress.close();
          }
        }),
        switchMap((result: Array<HttpResponse<string>>) => {
          return this.handleResourceChangeResponse(result);
        }),
        catchError((error) => {
          this.modal.show(
            ModalType.error,
            'key_error',
            this.utils.getServiceError(error)
          );
          return of(null);
        }),
        finalize(() => {
          if (editor) {
            editor.refresh();
          }
          if (progress) {
            progress.close();
          }
        })
      );
  }

  removeDirectMember(
    attributeName: string,
    targetID: string,
    idsToRemove: string[],
    editor: any
  ): Observable<any> {
    const progress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );

    return this.resource
      .removeResourceValue(targetID, attributeName, idsToRemove)
      .pipe(
        tap(() => {
          if (progress) {
            progress.close();
          }
        }),
        switchMap((result: Array<HttpResponse<string>>) => {
          return this.handleResourceChangeResponse(result);
        }),
        catchError((error) => {
          this.modal.show(
            ModalType.error,
            'key_error',
            this.utils.getServiceError(error)
          );
          return of(null);
        }),
        finalize(() => {
          if (editor) {
            editor.refresh();
          }
          if (progress) {
            progress.close();
          }
        })
      );
  }

  addBackLinkMember(
    attributeName: string,
    targetID: string,
    idsToAdd: string[],
    editor: any
  ): Observable<any> {
    const observableBatch = [];

    const progress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );

    idsToAdd.forEach((id: string) => {
      observableBatch.push(
        this.resource.addResourceValue(id, attributeName, [targetID])
      );
    });

    return forkJoin(observableBatch).pipe(
      tap(() => {
        if (progress) {
          progress.close();
        }
      }),
      switchMap((result: Array<HttpResponse<string>>) => {
        return this.handleResourceChangeResponse(result);
      }),
      catchError((error) => {
        this.modal.show(
          ModalType.error,
          'key_error',
          this.utils.getServiceError(error)
        );
        return of(null);
      }),
      finalize(() => {
        if (editor) {
          editor.refresh();
        }
        if (progress) {
          progress.close();
        }
      })
    );
  }

  removeBackLinkMember(
    attributeName: string,
    targetID: string,
    idsToRemove: string[],
    editor: any
  ): Observable<any> {
    const observableBatch = [];

    const progress = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );

    idsToRemove.forEach((id) => {
      observableBatch.push(
        this.resource.removeResourceValue(id, attributeName, [targetID])
      );
    });

    return forkJoin(observableBatch).pipe(
      tap(() => {
        if (progress) {
          progress.close();
        }
      }),
      switchMap((result: Array<HttpResponse<string>>) => {
        return this.handleResourceChangeResponse(result);
      }),
      catchError((error) => {
        this.modal.show(
          ModalType.error,
          'key_error',
          this.utils.getServiceError(error)
        );
        return of(null);
      }),
      finalize(() => {
        if (editor) {
          editor.refresh();
        }
        if (progress) {
          progress.close();
        }
      })
    );
  }

  launchWorkflow(
    targetIDs: Array<string>,
    targetXpath: string,
    workflowID: string
  ) {
    this.swap.broadcast({
      name: 'show-overlay',
      parameter: undefined,
    });

    const windowRef = this.window.open({
      content: WorkflowStarterComponent,
      width: 800,
      top: 50,
    });
    const windowIns: WorkflowStarterComponent = windowRef.content.instance;
    if (targetIDs && targetIDs.length > 0) {
      windowIns.hideTarget = true;
      windowIns.launchTargetIDs = targetIDs;
    }
    if (targetXpath) {
      windowIns.hideTarget = true;
      windowIns.launchTargetXpath = targetXpath;
    }
    if (workflowID) {
      windowIns.hideWorkflow = true;
      windowIns.launchWorkflowID = workflowID;
    }

    return windowRef.result.pipe(
      finalize(() => {
        this.swap.broadcast({
          name: 'hide-overlay',
          parameter: undefined,
        });
      })
    );
  }

  public handleResourceChangeResponse(
    response: HttpResponse<any> | HttpResponse<any>[]
  ): Observable<any> {
    let res: HttpResponse<any>;
    if (Array.isArray(response)) {
      if (response.length === 1) {
        res = response[0];
        if (res.status !== 202) {
          return EMPTY;
        }
      } else {
        const pos = response.findIndex((r) => r.status === 202);
        if (pos < 0) {
          return EMPTY;
        }
        const resBody = response[pos].body;
        if (resBody) {
          this.modal.show(
            ModalType.info,
            'key_info',
            resBody.message ?? 'key_approvalRequired'
          );
          return EMPTY;
        }
      }
    } else {
      res = response;
      if (res.status !== 202) {
        return EMPTY;
      }
    }
    if (res.body) {
      const resBody = res.body;
      if (resBody) {
        if (resBody.formid && resBody.manualtaskid) {
          return this.wizard
            .open(resBody.formid, resBody.formdata, false, null, 'key_postpone')
            .pipe(
              switchMap((result: any) => {
                if (result.resource) {
                  return this.resource.responseManualTask(
                    resBody.manualtaskid,
                    this.utils.ToSaveResource(result.resource)
                  );
                } else {
                  // this.modal.show(
                  //   ModalType.info,
                  //   'key_info',
                  //   resBody.message ?? 'key_approvalRequired'
                  // );
                  return of(null);
                }
              }),
              tap(() => {
                setTimeout(() => {
                  this.swap.broadcast({
                    name: 'refresh-list',
                  });
                }, this.config.getConfig('intervalLarge', 1000));
              }),
              catchError((err: HttpErrorResponse) => {
                this.modal.show(
                  ModalType.info,
                  'key_error',
                  this.utils.getServiceError(err)
                );
                return EMPTY;
              })
            );
        } else {
          this.modal.show(
            ModalType.info,
            'key_info',
            resBody.message ?? 'key_approvalRequired'
          );
          return EMPTY;
        }
      }
    }
  }
}
