import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appAddtionalTab1]',
})
export class AddtionalTab1Directive {
  constructor(public tpl: TemplateRef<any>) {}
}

@Directive({
  selector: '[appAddtionalTab2]',
})
export class AddtionalTab2Directive {
  constructor(public tpl: TemplateRef<any>) {}
}
