<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            class="first-dialog-item"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.attribute.systemName"
              />
            </mat-form-field>
            <!-- <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_componentName' | translate"
                [(ngModel)]="data.config.name"
              />
            </mat-form-field> -->
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <div
              fxFlex="0 0 50"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <mat-form-field fxFlex="0 0 50">
                <input
                  matInput
                  disabled
                  [placeholder]="'key_type' | translate"
                  [(ngModel)]="data.config.name"
                />
              </mat-form-field>
              <mat-form-field fxFlex="0 0 45">
                <input
                  matInput
                  type="number"
                  min="0"
                  [placeholder]="'key_hintLimit' | translate"
                  [(ngModel)]="data.config.hintLimit"
                />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="0 0 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 15px"
          >
            <mat-form-field fxFlex="50">
              <mat-select
                [placeholder]="'key_displayMode' | translate"
                [(ngModel)]="data.config.displayMode"
              >
                <mat-option value="builder">Builder</mat-option>
                <mat-option value="query">Query</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                [placeholder]="'key_allowedSchema' | translate"
                [(ngModel)]="data.config.schema"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 15px"
          >
            <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.showResults">
              {{ 'key_viewResults' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="50"
              [(ngModel)]="data.config.showAttributePicker"
            >
              {{ 'key_showAttributePicker' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            *ngIf="data.creationMode"
            style="margin-bottom: 15px"
          >
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_valueExpression' | translate"
                [(ngModel)]="data.config.expression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-form-field
              fxFlex="auto"
              [appearance]="'fill'"
              style="width: 100%; margin-bottom: -6px"
            >
              <textarea
                matInput
                [(ngModel)]="data.config.prefix"
                [rows]="3"
              ></textarea>
              <mat-label>{{ 'key_prefix' | translate }}</mat-label>
            </mat-form-field>
          </div>
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-form-field
              fxFlex="auto"
              [appearance]="'fill'"
              style="width: 100%; margin-bottom: -6px"
            >
              <textarea
                matInput
                [(ngModel)]="data.config.surfix"
                [rows]="3"
              ></textarea>
              <mat-label>{{ 'key_surfix' | translate }}</mat-label>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_columns' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="font-weight: 400; margin-bottom: 20px">
            {{ 'key_columnsToShow' | translate }}
          </div>
          <div fxLayout="row" style="margin-bottom: 40px">
            <div fxFlex="40" fxLayout="column">
              <div
                fxFlex="auto"
                dragula="COLUMNS"
                [(dragulaModel)]="data.config.tableConfig.columns"
                style="overflow-y: auto"
              >
                <div
                  *ngFor="let column of data.config.tableConfig.columns"
                  fxLayout="row"
                  [ngClass]="{
                    'attribute-cloumn-selected':
                      selectedColumnAttribute &&
                      column.field === selectedColumnAttribute.field
                  }"
                  class="attribute-column"
                  style="padding-left: 10px; margin: 5px 0"
                >
                  <div
                    fxFlex="auto"
                    style="line-height: 34px; cursor: pointer"
                    (click)="onSelectColumnAttribute(column)"
                  >
                    {{ column.field }}
                  </div>
                  <button
                    fxFlex="none"
                    mat-icon-button
                    color="warn"
                    [disableRipple]="true"
                    [matTooltip]="'key_delete' | translate"
                    (click)="onDeleteColumn(column)"
                  >
                    <mat-icon style="font-size: 20px; margin-top: -8px"
                      >delete_outline</mat-icon
                    >
                  </button>
                  <div
                    fxFlex="none"
                    class="handle"
                    style="width: 36px; cursor: pointer; color: lightgray"
                  >
                    <mat-icon style="line-height: 34px">menu</mat-icon>
                  </div>
                </div>
              </div>
              <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
                <mat-form-field
                  fxFlex="auto"
                  style="margin-bottom: -20px; margin-right: 0"
                >
                  <input
                    matInput
                    [placeholder]="'key_addAttributeColumn' | translate"
                    [(ngModel)]="columnToAdd"
                  />
                </mat-form-field>
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="!columnToAdd"
                  (click)="onAddColumn()"
                >
                  <mat-icon>playlist_add</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex="60" class="attribute-column-setting">
              <div
                *ngIf="selectedColumnAttribute"
                fxLayout="column"
                fxLayoutGap="10px"
                style="font-size: 12px; padding: 10px"
              >
                <mat-form-field>
                  <input
                    matInput
                    [placeholder]="'key_field' | translate"
                    [(ngModel)]="selectedColumnAttribute.field"
                  />
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    [placeholder]="'key_title' | translate"
                    [(ngModel)]="selectedColumnAttribute.title"
                  />
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.sortable"
                      >{{ 'key_sortable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.locked"
                      >{{ 'key_locked' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="33">
                    <input
                      matInput
                      type="number"
                      min="-1"
                      max="800"
                      [placeholder]="'key_width' | translate"
                      [(ngModel)]="selectedColumnAttribute.width"
                    />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.filterable"
                      >{{ 'key_filterable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="76">
                    <mat-select
                      [disabled]="!selectedColumnAttribute.filterable"
                      [placeholder]="'key_filterType' | translate"
                      [(ngModel)]="selectedColumnAttribute.filter"
                    >
                      <mat-option value="text">text</mat-option>
                      <mat-option value="numeric">numeric</mat-option>
                      <mat-option value="boolean">boolean</mat-option>
                      <mat-option value="date">date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="33">
              <input
                matInput
                [placeholder]="'key_lockIcon' | translate"
                [(ngModel)]="data.config.tableConfig.lockIcon"
              />
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <input
                matInput
                [placeholder]="'key_lockTooltip' | translate"
                [(ngModel)]="data.config.tableConfig.lockText"
              />
            </mat-form-field>
            <mat-form-field fxFlex="33">
              <mat-select
                [placeholder]="'key_iconColor' | translate"
                [(ngModel)]="data.config.tableConfig.lockColor"
              >
                <mat-option value="primary">{{
                  'key_primary' | translate
                }}</mat-option>
                <mat-option value="accent">{{
                  'key_accent' | translate
                }}</mat-option>
                <mat-option value="warn">{{
                  'key_warn' | translate
                }}</mat-option>
                <mat-option value="disabled">{{
                  'key_disabled' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_table' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="50">
              <mat-select
                [placeholder]="'key_scrollType' | translate"
                [(ngModel)]="data.config.tableConfig.scrollMode"
              >
                <mat-option value="basic">{{
                  'key_basic' | translate
                }}</mat-option>
                <mat-option value="virtual">{{
                  'key_virtual' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="50">
              <mat-form-field fxFlex="50">
                <input
                  matInput
                  type="number"
                  min="0"
                  max="100"
                  [placeholder]="'key_scrollHeight' | translate"
                  [(ngModel)]="data.config.tableConfig.scrollHeight"
                  [disabled]="data.config.tableConfig.scrollMode === 'basic'"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="50">
              <mat-select
                [placeholder]="'key_pagingType' | translate"
                [(ngModel)]="data.config.tableConfig.pageType"
                [disabled]="data.config.tableConfig.scrollMode === 'virtual'"
              >
                <mat-option value="numeric">{{
                  'key_numeric' | translate
                }}</mat-option>
                <mat-option value="input">{{
                  'key_input' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="50">
              <mat-slide-toggle
                [disabled]="data.config.tableConfig.scrollMode === 'virtual'"
                [(ngModel)]="data.config.tableConfig.pageInfo"
                >{{ 'key_showPageInfo' | translate }}</mat-slide-toggle
              >
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <div fxFlex="50">
              <mat-form-field fxFlex="1 1 auto">
                <input
                  matInput
                  type="number"
                  min="1"
                  max="100"
                  [placeholder]="'key_pageSize' | translate"
                  [(ngModel)]="data.config.tableConfig.pageSize"
                />
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <mat-slide-toggle
                [disabled]="data.config.tableConfig.scrollMode === 'virtual'"
                [(ngModel)]="data.config.tableConfig.pageButton"
                >{{ 'key_showPageButton' | translate }}</mat-slide-toggle
              >
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="50">
              <input
                matInput
                type="number"
                min="1"
                [placeholder]="'key_tableHeight' | translate"
                [(ngModel)]="data.config.tableConfig.tableHeight"
              />
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                type="number"
                min="1"
                max="10"
                [placeholder]="'key_padding' | translate"
                [(ngModel)]="data.config.tableConfig.cellPadding"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="50">
              <input
                matInput
                type="number"
                min="10"
                max="20"
                [placeholder]="'key_fontSize' | translate"
                [(ngModel)]="data.config.tableConfig.fontSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                type="number"
                min="100"
                max="900"
                [placeholder]="'key_fontWeight' | translate"
                [(ngModel)]="data.config.tableConfig.fontWeight"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_export' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="0 0 33"
              [(ngModel)]="data.config.tableConfig.exportToClipBoard"
            >
              {{ 'key_exportToClipboard' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="0 0 66"
              [(ngModel)]="data.config.tableConfig.exportToExcel"
            >
              {{ 'key_exportToExcel' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="0 0 33"
              [(ngModel)]="data.config.tableConfig.exportAllPages"
            >
              {{ 'key_exportAllPages' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="0 0 33">
              <input
                matInput
                type="number"
                [min]="0"
                [placeholder]="'key_exportMaxCount' | translate"
                [(ngModel)]="data.config.tableConfig.exportMaxCount"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-top: 40px"
          >
            <mat-slide-toggle
              fxFlex="0 0 33"
              [(ngModel)]="data.config.tableConfig.exportReferenceId"
            >
              {{ 'key_exportReferenceId' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="0 0 33"
              [(ngModel)]="data.config.tableConfig.exportHeader"
              [disabled]="!data.config.tableConfig.exportToClipBoard"
            >
              {{ 'key_exportHeader' | translate }}</mat-slide-toggle
            >
            <div fxFlex="0 0 33">
              <mat-form-field style="margin-top: -20px">
                <input
                  matInput
                  [placeholder]="'key_separator' | translate"
                  [(ngModel)]="data.config.tableConfig.exportSeparator"
                  [disabled]="!data.config.tableConfig.exportToClipBoard"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="0 0 33"
              [(ngModel)]="data.config.tableConfig.exportState.enabled"
              [disabled]="!data.config.tableConfig.exportToClipBoard"
            >
              {{ 'key_exportState' | translate }}</mat-slide-toggle
            >
            <div fxFlex="0 0 33">
              <mat-form-field style="margin-top: -20px">
                <input
                  matInput
                  [placeholder]="'key_stateLocked' | translate"
                  [(ngModel)]="data.config.tableConfig.exportState.stateLocked"
                  [disabled]="
                    !data.config.tableConfig.exportToClipBoard ||
                    !data.config.tableConfig.exportState.enabled
                  "
                />
              </mat-form-field>
            </div>
            <div fxFlex="0 0 33">
              <mat-form-field style="margin-top: -20px">
                <input
                  matInput
                  [placeholder]="'key_stateUnlocked' | translate"
                  [(ngModel)]="
                    data.config.tableConfig.exportState.stateUnlocked
                  "
                  [disabled]="!data.config.tableConfig.exportState.enabled"
                />
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="none" style="margin-top: 40px">
            {{ 'key_attributesToExport' | translate }}
          </div>
          <div fxFlex="none" fxLayout="column" fxLayoutGap="20px">
            <mat-list fxFlex="none">
              <div>
                <mat-list-item
                  *ngFor="
                    let attribute of data.config.tableConfig.exportAttributes
                  "
                  style="
                    border: solid lightgray;
                    height: 38px;
                    margin-top: -2px;
                    padding-top: 2px;
                    color: gray;
                  "
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px"
                    style="width: 100%"
                  >
                    <div fxFlex="auto" style="padding-bottom: 6px">
                      {{ attribute }}
                    </div>
                    <div fxFlex="0 0 20px">
                      <mat-icon
                        (click)="onRemoveExportAttribute(attribute)"
                        style="cursor: pointer"
                        >clear</mat-icon
                      >
                    </div>
                  </div>
                </mat-list-item>
              </div>
            </mat-list>
            <div
              fxFlex="none"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="40px"
            >
              <mat-form-field fxFlex="auto">
                <mat-select
                  #cmbExportAttribute
                  [placeholder]="'key_attributeName' | translate"
                >
                  <mat-option
                    *ngFor="let col of data.config.tableConfig.columns"
                    [value]="col.field"
                    >{{ col.field }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <button
                fxFlex="120px"
                mat-stroked-button
                [disabled]="!cmbExportAttribute.value"
                (click)="onAddExportAttribute(cmbExportAttribute.value)"
                style="height: 36px"
              >
                {{ 'key_add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
