import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() enableAutoFocus: boolean = undefined;

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    if (this.enableAutoFocus === undefined || this.enableAutoFocus === true) {
      setTimeout(() => {
        this.host.nativeElement.focus();
      });
    }
  }
}
