import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HtmlCardConfig } from '../../models/componentContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { TransService } from '../../models/translation.model';
import { AuthService } from '../../services/auth.service';
import { InfoService } from '../../services/info.service';
import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';
import { HtmlCardConfigComponent } from './html-card-config.component';

@Component({
  selector: 'app-html-card',
  templateUrl: './html-card.component.html',
  styleUrls: ['./html-card.component.scss'],
})
export class HtmlCardComponent
  implements OnInit, AfterViewInit, DynamicComponent
{
  @Input()
  config: HtmlCardConfig;

  @ViewChild('titleBar') titleBar: TemplateRef<any>;

  windowRef: WindowRef;

  localConfig: HtmlCardConfig;

  constructor(
    private dialog: MatDialog,
    private utils: UtilsService,
    private info: InfoService,
    private translate: TransService,
    private auth: AuthService,
    private swap: SwapService,
    private resource: ResourceService
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(): void {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  initComponent() {
    this.localConfig = new HtmlCardConfig();

    this.utils.CopyInto(this.config, this.localConfig, true, true);

    return this.localConfig;
  }

  updateDataSource() {}

  resize() {}

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(HtmlCardConfigComponent, {
      minWidth: '600px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource();
      }),
      switchMap(() => {
        const returnValue: HtmlCardConfig = this.utils.DeepCopy(
          this.localConfig
        );
        return of(returnValue);
      })
    );
  }

  buildText(text: string) {
    if (text) {
      let result = text;

      let match = result.match(/\[#\w+\/?\w+#?\]/gi);
      if (match && match.length > 0) {
        for (let i = 0; i < match.length; i++) {
          const itemname = match[i].substring(2, match[i].length - 1);
          switch (itemname.toLowerCase()) {
            case 'uiconfiginfo':
              {
                const resolved = this.info.getUiConfigInfo();
                result = result.replace(
                  new RegExp(`\\[#${itemname}\\]`, 'gi'),
                  resolved
                );
              }
              break;
            case 'uiconfigcolor':
              {
                const resolved = this.info.getUiConfigColor();
                result = result.replace(
                  new RegExp(`\\[#${itemname}\\]`, 'gi'),
                  resolved
                );
              }
              break;
            default:
              {
                const tags = itemname.split('/');
                if (tags.length === 2) {
                  switch (tags[0].toLowerCase()) {
                    case 'loginuser':
                      {
                        const resolvedAttribute = this.utils.ExtraValue(
                          this.resource.loginUser,
                          tags[1]
                        );
                        if (
                          resolvedAttribute &&
                          typeof resolvedAttribute === 'string'
                        ) {
                          result = result.replace(
                            new RegExp(`\\[#${itemname}\\]`, 'gi'),
                            resolvedAttribute
                          );
                        }
                      }
                      break;

                    default:
                      break;
                  }
                }
              }
              break;
          }
        }
      }

      match = result.match(/\[&\w+#?\]/gi);
      if (match && match.length > 0) {
        for (let i = 0; i < match.length; i++) {
          const itemname = match[i].substring(2, match[i].length - 1);
          result = result.replace(
            new RegExp(`\\[&${itemname}\\]`, 'gi'),
            this.translate.instant(itemname)
          );
        }
      }

      return result;
    }
  }

  onLogout() {
    this.swap.broadcast({ name: 'hide-overlay' });
    this.auth.logout();
  }
}
