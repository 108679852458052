<div
  *ngIf="simpleMode && loadAsReadonly && !simpleEditing; else fullMode"
  [matTooltip]="tooltip"
  style="font-weight: 400"
>
  <div class="accent-text-color" style="font-size: 0.75em">
    {{ displayName | translate }}
  </div>
  <div
    *ngIf="
      !value || !value[0] || !(value[0] | extraValue: 'DisplayName');
      else showValue
    "
    style="cursor: pointer"
    (click)="onStartEdit()"
  >
    {{ 'key_noValuePlaceHolder' | translate }}
  </div>
  <ng-template #showValue>
    <div style="cursor: pointer" (click)="onStartEdit()">
      {{ value[0] | extraValue: 'DisplayName' }}
    </div>
  </ng-template>
</div>
<ng-template #fullMode>
  <div *ngIf="!valueIsString; else noneString" style="width: 100%">
    <!-- ngIf will not be used any more, use ngClass for display control  -->
    <!-- *ngIf="configMode || showEditor(resource.rightSets)" -->
    <div
      fxLayout="column"
      [ngClass]="{
        'config-hidden': configMode && !showEditor(resource.rightSets),
        'edit-mode':
          !creationMode &&
          config.attributeName &&
          !config.attributeName.endsWith('#')
      }"
      class="editor-wrapper"
    >
      <div fxFlex="none" fxLayout="row" style="width: 100%">
        <kendo-textbox-container
          fxFlex="auto"
          [ngClass]="{
            'idp-disabled': disabled(resource.rightSets) || importMode
          }"
          [floatingLabel]="displayName | translate"
        >
          <kendo-multiselect
            #idp
            [(ngModel)]="value"
            [data]="dataSource"
            [textField]="textField"
            [valueField]="valueField"
            [clearButton]="false"
            [filterable]="true"
            [readonly]="disabled(resource.rightSets) || importMode"
            [matTooltip]="tooltip"
            [autoClose]="!config.isMultivalue"
            [placeholder]="placeholder"
            [popupSettings]="{
              width: config.popupWidth,
              height: config.popupHeight
            }"
            (focus)="onFocuse()"
            (filterChange)="onFilterChange($event)"
            (valueChange)="onChange($event)"
            style="font-size: 1em"
          >
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
              <div (dblclick)="onDoubleClick(dataItem)" class="tag-label">
                {{
                  (dataItem | extraValue: 'DisplayName') ||
                    ('key_noDisplayNamePlaceHolder' | translate)
                }}
              </div>
            </ng-template>
            <ng-template kendoMultiSelectHeaderTemplate let-dataItem>
              <div
                *ngIf="dataSource && dataSource.length > 0"
                fxLayout="row"
                fxLayoutAlign="start center"
                class="popup-header"
              >
                <div
                  *ngIf="config.showPhoto && config.photoAttribute"
                  fxFlex="40px"
                ></div>
                <div
                  *ngFor="let attribute of allAttributes"
                  [fxFlex]="attribute.width || 0"
                >
                  {{
                    attribute.title
                      ? (attribute.title | translate)
                      : (attribute.field
                        | translateAttribute: config.objectType:0
                        | async)
                  }}
                </div>
              </div>
            </ng-template>
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                style="width: 100%; height: 100%"
              >
                <div
                  *ngIf="config.showPhoto && config.photoAttribute"
                  fxFlex="40px"
                >
                  <div class="info-avatar">
                    <img
                      *ngIf="dataItem | examValue: config.photoAttribute"
                      [src]="
                        'data:image/png;base64,' +
                        (dataItem | extraValue: config.photoAttribute)
                      "
                      class="info-avatar-image"
                    />
                    <div
                      *ngIf="!(dataItem | examValue: config.photoAttribute)"
                      class="info-avatar-initial"
                    >
                      <mat-icon style="font-size: 16px">{{
                        config.photoPlaceHolder
                      }}</mat-icon>
                    </div>
                  </div>
                </div>
                <div
                  *ngFor="let attribute of allAttributes"
                  [fxFlex]="attribute.width || 0"
                  class="popup-row"
                >
                  {{ dataItem | extraValue: attribute.field + ':DisplayName' }}
                </div>
              </div>
            </ng-template>
            <ng-template kendoMultiSelectFooterTemplate let-dataItem>
              <div
                *ngIf="dataSource && dataSource.length > 0"
                fxLayout="row"
                fxLayoutAlign="start center"
                style="
                  font-size: 12px;
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.54);
                  width: 100%;
                "
              >
                <div fxFlex="auto"></div>
                <div *ngIf="totalCount">
                  <div
                    *ngIf="
                      totalCount <= config.suggestionNumber;
                      else moreItems
                    "
                    fxFlex="none"
                    style="margin-right: 20px"
                  >
                    {{ 'key_totalCount' | translate }}: {{ totalCount }}
                  </div>
                  <ng-template #moreItems>
                    <a
                      fxFlex="none"
                      class="primary-color more-items-link"
                      (click)="onMoreItems()"
                      >{{ 'key_totalCount' | translate }}: {{ totalCount }}</a
                    >
                  </ng-template>
                </div>
              </div>
            </ng-template>
            <ng-template kendoMultiSelectNoDataTemplate>
              <div
                *ngIf="!isLoading"
                fxLayout="column"
                fxLayoutAlign="center center"
              >
                <mat-icon fxFlex="none" style="font-size: 30px"
                  >error_outline</mat-icon
                >
                <div fxFlex="none" style="font-size: 16px; margin-top: 16px">
                  {{ 'key_noData' | translate }}
                </div>
              </div>
            </ng-template>
          </kendo-multiselect>
        </kendo-textbox-container>
        <div #anchor fxFlex="none" class="editor-identity-serach-all">
          <button
            mat-icon-button
            fxFlex="none"
            [disabled]="
              disabled(resource.rightSets) ||
              !config.allowEmptySearch ||
              importMode
            "
            [matTooltip]="'key_searchAll' | translate"
            (click)="onEmptySearch()"
            class="identity-search"
            style="top: 12px"
          >
            <mat-icon
              *ngIf="config.isMultivalue; else singleValueSearch"
              style="font-size: 2em"
              >manage_search</mat-icon
            >
            <ng-template #singleValueSearch>
              <mat-icon>search</mat-icon>
            </ng-template>
          </button>
        </div>
        <button
          *ngIf="config.isMultivalue && config.showBulkImport"
          mat-icon-button
          fxFlex="none"
          [disabled]="disabled(resource.rightSets)"
          [matTooltip]="'key_import' | translate"
          (click)="onImport()"
          class="identity-paste"
          style="top: 12px"
        >
          <mat-icon style="font-size: 20px">content_paste</mat-icon>
        </button>
      </div>
      <div
        *ngIf="!control || (control && control.valid)"
        fxFlex="none"
        class="label-attribute-desc"
      >
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="description"
            style="margin-right: 10px; line-height: initial"
            [matTooltip]="description | translate"
          >
            {{ description | translate | ellipsis: config.hintLimit }}
          </div>
          <div
            fxFlex="none"
            *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
            style="font-size: 14px; color: #d8bb2e"
          >
            <mat-icon
              [matTooltip]="'key_noWriteAccess' | translate"
              style="font-size: 14px"
              >error</mat-icon
            >
          </div>
          <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
            <mat-icon
              [matTooltip]="'key_noReadAccess' | translate"
              style="font-size: 14px; color: red; padding-top: 4px"
            >
              error</mat-icon
            >
          </div>
        </div>
      </div>
      <div
        fxFlex="none"
        *ngIf="control && control.invalid"
        class="label-attribute-desc"
        [matTooltip]="control.errors['message'] | translate"
      >
        {{ control.errors['message'] | translate | ellipsis: config.hintLimit }}
      </div>
    </div>
    <kendo-popup
      #popup
      [anchor]="{ nativeElement: anchor }"
      [margin]="{ horizontal: -250, vertical: -3 }"
      (anchorViewportLeave)="importMode = false"
      *ngIf="importMode"
    >
      <div fxLayout="column" fxLayoutAlign="start stretch" style="width: 320px">
        <mat-form-field fxFlex="auto" appearance="fill">
          <mat-label>{{ 'key_import' | translate }}</mat-label>
          <textarea
            appAutoFocus
            matInput
            [placeholder]="'key_importIdentities' | translate"
            rows="6"
            [(ngModel)]="importString"
            (keyup.enter)="onBulkImport()"
            (keyup.esc)="importMode = false"
          ></textarea>
        </mat-form-field>
        <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center">
          <button
            mat-icon-button
            fxFlex="none"
            [matTooltip]="'key_import' | translate"
            color="primary"
            style="height: 32px; width: 32px; margin-top: -20px"
            (click)="onBulkImport()"
          >
            <mat-icon style="font-size: 18px; margin-top: -10px">send</mat-icon>
          </button>
        </div>
      </div>
    </kendo-popup>
  </div>
  <ng-template #noneString>
    <mat-form-field
      fxFlex="auto"
      [ngClass]="{
        'config-hidden': configMode && !showEditor(resource.rightSets),
        'edit-mode':
          !creationMode &&
          config.attributeName &&
          !config.attributeName.endsWith('#')
      }"
      style="width: 100%; margin-bottom: -6px"
    >
      <input
        matInput
        #editor="ngModel"
        autocomplete="off"
        type="text"
        [placeholder]="displayName | translate"
        [matTooltip]="tooltip"
        [(ngModel)]="value"
        [disabled]="true"
      />
      <mat-hint align="start" *ngIf="!control || (control && control.valid)">
        <div fxLayout="row">
          <div
            fxFlex="auto"
            *ngIf="description"
            style="margin-right: 10px"
            [matTooltip]="description | translate"
          >
            {{ description | translate | ellipsis: config.hintLimit }}
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
            style="font-size: 14px; color: #d8bb2e"
          >
            <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
              >error</mat-icon
            >
          </div>
          <div
            fxFlex="auto"
            *ngIf="!hideNoReadAccessMessage && !readAccess"
            style="font-size: 14px; color: red"
          >
            <mat-icon [matTooltip]="'key_noReadAccess' | translate"
              >error</mat-icon
            >
          </div>
        </div>
      </mat-hint>
    </mat-form-field>
  </ng-template>
</ng-template>
