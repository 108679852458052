<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_htmlCardConfig' | translate }}
  </h3>
  <div mat-dialog-content>
    <mat-tab-group class="config-tab-group">
      <mat-tab [label]="'key_general' | translate">
        <div fxLayout="column">
          <!-- <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            style="margin-top: 10px; margin-bottom: 20px"
          >
            <mat-slide-toggle [(ngModel)]="data.config.showAsModal">{{
              'key_showAsModal' | translate
            }}</mat-slide-toggle>
          </div> -->
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.permissionSets"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_entries' | translate">
        <div fxLayout="row" style="height: 100%; overflow: hidden">
          <div fxFlex="40" fxLayout="column">
            <div
              fxFlex="auto"
              dragula="COLUMNS"
              [(dragulaModel)]="data.config.entries"
              style="overflow-y: auto"
            >
              <div
                *ngFor="let entry of data.config.entries"
                fxLayout="row"
                [ngClass]="{
                  'attribute-entry-selected':
                    selectedEntry && entry.name === selectedEntry.name
                }"
                class="attribute-entry"
                style="padding-left: 10px; margin: 5px 0"
              >
                <div
                  fxFlex="auto"
                  style="line-height: 34px; cursor: pointer"
                  (click)="onSelectEntry(entry)"
                >
                  {{ entry.name }}
                </div>
                <button
                  fxFlex="none"
                  mat-icon-button
                  color="warn"
                  [disableRipple]="true"
                  [matTooltip]="'key_delete' | translate"
                  (click)="onDeleteEntry(entry)"
                >
                  <mat-icon style="font-size: 20px; margin-top: -8px"
                    >delete_outline</mat-icon
                  >
                </button>
                <div
                  fxFlex="none"
                  class="handle"
                  style="width: 36px; cursor: pointer; color: lightgray"
                >
                  <mat-icon style="line-height: 34px">menu</mat-icon>
                </div>
              </div>
            </div>
            <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
              <mat-form-field
                fxFlex="auto"
                style="margin-bottom: -20px; margin-right: 0"
              >
                <input
                  matInput
                  [placeholder]="'key_addEntry' | translate"
                  [(ngModel)]="entryToAdd"
                />
              </mat-form-field>
              <button
                mat-icon-button
                color="primary"
                [disabled]="!entryToAdd"
                (click)="onAddEntry()"
              >
                <mat-icon>playlist_add</mat-icon>
              </button>
            </div>
          </div>
          <div fxFlex="60" class="attribute-entry-setting">
            <div
              *ngIf="selectedEntry"
              fxLayout="column"
              fxLayoutGap="10px"
              style="font-size: 12px; padding: 10px"
            >
              <mat-form-field>
                <input
                  matInput
                  [placeholder]="'key_name' | translate"
                  [(ngModel)]="selectedEntry.name"
                />
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ 'key_html' | translate }}</mat-label>
                <textarea
                  matInput
                  rows="10"
                  [(ngModel)]="selectedEntry.html"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
    >
      {{ 'key_submit' | translate }}
    </button>
    <!-- <button fxFlex="none" mat-stroked-button (click)="onRefresh()">
      {{ 'key_apply' | translate }}
    </button> -->
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
