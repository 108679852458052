<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3
    *ngIf="data.type !== type.progress"
    mat-dialog-title
    cdkDragHandle
    [style.background]="barColor"
  >
    {{ title }}
  </h3>
  <div
    *ngIf="data.type === type.progress"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin: 0"
  >
    <div fxFlex="0 0 auto" style="color: lightslategray">
      <fa-icon
        [icon]="['fas', 'spinner']"
        size="2x"
        [fixedWidth]="true"
        [pulse]="true"
      ></fa-icon>
    </div>
    <div fxFlex="auto">
      <h3>{{ title }}</h3>
    </div>
  </div>
  <div
    *ngIf="data.type !== type.progress"
    mat-dialog-content
    fxLayout="row"
    fxLayoutAlign="start strech"
    fxLayoutGap="10px"
    style="margin-top: 20px"
  >
    <mat-icon fxFlex="0 0 auto" [style.color]="barColor">{{
      iconName
    }}</mat-icon>

    <div fxFlex="auto" [innerHTML]="content | safeHtml"></div>
  </div>
  <div
    *ngIf="
      data.type !== type.progress ||
      (data.type === type.progress && data.buttons)
    "
    mat-dialog-actions
    flexLayout="row"
    flexLayoutAlign="end center"
  >
    <button
      fxFlex="auto"
      *ngIf="(data.type === 'info' || data.type === 'error') && !data.buttons"
      mat-stroked-button
      mat-dialog-close="ok"
      cdkFocusInitial
    >
      {{ 'key_ok' | translate }}
    </button>
    <div
      *ngIf="data.type === 'confirm' && !data.buttons"
      fxFlex="auto"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <button fxFlex="auto" mat-stroked-button mat-dialog-close="yes">
        {{ 'key_yes' | translate }}
      </button>
      <button
        fxFlex="auto"
        mat-stroked-button
        mat-dialog-close="no"
        cdkFocusInitial
      >
        {{ 'key_no' | translate }}
      </button>
    </div>
    <div
      *ngIf="data.buttons"
      fxFlex="auto"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <div *ngFor="let button of data.buttons">
        <button
          *ngIf="button.focus === true; else noFocusButton"
          mat-stroked-button
          [color]="button.primary === true ? 'primary' : 'basic'"
          [mat-dialog-close]="button.value"
          cdkFocusInitial
        >
          {{ button.text | translate }}
        </button>
        <ng-template #noFocusButton>
          <button
            mat-stroked-button
            [color]="button.primary === true ? 'primary' : 'basic'"
            [mat-dialog-close]="button.value"
          >
            {{ button.text | translate }}
          </button>
        </ng-template>
      </div>
      <!-- <button
        *ngFor="let button of data.buttons"
        fxFlex="auto"
        mat-button
        mat-dialog-close="button.value"
        cdkFocusInitial
      >
        {{ button.text | translate }}
      </button> -->
    </div>
  </div>
</div>
