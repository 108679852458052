import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription, forkJoin, of } from 'rxjs';
import {
  BroadcastEvent,
  BulkImportDef,
  ImportAttributeDef,
  ImportItem,
  ImportItemDef,
  Resource,
  ResourceSet,
} from '../core/models/dataContract.model';

import { SwapService } from '../core/services/swap.service';
import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { ModalType } from '../core/models/componentContract.model';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ModalService } from '../core/services/modal.service';
import { TransService } from '../core/models/translation.model';
import { UtilsService } from '../core/services/utils.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  allResources: any;

  bulkImportDef: BulkImportDef;

  bulkImportAllowed = false;
  bulkImportDisplayed = false;

  get resourceKeys() {
    return this.allResources ? Object.keys(this.allResources) : [];
  }

  private downloadFile(text: string) {
    const filename = 'bulkimport.log';
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  private validateBulkImportItem(def: ImportItemDef): boolean {
    if (def && def.allowOverwrite) {
      if (
        def.attributes &&
        def.attributes.findIndex((a) => a.isRequired && a.isUnique) < 0
      ) {
        return false;
      }
    }

    return true;
  }

  private validateBulkImportProperty(
    propertyFromDef: string[],
    propertyFromCsv: string[]
  ) {
    if (!propertyFromCsv || propertyFromCsv.length !== propertyFromDef.length) {
      return false;
    }
    for (let index = 0; index < propertyFromDef.length; index++) {
      if (
        !propertyFromCsv[index] ||
        propertyFromCsv[index].toLowerCase().trim() !==
          propertyFromDef[index].toLowerCase().trim()
      ) {
        return false;
      }
    }
    return true;
  }

  private buildBulkImportItem(
    separator: string,
    objectType: string,
    attributeDefs: Array<ImportAttributeDef>,
    valueFromCsv: string
  ): ImportItem {
    const valuesFromCsv = valueFromCsv.split(separator);
    if (valuesFromCsv.length !== attributeDefs.length) {
      return {
        hasError: true,
        errorMsg: 'key_invalidFormat',
      };
    }
    const uniquenessChecks: string[] = [];
    const objectToImport: Resource = {
      ObjectType: objectType,
    };
    for (let i = 0; i < attributeDefs.length; i++) {
      const csvValue =
        valuesFromCsv[i] === undefined ? '' : valuesFromCsv[i].trim();
      if (csvValue === '' || csvValue === undefined) {
        if (attributeDefs[i].isRequired) {
          return {
            hasError: true,
            errorMsg: 'key_valueRequired',
          };
        }
      } else {
        if (attributeDefs[i].type === 'number' && isNaN(Number(csvValue))) {
          return {
            hasError: true,
            errorMsg: 'key_cannotParseToNumber',
          };
        }
        if (
          attributeDefs[i].type === 'boolean' &&
          !/(true|false)/i.test(csvValue)
        ) {
          return {
            hasError: true,
            errorMsg: 'key_cannotParseToBoolean',
          };
        }
        if (attributeDefs[i].validation) {
          if (!new RegExp(attributeDefs[i].validation).test(csvValue)) {
            return {
              hasError: true,
              errorMsg: 'key_cannotPassValidation',
            };
          }
        }
        if (attributeDefs[i].isUnique) {
          uniquenessChecks.push(
            `${attributeDefs[i].name.trim()}='${csvValue}'`
          );
        }

        objectToImport[attributeDefs[i].name.trim()] = csvValue;
      }
    }

    return {
      hasError: false,
      uniquenessCheck:
        uniquenessChecks.length > 0 ? uniquenessChecks.join(' or ') : '',
      objectToImport: objectToImport,
    };
  }

  constructor(
    private swap: SwapService,
    private config: ConfigService,
    private resource: ResourceService,
    private modal: ModalService,
    private translate: TransService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.allResources = this.config.getConfig('allResources', {});

    this.bulkImportDef = this.config.getConfig('bulkImport', null);
    if (
      this.bulkImportDef &&
      this.resource.inPermissionSets(this.bulkImportDef.permission)
    ) {
      this.bulkImportDisplayed = this.bulkImportDef.display;
      if (
        this.bulkImportDef.items &&
        this.bulkImportDef.items.findIndex(
          (item) => this.resource.inPermissionSets(item.permission) === true
        ) >= 0
      ) {
        this.bulkImportAllowed = true;
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  permissionAllowed(entry: any) {
    if (entry && entry.permissionSets && entry.permissionSets.length > 0) {
      return this.resource.inPermissionSets(entry.permissionSets);
    }

    return true;
  }

  importItemAllowed(permission: string[]) {
    return this.resource.inPermissionSets(permission);
  }

  onPrimaryAction(actionName: string) {
    this.swap.cardEvent(new BroadcastEvent(actionName, 'primary'));
  }

  onSecondaryAction(actionName: string) {
    this.swap.cardEvent(new BroadcastEvent(actionName, 'secondary'));
  }

  onExpandBulkImport() {
    this.bulkImportDisplayed = !this.bulkImportDisplayed;
  }

  onBulkImport(event: any, importDef: ImportItemDef) {
    if (!this.validateBulkImportItem(importDef)) {
      this.modal.show(
        ModalType.error,
        'key_error',
        'key_bulkImportOverwriteNotAllowed'
      );
      return;
    }
    if (event.target && event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const reader: FileReader = new FileReader();
      reader.readAsText(file);

      const process: MatDialogRef<ModalComponent, any> = this.modal.show(
        ModalType.progress,
        'key_processing',
        '',
        '300px'
      );

      const lineBreak =
        this.config.getConfigEx('bulkImport:lineBreak', 'win').toLowerCase() ===
        'win'
          ? '\r\n'
          : '\n';

      reader.onload = () => {
        const csvText: string = reader.result as string;
        const propertyNames = csvText
          .slice(0, csvText.indexOf(lineBreak))
          .split(importDef.separator);
        const dataRows = csvText
          .slice(csvText.indexOf(lineBreak) + lineBreak.length)
          .split(lineBreak);

        if (
          !this.validateBulkImportProperty(
            importDef.attributes.map((a) => a.name),
            propertyNames
          )
        ) {
          if (process) {
            process.close();
          }
          this.modal.show(
            ModalType.error,
            'key_error',
            'key_importAttributesNotMatch'
          );
        } else {
          const obs = [];
          const results: Array<string> = [];

          // this.swap.broadcast({
          //   name: 'modal-title-update',
          //   parameter: [
          //     { key: 'Loading file', value: 'Imported __ resources' },
          //   ],
          // });

          dataRows.forEach((row: string, index: number) => {
            const importItem = this.buildBulkImportItem(
              importDef.separator || ',',
              importDef.objectType,
              importDef.attributes,
              row
            );
            if (importItem.hasError) {
              results.push(
                `${this.translate.instant('key_line')} ${
                  index + 2
                }: ${this.translate.instant(importItem.errorMsg)}`
              );
            } else {
              if (importItem.uniquenessCheck) {
                obs.push(
                  this.resource
                    .getResourceByQuery(
                      `/${importDef.objectType}[${importItem.uniquenessCheck}]`,
                      ['DisplayName']
                    )
                    .pipe(
                      switchMap((checkResult: ResourceSet) => {
                        if (
                          checkResult.results &&
                          checkResult.results.length > 0
                        ) {
                          if (importDef.allowOverwrite) {
                            importItem.objectToImport['ObjectID'] =
                              this.utils.ExtraValue(
                                checkResult.results[0],
                                'ObjectID'
                              );
                            return this.resource.updateResource(
                              importItem.objectToImport
                            );
                          } else {
                            results.push(
                              `${this.translate.instant('key_line')} ${
                                index + 2
                              }: ${this.translate.instant(
                                'key_valueNotUnique'
                              )}`
                            );
                            return of(null);
                          }
                        } else {
                          return this.resource.createResource(
                            importItem.objectToImport
                          );
                        }
                      }),
                      catchError((err: any) => {
                        results.push(
                          `${this.translate.instant('key_line')} ${
                            index + 2
                          }: ${this.translate.instant(
                            'key_errorPersistingChanges'
                          )} - ${err.error ?? err.message ?? ''}`
                        );
                        return of(null);
                      })
                    )
                );
              } else {
                obs.push(
                  this.resource.createResource(importItem.objectToImport).pipe(
                    catchError((err: any) => {
                      results.push(
                        `${this.translate.instant('key_line')} ${
                          index + 2
                        }: ${this.translate.instant(
                          'key_errorPersistingChanges'
                        )} - ${err.error ?? err.message ?? ''}`
                      );
                      return of(null);
                    })
                  )
                );
              }
            }
          });
          this.subscription.add(
            forkJoin(obs)
              .pipe(
                finalize(() => {
                  this.downloadFile(results.join(lineBreak));
                  if (process) {
                    process.close();
                  }
                })
              )
              .subscribe()
          );
        }

        // reset the file uploader
        event.target.value = '';
      };

      reader.onerror = () => {
        if (process) {
          process.close();
        }
        this.modal.show(ModalType.error, 'key_error', 'key_unexpectedError');
      };
    }
  }
}
