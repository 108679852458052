<div fxLayout="column" style="height: 100%; width: 100%">
  <div fxFlex="40px" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field fxFlex="300px" appearance="outline" style="font-size: 12px">
      <mat-label>{{ 'key_searchInTree' | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="filterString"
        style="font-size: 14px"
        (keyup.enter)="onFilterTree()"
        (keyup.esc)="onRefreshTree()"
      />
      <div matSuffix fxLayout="row" fxLayoutAlign="start center">
        <button
          mat-icon-button
          [disabled]="!filterString || filterString.trim().length < 3"
          [matTooltip]="'key_search' | translate"
          (click)="onFilterTree()"
          style="margin-right: 5px"
        >
          <mat-icon style="font-size: 22px; color: rgba(0, 0, 0, 0.54)"
            >input</mat-icon
          >
        </button>
        <button
          mat-icon-button
          [matTooltip]="'key_refresh' | translate"
          (click)="onRefreshTree()"
        >
          <mat-icon style="font-size: 22px; color: rgba(0, 0, 0, 0.54)"
            >refresh</mat-icon
          >
        </button>
      </div>
    </mat-form-field>
    <div fxFlex="auto"></div>
    <div fxFlex="none" style="margin-bottom: 10px; margin-right: 20px">
      <app-action-menu
        #actionMenu
        [menuType]="'button'"
        [menuIcon]="'menu_open'"
        [menuText]="'key_action' | translate"
        [menuColor]="'primary'"
        (openMenu)="onMenuOpen()"
        (action)="onMenuAction($event)"
      ></app-action-menu>
    </div>
  </div>
  <div fxFlex="auto" style="border-top: solid rgba(0, 0, 0, 0.07)">
    <kendo-splitter
      style="height: 100%; background-color: #eeeeee; border-width: 0"
    >
      <kendo-splitter-pane
        [collapsible]="true"
        size="300px"
        style="padding-top: 10px; height: 85vh; padding-bottom: 20px"
      >
        <app-tree-view
          #orgStructure
          [name]="treeViewName"
          [config]="{
            initQuery: initQuery,
            childrenQuery: childrenQuery,
            sort: sortConfig,
            attributeStyles: attributeStyles,
            pageSize: pageSize
          }"
          (selectionChange)="onTreeSelectionChange($event)"
        ></app-tree-view>
      </kendo-splitter-pane>
      <kendo-splitter-pane
        style="padding: 50px 0 0 10px; overflow: hidden; width: 500px"
      >
        <router-outlet></router-outlet>
      </kendo-splitter-pane>
    </kendo-splitter>
  </div>
</div>
