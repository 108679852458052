import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-activity-simulate-updates',
  templateUrl: './activity-simulate-updates.component.html',
  styleUrls: ['./activity-simulate-updates.component.scss'],
})
export class ActivitySimulateUpdatesComponent
  extends WorkflowActivityView
  implements OnInit
{
  waitCompletion = false;
  maxWait = '';
  maxWaitSeconds = 0;
  waitMessage = '';

  @Input()
  get waitForCompletion() {
    return this.waitCompletion;
  }
  set waitForCompletion(value: boolean) {
    this.waitCompletion = value;
    this.waitForCompletionChange.emit(this.waitCompletion);
  }
  @Output()
  waitForCompletionChange = new EventEmitter();

  @Input()
  get maxWaitTime() {
    return this.maxWait;
  }
  set maxWaitTime(value: string) {
    this.maxWait = value;
    this.maxWaitTimeChange.emit(this.maxWait);
  }
  @Output()
  maxWaitTimeChange = new EventEmitter();

  @Input()
  get waitForCompletionMessage() {
    return this.waitMessage;
  }
  set waitForCompletionMessage(value: string) {
    this.waitMessage = value;
    this.waitForCompletionMessageChange.emit(this.waitMessage);
  }
  @Output()
  waitForCompletionMessageChange = new EventEmitter();

  constructor(private utils: UtilsService) {
    super();
  }

  ngOnInit(): void {
    this.maxWaitSeconds = this.utils.durationToSeconds(this.maxWaitTime);
  }

  onMaxTimeChange() {
    this.maxWaitTime = this.utils.secondsToDuration(this.maxWaitSeconds);
  }
}
