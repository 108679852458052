<div fxLayout="column" class="config-dialog">
  <div fxFlex="auto" mat-dialog-content>
    <mat-tab-group style="height: 100%">
      <mat-tab [label]="'key_general' | translate">
        <form
          #reportCardGeneralForm="ngForm"
          autocomplete="off"
          (keydown.enter)="$event.preventDefault()"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="10px"
            class="first-dialog-item"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <mat-form-field fxFlex="50">
                <input
                  #fieldName="ngModel"
                  matInput
                  name="name"
                  required
                  pattern="[A-Za-z0-9]*"
                  [appDistinctValidator]="existingReportNames"
                  [placeholder]="'key_name' | translate"
                  [(ngModel)]="data.name"
                />
                <mat-error *ngIf="fieldName.hasError('required')">{{
                  'key_valueRequired' | translate
                }}</mat-error>
                <mat-error *ngIf="fieldName.hasError('pattern')">{{
                  'key_onlyLettersAndNumbers' | translate
                }}</mat-error>
                <mat-error *ngIf="fieldName.hasError('distinct')">{{
                  'key_valueExist' | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="50">
                <input
                  matInput
                  name="image"
                  [placeholder]="'key_imagePath' | translate"
                  [(ngModel)]="data.image"
                />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="icon"
                    [placeholder]="'key_stateCardIconText' | translate"
                    [(ngModel)]="data.icon"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.iconColor"
                  cpPosition="right"
                  [style.backgroundColor]="data.iconColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="backgroundColor"
                    [placeholder]="'key_backgroundColor' | translate"
                    [(ngModel)]="data.backgroundColor"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.backgroundColor"
                  cpPosition="left"
                  [style.backgroundColor]="data.backgroundColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="text"
                    [placeholder]="'key_text' | translate"
                    [(ngModel)]="data.text"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.textColor"
                  cpPosition="right"
                  [style.backgroundColor]="data.textColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="description"
                    [placeholder]="'key_description' | translate"
                    [(ngModel)]="data.description"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.descriptionColor"
                  cpPosition="left"
                  [style.backgroundColor]="data.descriptionColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <mat-form-field fxFlex="50">
                <input
                  matInput
                  name="fontSize"
                  type="number"
                  min="10"
                  max="50"
                  [placeholder]="'key_fontSize' | translate"
                  [(ngModel)]="data.fontSize"
                />
              </mat-form-field>
              <button
                mat-stroked-button
                fxFlex="50"
                (click)="onResetToThemeColor()"
              >
                {{ 'key_resetToThemeColor' | translate }}
              </button>
            </div>
            <div fxLayout="row">
              <mat-slide-toggle
                name="chkUseAsLink"
                fxFlex="50"
                [(ngModel)]="data.useAsLink"
                style="font-size: 16px; margin-top: 20px; margin-bottom: 20px"
              >
                {{ 'key_useAsLink' | translate }}</mat-slide-toggle
              >
              <mat-form-field *ngIf="data.useAsLink" fxFlex="50">
                <input
                  #txtLinkUrl="ngModel"
                  matInput
                  name="txtLinkUrl"
                  [required]="data.useAsLink"
                  pattern="^(https?):\/\/[^\s/$.?#].[^\s]*$"
                  [placeholder]="'key_linkUrl' | translate"
                  [(ngModel)]="data.linkUrl"
                />
                <mat-error *ngIf="txtLinkUrl.hasError('pattern')">{{
                  'key_invalidFormat' | translate
                }}</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <mat-slide-toggle
                *ngIf="!data.useAsLink"
                name="chkHideSettingsOnStart"
                fxFlex="50"
                [(ngModel)]="data.hideConfig"
                style="font-size: 16px; margin-top: 20px; margin-bottom: 20px"
              >
                {{ 'key_hideSettingsOnStart' | translate }}</mat-slide-toggle
              >
              <mat-slide-toggle
                *ngIf="!data.useAsLink"
                name="chkIsHistorical"
                fxFlex="50"
                [(ngModel)]="data.isHistorical"
                style="font-size: 16px; margin-top: 20px; margin-bottom: 20px"
                (change)="onSetHistorical()"
              >
                {{ 'key_historicalReport' | translate }}</mat-slide-toggle
              >
            </div>
            <div *ngIf="data.isHistorical" fxLayout="row">
              <div
                fxFlex="50"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="20px"
              >
                <mat-form-field fxFlex="50">
                  <input
                    matInput
                    name="txtLinkedObjectType"
                    [placeholder]="'key_linkedObjectType' | translate"
                    [(ngModel)]="data.linkedObjectType"
                  />
                </mat-form-field>
                <mat-slide-toggle
                  name="chkIsRelativeDate"
                  fxFlex="50"
                  [(ngModel)]="data.isRelativeDate"
                  style="font-size: 16px; margin-top: 20px; margin-bottom: 20px"
                >
                  {{ 'key_relativeDate' | translate }}</mat-slide-toggle
                >
              </div>
              <kendo-datepicker
                name="dtpAbsoluteDate"
                *ngIf="!data.isRelativeDate"
                fxFlex="50"
                [format]="dateFormat"
                [max]="maxDate"
                [value]="reportDate"
                (valueChange)="onDateChange($event)"
                formatPlaceholder="formatPattern"
                style="width: 100%; margin-bottom: 20px; margin-top: 10px"
              >
                <kendo-datepicker-messages
                  [today]="'key_calendarToday' | translate"
                  [toggle]="'key_calendarToggle' | translate"
                ></kendo-datepicker-messages>
              </kendo-datepicker>
              <div *ngIf="data.isRelativeDate" fxLayout="row">
                <mat-form-field
                  style="width: 60px; margin-right: 10px; padding-top: 2px"
                >
                  <input
                    name="txtRelativeDate"
                    matInput
                    [min]="0"
                    type="number"
                    [(ngModel)]="data.relativeDate"
                  />
                </mat-form-field>
                <div style="font-size: 16px; margin-top: 20px">
                  {{ 'key_daysAgo' | translate }}
                </div>
              </div>
            </div>
            <mat-form-field>
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.permissionSets"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </form>
      </mat-tab>
      <mat-tab
        *ngIf="!data.useAsLink"
        [label]="'key_inputParameters' | translate"
      >
        <form
          #reportCardParameterForm="ngForm"
          autocomplete="off"
          (keydown.enter)="$event.preventDefault()"
        >
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field
              appearance="fill"
              style="margin-top: 20px; margin-bottom: 10px"
            >
              <mat-label>{{ 'key_query' | translate }}</mat-label>
              <textarea
                #fieldQuery="ngModel"
                matInput
                name="query"
                [rows]="3"
                [required]="!data.useAsLink"
                [appApiValidator]="
                  data.isHistorical ? queryValidationConfig : null
                "
                [(ngModel)]="data.query"
                [ngModelOptions]="{ updateOn: 'blur' }"
              ></textarea>
              <mat-error *ngIf="fieldQuery.hasError('required')">{{
                'key_valueRequired' | translate
              }}</mat-error>
              <mat-error *ngIf="fieldQuery.hasError('message')">{{
                fieldQuery.errors['message']
              }}</mat-error>
            </mat-form-field>
            <app-editor-iterables
              style="font-size: 16px; margin-top: 10px"
              name="itrParameters"
              [config]="parameterConfig"
              [ngModel]="attrParameter"
              (valueChange)="onParameterChanged()"
            ></app-editor-iterables>
          </div>
        </form>
      </mat-tab>
      <mat-tab *ngIf="!data.useAsLink" [label]="'key_table' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="10px"
          class="first-dialog-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_title' | translate"
                [(ngModel)]="data.tableConfig.title"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_objectType' | translate"
                [(ngModel)]="data.tableConfig.objectType"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="10"
                max="30"
                [placeholder]="'key_fontSize' | translate"
                [(ngModel)]="data.tableConfig.fontSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="100"
                max="900"
                [placeholder]="'key_fontWeight' | translate"
                [(ngModel)]="data.tableConfig.fontWeight"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="1"
                max="200"
                [placeholder]="'key_pageSize' | translate"
                [(ngModel)]="data.tableConfig.pageSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="100"
                [placeholder]="'key_tableHeight' | translate"
                [(ngModel)]="data.tableConfig.tableHeight"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="20"
                [placeholder]="'key_padding' | translate"
                [(ngModel)]="data.tableConfig.cellPadding"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_formatDate' | translate"
                [(ngModel)]="data.tableConfig.datetimeFormat"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <mat-select
                [placeholder]="'key_scrollType' | translate"
                [(ngModel)]="data.tableConfig.scrollMode"
              >
                <mat-option value="basic">{{
                  'key_basic' | translate
                }}</mat-option>
                <mat-option value="virtual">{{
                  'key_virtual' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="100"
                [placeholder]="'key_scrollHeight' | translate"
                [(ngModel)]="data.tableConfig.scrollHeight"
                [disabled]="data.tableConfig.scrollMode === 'basic'"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_navigationKey' | translate"
                [(ngModel)]="data.tableConfig.navigationKey"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_defaultLinkAction' | translate"
                [(ngModel)]="data.tableConfig.defaultLinkAction"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.tableConfig.linkDisabled"
            >
              {{ 'key_disableLink' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.tableConfig.linkNoneForm"
            >
              {{ 'key_noneFormLink' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.tableConfig.pageInfo"
            >
              {{ 'key_showPageInfo' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="padding-top: 20px"
          >
            <mat-form-field fxFlex="100">
              <mat-chip-list #clLinkActions>
                <mat-chip
                  *ngFor="let action of data.tableConfig.linkActions"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveLinkAction(action)"
                  style="font-size: 10px"
                >
                  {{ action }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_linkActions' | translate"
                  [matChipInputFor]="clLinkActions"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddLinkAction($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="!data.useAsLink" [label]="'key_columns' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="font-weight: 400; margin-bottom: 20px">
            {{ 'key_columnsToShow' | translate }}
          </div>
          <div fxLayout="row" style="margin-bottom: 40px">
            <div fxFlex="40" fxLayout="column">
              <div
                fxFlex="auto"
                dragula="COLUMNS"
                [(dragulaModel)]="data.tableConfig.columns"
                style="overflow-y: auto"
              >
                <div
                  *ngFor="let column of data.tableConfig.columns"
                  fxLayout="row"
                  [ngClass]="{
                    'attribute-cloumn-selected':
                      selectedColumnAttribute &&
                      column.field === selectedColumnAttribute.field &&
                      column.reportType === selectedColumnAttribute.reportType
                  }"
                  class="attribute-column"
                  style="padding-left: 10px; margin: 5px 0"
                >
                  <div
                    fxFlex="auto"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    style="line-height: 34px; cursor: pointer"
                    (click)="onSelectColumnAttribute(column)"
                  >
                    <div fxFlex="none">
                      {{ column.field }}
                    </div>
                    <div
                      *ngIf="column.reportType === 'recent'"
                      class="disabled-text-color"
                      style="line-height: 10px; margin-left: 10px"
                      [matTooltip]="'key_recent' | translate"
                    >
                      <span
                        class="material-icons-outlined"
                        style="font-size: 20px"
                        >history</span
                      >
                    </div>
                    <div
                      *ngIf="column.reportType === 'historical'"
                      class="disabled-text-color"
                      style="line-height: 10px; margin-left: 10px"
                      [matTooltip]="'key_historical' | translate"
                    >
                      <span
                        class="material-icons-outlined"
                        style="font-size: 18px"
                        >date_range</span
                      >
                    </div>
                  </div>
                  <button
                    fxFlex="none"
                    mat-icon-button
                    color="warn"
                    [disableRipple]="true"
                    [matTooltip]="'key_delete' | translate"
                    (click)="onDeleteColumn(column)"
                  >
                    <mat-icon style="font-size: 20px; margin-top: -8px"
                      >delete_outline</mat-icon
                    >
                  </button>
                  <div
                    fxFlex="none"
                    class="handle"
                    style="width: 36px; cursor: pointer; color: lightgray"
                  >
                    <mat-icon style="line-height: 34px">menu</mat-icon>
                  </div>
                </div>
              </div>
              <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
                <mat-form-field
                  fxFlex="auto"
                  style="margin-bottom: -20px; margin-right: 0"
                >
                  <input
                    matInput
                    [placeholder]="'key_addAttributeColumn' | translate"
                    [(ngModel)]="columnToAdd"
                  />
                </mat-form-field>
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="!columnToAdd"
                  (click)="onAddColumn()"
                >
                  <mat-icon>playlist_add</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex="60" class="attribute-column-setting">
              <div
                *ngIf="selectedColumnAttribute"
                fxLayout="column"
                fxLayoutGap="10px"
                style="font-size: 12px; padding: 10px"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="20px"
                >
                  <mat-form-field fxFlex="auto">
                    <input
                      matInput
                      [placeholder]="'key_field' | translate"
                      [(ngModel)]="selectedColumnAttribute.field"
                    />
                  </mat-form-field>
                  <mat-slide-toggle
                    *ngIf="data.isHistorical"
                    fxFlex="33"
                    [disabled]="
                      reportTypeDisabled(
                        selectedColumnAttribute.field,
                        data.tableConfig.columns
                      )
                    "
                    [ngModel]="
                      selectedColumnAttribute.reportType === 'historical'
                    "
                    (change)="onReportValueChange(selectedColumnAttribute)"
                    >{{ 'key_historicalValue' | translate }}</mat-slide-toggle
                  >
                  <!-- <mat-form-field *ngIf="data.isHistorical" fxFlex="33">
                    <mat-select
                      [placeholder]="'key_valueType' | translate"
                      [(ngModel)]="selectedColumnAttribute.reportType"
                    >
                      <mat-option
                        value="current"
                        [disabled]="
                          isReportTypeDisabled(
                            selectedColumnAttribute.field,
                            'current',
                            data.tableConfig.columns
                          )
                        "
                        >{{ 'key_current' | translate }}</mat-option
                      >
                      <mat-option
                        value="recent"
                        [disabled]="
                          isReportTypeDisabled(
                            selectedColumnAttribute.field,
                            'recent',
                            data.tableConfig.columns
                          )
                        "
                        >{{ 'key_recent' | translate }}</mat-option
                      >
                      <mat-option
                        value="historical"
                        [disabled]="
                          isReportTypeDisabled(
                            selectedColumnAttribute.field,
                            'historical',
                            data.tableConfig.columns
                          )
                        "
                        >{{ 'key_historical' | translate }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field> -->
                </div>
                <mat-form-field>
                  <input
                    matInput
                    [placeholder]="'key_title' | translate"
                    [(ngModel)]="selectedColumnAttribute.title"
                  />
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.sortable"
                      [disabled]="data.isHistorical"
                      (change)="onEnableSorting(selectedColumnAttribute)"
                      >{{ 'key_sortable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.locked"
                      [disabled]="data.isHistorical"
                      >{{ 'key_locked' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="33">
                    <input
                      matInput
                      type="number"
                      min="-1"
                      max="800"
                      [placeholder]="'key_width' | translate"
                      [(ngModel)]="selectedColumnAttribute.width"
                    />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.filterable"
                      [disabled]="data.isHistorical"
                      >{{ 'key_filterable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [disabled]="
                        data.isHistorical || !selectedColumnAttribute.filterable
                      "
                      [(ngModel)]="selectedColumnAttribute.isReference"
                      (change)="
                        onEnableReferenceFilter(selectedColumnAttribute)
                      "
                      >{{ 'key_isReference' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="33">
                    <mat-select
                      [disabled]="
                        data.isHistorical || !selectedColumnAttribute.filterable
                      "
                      [placeholder]="'key_filterType' | translate"
                      [(ngModel)]="selectedColumnAttribute.filter"
                    >
                      <mat-option value="text">text</mat-option>
                      <mat-option value="numeric">numeric</mat-option>
                      <mat-option value="boolean">boolean</mat-option>
                      <mat-option value="date">date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!data.isHistorical"
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <div fxFlex="none" style="font-weight: 400; margin-bottom: 20px">
              {{ 'key_addSortingOptions' | translate }}
            </div>
            <app-editor-iterables
              [config]="sortConfig"
              [ngModel]="attrSorting"
              style="font-size: 16px"
            ></app-editor-iterables>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="!data.useAsLink" [label]="'key_masterDetail' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="40">
              <mat-select
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.tableConfig.detailType"
              >
                <mat-option value="none">{{
                  'key_none' | translate
                }}</mat-option>
                <mat-option value="table">{{
                  'key_table' | translate
                }}</mat-option>
                <mat-option value="component">{{
                  'key_component' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              *ngIf="data.tableConfig.detailType === 'component'"
              fxFlex="40"
            >
              <mat-label>{{ 'key_componentID' | translate }}</mat-label>
              <input
                matInput
                [(ngModel)]="data.tableConfig.detailComponentID"
              />
            </mat-form-field>
            <mat-slide-toggle
              *ngIf="
                data.tableConfig.detailType === 'table' ||
                data.tableConfig.detailType === 'component'
              "
              fxFlex="20"
              [(ngModel)]="data.tableConfig.detailExpanded"
              style="margin-bottom: 20px; font-size: 16px"
            >
              {{ 'key_expandAll' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            *ngIf="data.tableConfig.detailType === 'component'"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxLayoutGap="20px"
          >
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'key_data' | translate }}</mat-label>
              <textarea
                matInput
                [(ngModel)]="data.tableConfig.detailComponentData"
              ></textarea>
            </mat-form-field>
          </div>
          <div
            *ngIf="data.tableConfig.detailType === 'table'"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="40">
              <mat-label>{{ 'key_attribute' | translate }}</mat-label>
              <input matInput [(ngModel)]="data.tableConfig.detailAttribute" />
            </mat-form-field>
            <mat-form-field fxFlex="60">
              <mat-label>{{ 'key_query' | translate }}</mat-label>
              <input matInput [(ngModel)]="data.tableConfig.detailQuery" />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              data.tableConfig.detailType === 'table' ||
              data.tableConfig.detailType === 'component'
            "
            fxLayout="column"
            fxLayoutAlign="start stretch"
          >
            <div
              *ngIf="data.tableConfig.detailType === 'table'"
              fxFlex="none"
              style="font-weight: 400; margin-bottom: 20px; margin-top: 15px"
            >
              {{ 'key_columnsToShow' | translate }}
            </div>
            <div
              *ngIf="data.tableConfig.detailType === 'table'"
              fxLayout="row"
              style="margin-bottom: 40px"
            >
              <div fxFlex="40" fxLayout="column">
                <div
                  fxFlex="auto"
                  dragula="COLUMNS"
                  [(dragulaModel)]="data.tableConfig.detailTableConfig.columns"
                  style="overflow-y: auto"
                >
                  <div
                    *ngFor="
                      let column of data.tableConfig.detailTableConfig.columns
                    "
                    fxLayout="row"
                    [ngClass]="{
                      'attribute-cloumn-selected':
                        selectedDetailColumnAttribute &&
                        column.field === selectedDetailColumnAttribute.field &&
                        column.reportType ===
                          selectedDetailColumnAttribute.reportType
                    }"
                    class="attribute-column"
                    style="padding-left: 10px; margin: 5px 0"
                  >
                    <div
                      fxFlex="auto"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      style="line-height: 34px; cursor: pointer"
                      (click)="onSelectDetailColumnAttribute(column)"
                    >
                      <div fxFlex="none">
                        {{ column.field }}
                      </div>
                      <div
                        *ngIf="column.reportType === 'recent'"
                        class="disabled-text-color"
                        style="line-height: 10px; margin-left: 10px"
                        [matTooltip]="'key_recent' | translate"
                      >
                        <span
                          class="material-icons-outlined"
                          style="font-size: 20px"
                          >history</span
                        >
                      </div>
                      <div
                        *ngIf="column.reportType === 'historical'"
                        class="disabled-text-color"
                        style="line-height: 10px; margin-left: 10px"
                        [matTooltip]="'key_historical' | translate"
                      >
                        <span
                          class="material-icons-outlined"
                          style="font-size: 18px"
                          >date_range</span
                        >
                      </div>
                    </div>
                    <button
                      fxFlex="none"
                      mat-icon-button
                      color="warn"
                      [disableRipple]="true"
                      [matTooltip]="'key_delete' | translate"
                      (click)="onDeleteDetailColumn(column)"
                    >
                      <mat-icon style="font-size: 20px; margin-top: -8px"
                        >delete_outline</mat-icon
                      >
                    </button>
                    <div
                      fxFlex="none"
                      class="handle"
                      style="width: 36px; cursor: pointer; color: lightgray"
                    >
                      <mat-icon style="line-height: 34px">menu</mat-icon>
                    </div>
                  </div>
                </div>
                <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
                  <mat-form-field
                    fxFlex="auto"
                    style="margin-bottom: -20px; margin-right: 0"
                  >
                    <input
                      matInput
                      [placeholder]="'key_addAttributeColumn' | translate"
                      [(ngModel)]="detailColumnToAdd"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="primary"
                    [disabled]="!detailColumnToAdd"
                    (click)="onAddDetailColumn()"
                  >
                    <mat-icon>playlist_add</mat-icon>
                  </button>
                </div>
              </div>
              <div fxFlex="60" class="attribute-column-setting">
                <div
                  *ngIf="selectedDetailColumnAttribute"
                  fxLayout="column"
                  fxLayoutGap="10px"
                  style="font-size: 12px; padding: 10px"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px"
                  >
                    <mat-form-field fxFlex="auto">
                      <input
                        matInput
                        [placeholder]="'key_field' | translate"
                        [(ngModel)]="selectedDetailColumnAttribute.field"
                      />
                    </mat-form-field>
                    <mat-slide-toggle
                      *ngIf="data.isHistorical"
                      fxFlex="33"
                      [disabled]="
                        reportTypeDisabled(
                          selectedDetailColumnAttribute.field,
                          data.tableConfig.detailTableConfig.columns
                        )
                      "
                      [ngModel]="
                        selectedDetailColumnAttribute.reportType ===
                        'historical'
                      "
                      (change)="
                        onReportValueChange(selectedDetailColumnAttribute)
                      "
                      >{{ 'key_historicalValue' | translate }}</mat-slide-toggle
                    >
                    <!-- <mat-form-field *ngIf="data.isHistorical" fxFlex="33">
                      <mat-select
                        [placeholder]="'key_valueType' | translate"
                        [(ngModel)]="selectedDetailColumnAttribute.reportType"
                      >
                        <mat-option
                          value="current"
                          [disabled]="
                            isReportTypeDisabled(
                              selectedDetailColumnAttribute.field,
                              'current',
                              data.tableConfig.detailTableConfig.columns
                            )
                          "
                          >{{ 'key_current' | translate }}</mat-option
                        >
                        <mat-option
                          value="recent"
                          [disabled]="
                            isReportTypeDisabled(
                              selectedDetailColumnAttribute.field,
                              'recent',
                              data.tableConfig.detailTableConfig.columns
                            )
                          "
                          >{{ 'key_recent' | translate }}</mat-option
                        >
                        <mat-option
                          value="historical"
                          [disabled]="
                            isReportTypeDisabled(
                              selectedDetailColumnAttribute.field,
                              'historical',
                              data.tableConfig.detailTableConfig.columns
                            )
                          "
                          >{{ 'key_historical' | translate }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field> -->
                  </div>
                  <mat-form-field>
                    <input
                      matInput
                      [placeholder]="'key_title' | translate"
                      [(ngModel)]="selectedDetailColumnAttribute.title"
                    />
                  </mat-form-field>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="33">
                      <mat-slide-toggle
                        [(ngModel)]="selectedDetailColumnAttribute.sortable"
                        [disabled]="data.isHistorical"
                        (change)="
                          onEnableSorting(selectedDetailColumnAttribute)
                        "
                        >{{ 'key_sortable' | translate }}</mat-slide-toggle
                      >
                    </div>
                    <div fxFlex="33">
                      <mat-slide-toggle
                        [(ngModel)]="selectedDetailColumnAttribute.locked"
                        [disabled]="data.isHistorical"
                        >{{ 'key_locked' | translate }}</mat-slide-toggle
                      >
                    </div>
                    <mat-form-field fxFlex="33">
                      <input
                        matInput
                        type="number"
                        min="-1"
                        max="800"
                        [placeholder]="'key_width' | translate"
                        [(ngModel)]="selectedDetailColumnAttribute.width"
                      />
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="33">
                      <mat-slide-toggle
                        [(ngModel)]="selectedDetailColumnAttribute.filterable"
                        [disabled]="data.isHistorical"
                        >{{ 'key_filterable' | translate }}</mat-slide-toggle
                      >
                    </div>
                    <div fxFlex="33">
                      <mat-slide-toggle
                        [disabled]="
                          data.isHistorical ||
                          !selectedDetailColumnAttribute.filterable
                        "
                        [(ngModel)]="selectedDetailColumnAttribute.isReference"
                        (change)="
                          onEnableReferenceFilter(selectedDetailColumnAttribute)
                        "
                        >{{ 'key_isReference' | translate }}</mat-slide-toggle
                      >
                    </div>
                    <mat-form-field fxFlex="33">
                      <mat-select
                        [disabled]="
                          data.isHistorical ||
                          !selectedDetailColumnAttribute.filterable
                        "
                        [placeholder]="'key_filterType' | translate"
                        [(ngModel)]="selectedDetailColumnAttribute.filter"
                      >
                        <mat-option value="text">text</mat-option>
                        <mat-option value="numeric">numeric</mat-option>
                        <mat-option value="boolean">boolean</mat-option>
                        <mat-option value="date">date</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="data.tableConfig.detailType === 'table'"
              fxFlex="none"
              style="font-weight: 400; margin-bottom: 20px"
            >
              {{ 'key_addSortingOptions' | translate }}
            </div>
            <app-editor-iterables
              *ngIf="data.tableConfig.detailType === 'table'"
              [config]="sortConfig"
              [ngModel]="attrDetailSorting"
              style="font-size: 16px"
            ></app-editor-iterables>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="!data.useAsLink && data.tableConfig.detailType === 'table'"
        [label]="'key_detailTable' | translate"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="10px"
          class="first-dialog-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_title' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.title"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_objectType' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.objectType"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="10"
                max="30"
                [placeholder]="'key_fontSize' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.fontSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="100"
                max="900"
                [placeholder]="'key_fontWeight' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.fontWeight"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="1"
                max="200"
                [placeholder]="'key_pageSize' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.pageSize"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="100"
                [placeholder]="'key_tableHeight' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.tableHeight"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="20"
                [placeholder]="'key_padding' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.cellPadding"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_formatDate' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.datetimeFormat"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="25">
              <mat-select
                [placeholder]="'key_scrollType' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.scrollMode"
              >
                <mat-option value="basic">{{
                  'key_basic' | translate
                }}</mat-option>
                <mat-option value="virtual">{{
                  'key_virtual' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                type="number"
                min="0"
                max="100"
                [placeholder]="'key_scrollHeight' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.scrollHeight"
                [disabled]="
                  data.tableConfig.detailTableConfig.scrollMode === 'basic'
                "
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_navigationKey' | translate"
                [(ngModel)]="data.tableConfig.detailTableConfig.navigationKey"
              />
            </mat-form-field>
            <mat-form-field fxFlex="25">
              <input
                matInput
                [placeholder]="'key_defaultLinkAction' | translate"
                [(ngModel)]="
                  data.tableConfig.detailTableConfig.defaultLinkAction
                "
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.tableConfig.detailTableConfig.linkDisabled"
            >
              {{ 'key_disableLink' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="25"
              [(ngModel)]="data.tableConfig.detailTableConfig.linkNoneForm"
            >
              {{ 'key_noneFormLink' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="padding-top: 20px"
          >
            <mat-form-field fxFlex="75">
              <mat-chip-list #clLinkActions>
                <mat-chip
                  *ngFor="
                    let action of data.tableConfig.detailTableConfig.linkActions
                  "
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveLinkAction(action)"
                  style="font-size: 10px"
                >
                  {{ action }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_linkActions' | translate"
                  [matChipInputFor]="clLinkActions"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddLinkAction($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="!data.useAsLink" [label]="'key_export' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.tableConfig.exportToClipBoard"
            >
              {{ 'key_exportToClipboard' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.tableConfig.exportToExcel"
            >
              {{ 'key_exportToExcel' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.tableConfig.exportAllPages"
            >
              {{ 'key_exportAllPages' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.tableConfig.exportReferenceId"
            >
              {{ 'key_exportReferenceId' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="33"
              [(ngModel)]="data.tableConfig.exportHeader"
              [disabled]="!data.tableConfig.exportToClipBoard"
            >
              {{ 'key_exportHeader' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="33">
              <input
                matInput
                [placeholder]="'key_separator' | translate"
                [(ngModel)]="data.tableConfig.exportSeparator"
                [disabled]="!data.tableConfig.exportToClipBoard"
              />
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            fxLayoutAlign="start center"
            style="margin-top: 10px"
          >
            <mat-form-field fxFlex="33">
              <input
                matInput
                type="number"
                min="100"
                max="5000"
                [placeholder]="'key_exportMaxCount' | translate"
                [(ngModel)]="data.tableConfig.exportMaxCount"
              />
            </mat-form-field>
          </div>
          <div fxFlex="none" style="margin-top: 40px">
            {{ 'key_attributesToExport' | translate }}
          </div>
          <div fxFlex="none" fxLayout="column" fxLayoutGap="20px">
            <mat-list fxFlex="none">
              <div>
                <mat-list-item
                  *ngFor="let attribute of data.tableConfig.exportAttributes"
                  style="
                    border: solid lightgray;
                    height: 38px;
                    margin-top: -2px;
                    padding-top: 2px;
                    color: gray;
                  "
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px"
                    style="width: 100%"
                  >
                    <div fxFlex="auto" style="padding-bottom: 6px">
                      {{ attribute }}
                    </div>
                    <div fxFlex="0 0 20px">
                      <mat-icon
                        (click)="onRemoveExportAttribute(attribute)"
                        style="cursor: pointer"
                        >clear</mat-icon
                      >
                    </div>
                  </div>
                </mat-list-item>
              </div>
            </mat-list>
            <div
              fxFlex="none"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="40px"
            >
              <mat-form-field fxFlex="auto">
                <mat-select
                  #cmbExportAttribute
                  [placeholder]="'key_attributeName' | translate"
                >
                  <mat-option
                    *ngFor="let col of data.tableConfig.columns"
                    [value]="col.field"
                    >{{ col.field }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <button
                fxFlex="120px"
                mat-stroked-button
                [disabled]="!cmbExportAttribute.value"
                (click)="onAddExportAttribute(cmbExportAttribute.value)"
                style="height: 36px"
              >
                {{ 'key_add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [disabled]="!formIsValid"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div
      *ngIf="historicalColumnErrorMessage"
      class="warn-color"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <span class="material-icons-outlined">error</span>
      <div style="font-size: 16px">{{ historicalColumnErrorMessage }}</div>
    </div>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title [win]="win" [title]="'key_reportConfig'"></app-popup-title>
</ng-template>
