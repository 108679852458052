import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

export class Language {
  public label: string;
  public code: string[];
  public route: string;
  public culture?: string;
  public enabled?: boolean;
  public default?: boolean;
  public icon?: string;
}

@Injectable()
export class TransService extends TranslateService {
  get currentCulture() {
    return this.currentLang.toLowerCase();
  }
  public supportedLanguages: Language[];
}
