import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { BroadcastEvent, SidebarItem } from '../core/models/dataContract.model';

import { ConfigService } from '../core/services/config.service';
import { SwapService } from '../core/services/swap.service';
import { ResourceService } from '../core/services/resource.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  maxSize = 260;

  @Input()
  minSize = 60;

  mode = 'expanded';
  currentSize = this.maxSize;
  icon = 'chevron_left';

  expandedImageSource = this.config.getConfigEx(
    'images:logoLarge',
    'assets/img/ocglogoblack.png'
  );
  expandedImageWidth = this.config.getConfigEx('images:logoLargeHeight', 46);

  collapsedImageSource = this.config.getConfigEx(
    'images:logoSmall',
    'assets/img/favicon.png'
  );
  collapsedImageWidth = this.config.getConfigEx('images:logoSmallHeight', 42);

  @Output()
  resized = new EventEmitter<string>();

  sidebarSettings: any;
  sidebarItems: Array<any> = [];

  constructor(
    private config: ConfigService,
    private router: Router,
    private swap: SwapService,
    private resource: ResourceService
  ) {
    const isFloating: boolean = this.config.getConfig('floatingSidebar', true);

    if (isFloating) {
      this.mode = 'collapsed';
      this.icon = 'chevron_right';
      this.currentSize = this.minSize;
    }

    this.subscription.add(
      this.swap.windowResized.subscribe((size: string) => {
        if (!isFloating) {
          switch (size) {
            case 'xs':
            case 'sm':
              this.mode = 'collapsed';
              this.icon = 'chevron_right';
              this.currentSize = this.minSize;
              break;
            case 'md':
            case 'lg':
            default:
              this.mode = 'expanded';
              this.icon = 'chevron_left';
              this.currentSize = this.maxSize;
              break;
          }
        }
      })
    );
  }

  ngOnInit() {
    this.sidebarSettings = this.config.getConfig('sidebarSettings', {
      backColor: 'white',
      frontColor: '#3c4858',
      iconColor: '#a9afbb',
    });

    this.sidebarItems = this.config
      .getConfig('sidebarItems', [])
      .filter((item: SidebarItem) => item.enabled !== false);

    this.sidebarItems = this.sidebarItems.map((item: any) => {
      if (!item.permissionSets || item.permissionSets.length === 0) {
        return item;
      } else {
        const found = this.resource.rightSets.some(
          (r) => item.permissionSets.indexOf(r) >= 0
        );
        if (!found) {
          item.enabled = false;
        }
        return item;
      }
    });

    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          tap((event: BroadcastEvent) => {
            if (
              event.name === 'pull-notification' &&
              event.parameter &&
              event.parameter.name
            ) {
              const pos = this.sidebarItems.findIndex(
                (s) =>
                  s.name.toLowerCase() === event.parameter.name.toLowerCase()
              );
              if (pos >= 0) {
                this.sidebarItems[pos].showBadge =
                  event.parameter.value !== null &&
                  event.parameter.value !== undefined &&
                  event.parameter.value !== 0;
                this.sidebarItems[pos].badgeSize = event.parameter.hideMessage
                  ? 'small'
                  : 'medium';
                this.sidebarItems[pos].badge = event.parameter.value;
              }
            }
          })
        )
        .subscribe()
    );

    this.swap.verifyWindowSize();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isFocusedItem(item: SidebarItem) {
    const retVal = this.router.url === '/app/' + item.path;
    return retVal;
  }

  resize() {
    if (this.mode === 'expanded') {
      this.mode = 'collapsed';
      this.icon = 'chevron_right';
      this.currentSize = this.minSize;
      this.resized.emit(this.mode);
    } else if (this.mode === 'collapsed') {
      this.mode = 'expanded';
      this.icon = 'chevron_left';
      this.currentSize = this.maxSize;
      this.resized.emit(this.mode);
    }
  }

  onAction(item: SidebarItem) {
    this.swap.sidebarEvent(new BroadcastEvent(item.name, item.path));
  }
}
